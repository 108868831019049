import React, { useState, useContext } from 'react';
import CheckBox from '..//CheckBox'
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { useForm, Controller } from "react-hook-form";
import { UserInputData } from './StartRopeFrame';
import Toggle, { TOGGLE_STATES } from '..//Toggle'
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';
import { Contents, FormTitle } from '../css/CSS.js';
import { useNavigate } from 'react-router-dom';
import useAuthAxios from "../../useAuthAxios";
import { ConfirmationContext } from "../../Confirmation";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import ja from 'date-fns/locale/ja'
import LoadingDialog from '../Loading';

export default function Kakunin2(props) {
    //各コンポーネントの動作を設定
    const validationRules = {
        jibanSonotaNote: { maxLength: { value: 6, message: '6桁以内で入力してください。' } },
        meterSuidoKoukei: { maxLength: { value: 2, message: '2桁以内で入力してください。' }, pattern: { value: /^[0-9]+$/, message: '半角数字のみで入力してください。' } },
        zandoShoriNote: { maxLength: { value: 30, message: '30桁以内で入力してください。' } },
    }

    const { currentState, setCurrentState } = useContext(UserInputData);
    const { control, handleSubmit, getValues, setValue, formState: { errors }, watch } = useForm({
        defaultValues: { ...currentState.kakunin },
        mode: 'onChange'
    });

    const [formNext, setFormNext] = useState(false)
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (check_kakuninShinseiDate === false) {
                if (formNext === true) {
                    props.handleNext();
                }
            }
        }
        setFormNext(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formNext]);

    const [formBack, setFormBack] = useState(false)
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (check_kakuninShinseiDate === false) {
                if (formBack === true) {
                    props.handleBack();
                }
            }
        }
        setFormBack(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formBack]);

    const { kojiCD, cutTeimei, changeTeimei } = useContext(ConfirmationContext);
    const authAxios = useAuthAxios();
    const navigate = useNavigate();

    const [formPost, setFormPost] = useState(false)
    const [processing_post, setProcessing_post] = useState(false);
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (check_kakuninShinseiDate === false) {
                if (formPost === true) {
                    setProcessing_post(true);
                    authAxios
                        .post('/api/confirmation/chakko/' + kojiCD + "?order=update", currentState)
                        .then(response => {
                            if (response.status === 200) {
                                navigate("/confirmation/list");
                                setProcessing_post(false);
                            }
                        }).catch((response) => {
                            alert("保存に失敗しました。", response.body);
                            setProcessing_post(false);
                        })
                }
            }
        }
        setFormPost(false);
        setTimeout(() => {
            setProcessing_post(false);
        }, 10000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formPost]);

    const onSubmit = (action) => {

        if (getValues("meterSuidoKoukei") === "") {
            setValue("meterSuidoKoukei", 0)
        }

        const kakunin = getValues();

        if (action === "post") {
            setFormPost(true);
            setCurrentState({ ...currentState, Kakunin2Enable: "true", kakunin, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
        } else if (action === "back") {
            setCurrentState({ ...currentState, Kakunin2Enable: "true", kakunin, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
            setFormBack(true)
        } else if (action === "next") {
            setCurrentState({ ...currentState, Kakunin2Enable: "true", kakunin, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
            setFormNext(true)
        }
    };


    const [value_kakuninShinseiDate, setkakuninShinseiDate] = React.useState("");

    const handleChange_kakuninShinseiDate = (newValue) => {
        setkakuninShinseiDate(newValue)
    }

    const [check_kakuninShinseiDate, setcheck_kakuninShinseiDate] = React.useState(false);
    const handleErr = (err) => {
        err === null ?
            setcheck_kakuninShinseiDate(false) : setcheck_kakuninShinseiDate(true)
    }

    return (
        <Contents component="main" >
            <form onSubmit={handleSubmit(onSubmit)} >
                <Grid container columns={12} columnSpacing={{ xs: 1, sm: 3, md: 3 }}>
                    <Grid>
                        <FormTitle >
                            確認事項2
                        </FormTitle>
                    </Grid>
                </Grid>
                <Grid container columns={12} rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 2 }}>
                    <Grid item xs={4} >
                        <Controller
                            control={control}
                            name="jibanKairyo"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1.5, pr: 2 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }} style={watch("jibanKairyo") === 2 ? { textDecoration: "line-through" } : {}} >地盤改良</Typography>}
                                    labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <Controller
                            control={control}
                            name="jibanKairyoUmu"
                            render={({ field: { value, onChange } }) => (
                                <Toggle
                                    setCheckedToggle={onChange}
                                    checkedToggle={value}
                                    disabled={watch("jibanKairyo") === 2 ? true : false}
                                    toggleValue={"有"}
                                    toggleSts={TOGGLE_STATES.Two}
                                    toggleValue2={"無"}
                                    toggleSts2={TOGGLE_STATES.One}
                                />
                            )}
                        />
                    </Grid>
                    <Grid container columns={12} sx={{ transform: "scale(1.1)", pl: 11, pt: 1 }}>
                        <Grid item xs={0.8} sx={{ pt: 0.5 }}>
                            <Controller
                                control={control}
                                name="jibanHyuoso"
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        control={
                                            <CheckBox
                                                setCheckedvalue={onChange}
                                                checkedvalue={value}
                                                color='primary'
                                                disabled={watch("jibanKairyo") === 2 ? true : watch("jibanKairyoUmu") === TOGGLE_STATES.One ? true : false}
                                            />
                                        }
                                        label={<Typography
                                            style={
                                                watch("jibanKairyo") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("jibanKairyoUmu") === TOGGLE_STATES.One ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : watch("jibanHyuoso") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                            : {}
                                            }
                                        > 表層</Typography>}

                                        labelPlacement="end"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={1} sx={{ pt: 0.5 }}>
                            <Controller
                                control={control}
                                name="jibanKoukankui"
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        control={
                                            <CheckBox
                                                setCheckedvalue={onChange}
                                                checkedvalue={value}
                                                color='primary'
                                                disabled={watch("jibanKairyo") === 2 ? true : watch("jibanKairyoUmu") === TOGGLE_STATES.One ? true : false}
                                            />
                                        }
                                        label={<Typography
                                            style={
                                                watch("jibanKairyo") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("jibanKairyoUmu") === TOGGLE_STATES.One ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : watch("jibanKoukankui") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                            : {}
                                            }
                                        >鋼管杭</Typography>}
                                        labelPlacement="end"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={1.8} sx={{ pt: 0.5 }}>
                            <Controller
                                control={control}
                                name="jibanTigerPile"
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        control={
                                            <CheckBox
                                                setCheckedvalue={onChange}
                                                checkedvalue={value}
                                                color='primary'
                                                disabled={watch("jibanKairyo") === 2 ? true : watch("jibanKairyoUmu") === TOGGLE_STATES.One ? true : false}
                                            />
                                        }
                                        label={<Typography
                                            style={
                                                watch("jibanKairyo") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("jibanKairyoUmu") === TOGGLE_STATES.One ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : watch("jibanTigerPile") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                            : {}
                                            }
                                        >タイガーパイル</Typography>}
                                        labelPlacement="end"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={2.2} sx={{ pt: 0.5 }}>
                            <Controller
                                control={control}
                                name="jibanKiseiConcreteKui"
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        control={
                                            <CheckBox
                                                setCheckedvalue={onChange}
                                                checkedvalue={value}
                                                color='primary'
                                                disabled={watch("jibanKairyo") === 2 ? true : watch("jibanKairyoUmu") === TOGGLE_STATES.One ? true : false}
                                            />
                                        }
                                        label={<Typography
                                            style={
                                                watch("jibanKairyo") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("jibanKairyoUmu") === TOGGLE_STATES.One ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : watch("jibanKiseiConcreteKui") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                            : {}
                                            }
                                        >既成コンクリート杭</Typography>}
                                        labelPlacement="end"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={2} sx={{ pt: 0.5 }}>
                            <Controller
                                control={control}
                                name="jibanPurePileKouho"
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        control={
                                            <CheckBox
                                                setCheckedvalue={onChange}
                                                checkedvalue={value}
                                                color='primary'
                                                disabled={watch("jibanKairyo") === 2 ? true : watch("jibanKairyoUmu") === TOGGLE_STATES.One ? true : false}
                                            />
                                        }
                                        label={<Typography
                                            style={
                                                watch("jibanKairyo") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("jibanKairyoUmu") === TOGGLE_STATES.One ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : watch("jibanPurePileKouho") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                            : {}
                                            }
                                        >ピュアパイル工法</Typography>}
                                        labelPlacement="end"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4} >
                            <Grid container columns={4}  >
                                <Grid item xs={0.91} sx={{ pt: 0.5 }}>
                                    <Controller
                                        control={control}
                                        name="jibanSonota"
                                        render={({ field: { onChange, value } }) => (
                                            <FormControlLabel
                                                control={
                                                    <CheckBox
                                                        setCheckedvalue={onChange}
                                                        checkedvalue={value}
                                                        color='primary'
                                                        disabled={watch("jibanKairyo") === 2 ? true : watch("jibanKairyoUmu") === TOGGLE_STATES.One ? true : false}
                                                    />
                                                }
                                                label={<Typography
                                                    style={
                                                        watch("jibanKairyo") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                            : watch("jibanKairyoUmu") === TOGGLE_STATES.One ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                                : watch("jibanSonota") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                                    : {}
                                                    }
                                                >その他</Typography>}
                                                labelPlacement="end"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={1.3} sx={{ pb: 1, mr: 1 }} >
                                    <Controller
                                        control={control}
                                        name="jibanSonotaNote"
                                        rules={validationRules.haichiHenko1Before}
                                        render={({ field, fieldState }) => (
                                            <
                                                TextField
                                                {...field}
                                                variant="standard"
                                                error={fieldState.invalid}
                                                inputProps={{ maxLength: 6 }}
                                                disabled={watch("jibanKairyo") === 2 ? true : watch("jibanKairyoUmu") === TOGGLE_STATES.One ? true : watch("jibanSonota") === 2 ? true : false}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container columns={20} rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 1, pl: 1.5 }} >
                        <Grid item xs={6.6}>
                            <Controller
                                control={control}
                                name="kakuninShinsei"
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        sx={{ transform: "scale(1.3)", pl: 1, pt: 1 }}
                                        control={
                                            <CheckBox
                                                setCheckedvalue={onChange}
                                                checkedvalue={value}
                                                color='primary'
                                            />
                                        }
                                        label={<Typography sx={{ pl: 1.1 }} style={watch("kakuninShinsei") === 2 ? { textDecoration: "line-through" } : {}}>確認申請</Typography>}
                                        labelPlacement="end"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} sx={{ ml: 1 }}>
                            <Controller
                                control={control}
                                name="kakuninShinseiSts"
                                render={({ field: { value, onChange } }) => (
                                    <Toggle
                                        setCheckedToggle={onChange}
                                        checkedToggle={value}
                                        disabled={watch("kakuninShinsei") === 2 ? true : false}
                                        toggleValue={"済"}
                                        toggleSts={TOGGLE_STATES.One}
                                        toggleValue2={"未"}
                                        toggleSts2={TOGGLE_STATES.Two}
                                        toggleValue3={"無（工事届）"}
                                        toggleSts3={TOGGLE_STATES.Three}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={7} >
                            <Controller
                                control={control}
                                name="kakuninShinseiDate"
                                render={({ field }) => (
                                    <FormControlLabel
                                        control={
                                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja} dateFormats={{ monthAndYear: 'yyyy年MM月' }}>
                                                <DesktopDatePicker
                                                    openTo="day"
                                                    views={['year', 'month', 'day']}
                                                    value={value_kakuninShinseiDate}
                                                    onChange={handleChange_kakuninShinseiDate}
                                                    inputFormat="yyyy/MM/dd"
                                                    inputProps={{ style: { padding: '6px' } }}
                                                    disabled={watch("kakuninShinsei") === 2 ? true : watch("kakuninShinseiSts") === TOGGLE_STATES.Two ? false : true}
                                                    onError={(err) => handleErr(err)}
                                                    {...field}
                                                    renderInput={(params) =>
                                                        <TextField {...params} sx={{ width: 170 }}
                                                            error={check_kakuninShinseiDate}
                                                        />}
                                                />
                                            </LocalizationProvider>
                                        }
                                        label={<Typography sx={{ pr: 0.5 }}
                                            style={
                                                watch("kakuninShinsei") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("kakuninShinseiSts") !== TOGGLE_STATES.Two ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                            }
                                        >下付予定</Typography>}
                                        labelPlacement="start"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container columns={12} rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 1, pl: 2.7 }}>
                        <Grid item xs={4}>
                            <Controller
                                control={control}
                                name="zandoShori"
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        sx={{ transform: "scale(1.3)", pt: 1.5, pl: 0.7 }}
                                        control={
                                            <CheckBox
                                                setCheckedvalue={onChange}
                                                checkedvalue={value}
                                                color='primary'
                                            />
                                        }
                                        label={<Typography sx={{ pl: 1.1 }} style={watch("zandoShori") === 2 ? { textDecoration: "line-through" } : {}}>残土処理の必要</Typography>}
                                        labelPlacement="end"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={2.4}>
                            <Controller
                                control={control}
                                name="zandoShoriUmu"
                                render={({ field: { value, onChange } }) => (
                                    <Toggle
                                        setCheckedToggle={onChange}
                                        checkedToggle={value}
                                        disabled={watch("zandoShori") === 2 ? true : false}
                                        toggleValue={"有"}
                                        toggleSts={TOGGLE_STATES.Two}
                                        toggleValue2={"無"}
                                        toggleSts2={TOGGLE_STATES.One}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={0.6} sx={{ pt: 2 }}>
                            <Typography sx={{ pt: 1 }}
                                style={watch("zandoShori") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : {}}
                            >メモ</Typography>
                        </Grid>
                        <Grid item xs={4} >
                            <Controller
                                control={control}
                                name="zandoShoriNote"
                                rules={validationRules.zandoShoriNote}
                                render={({ field, fieldState }) => (
                                    <
                                        TextField
                                        sx={{ pt: 0.5 }}
                                        {...field}
                                        style={{ width: '140%' }}
                                        variant="standard"
                                        error={fieldState.invalid}
                                        inputProps={{ maxLength: 30 }}
                                        disabled={watch("zandoShori") === 2 ? true : false}
                                    />
                                )}
                            />
                        </Grid>

                    </Grid>
                    <Grid container columns={12} rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 1, pl: 2.7 }}>
                        <Grid item xs={4} alignContent="flex-start">
                            <Controller
                                control={control}
                                name="senkoGaikoKoji"
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        sx={{ transform: "scale(1.3)", pt: 1.5, pl: 0.5 }}
                                        control={
                                            <CheckBox
                                                setCheckedvalue={onChange}
                                                checkedvalue={value}
                                                color='primary'
                                            />
                                        }
                                        label={<Typography sx={{ pl: 1.1 }} style={watch("senkoGaikoKoji") === 2 ? { textDecoration: "line-through" } : {}}>先行外構工事</Typography>}
                                        labelPlacement="end"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <Controller
                                control={control}
                                name="senkoGaikoKojiUmu"
                                render={({ field: { value, onChange } }) => (
                                    <Toggle
                                        setCheckedToggle={onChange}
                                        checkedToggle={value}
                                        disabled={watch("senkoGaikoKoji") === 2 ? true : false}
                                        toggleValue={"有"}
                                        toggleSts={TOGGLE_STATES.Two}
                                        toggleValue2={"無"}
                                        toggleSts2={TOGGLE_STATES.One}
                                    />
                                )}
                            />

                        </Grid>
                    </Grid>
                    <Grid container columns={16} sx={{ pt: 2, pl: 5.8 }}>
                        <Grid item xs={16} >
                            <Controller
                                control={control}
                                name="pouchIchi"
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        sx={{ transform: "scale(1.3)", pl: 1 }}
                                        control={
                                            <CheckBox
                                                setCheckedvalue={onChange}
                                                checkedvalue={value}
                                                color='primary'
                                            />
                                        }
                                        label={<Typography sx={{ pl: 1.1 }} style={watch("pouchIchi") === 2 ? { textDecoration: "line-through" } : {}}>ポーチ・カーポート・車庫　位置の確認</Typography>}
                                        labelPlacement="end"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={16}>
                        <Grid container columns={16} >
                            <Grid item xs={16} sx={{ pl: 1.9 }}>
                                <Controller
                                    control={control}
                                    name="meterIchi"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            sx={{ transform: "scale(1.3)", pl: 1 }}
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1.1 }} style={watch("meterIchi") === 2 ? { textDecoration: "line-through" } : {}}>各メーター位置及び引込位置確認</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ pt: 1 }}>
                                <Grid container columns={16} sx={{ transform: "scale(1.1)", pl: 3 }} >
                                    <Grid item xs={3}>
                                        <Typography sx={{ pt: 0.5, pl: 2.8 }}
                                            style={watch("meterIchi") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                : {}
                                            }
                                        >配置図記載</Typography>
                                    </Grid>
                                    <Grid item xs={3.7} sx={{ pt: 0.5 }}>
                                        <Controller
                                            control={control}
                                            name="meterSuido"
                                            render={({ field: { onChange, value } }) => (
                                                <FormControlLabel
                                                    control={
                                                        <CheckBox
                                                            setCheckedvalue={onChange}
                                                            checkedvalue={value}
                                                            color='primary'
                                                            disabled={watch("meterIchi") === 2 ? true : false}
                                                        />
                                                    }
                                                    label={<Typography
                                                        style={watch("meterIchi") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                            : watch("meterSuido") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                                : {}
                                                        }
                                                    >水道　メーター口径</Typography>}
                                                    labelPlacement="end"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={1.6}>
                                        <Controller
                                            control={control}
                                            name="meterSuidoKoukei"
                                            rules={validationRules.meterSuidoKoukei}
                                            render={({ field, fieldState }) => (
                                                <FormControlLabel
                                                    control={<
                                                        TextField
                                                        sx={{ pt: 0.2 }}
                                                        {...field}
                                                        style={{ width: '30%' }}
                                                        variant="standard"
                                                        error={fieldState.invalid}
                                                        inputProps={{ maxLength: 2, style: { textAlign: 'right' } }}
                                                        disabled={watch("meterIchi") === 2 ? true : watch("meterSuido") === 2 ? true : false}
                                                    />}
                                                    label={<Typography sx={{ pl: 1, }}
                                                        style={watch("meterIchi") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                            : watch("meterSuido") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                                : {}
                                                        }
                                                    >Φ</Typography>}
                                                    labelPlacement="end"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={2.64} sx={{ pt: 0.5 }}>
                                        <Controller
                                            control={control}
                                            name="meterDenki"
                                            render={({ field: { onChange, value } }) => (
                                                <FormControlLabel
                                                    control={
                                                        <CheckBox
                                                            setCheckedvalue={onChange}
                                                            checkedvalue={value}
                                                            color='primary'
                                                            disabled={watch("meterIchi") === 2 ? true : false}
                                                        />
                                                    }
                                                    label={<Typography
                                                        style={watch("meterIchi") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                            : watch("meterDenki") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                                : {}
                                                        }
                                                    >電気</Typography>}
                                                    labelPlacement="end"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={2} sx={{ pt: 0.5 }}>
                                        <Controller
                                            control={control}
                                            name="meterGas"
                                            render={({ field: { onChange, value } }) => (
                                                <FormControlLabel
                                                    control={
                                                        <CheckBox
                                                            setCheckedvalue={onChange}
                                                            checkedvalue={value}
                                                            color='primary'
                                                            disabled={watch("meterIchi") === 2 ? true : false}
                                                        />
                                                    }
                                                    label={<Typography
                                                        style={watch("meterIchi") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                            : watch("meterGas") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                                : {}
                                                        }
                                                    >ガス</Typography>}
                                                    labelPlacement="end"
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={16} >
                        <Grid container columns={16} >
                            <Grid item xs={16} sx={{ pl: 0.5 }}>
                                <Controller
                                    control={control}
                                    name="kyuhaisuiRoute"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            sx={{ transform: "scale(1.3)" }}
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1.1 }} style={watch("kyuhaisuiRoute") === 2 ? { textDecoration: "line-through" } : {}}>給排水管ルート</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={16} sx={{ pt: -1 }}>
                                <Grid container columns={16} sx={{ pl: 5, pt: 1, transform: "scale(1.1)" }}>
                                    <Grid item xs={2.2}>
                                        <Typography sx={{ pl: 2.3 }}
                                            style={watch("kyuhaisuiRoute") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                : {}
                                            }
                                        >配置図記載</Typography>
                                    </Grid>
                                    <Grid item xs={2} >
                                        <Controller
                                            control={control}
                                            name="kyuhaisuiUsuiMas"
                                            render={({ field: { onChange, value } }) => (
                                                <FormControlLabel
                                                    control={
                                                        <CheckBox
                                                            setCheckedvalue={onChange}
                                                            checkedvalue={value}
                                                            color='primary'
                                                            disabled={watch("kyuhaisuiRoute") === 2 ? true : false}
                                                        />
                                                    }
                                                    label={<Typography
                                                        style={watch("kyuhaisuiRoute") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                            : watch("kyuhaisuiUsuiMas") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                                : {}
                                                        }
                                                    >雨水マス</Typography>}
                                                    labelPlacement="end"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={2} >
                                        <Controller
                                            control={control}
                                            name="kyuhaisuiOsuiMas"
                                            render={({ field: { onChange, value } }) => (
                                                <FormControlLabel
                                                    control={
                                                        <CheckBox
                                                            setCheckedvalue={onChange}
                                                            checkedvalue={value}
                                                            color='primary'
                                                            disabled={watch("kyuhaisuiRoute") === 2 ? true : false}
                                                        />
                                                    }
                                                    label={<Typography
                                                        style={watch("kyuhaisuiRoute") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                            : watch("kyuhaisuiOsuiMas") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                                : {}
                                                        }
                                                    >汚水マス</Typography>}
                                                    labelPlacement="end"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Controller
                                            control={control}
                                            name="kyuhaisuiSaishuMas"
                                            render={({ field: { onChange, value } }) => (
                                                <FormControlLabel
                                                    control={
                                                        <CheckBox
                                                            setCheckedvalue={onChange}
                                                            checkedvalue={value}
                                                            color='primary'
                                                            disabled={watch("kyuhaisuiRoute") === 2 ? true : false}
                                                        />
                                                    }
                                                    label={<Typography
                                                        style={watch("kyuhaisuiRoute") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                            : watch("kyuhaisuiSaishuMas") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                                : {}
                                                        }
                                                    >最終マス</Typography>}
                                                    labelPlacement="end"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={1.5}>
                                        <Controller
                                            control={control}
                                            name="kyuhaisuiJokasou"
                                            render={({ field: { onChange, value } }) => (
                                                <FormControlLabel
                                                    control={
                                                        <CheckBox
                                                            setCheckedvalue={onChange}
                                                            checkedvalue={value}
                                                            color='primary'
                                                            disabled={watch("kyuhaisuiRoute") === 2 ? true : false}
                                                        />
                                                    }
                                                    label={<Typography
                                                        style={watch("kyuhaisuiRoute") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                            : watch("kyuhaisuiJokasou") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                                : {}
                                                        }
                                                    >浄化槽</Typography>}
                                                    labelPlacement="end"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={1.5}>
                                        <Controller
                                            control={control}
                                            name="kyuhaisuiRissuisen"
                                            render={({ field: { onChange, value } }) => (
                                                <FormControlLabel
                                                    control={
                                                        <CheckBox
                                                            setCheckedvalue={onChange}
                                                            checkedvalue={value}
                                                            color='primary'
                                                            disabled={watch("kyuhaisuiRoute") === 2 ? true : false}
                                                        />
                                                    }
                                                    label={<Typography
                                                        style={watch("kyuhaisuiRoute") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                            : watch("kyuhaisuiRissuisen") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                                : {}
                                                        }
                                                    >立水栓</Typography>}
                                                    labelPlacement="end"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={1.5}>
                                        <Controller
                                            control={control}
                                            name="kyuhaisuiSansuisen"
                                            render={({ field: { onChange, value } }) => (
                                                <FormControlLabel
                                                    control={
                                                        <CheckBox
                                                            setCheckedvalue={onChange}
                                                            checkedvalue={value}
                                                            color='primary'
                                                            disabled={watch("kyuhaisuiRoute") === 2 ? true : false}
                                                        />
                                                    }
                                                    label={<Typography
                                                        style={watch("kyuhaisuiRoute") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                            : watch("kyuhaisuiSansuisen") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                                : {}
                                                        }
                                                    >散水栓</Typography>}
                                                    labelPlacement="end"
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="acShitugaiki"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pr: 0.6 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1.1 }} style={watch("acShitugaiki") === 2 ? { textDecoration: "line-through" } : {}}>AC室外機</Typography>}
                                    labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="ecoCuteEneFarm"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pl: 2.1 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1.1 }} style={watch("ecoCuteEneFarm") === 2 ? { textDecoration: "line-through" } : {}}>エコキュート・エネファーム</Typography>}
                                    labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid container columns={16} rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 3 }}>
                        <Grid item xs={12} >
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("post")} disabled={processing_post}>
                                {processing_post ? "処理中" : "保存して一覧へ"}
                            </Button>
                            <LoadingDialog load={processing_post} />
                        </Grid>
                        <Grid item xs={2} sx={{ pr: 1 }}>
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("back")} >
                                戻る
                            </Button>
                        </Grid>
                        <Grid item xs={1.5} sx={{ pl: 1 }}>
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("next")}>
                                次へ
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form >
        </Contents >
    );
}