import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import { SelectContractTitle, SelectContractContent, NomalButton, GrayButton } from '../../css/CSS.js';

export default function DigitalConstractListDelConfirm(props) {
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
        props.setConfirmation(false)
    };

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const handleOpen = () => {
        setOpen(false)
        props.setDeleteCheck(true)
    }

    return (
        <React.Fragment>
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    fullWidth
                >
                    <SelectContractTitle id="alert-dialog-title">
                        <Grid container columns={12} rowSpacing={{ xs: 3 }} columnSpacing={4} >
                            <Grid item xs={11} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                {props.contractType === "base" ? "工事請負契約書" : props.contractType === "modfy" ? "変更工事請負契約書" : null}削除
                            </Grid>
                            <Grid item xs={1} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                <ClearIcon fontSize="large" onClick={() => handleClose()} />
                            </Grid>
                        </Grid>
                    </SelectContractTitle>
                    <SelectContractContent >
                        <Grid container columns={12} rowSpacing={{ xs: 3 }} columnSpacing={1} sx={{ pt: 3 }}>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography>
                                    下記、{props.contractType === "base" ? "工事請負契約書" : props.contractType === "modfy" ? "変更工事請負契約書" : null}を削除します。<br />
                                    工事コード:{props.kojiCD}
                                </Typography>
                            </Grid>
                        </Grid>
                    </SelectContractContent>
                    <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <GrayButton type="button" onClick={handleClose} sx={{ mr: 5 }}><CloseIcon sx={{ mr: 1 }} />キャンセル</GrayButton>
                        <NomalButton type="button" onClick={handleOpen} style={{ width: "200px" }}><DeleteForeverIcon sx={{ mr: 1 }} />削除</NomalButton>
                    </DialogActions>
                </Dialog>
            </div>
        </React.Fragment>
    );
}