import React, { useState, useContext } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import { UserInputData } from './JyotoFrame';
import Button from '@mui/material/Button';
import { Contents, FormTitle } from '../css/CSS.js';
import useAuthAxios from "../../useAuthAxios";
import { ConfirmationContext } from "../../Confirmation";
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import CheckBox from '../CheckBox'
import LoadingDialog from '../Loading';

export default function TuikaKojiKanri(props) {

    const validationRules = {
        tuikaHenko: {
            maxLength: { value: 155, message: '152文字以内で入力してください。' },
            validate: (value) => {
                // 1行38文字以内
                for (let v of value.split("\n")) {
                    if (v.length > 38) return "1行38文字以内で入力してください。";
                }
                // 最大4行
                if (value.split("\n").length > 4) {
                    return "4行以内で入力してください。";
                }
            }
        },
    }

    const { currentState, setCurrentState } = useContext(UserInputData);
    const { control, handleSubmit, getValues, formState: { errors }, watch } = useForm({
        defaultValues: { ...currentState.tuikaHenko, ...currentState.kojiKanriHoukokusho },
        mode: 'onChange',
    });

    const [formBack, setFormBack] = useState(false)
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (formBack === true) {
                props.handleBack();
            }
        }
        setFormBack(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formBack]);
    const { kojiCD, cutTeimei, changeTeimei } = useContext(ConfirmationContext);
    const authAxios = useAuthAxios();

    const navigate = useNavigate();

    const [processing_post, setProcessing_post] = useState(false);
    const [formPost, setFormPost] = useState(false)
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (formPost === true) {
                setProcessing_post(true)
                authAxios
                    .post('/api/confirmation/jyoto/' + kojiCD + "?order=update", currentState)
                    .then(response => {
                        if (response.status === 200) {
                            navigate("/confirmation/list");
                            setProcessing_post(false)
                        }
                    }).catch((response) => {
                        alert("保存に失敗しました。", response.body);
                        setProcessing_post(false)
                    })
            }
        }
        setFormPost(false);
        setTimeout(() => {
            setProcessing_post(false);
        }, 10000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formPost]);

    const [processing_save, setProcessing_save] = useState(false);
    const [formSave, setFormSave] = useState(false)
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (formSave === true) {
                setProcessing_save(true)
                authAxios
                    .post('/api/confirmation/jyoto/' + kojiCD + "?order=update", currentState)
                    .then(response => {
                        if (response.status === 200) {
                            props.handleSave(true);
                            setProcessing_save(true)
                        }
                    }).catch((response) => {
                        alert("保存に失敗しました。", response.body);
                        setProcessing_save(true)
                    })
            }
        }
        setFormSave(false);
        setTimeout(() => {
            setProcessing_save(false);
        }, 10000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formSave]);

    const onSubmit = (action) => {
        const tuikaHenko = { "tuikaHenkoKoji": getValues("tuikaHenkoKoji"), "tuikaHenkoKojiNote": getValues("tuikaHenkoKojiNote") }
        const kojiKanriHoukokusho = { "kojiKanriHoukokusho": getValues("kojiKanriHoukokusho"), "kojiKanriHoukokushoWatasiHaikin": getValues("kojiKanriHoukokushoWatasiHaikin"), "kojiKanriHoukokushoWatasiJyoto": getValues("kojiKanriHoukokushoWatasiJyoto") }
        if (action === "post") {
            setCurrentState({ ...currentState, tuikaHenko, kojiKanriHoukokusho, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
            setFormPost(true)
        } else if (action === "save") {
            setCurrentState({ ...currentState, tuikaHenko, kojiKanriHoukokusho, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
            setFormSave(true);
        } else if (action === "back") {
            setCurrentState({ ...currentState, tuikaHenko, kojiKanriHoukokusho, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
            setFormBack(true)
        }
    };

    return (
        <Contents component="main" sx={{ pt: 1 }}>
            <form onSubmit={handleSubmit(onSubmit)} >
                <Grid container columns={12} columnSpacing={{ xs: 1, sm: 3, md: 3 }}>
                    <Grid>
                        <FormTitle>
                            追加・変更工事
                        </FormTitle>
                    </Grid>
                </Grid>
                <Grid container columns={12} >
                    <Grid container columns={12} rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 0.5 }}>
                        <Grid item xs={2.8} sx={{ pl: 1 }}>
                            <Controller
                                control={control}
                                name="tuikaHenkoKoji"
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        sx={{ transform: "scale(1.3)", pt: 1.5, pl: 1.3 }}
                                        control={
                                            <CheckBox
                                                setCheckedvalue={onChange}
                                                checkedvalue={value}
                                                color='primary'
                                            />
                                        }
                                        label={<Typography sx={{ pl: 1 }}
                                            style={watch("tuikaHenkoKoji") === 2 ? { textDecoration: "line-through" } : {}
                                            }
                                        >追加・変更工事の確認</Typography>}
                                        labelPlacement="end"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={9.2} sx={{ display: 'flex', alignItems: 'end' }}>
                            <Typography sx={{ transform: "scale(1.1)" }}
                                style={watch("tuikaHenkoKoji") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : {}}
                            >(内容により後日、追加変更発注書をお送りさせていただきます)</Typography>
                        </Grid>
                    </Grid>

                    <Grid container columns={16} sx={{ pl: 1 }}>
                        <Controller
                            control={control}
                            name="tuikaHenkoKojiNote"
                            rules={validationRules.tuikaHenko}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    style={{ width: '62%' }}
                                    margin="normal"
                                    multiline
                                    minRows={4}
                                    maxRows={4}
                                    variant="outlined"
                                    error={errors.tuikaHenkoKojiNote}
                                    helperText={errors.tuikaHenkoKojiNote && errors.tuikaHenkoKojiNote?.message}
                                    inputProps={{ maxLength: 155 }}
                                    disabled={watch("tuikaHenkoKoji") === 2 ? true : false}
                                    placeholder="38文字、4行まで入力できます。"
                                />
                            )}
                        />
                    </Grid>

                    <Grid container columns={12} columnSpacing={{ xs: 1, sm: 3, md: 3 }}>
                        <FormTitle>
                            工事監理報告書
                        </FormTitle>
                    </Grid>
                    <Grid container columns={12} sx={{ pt: 0.5, pl: 0.6 }}>
                        <Grid item xs={3} >
                            <Controller
                                control={control}
                                name="kojiKanriHoukokusho"
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        sx={{ transform: "scale(1.3)", pt: 1.5, }}
                                        control={
                                            <CheckBox
                                                setCheckedvalue={onChange}
                                                checkedvalue={value}
                                                color='primary'
                                            />
                                        }
                                        label={<Typography sx={{ pl: 1 }}
                                            style={watch("kojiKanriHoukokusho") === 2 ? { textDecoration: "line-through" } : {}
                                            }
                                        >工事監理報告書</Typography>} labelPlacement="end"

                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={16} alignContent="flex-start">
                            <Grid container columns={16} columnSpacing={{ xs: 1, sm: 2, md: 1 }} sx={{ transform: "scale(1.1)", pt: 1, pl: 8.5 }}>
                                <Grid item xs={1.5} >
                                    <Controller
                                        control={control}
                                        name="kojiKanriHoukokushoWatasiHaikin"
                                        render={({ field: { onChange, value } }) => (
                                            <FormControlLabel
                                                control={
                                                    <CheckBox
                                                        setCheckedvalue={onChange}
                                                        checkedvalue={value}
                                                        color='primary'
                                                        disabled={watch("kojiKanriHoukokusho") === 2 ? true : false}
                                                    />
                                                }
                                                label={<Typography sx={{ pl: 1 }}
                                                    style={watch("kojiKanriHoukokusho") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : watch("kojiKanriHoukokushoWatasiHaikin") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                            : {}
                                                    }
                                                >配筋時</Typography>}
                                                labelPlacement="end"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={1.4} >
                                    <Controller
                                        control={control}
                                        name="kojiKanriHoukokushoWatasiJyoto"
                                        render={({ field: { onChange, value } }) => (
                                            <FormControlLabel
                                                control={
                                                    <CheckBox
                                                        setCheckedvalue={onChange}
                                                        checkedvalue={value}
                                                        color='primary'
                                                        disabled={watch("kojiKanriHoukokusho") === 2 ? true : false}
                                                    />
                                                }
                                                label={<Typography sx={{ pl: 1 }}
                                                    style={watch("kojiKanriHoukokusho") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : watch("kojiKanriHoukokushoWatasiJyoto") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                            : {}
                                                    }
                                                >上棟時</Typography>}
                                                labelPlacement="end"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={4} sx={{ pt: 0.1 }}>
                                    <Typography
                                        style={watch("kojiKanriHoukokusho") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                            : watch("kojiKanriHoukokushoWatasiHaikin") === 2 && watch("kojiKanriHoukokushoWatasiJyoto") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                : {}
                                        }
                                    >お渡しさせて頂きました。</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container columns={12} rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 2 }}>
                        <Grid container columns={16} rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 5 }}>
                            <Grid item xs={11} >
                                <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("post")} disabled={processing_post}>
                                    {processing_post ? "処理中" : "保存して一覧へ"}
                                </Button>
                                <LoadingDialog load={processing_post} />
                            </Grid>
                            <Grid item xs={2} >
                                <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("back")} >
                                    戻る
                                </Button>
                            </Grid>
                            <Grid item xs={3} >
                                <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("save")} disabled={processing_save}>
                                    {processing_save ? "処理中" : "確認画面へ"}
                                </Button>
                                <LoadingDialog load={processing_save} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Contents >
    );
}
