import React, { useState, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import { SelectContractContent, SelectContractTitle, ReturnStatusButton, AdvancedStatusButton } from '../../css/CSS.js';
import useAuthAxios from "../../../useAuthAxios";
import { useNavigate } from 'react-router-dom';
import LoadingDialog from '../../Loading';
import Typography from '@mui/material/Typography';
import AssignmentReturnedOutlinedIcon from '@mui/icons-material/AssignmentReturnedOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import UndoIcon from '@mui/icons-material/Undo';
import { DigitalConstractsContext } from "../../../DigitalConstracts";

export default function DigitalConstractApprovedInternal(props) {
    const [open, setOpen] = React.useState(false);
    const authAxios = useAuthAxios();
    const navigate = useNavigate();
    const [processing, setProcessing] = useState(false);


    const headers = { "Authorization": "Bearer " + localStorage.getItem("Auth"), };
    const { kojiCD, seqNo, contractType, setStatusCheck } = useContext(DigitalConstractsContext);

    const handleClose = () => {
        setOpen(false);
        props.close(false)
    };

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const [rejected, setRejected] = React.useState(false);
    const handleRejected = () => {
        setRejected(true)
    };
    React.useEffect(() => {
        if (processing === true) { return }
        if (rejected === true) {
            setProcessing(true)
            setStatusCheck(false)
            authAxios
                .post('api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + '/approval?action=rejected'
                    , { approverList: null }
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        setOpen(false);
                        props.close(false)
                        setRejected(false)
                        setProcessing(false)
                        setStatusCheck(true)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        alert("承認差戻しに失敗しました。");
                        setRejected(false)
                        setProcessing(false)
                        setOpen(false);
                        props.close(false)
                    }
                    setProcessing(false)
                });

            setTimeout(() => {
            }, 10000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rejected]);

    const [approved, setApproved] = React.useState(false);
    const handleApproved = () => {
        setApproved(true)
    };

    React.useEffect(() => {
        if (processing === true) { return }
        if (approved === true) {
            setProcessing(true)
            setStatusCheck(false)
            authAxios
                .post('api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + '/approval?action=approved'
                    , { approverList: null }
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        setApproved(false)
                        setProcessing(false)
                        setStatusCheck(true)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        alert("承認に失敗しました。");
                    }
                    setApproved(false)
                    setProcessing(false)
                });

            setTimeout(() => {
            }, 10000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approved]);

    return (
        <React.Fragment>
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    fullWidth
                    maxWidth="md"
                >
                    <LoadingDialog load={processing} />
                    <SelectContractTitle >
                        <Grid container columns={12} rowSpacing={{ xs: 3 }} columnSpacing={4} >
                            <Grid item xs={11} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                社内承認
                            </Grid>
                            <Grid item xs={1} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                <ClearIcon fontSize="large" onClick={() => handleClose()} />
                            </Grid>
                        </Grid>
                    </SelectContractTitle>
                    <SelectContractContent>
                        <Grid container columns={12} columnSpacing={1} sx={{ pt: 3 }}>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography sx={{ fontSize: 20 }} >この請負契約書の社内承認を行い、支店長名を受託者欄に記名します。</Typography>
                            </Grid>
                        </Grid>
                    </SelectContractContent>
                    <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Grid sx={{ mr: 5 }}>
                            <ReturnStatusButton sx={{ width: "250px" }} type="button" onClick={handleRejected}>
                                <UndoIcon sx={{ mr: 1 }} />
                                差戻す
                            </ReturnStatusButton>
                        </Grid >
                        <Grid >
                            <AdvancedStatusButton sx={{ width: "250px" }} onClick={() => handleApproved()} >
                                <AssignmentReturnedOutlinedIcon sx={{ mr: 1 }} />承認
                            </AdvancedStatusButton>
                        </Grid >
                    </DialogActions>
                </Dialog>
            </div>
        </React.Fragment>
    );
}