import React, { useState, useRef, useContext } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SignaturePad from 'react-signature-canvas'
import useAuthAxios from "../../../useAuthAxios";
import { ConstructSign, NomalButton, GrayButton, SquareButton } from '../../css/CSS.js';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import UndoIcon from '@mui/icons-material/Undo';
import CreateIcon from '@mui/icons-material/Create';
import { useNavigate } from 'react-router-dom';
import { DigitalConstractsContext } from "../../../DigitalConstracts";

export default function DigitalConstractSignature(props) {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const [imageURL, setImageURL] = useState(null);
    const sigCanvas = useRef({});
    const authAxios = useAuthAxios()
    const headers = { "Authorization": "Bearer " + localStorage.getItem("Auth") };
    const { kojiCD, seqNo, contractType, customerNo } = useContext(DigitalConstractsContext);

    const [canvasWidth, setCanvasWidth] = useState(1000);
    const [canvasHeight, setCanvasHeight] = useState(250);
    const canvasSize = { width: canvasWidth + "px", height: canvasHeight + "px" }
    const handleClickOpen = () => {
        setOpen(true);
        setTimeout(() => {
            sigCanvas.current.fromDataURL(imageURL, canvasSize);
        }, 200);
    };
    const handleClose = () => { setOpen(false); };
    const clear = () => sigCanvas.current.clear();

    React.useLayoutEffect(() => {
        if (customerNo) {
            authAxios
                .get('/api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + "/customer/sign"
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        setOpen(false)
                        if (customerNo === "1") {
                            if (response.data.customer1Sign == null) { setImageURL(response.data.customer1Sign) }
                            else {
                                setImageURL("data:image/png;base64," + response.data.customer1Sign)
                            }
                        } else if (customerNo === "2") {
                            if (response.data.customer2Sign == null) { setImageURL(response.data.customer2Sign) }
                            else {
                                setImageURL("data:image/png;base64," + response.data.customer2Sign)
                            }
                        } else if (customerNo === "3") {
                            if (response.data.customer3Sign == null) { setImageURL(response.data.customer3Sign) }
                            else {
                                setImageURL("data:image/png;base64," + response.data.customer3Sign)
                            }
                        } else if (customerNo === "4") {
                            if (response.data.customer4Sign == null) { setImageURL(response.data.customer4Sign) }
                            else {
                                setImageURL("data:image/png;base64," + response.data.customer4Sign)
                            }
                        }
                    } else if (response.status === 401) {
                        navigate("/");
                    }
                }).catch((response) => {
                    alert("サイン取得に失敗しました。", response.body);
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerNo]);

    const save = () => {
        setImageURL(sigCanvas.current.toDataURL("image/png"));
        props.setSignData(sigCanvas.current.toDataURL("image/png"))
        setOpen(false)
    }

    //const [activeDelete, setActiveDelete] = useState(false);
    //const deleteSign = () => {
    //    setActiveDelete(true)
    //    setOpen(false)
    //}

    //React.useEffect(() => {
    //    if (activeDelete === true) {
    //        authAxios
    //            .delete('/api/confirmation/chakko/' + kojiCD + '/sign?signType=custmer')
    //            .then(response => {
    //                if (response.status === 200) {
    //                    setActiveDelete(false)
    //                    //setSignCustomer(false)
    //                    setImageURL(null)
    //                    setOpen(false)
    //                }
    //            }).catch((response) => {
    //                alert("削除に失敗しました。", response.body);
    //                setActiveDelete(false)
    //                setOpen(false)
    //                //setSignCustomer(false)
    //            })
    //    }
    //    // eslint-disable-next-line react-hooks/exhaustive-deps
    //}, [activeDelete]);

    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width, height
        };
    }
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    React.useEffect(() => {
        const onResize = () => {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, []);

    React.useEffect(() => {
        if (windowDimensions.width < canvasWidth) {
            setCanvasHeight(200)
            setCanvasWidth(800)
        } else {
            setCanvasHeight(250)
            setCanvasWidth(1000)
        }
    }, [windowDimensions]);

    return (
        <React.Fragment>
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="xl"
                >
                    <Grid container columns={12} sx={{ pt: 2, bgcolor: "#EEEEEE" }}>
                        <Grid item xs={11} sx={{ bgcolor: "#EEEEEE" }}>
                            <DialogTitle id="alert-dialog-title" sx={{ bgcolor: "#EEEEEE" }}>
                                {"お客様サイン"}
                            </DialogTitle>
                        </Grid >
                        <Grid item xs={1} sx={{ bgcolor: "#EEEEEE" }}>
                            <DialogActions sx={{ bgcolor: "#EEEEEE" }}>
                                <Button onClick={handleClose} ><CloseIcon fontSize="large" style={{ color: "#000000" }} /></Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                    <DialogContent sx={{ bgcolor: "#EEEEEE" }}>
                        <ConstructSign sx={{ width: canvasSize.width, height: canvasSize.height }}>
                            <SignaturePad
                                ref={sigCanvas}
                                canvasProps={canvasSize}
                                minWidth={0.5}
                                maxWidth={4.0}
                                dotSize={3.0}
                                minDistance={1.0}
                                throttle={16}
                                velocityFilterWeight={0.7}
                                clearOnResize={false}
                            />
                        </ConstructSign>
                    </DialogContent>
                    <DialogActions sx={{ bgcolor: "#EEEEEE" }}>
                        <Grid container columns={12} rowSpacing={{ xs: 2 }} columnSpacing={1}>
                            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'start', alignItems: 'end' }}>
                                <GrayButton sx={{ width: "200px" }} onClick={clear}><UndoIcon sx={{ mr: 1 }} />クリア</GrayButton>
                            </Grid>
                            {/*削除ボタン*/}
                            {/*<Grid item xs={7.5} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>*/}
                            {/*    <CautionButton onClick={deleteSign}><DeleteForeverIcon sx={{ mr: 1 }} />削除</CautionButton>*/}
                            {/*</Grid>*/}
                            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                <NomalButton sx={{ width: "250px", height: "80px", fontSize: 25 }} onClick={save} >
                                    <DownloadDoneIcon sx={{ mr: 1 }} />
                                    保存
                                </NomalButton>
                            </Grid>

                        </Grid>
                    </DialogActions>
                </Dialog>
                {imageURL ? (
                    <Grid container columns={12} >
                        <Grid item xs={10} style={{
                            maxWidth: "100%",
                            height: "100px",
                            backgroundColor: "#ffffff",
                            border: "1px solid",
                            borderColor: "#e9e9e9",
                        }}>
                            <img
                                src={imageURL}
                                alt="Customer"
                                style={{
                                    display: "block",
                                    padding: "5px",
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                }}

                            />
                        </Grid>
                        <Grid item xs={2}>
                            <SquareButton type="button" onClick={handleClickOpen} disabled={props.disabled}>
                                <CreateIcon sx={{ mr: 1 }} />
                                サイン
                            </SquareButton>
                        </Grid>
                    </Grid>

                ) :
                    <Grid container columns={12} >
                        <Grid item xs={10}
                            style={{
                                height: "100px", width: "100%", backgroundColor: "#ffffff"
                                , border: "1px solid",
                                borderColor: "#e9e9e9",
                            }}>
                        </Grid>
                        <Grid item xs={2}>
                            <SquareButton type="button" onClick={handleClickOpen} disabled={props.disabled}>
                                <CreateIcon sx={{ mr: 1 }} />
                                サイン
                            </SquareButton>
                        </Grid>
                    </Grid>
                }
            </div>
        </React.Fragment>
    );
}