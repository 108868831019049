import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { Menu, MenuText } from './css/CSS.js';

export default function TemporaryDrawer() {
    const [state, setState] = React.useState({
        left: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    return (
        <div >
            {['left'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <MenuIcon
                        size="large"
                        edge="start"
                        color="inherit"
                        onClick={toggleDrawer(anchor, true)}>
                    </MenuIcon>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        <Box
                            sx={{ width: 250 }}
                            role="presentation"
                            onClick={toggleDrawer(anchor, false)}
                            onKeyDown={toggleDrawer(anchor, false)}
                        >
                            <Menu>
                                <ListItem key={"TOP"} disablePadding>
                                    <ListItemButton component={Link} to="/top">
                                        <MenuText primary={"業務選択"} />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem key={"CONFLIST"} disablePadding>
                                    <ListItemButton component={Link} to="/confirmation/list" sx={{ ml: 5 }}>
                                        < MenuText primary={"立会確認書作成"} />
                                    </ListItemButton>
                                </ListItem>
                                {localStorage.getItem("use") === "1" ?
                                    <ListItem key={"CONSTRACTLIST"} disablePadding>
                                        {localStorage.getItem("contractListOpe") === "1" ?
                                            <ListItemButton component={Link} to="/digitalconstract/list" sx={{ ml: 5 }}>
                                                < MenuText primary={"電子契約"} />
                                            </ListItemButton>
                                            : <ListItemButton component={Link} to="/digitalconstract" sx={{ ml: 5 }}>
                                                < MenuText primary={"電子契約"} />
                                            </ListItemButton>
                                        }
                                    </ListItem> : null
                                }
                            </Menu>
                            <Divider />
                            <Menu>
                                <ListItem key={"LOGOUT"} disablePadding>
                                    <ListItemButton component={Link} to="/logout">
                                        <MenuText primary={"ログアウト"} />
                                    </ListItemButton>
                                </ListItem>
                            </Menu>
                        </Box>
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}
