import React, { useState, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import { SelectContractContent, SelectContractTitle, GrayButton, ReturnStatusButton } from '../../css/CSS.js';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import useAuthAxios from "../../../useAuthAxios";
import { useNavigate } from 'react-router-dom';
import LoadingDialog from '../../Loading';
import Typography from '@mui/material/Typography';
import UndoIcon from '@mui/icons-material/Undo';
import ClearIcon from '@mui/icons-material/Clear';
import { DigitalConstractsContext } from "../../../DigitalConstracts";

export default function DigitalConstractDropRequest(props) {
    const [open, setOpen] = React.useState(false);
    const { kojiCD, seqNo, contractType, status, setStatusCheck } = useContext(DigitalConstractsContext);
    const headers = { "Authorization": "Bearer " + localStorage.getItem("Auth") };
    const authAxios = useAuthAxios();
    const navigate = useNavigate();
    const [processing, setProcessing] = useState(false);
    const [dropRequest, setDropRequest] = useState(false);
    const [dropRequestType, setDropRequestType] = useState();
    const [dropRequestBody, setDropRequestBody] = useState();


    const handleClose = () => {
        setOpen(false);
        props.close(false)
        setStatusCheck(true)
    };

    React.useEffect(() => {
        setOpen(props.open);
        setStatusCheck(false)
    }, [props.open]);

    const handleDropRequest = () => {
        if (status === "社内確認依頼中") {
            setDropRequestType("/confirm?action=cancel")
            setDropRequestBody({ comfirmerList: null })
        } else if (status === "社内承認依頼中") {
            setDropRequestType("/approval?action=cancel")
            setDropRequestBody({ approversList: null })
        } else if (status === "お客様署名済み" ||
            status === "お客様承認依頼中" || status === "お客様承認依頼エラー" || status === "お客様承認依頼却下") {
            setDropRequestType("/customer/approval?order=cancel")
            setDropRequestBody({})
        }
        setDropRequest(true)
    };

    React.useEffect(() => {
        if (processing === true) { return }
        if (dropRequest === true) {
            setProcessing(true)
            setStatusCheck(false)
            authAxios
                .post('api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + dropRequestType
                    , { dropRequestBody }
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        setOpen(false);
                        props.close(false)
                        setDropRequest(false)
                        setProcessing(false)
                        setStatusCheck(true)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        if (typeof error.response.data === 'object') {
                            alert("認証情報が正しくありません。")
                        } else {
                            alert("認証情報が正しくありません。\n\n" + error.response.data);
                        }
                        navigate("/");
                    } else if (error.response.status === 500) {
                        alert("依頼取り下げに失敗しました。\n\n" + error.response.data.title);
                        setDropRequest(false)
                        setProcessing(false)
                    }
                    else {
                        if (typeof error.response.data === 'object') {
                            alert("依頼取り下げに失敗しました。")
                        } else {
                            alert("依頼取り下げに失敗しました。\n\n" + error.response.data);
                        }
                        setDropRequest(false)
                        setProcessing(false)
                    }
                    setProcessing(false)
                });

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropRequest]);

    return (
        <React.Fragment>
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    fullWidth
                    maxWidth="sm"
                >
                    <LoadingDialog load={processing} />
                    <SelectContractTitle >
                        <Grid container columns={12} rowSpacing={{ xs: 3 }} columnSpacing={4} >
                            <Grid item xs={11} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                取下げ確認
                            </Grid>
                            <Grid item xs={1} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                <ClearIcon fontSize="large" onClick={() => handleClose()} />
                            </Grid>
                        </Grid>
                    </SelectContractTitle>
                    <SelectContractContent>
                        <Grid container columns={12} columnSpacing={1} sx={{ pt: 3 }}>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography sx={{ fontSize: 20 }} >{status + "の契約書を取下げます。"}</Typography>
                            </Grid>
                        </Grid>
                    </SelectContractContent>
                    <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Grid sx={{ mr: 5 }}>
                            <GrayButton type="button" onClick={handleClose}>キャンセル<KeyboardReturnIcon sx={{ ml: 1 }} /></GrayButton>
                        </Grid >
                        <Grid >
                            <ReturnStatusButton sx={{ width: "250px" }} onClick={handleDropRequest}><UndoIcon sx={{ mr: 1 }} />取下げ</ReturnStatusButton>
                        </Grid >
                    </DialogActions>
                </Dialog>
            </div>
        </React.Fragment>
    );
}