import React, { useContext, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CheckBox from '../CheckBox'
import { useForm, Controller } from "react-hook-form";
import { UserInputData } from './ShunkoFrame';
import { Contents, FormTitle } from '../css/CSS.js';
import { useNavigate } from 'react-router-dom';
import useAuthAxios from "../../useAuthAxios";
import { ConfirmationContext } from "../../Confirmation";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import ja from 'date-fns/locale/ja'
import LoadingDialog from '../Loading';
import Toggle, { TOGGLE_STATES } from '../Toggle'

export default function Kakunin(props) {

    //各コンポーネントの動作を設定
    const validationRules = {
        kagiHonsu: { maxLength: { value: 2, message: '2桁以内で入力してください。' }, pattern: { value: /^[0-9]+$/, message: '半角数字のみで入力してください。' }, },
        kagi2Door: { maxLength: { value: 6, message: '6桁以内で入力してください。' } },
        kagi2Honsu: { maxLength: { value: 2, message: '2桁以内で入力してください。' }, pattern: { value: /^[0-9]+$/, message: '半角数字のみで入力してください。' }, },
        kagi3Door: { maxLength: { value: 6, message: '6桁以内で入力してください。' } },
        kagi3Honsu: { maxLength: { value: 2, message: '2桁以内で入力してください。' }, pattern: { value: /^[0-9]+$/, message: '半角数字のみで入力してください。' }, },
        lifeSuidoNote: { maxLength: { value: 8, message: '8桁以内で入力してください。' } },
        lifeDenkiNote: { maxLength: { value: 8, message: '8桁以内で入力してください。' } },
        lifeGasNote: { maxLength: { value: 8, message: '8桁以内で入力してください。' } },
        lifeTVNote: { maxLength: { value: 8, message: '8桁以内で入力してください。' } },
        lifeTELNote: { maxLength: { value: 8, message: '8桁以内で入力してください。' } },
        lifeInternetNote: { maxLength: { value: 8, message: '8桁以内で入力してください。' } },
        lifeSekiyuNote: { maxLength: { value: 8, message: '8桁以内で入力してください。' } },
    }
    const { currentState, setCurrentState } = useContext(UserInputData);
    const { control, handleSubmit, getValues, formState: { errors }, watch, setValue } = useForm({
        defaultValues: { ...currentState, ...currentState.kakunin, },
        mode: 'onChange'
    });

    const [formNext, setFormNext] = useState(false)
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (check_hikiwatasiYMD === false && check_nyukyoYMD === false) {
                if (formNext === true) {
                    props.handleNext();
                }
            }
        }
        setFormNext(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formNext]);

    const { kojiCD, cutTeimei, changeTeimei } = useContext(ConfirmationContext);
    const authAxios = useAuthAxios();
    const navigate = useNavigate();

    const [formPost, setFormPost] = useState(false)
    const [processing_post, setProcessing_post] = useState(false);
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (check_hikiwatasiYMD === false && check_nyukyoYMD === false) {
                if (formPost === true) {
                    setProcessing_post(true);
                    authAxios
                        .post('/api/confirmation/shunko/' + kojiCD + "?order=update", currentState)
                        .then(response => {
                            if (response.status === 200) {
                                navigate("/confirmation/list");
                                setProcessing_post(false);
                            }
                        }).catch((response) => {
                            alert("保存に失敗しました。", response.body);
                            setProcessing_post(false);
                        })
                }
            }
        }
        setFormPost(false);
        setTimeout(() => {
            setProcessing_post(false);
        }, 10000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formPost]);

    const onSubmit = (action) => {
        if (getValues("kagiHonsu") === "") { setValue("kagiHonsu", 0) }
        if (getValues("kagi2Honsu") === "") { setValue("kagi2Honsu", 0) }
        if (getValues("kagi3Honsu") === "") { setValue("kagi3Honsu", 0) }

        const kakunin = getValues();
        const kiroku = getValues("kiroku")
        if (action === "post") {
            setCurrentState({ ...currentState, kakunin, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei, kiroku });
            setFormPost(true);
        } else if (action === "next") {
            setCurrentState({ ...currentState, kakunin, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei, kiroku });
            setFormNext(true)
        }



    };

    const [value_hikiwatasiYMD, sethikiwatasiYMD] = React.useState("");
    const handleChange_hikiwatasiYMD = (newValue) => {
        sethikiwatasiYMD(newValue)
    }
    const [check_hikiwatasiYMD, setcheck_hikiwatasiYMD] = React.useState(false);
    const handleErr = (err) => {
        err === null ?
            setcheck_hikiwatasiYMD(false) : setcheck_hikiwatasiYMD(true)
    }

    const [value_nyukyoYMD, setnyukyoYMD] = React.useState("");
    const handleChange_nyukyoYMD = (newValue) => {
        setnyukyoYMD(newValue)
    }
    const [check_nyukyoYMD, setcheck_nyukyoYMD] = React.useState(false);
    const handleErr_nyukyoYMD = (err) => {
        err === null ?
            setcheck_nyukyoYMD(false) : setcheck_nyukyoYMD(true)
    }

    return (
        <Contents component="main" sx={{ pt: 1 }}>
            <form onSubmit={handleSubmit(onSubmit)} >
                <Grid container columns={12} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 2 }}>
                    <Grid item xs={4} >
                        <Typography sx={{ pl: 6, pt: 0.5, transform: "scale(1.3)" }} >別紙　打合せ記録</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Controller
                            control={control}
                            name="kiroku"
                            rules={validationRules.kiroku}
                            render={({ field: { value, onChange } }) => (
                                <Toggle
                                    setCheckedToggle={onChange}
                                    checkedToggle={value}
                                    toggleValue={"有"}
                                    toggleSts={TOGGLE_STATES.Two}
                                    toggleValue2={"無"}
                                    toggleSts2={TOGGLE_STATES.One}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container columns={12} columnSpacing={{ xs: 1, sm: 3, md: 3 }}>
                    <Grid>
                        <FormTitle>
                            確認事項
                        </FormTitle>
                    </Grid>
                </Grid>
                <Grid container columns={16} sx={{ pt: 1 }} >
                    <Grid item xs={16} sx={{ pl: 1.5 }}>
                        <Controller
                            control={control}
                            name="naibuKakunin"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)" }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }} style={watch("naibuKakunin") === 2 ? { textDecoration: "line-through" } : {}}>内部確認・取扱い説明</Typography>}
                                    labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={16} alignContent="flex-start">
                        <Grid container columns={14} sx={{ transform: "scale(1.1)", pt: 1, pl: 8.5 }}>
                            <Grid item xs={2} >
                                <Controller
                                    control={control}
                                    name="naibuCloth"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("naibuKakunin") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("naibuKakunin") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("naibuCloth") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >クロス</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2} >
                                <Controller
                                    control={control}
                                    name="naibuFlooring"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("naibuKakunin") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("naibuKakunin") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("naibuFlooring") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >フローリング</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2} >
                                <Controller
                                    control={control}
                                    name="naibuMado"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("naibuKakunin") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("naibuKakunin") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("naibuMado") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >窓</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2}  >
                                <Controller
                                    control={control}
                                    name="naibuTategu"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("naibuKakunin") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("naibuKakunin") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("naibuTategu") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >建具</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2} >
                                <Controller
                                    control={control}
                                    name="naibuKitchen"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("naibuKakunin") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("naibuKakunin") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("naibuKitchen") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >キッチン</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2} >
                                <Controller
                                    control={control}
                                    name="naibuUB"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("naibuKakunin") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("naibuKakunin") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("naibuUB") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >UB</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={16} alignContent="flex-start">
                        <Grid container columns={14} sx={{ transform: "scale(1.1)", pt: 1, pl: 8.5 }}>
                            <Grid item xs={2} >
                                <Controller
                                    control={control}
                                    name="naibuSenmenKeshoDai"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("naibuKakunin") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("naibuKakunin") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("naibuSenmenKeshoDai") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >洗面化粧台</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2} >
                                <Controller
                                    control={control}
                                    name="naibuToilet"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("naibuKakunin") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("naibuKakunin") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("naibuToilet") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >トイレ</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2} >
                                <Controller
                                    control={control}
                                    name="naibuKankiSystem"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("naibuKakunin") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("naibuKakunin") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("naibuKankiSystem") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >換気システム</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2} >
                                <Controller
                                    control={control}
                                    name="naibuAircon"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("naibuKakunin") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("naibuKakunin") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("naibuAircon") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >エアコン</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2}  >
                                <Controller
                                    control={control}
                                    name="naibuDanbou"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("naibuKakunin") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("naibuKakunin") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("naibuDanbou") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >暖房</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2} >
                                <Controller
                                    control={control}
                                    name="naibuSonota"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("naibuKakunin") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("naibuKakunin") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("naibuSonota") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >その他</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container columns={16} sx={{ pt: 2 }} >
                    <Grid item xs={16} sx={{ pl: 1.5 }}>
                        <Controller
                            control={control}
                            name="gaibuKakunin"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)" }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }} style={watch("gaibuKakunin") === 2 ? { textDecoration: "line-through" } : {}}>外部確認・取扱い説明</Typography>}
                                    labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={16} alignContent="flex-start">
                        <Grid container columns={14} sx={{ transform: "scale(1.1)", pt: 1, pl: 8.5 }}>
                            <Grid item xs={2} >
                                <Controller
                                    control={control}
                                    name="gaibuYane"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("gaibuKakunin") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("gaibuKakunin") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("gaibuYane") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >屋根</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2} >
                                <Controller
                                    control={control}
                                    name="gaibuGaiheki"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("gaibuKakunin") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("gaibuKakunin") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("gaibuGaiheki") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >外壁</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3.5} >
                                <Controller
                                    control={control}
                                    name="gaibuiBalconySundeck"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("gaibuKakunin") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("gaibuKakunin") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("gaibuiBalconySundeck") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >バルコニー・サンデッキ</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3.5}  >
                                <Controller
                                    control={control}
                                    name="gaibuMeterMas"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("gaibuKakunin") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("gaibuKakunin") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("gaibuMeterMas") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >メーター・マス</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4} sx={{ pt: 1 }}>
                                <Controller
                                    control={control}
                                    name="gaibuKyutokiEcocute"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("gaibuKakunin") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("gaibuKakunin") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("gaibuKyutokiEcocute") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >給湯器・エコキュート</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3.5} sx={{ pt: 1 }}>
                                <Controller
                                    control={control}
                                    name="gaibuAirconSitugaiki"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("gaibuKakunin") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("gaibuKakunin") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("gaibuAirconSitugaiki") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >エアコン室外機</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4} sx={{ pt: 1 }}>
                                <Controller
                                    control={control}
                                    name="gaibuTeakGenkanOil"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("gaibuKakunin") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("gaibuKakunin") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("gaibuTeakGenkanOil") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >チーク玄関ドア用保護オイル</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={16} sx={{}}>
                    <Grid container columns={16} sx={{ pt: 1 }} >
                        <Grid item xs={1} sx={{ pt: 1 }}>
                            <Controller
                                control={control}
                                name="kagi"
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        sx={{ transform: "scale(1.3)", pr: 6.5 }}
                                        control={
                                            <CheckBox
                                                setCheckedvalue={onChange}
                                                checkedvalue={value}
                                                color='primary'
                                            />
                                        }
                                        label={<Typography sx={{ pl: 1 }} style={watch("kagi") === 2 ? { textDecoration: "line-through" } : {}}>鍵</Typography>}
                                        labelPlacement="end"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={1.4} sx={{ pt: 1.1 }}>
                            {<Typography sx={{ pl: 1, transform: "scale(1.1)" }}
                                style={watch("kagi") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : {}
                                }>玄関ドア:</Typography>}
                        </Grid>
                        <Grid item xs={0.3} sx={{ pt: 0.8, transform: "scale(1.1)", }}>
                            <Controller
                                control={control}
                                name="kagiHonsu"
                                rules={validationRules.kagiHonsu}
                                render={({ field, fieldState }) => (
                                    < TextField
                                        {...field}
                                        style={{ width: '100%' }}
                                        variant="standard"
                                        error={fieldState.invalid}
                                        inputProps={{ maxLength: 2, style: { textAlign: 'right' } }}
                                        disabled={watch("kagi") === 2 ? true : false}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={1} sx={{ pt: 1.1, transform: "scale(1.1)", }}>
                            <Typography sx={{ pl: 1 }} style={watch("kagi") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : {}}>本</Typography>
                        </Grid>
                        <Grid item xs={1.5} sx={{ transform: "scale(1.1)", pt: 0.6 }}>
                            <Controller
                                control={control}
                                name="kagi2Door"
                                rules={validationRules.kagi2Door}
                                render={({ field, fieldState }) => (
                                    < TextField
                                        {...field}
                                        style={{ width: '100%', style: { textAlign: 'right' } }}
                                        variant="standard"
                                        error={fieldState.invalid}
                                        inputProps={{ maxLength: 6 }}
                                        disabled={watch("kagi") === 2 ? true : false}
                                    />

                                )}
                            />
                        </Grid>
                        <Grid item xs={0.9} sx={{ pt: 1.1 }}>
                            {<Typography sx={{ pl: 1, transform: "scale(1.1)" }}
                                style={watch("kagi") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : {}
                                }>ドア:</Typography>}
                        </Grid>
                        <Grid item xs={0.3} sx={{ pt: 0.8, transform: "scale(1.1)", }}>
                            <Controller
                                control={control}
                                name="kagi2Honsu"
                                rules={validationRules.kagi2Honsu}
                                render={({ field, fieldState }) => (
                                    < TextField
                                        {...field}
                                        style={{ width: '100%' }}
                                        variant="standard"
                                        error={fieldState.invalid}
                                        inputProps={{ maxLength: 2, style: { textAlign: 'right' } }}
                                        disabled={watch("kagi") === 2 ? true : false}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={1} sx={{ pt: 1.1, transform: "scale(1.1)", }}>
                            <Typography sx={{ pl: 1 }} style={watch("kagi") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : {}}>本</Typography>
                        </Grid>
                        <Grid item xs={1.5} sx={{ transform: "scale(1.1)", pt: 0.6 }}>
                            <Controller
                                control={control}
                                name="kagi3Door"
                                rules={validationRules.kagi3Door}
                                render={({ field, fieldState }) => (
                                    < TextField
                                        {...field}
                                        style={{ width: '100%' }}
                                        variant="standard"
                                        error={fieldState.invalid}
                                        inputProps={{ maxLength: 6 }}
                                        disabled={watch("kagi") === 2 ? true : false}
                                    />

                                )}
                            />
                        </Grid>
                        <Grid item xs={0.9} sx={{ pt: 1.1 }}>
                            {<Typography sx={{ pl: 1, transform: "scale(1.1)" }}
                                style={watch("kagi") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : {}
                                }>ドア:</Typography>}
                        </Grid>
                        <Grid item xs={0.3} sx={{ pt: 0.8, transform: "scale(1.1)", }}>
                            <Controller
                                control={control}
                                name="kagi3Honsu"
                                rules={validationRules.kagi3Honsu}
                                render={({ field, fieldState }) => (
                                    < TextField
                                        {...field}
                                        style={{ width: '100%', style: { textAlign: 'right' } }}
                                        variant="standard"
                                        error={fieldState.invalid}
                                        inputProps={{ maxLength: 2, style: { textAlign: 'right' } }}
                                        disabled={watch("kagi") === 2 ? true : false}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={1} sx={{ pt: 1.1, transform: "scale(1.1)", }}>
                            <Typography sx={{ pl: 1 }} style={watch("kagi") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : {}}>本</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={16} sx={{}}>
                    <Grid container columns={12} sx={{ pt: 2.5 }} >
                        <Grid item xs={1.5} sx={{ pl: 0.2, pt: 1 }}>
                            <Controller
                                control={control}
                                name="hikiwatasi"
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        sx={{ transform: "scale(1.3)" }}
                                        control={
                                            <CheckBox
                                                setCheckedvalue={onChange}
                                                checkedvalue={value}
                                                color='primary'
                                            />
                                        }
                                        label={<Typography sx={{ pl: 1 }} style={watch("hikiwatasi") === 2 ? { textDecoration: "line-through" } : {}}>お引渡し日</Typography>}
                                        labelPlacement="end"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={1.8} >
                            <Controller
                                control={control}
                                name="hikiwatasiYMD"
                                render={({ field }) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja} dateFormats={{ monthAndYear: 'yyyy年MM月' }}>
                                        <DesktopDatePicker
                                            openTo="day"
                                            views={['year', 'month', 'day']}
                                            value={value_hikiwatasiYMD ?? ""}
                                            onChange={handleChange_hikiwatasiYMD}
                                            inputFormat="yyyy/MM/dd"
                                            inputProps={{ style: { padding: '6px' } }}
                                            disabled={watch("hikiwatasi") === 2 ? true : false}
                                            onError={(err) => handleErr(err)}
                                            {...field}
                                            renderInput={(params) =>
                                                <TextField {...params} sx={{ width: 150 }}
                                                    error={check_hikiwatasiYMD}
                                                />}
                                        />
                                    </LocalizationProvider>
                                )}
                            />
                        </Grid>
                        <Grid item xs={0.5} sx={{ pt: 0.8, transform: "scale(1.1)", }}>
                            <Typography sx={{ pl: 1 }} style={watch("hikiwatasi") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : {}}>時刻</Typography>
                        </Grid>
                        <Grid item xs={2} >
                            <Controller
                                control={control}
                                name="hikiwatasiHM"
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id="time"
                                        type="time"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300
                                            , style: { padding: '6px' }
                                        }}
                                        sx={{ width: 100 }}
                                        disabled={watch("hikiwatasi") === 2 ? true : false}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={16} sx={{}}>
                    <Grid container columns={12} sx={{ pt: 2.5 }} >
                        <Grid item xs={2.8} sx={{ pl: 2, pt: 1 }}>
                            <Controller
                                control={control}
                                name="nyukyo"
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        sx={{ transform: "scale(1.3)" }}
                                        control={
                                            <CheckBox
                                                setCheckedvalue={onChange}
                                                checkedvalue={value}
                                                color='primary'
                                            />
                                        }
                                        label={<Typography sx={{ pl: 1 }} style={watch("nyukyo") === 2 ? { textDecoration: "line-through" } : {}}>ご入居予定日(お引越し)</Typography>}
                                        labelPlacement="end"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={1.8} sx={{ pt: 0.5 }}>
                            <Controller
                                control={control}
                                name="nyukyoYMD"
                                render={({ field }) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja} dateFormats={{ monthAndYear: 'yyyy年MM月' }}>
                                        <DesktopDatePicker
                                            openTo="day"
                                            views={['year', 'month', 'day']}
                                            value={value_nyukyoYMD}
                                            onChange={handleChange_nyukyoYMD}
                                            inputFormat="yyyy/MM/dd"
                                            inputProps={{ style: { padding: '6px' } }}
                                            disabled={watch("nyukyo") === 2 ? true : false}
                                            onError={(err) => handleErr_nyukyoYMD(err)}
                                            {...field}
                                            renderInput={(params) =>
                                                <TextField {...params} sx={{ width: 150 }}
                                                    error={check_nyukyoYMD}
                                                />}
                                        />
                                    </LocalizationProvider>
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container columns={16} sx={{ pt: 2 }} >
                    <Grid item xs={16} sx={{ pl: 2.5, pb: 1 }}>
                        <Controller
                            control={control}
                            name="lifeLineSetumei"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)" }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }} style={watch("lifeLineSetumei") === 2 ? { textDecoration: "line-through" } : {}}>ライフライン使用手配説明</Typography>}
                                    labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={16} alignContent="flex-start">
                        <Grid container columns={14} sx={{ transform: "scale(1.1)", pl: 8.5 }}>
                            <Grid item xs={4} >
                                <Grid container columns={4} sx={{ pt: 1 }}>
                                    <Grid item xs={1.1} sx={{ pt: 0.5 }}>
                                        <Controller
                                            control={control}
                                            name="lifeSuido"
                                            render={({ field: { onChange, value } }) => (
                                                <FormControlLabel
                                                    control={
                                                        <CheckBox
                                                            setCheckedvalue={onChange}
                                                            checkedvalue={value}
                                                            color='primary'
                                                            disabled={watch("lifeLineSetumei") === 2 ? true : false}
                                                        />
                                                    }
                                                    label={<Typography sx={{ pl: 1 }}
                                                        style={watch("lifeLineSetumei") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                            : watch("lifeSuido") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                                : {}
                                                        }
                                                    >水道</Typography>}
                                                    labelPlacement="end"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={2.9} sx={{ pt: 0.1 }}>
                                        <Controller
                                            control={control}
                                            name="lifeSuidoNote"
                                            rules={validationRules.lifeSuidoNote}
                                            render={({ field, fieldState }) => (
                                                < TextField
                                                    {...field}
                                                    style={{ width: '70%' }}
                                                    variant="standard"
                                                    error={fieldState.invalid}
                                                    inputProps={{ maxLength: 8 }}
                                                    disabled={watch("lifeLineSetumei") === 2 ? true : watch("lifeSuido") === 2 ? true : false}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} >
                                <Grid container columns={4} sx={{ pt: 1 }}>
                                    <Grid item xs={1.1} sx={{ pt: 0.5 }}>
                                        <Controller
                                            control={control}
                                            name="lifeDenki"
                                            render={({ field: { onChange, value } }) => (
                                                <FormControlLabel
                                                    control={
                                                        <CheckBox
                                                            setCheckedvalue={onChange}
                                                            checkedvalue={value}
                                                            color='primary'
                                                            disabled={watch("lifeLineSetumei") === 2 ? true : false}
                                                        />
                                                    }
                                                    label={<Typography sx={{ pl: 1 }}
                                                        style={watch("lifeLineSetumei") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                            : watch("lifeDenki") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                                : {}
                                                        }
                                                    >電気</Typography>}
                                                    labelPlacement="end"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={2.9} sx={{ pt: 0.1 }}>
                                        <Controller
                                            control={control}
                                            name="lifeDenkiNote"
                                            rules={validationRules.lifeDenkiNote}
                                            render={({ field, fieldState }) => (
                                                < TextField
                                                    {...field}
                                                    style={{ width: '70%' }}
                                                    variant="standard"
                                                    error={fieldState.invalid}
                                                    inputProps={{ maxLength: 8 }}
                                                    disabled={watch("lifeLineSetumei") === 2 ? true : watch("lifeDenki") === 2 ? true : false}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={5} >
                                <Grid container columns={6} sx={{ pt: 1 }}>
                                    <Grid item xs={1.8} sx={{ pt: 0.5 }}>
                                        <Controller
                                            control={control}
                                            name="lifeGas"
                                            render={({ field: { onChange, value } }) => (
                                                <FormControlLabel
                                                    control={
                                                        <CheckBox
                                                            setCheckedvalue={onChange}
                                                            checkedvalue={value}
                                                            color='primary'
                                                            disabled={watch("lifeLineSetumei") === 2 ? true : false}
                                                        />
                                                    }
                                                    label={<Typography sx={{ pl: 1 }}
                                                        style={watch("lifeLineSetumei") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                            : watch("lifeGas") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                                : {}
                                                        }
                                                    >ガス開栓</Typography>}
                                                    labelPlacement="end"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={4} sx={{ pt: 0.1 }}>
                                        <Controller
                                            control={control}
                                            name="lifeGasNote"
                                            rules={validationRules.lifeGasNote}
                                            render={({ field, fieldState }) => (
                                                < TextField
                                                    {...field}
                                                    style={{ width: '60%' }}
                                                    variant="standard"
                                                    error={fieldState.invalid}
                                                    inputProps={{ maxLength: 8 }}
                                                    disabled={watch("lifeLineSetumei") === 2 ? true : watch("lifeGas") === 2 ? true : false}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} >
                                <Grid container columns={4} sx={{ pt: 1 }}>
                                    <Grid item xs={1.1} sx={{ pt: 0.5 }}>
                                        <Controller
                                            control={control}
                                            name="lifeTV"
                                            render={({ field: { onChange, value } }) => (
                                                <FormControlLabel
                                                    control={
                                                        <CheckBox
                                                            setCheckedvalue={onChange}
                                                            checkedvalue={value}
                                                            color='primary'
                                                            disabled={watch("lifeLineSetumei") === 2 ? true : false}
                                                        />
                                                    }
                                                    label={<Typography sx={{ pl: 1 }}
                                                        style={watch("lifeLineSetumei") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                            : watch("lifeTV") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                                : {}
                                                        }
                                                    >TV</Typography>}
                                                    labelPlacement="end"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={2.9} sx={{ pt: 0.1 }}>
                                        <Controller
                                            control={control}
                                            name="lifeTVNote"
                                            rules={validationRules.lifeTVNote}
                                            render={({ field, fieldState }) => (
                                                < TextField
                                                    {...field}
                                                    style={{ width: '70%' }}
                                                    variant="standard"
                                                    error={fieldState.invalid}
                                                    inputProps={{ maxLength: 8 }}
                                                    disabled={watch("lifeLineSetumei") === 2 ? true : watch("lifeTV") === 2 ? true : false}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} >
                                <Grid container columns={4} sx={{ pt: 1 }}>

                                    <Grid item xs={1.1} sx={{ pt: 0.5 }}>
                                        <Controller
                                            control={control}
                                            name="lifeTEL"
                                            render={({ field: { onChange, value } }) => (
                                                <FormControlLabel
                                                    control={
                                                        <CheckBox
                                                            setCheckedvalue={onChange}
                                                            checkedvalue={value}
                                                            color='primary'
                                                            disabled={watch("lifeLineSetumei") === 2 ? true : false}
                                                        />
                                                    }
                                                    label={<Typography sx={{ pl: 1 }}
                                                        style={watch("lifeLineSetumei") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                            : watch("lifeTEL") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                                : {}
                                                        }
                                                    >電話</Typography>}
                                                    labelPlacement="end"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={2.9} sx={{ pt: 0.1 }}>
                                        <Controller
                                            control={control}
                                            name="lifeTELNote"
                                            rules={validationRules.lifeTELNote}
                                            render={({ field, fieldState }) => (
                                                < TextField
                                                    {...field}
                                                    style={{ width: '70%' }}
                                                    variant="standard"
                                                    error={fieldState.invalid}
                                                    inputProps={{ maxLength: 8 }}
                                                    disabled={watch("lifeLineSetumei") === 2 ? true : watch("lifeTEL") === 2 ? true : false}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={5} >
                                <Grid container columns={5} sx={{ pt: 1 }}>
                                    <Grid item xs={2.2} sx={{ pt: 0.5 }}>
                                        <Controller
                                            control={control}
                                            name="lifeInternet"
                                            render={({ field: { onChange, value } }) => (
                                                <FormControlLabel
                                                    control={
                                                        <CheckBox
                                                            setCheckedvalue={onChange}
                                                            checkedvalue={value}
                                                            color='primary'
                                                            disabled={watch("lifeLineSetumei") === 2 ? true : false}
                                                        />
                                                    }
                                                    label={<Typography sx={{ pl: 1 }}
                                                        style={watch("lifeLineSetumei") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                            : watch("lifeInternet") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                                : {}
                                                        }
                                                    >インターネット</Typography>}
                                                    labelPlacement="end"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={2.8} sx={{ pt: 0.1 }}>
                                        <Controller
                                            control={control}
                                            name="lifeInternetNote"
                                            rules={validationRules.lifeInternetNote}
                                            render={({ field, fieldState }) => (
                                                < TextField
                                                    {...field}
                                                    style={{ width: '70%' }}
                                                    variant="standard"
                                                    error={fieldState.invalid}
                                                    inputProps={{ maxLength: 8 }}
                                                    disabled={watch("lifeLineSetumei") === 2 ? true : watch("lifeInternet") === 2 ? true : false}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} >
                                <Grid container columns={4} sx={{ pt: 1 }}>
                                    <Grid item xs={1.1} sx={{ pt: 0.5 }}>
                                        <Controller
                                            control={control}
                                            name="lifeSekiyu"
                                            render={({ field: { onChange, value } }) => (
                                                <FormControlLabel
                                                    control={
                                                        <CheckBox
                                                            setCheckedvalue={onChange}
                                                            checkedvalue={value}
                                                            color='primary'
                                                            disabled={watch("lifeLineSetumei") === 2 ? true : false}
                                                        />
                                                    }
                                                    label={<Typography sx={{ pl: 1 }}
                                                        style={watch("lifeLineSetumei") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                            : watch("lifeSekiyu") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                                : {}
                                                        }
                                                    >石油</Typography>}
                                                    labelPlacement="end"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={2.9} sx={{ pt: 0.1 }}>
                                        <Controller
                                            control={control}
                                            name="lifeSekiyuNote"
                                            rules={validationRules.lifeSekiyuNote}
                                            render={({ field, fieldState }) => (
                                                < TextField
                                                    {...field}
                                                    style={{ width: '70%' }}
                                                    variant="standard"
                                                    error={fieldState.invalid}
                                                    inputProps={{ maxLength: 8 }}
                                                    disabled={watch("lifeLineSetumei") === 2 ? true : watch("lifeSekiyu") === 2 ? true : false}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid>
                                <Typography sx={{ pt: 2 }}
                                    style={watch("lifeLineSetumei") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                        : {}
                                    }
                                >※電気・水道・ガス料金は、お引渡時の検針までは弊社にて負担致します。</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container columns={12} rowSpacing={3} columnSpacing={{ xs: 1, sm: 3 }} sx={{ pt: 2 }}>
                    <Grid container columns={16} sx={{ pt: 5 }}>
                        <Grid item xs={14} >
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("post")} disabled={processing_post}>
                                {processing_post ? "処理中" : "保存して一覧へ"}
                            </Button>
                            <LoadingDialog load={processing_post} />
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("next")}>
                                次へ
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form >
        </Contents >
    );
}