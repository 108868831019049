import React, { useState, useContext } from 'react';
import { Title } from '../../css/CSS.js';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import useAuthAxios from "../../../useAuthAxios";
import Button from '@mui/material/Button';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { DigitalConstractsContext } from "../../../DigitalConstracts";
import GetPDFError from '../../GetPDFError';

import DigitalConstractPDFViewer from '../DigitalConstractConstitution/DigitalConstractPDFViewer'

export default function DigitalConstractPDFFrame(props) {
    const authAxios = useAuthAxios();
    const headers = { "Authorization": "Bearer " + localStorage.getItem("Auth") };
    const { kojiCD, seqNo, contractType, selectPDF } = useContext(DigitalConstractsContext);
    const navigate = useNavigate();
    const [openPDF, setOpenPDF] = React.useState(false);
    const [getPDFData, setGetPDFData] = React.useState();
    const [processingPDF, setProcessingPDF] = useState(false);

    const handleOpenPDF = () => { setOpenPDF(true); }

    React.useEffect(() => {
        setGetPDFData(null)
        if (selectPDF === "重要事項説明書") {
            authAxios
                .get('api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + "/file"
                    + "/" + "3"
                    + "/" + "重要事項説明書.pdf"
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        setGetPDFData(process.env.REACT_APP_API_URL
                            + "api/contracts/"
                            + (contractType === "工事請負契約書" ? "base" : "modfy")
                            + "/" + kojiCD
                            + "/" + seqNo
                            + "/file"
                            + "/" + "3"
                            + "/" + "重要事項説明書.pdf"
                        )
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        setProcessingPDF(true)
                        alert("重要事項説明書PDFの取得に失敗しました。");
                    }
                });
        } else if (selectPDF === "設計及び工事監理業務に関する書面") {
            authAxios
                .get('api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + "/file"
                    + "/" + "4"
                    + "/" + "設計及び工事監理業務に関する書面.pdf"
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        setGetPDFData(process.env.REACT_APP_API_URL
                            + "api/contracts/"
                            + (contractType === "工事請負契約書" ? "base" : "modfy")
                            + "/" + kojiCD
                            + "/" + seqNo
                            + "/file"
                            + "/" + "4"
                            + "/" + "設計及び工事監理業務に関する書面.pdf"
                        )
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        setProcessingPDF(true)
                        alert("設計及び工事監理業務に関する書面PDFの取得に失敗しました。");
                    }
                });
        } else {
            authAxios
                .get('api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + "/file"
                    + "/" + (contractType === "工事請負契約書" ? "1" : "2")
                    + "/" + (contractType === "工事請負契約書" ? "工事請負契約書.pdf" : "変更工事請負契約書.pdf")
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        setGetPDFData(process.env.REACT_APP_API_URL
                            + "api/contracts/"
                            + (contractType === "工事請負契約書" ? "base" : "modfy")
                            + "/" + kojiCD
                            + "/" + seqNo
                            + "/file"
                            + "/" + (contractType === "工事請負契約書" ? "1" : "2")
                            + "/" + (contractType === "工事請負契約書" ? "工事請負契約書.pdf" : "変更工事請負契約書.pdf")
                        )
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        setProcessingPDF(true)
                        alert("契約書PDFの取得に失敗しました。");
                    }
                });

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectPDF]);

    return (
        <Title >
            <Grid container columns={12} >
                <Grid item xs={12} sx={{ mt: 3 }} style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                        variant="text"
                        className="confButton"
                        type="button"
                        onClick={handleOpenPDF}
                    ><ZoomOutMapIcon sx={{ mr: 1 }} />
                        拡大表示
                    </Button>
                    <DigitalConstractPDFViewer open={openPDF} close={setOpenPDF} PDF={getPDFData} />
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    {getPDFData == null ?

                        <GetPDFError loading={processingPDF} />
                        :
                        <embed
                            type="application/pdf"
                            src={getPDFData}
                            width="100%" height="745vh"
                            style={{
                                border: "2px solid",
                                borderColor: "#2c2c2c",
                            }}
                        />
                    }
                </Grid>
            </Grid>
        </Title >
    );
}