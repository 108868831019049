import React, { useState, useCallback, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { UploadContractTitle, SelectContractContent, SelectContractCloseButton } from '../../css/CSS.js';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import UploadButton from './FileUpload';
import DigitalConstractDocumentDelConfirm from './DigitalConstractDocumentDelConfirm';
import useAuthAxios from "../../../useAuthAxios";
import { useDropzone } from 'react-dropzone';
import { useForm } from "react-hook-form";
import LoadingDialog from '../../Loading';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import DigitalConstractEditFileName from './DigitalConstractEditFileName'
import ClearIcon from '@mui/icons-material/Clear';
import { DigitalConstractsContext } from "../../../DigitalConstracts";
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import '../../css/NavMenu.css';


const TableFlame = styled(TableContainer)({
    borderRadius: "5px 5px 0 0",
    maxHeight: 330,
});
const TableHeader = styled(TableCell)({
    backgroundColor: "#1976d2",
    padding: "5px",
});
const Header = styled(TableRow)({
    backgroundColor: "#1976d2",
});
const Text = styled(Typography)({
    color: "#FFFFFF",
    transform: "scale(0.9)",
});
const CLTableCell = styled(TableCell)({
    padding: "0px",
});
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}
const headCells = [
    {
        id: 'checkbox',
        label: '',
        align: 'left'
    },
    {
        id: 'customerCD',
        label: '追加日時',
        align: 'left',
    },
    {
        id: 'fileName',
        label: 'ファイル名',
        align: 'left'
    },
    {
        id: 'documentName',
        label: '書類名',
        align: 'left'
    },
    {
        id: 'deleteicon',
        label: '',
        align: 'left'
    },
    {
        id: 'pdfviewer',
        label: '',
        align: 'left'
    }
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <Header>
                {headCells.map((headCell) => (
                    <TableHeader
                        key={headCell.id}
                        align={headCell.align}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={headCell.id === 'customerCD' ? { pr: 0, pl: 2 } : { pr: 0, pl: 0 }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <Text >{headCell.label}</Text>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableHeader>
                ))}
            </Header>
        </TableHead >
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number,
};

export default function DigitalConstractFileUpload(props) {
    const [open, setOpen] = React.useState(false);
    const handleClose = () => { setOpen(false); };
    const handleOpen = () => { setOpen(true) }
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const authAxios = useAuthAxios();
    const [attachedID, setAttachedID] = React.useState("");
    const [openDelete, setOpenDelete] = useState(false);
    const [fileName, setFilename] = React.useState("");
    const [deleteCheck, setDeleteCheck] = useState(false);
    const [attachFlg, setAttachFlg] = useState(false);
    const { setValue, getValues } = useForm({});
    const [uploadCheck, setuploadCheck] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [list, setList] = useState()
    const [attachedDocument, setAttachedDocument] = useState(false)
    const [convertAttachedFlg, setConvertAttachedFlg] = useState()
    const { kojiCD, seqNo, contractType, statusCheck, setStatusCheck } = useContext(DigitalConstractsContext);
    const navigate = useNavigate();
    const [countCheck, setCountCheck] = useState(0);

    React.useLayoutEffect(() => {
        if (processing === true) { return }
        if (open === true) {
            setProcessing(true)
            authAxios
                .get('api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + "/attaches"
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        const attachListData = JSON.parse((JSON.stringify(response.data)))
                        setList(attachListData.attachAllList)
                    }
                    setProcessing(false)
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        alert("添付資料一覧取得に失敗しました。");
                        setProcessing(false)
                    }
                    setProcessing(false)
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    React.useLayoutEffect(() => {
        if (processing === true) { return }
        if (open === true) {
            setProcessing(true)
            authAxios
                .get('api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + "/attaches"
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        const attachListData = JSON.parse((JSON.stringify(response.data)))
                        setList(attachListData.attachAllList)
                    }
                    setProcessing(false)
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        alert("添付資料一覧取得に失敗しました。");
                        setProcessing(false)
                    }
                    setProcessing(false)
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusCheck]);

    React.useLayoutEffect(() => {
        setCountCheck(0)
        list
            ?.map((row) => {
                if (row.attachedFlg === 1) { setCountCheck((prevCount) => prevCount + 1) }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list]);

    const onSubmit_attached = (fileID, attachedFlg) => {
        if (attachedFlg === 0) { setConvertAttachedFlg("add") }
        else if (attachedFlg === 1) { setConvertAttachedFlg("delete") }
        setAttachedID(fileID)
        if (countCheck >= 19) {
            alert("契約書にまとめられる資料は20件までです。");
        } else {
            setAttachedDocument(true)
        }
    };

    React.useEffect(() => {
        if (processing === true) { return }
        if (attachedDocument === true) {
            setProcessing(true)
            setStatusCheck(false)
            authAxios
                .put('api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + "/" + props.baseFileID
                    + "/combine"
                    + "?edit=" + convertAttachedFlg
                    + "&attachFileID=" + attachedID
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        setAttachedDocument(false)
                        setStatusCheck(true)
                        authAxios
                            .get('api/contracts/'
                                + (contractType === "工事請負契約書" ? "base" : "modfy")
                                + "/" + kojiCD
                                + "/" + seqNo
                                + "/attaches"
                                , { headers }
                            )
                            .then(response => {
                                if (response.status === 200) {
                                    const attachListData = JSON.parse((JSON.stringify(response.data)))
                                    setList(attachListData.attachAllList)
                                }
                                setProcessing(false)
                            }).catch((error) => {
                                if (error.response.status === 401) {
                                    alert("認証情報が正しくありません。");
                                    navigate("/");
                                } else {
                                    alert("添付資料一覧取得に失敗しました。");
                                    setProcessing(false)
                                }
                                setProcessing(false)
                            });
                    }
                    setConvertAttachedFlg(null)
                    setAttachedDocument(false)
                    setProcessing(false)
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        alert("添付・添付解除に失敗しました。");
                        setConvertAttachedFlg(null)
                        setAttachedDocument(false)
                        setProcessing(false)
                    }
                    setProcessing(false)
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attachedDocument]);

    const onSubmit_Delete = (fileID, fileName) => {
        setOpenDelete(true)
        setFilename(fileName)
        setAttachedID(fileID)
        setAttachFlg(true)
    };
    React.useEffect(() => {
        if (processing === true) { return }
        if (deleteCheck === true) {
            setProcessing(true)
            setStatusCheck(false)
            authAxios
                .delete('api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + "/attach"
                    + "/ " + attachedID
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        authAxios
                            .get('api/contracts/'
                                + (contractType === "工事請負契約書" ? "base" : "modfy")
                                + "/" + kojiCD
                                + "/" + seqNo
                                + "/attaches"
                                , { headers }
                            )
                            .then(response => {
                                if (response.status === 200) {
                                    const attachListData = JSON.parse((JSON.stringify(response.data)))
                                    setList(attachListData.attachAllList)
                                    setOpenDelete(false)
                                    setStatusCheck(true)
                                }
                                setProcessing(false)
                            }).catch((error) => {
                                if (error.response.status === 401) {
                                    alert("認証情報が正しくありません。");
                                    navigate("/");
                                } else {
                                    alert("添付資料一覧取得に失敗しました。");
                                }
                                setProcessing(false)
                            });
                    }
                    setDeleteCheck(false)
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        alert("添付資料の削除に失敗しました。");
                        setDeleteCheck(false)
                        setOpenDelete(false)
                        setProcessing(false)
                    }
                    setProcessing(false)
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteCheck]);

    const headers = { "Authorization": "Bearer " + localStorage.getItem("Auth"), 'Content-Type': 'multipart/form-data' };

    const onDrop = useCallback((acceptedFiles) => {
        setValue("attachFileData", acceptedFiles[0])
        setValue("attachFileName", acceptedFiles[0].name)
        setuploadCheck(true)
    }, []);

    //添付ファイル登録
    React.useEffect(() => {
        if (processing === true) { return }
        if (uploadCheck === true) {
            setProcessing(true)
            setStatusCheck(false)
            const body = new FormData();
            body.append("fileName", getValues("attachFileName"))
            body.append("file", getValues("attachFileData"))

            authAxios
                .post('api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + "/attach"
                    , body
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        setStatusCheck(true)
                        setuploadCheck(false)
                        setProcessing(false)
                    }

                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        alert("添付資料の登録に失敗しました。");
                    }
                    setProcessing(false)
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadCheck]);

    const [openPDF, setOpenPDF] = React.useState(false);
    const [openPDFWindow, setOpenPDFWindow] = React.useState(false);
    const [PDFDataURL, setPDFDataURL] = React.useState("");

    const [fileID, setFileID] = React.useState(null);
    const [docName, setDocName] = React.useState(null);
    const handleOpenPDF = (ID, Name) => {
        setFileID(ID)
        setDocName(Name)
        setOpenPDF(true);
    }

    React.useEffect(() => {
        if (openPDF === true) {
            window.open(process.env.REACT_APP_API_URL
                + "api/contracts/"
                + (contractType === "工事請負契約書" ? "base" : "modfy")
                + "/" + kojiCD
                + "/" + seqNo
                + "/attach"
                + "/" + fileID
                + "/" + docName
            )
            setOpenPDF(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openPDF]);


    const { getRootProps, getInputProps } = useDropzone({ onDrop, noClick: true });

    const [openEditFileName, setOpenEditFileName] = React.useState(false);
    const [baseFileName, setBaseFileName] = React.useState("");
    const [attachedFlg, setAttachedFlg] = React.useState(null);
    const [registDT, setRegistDT] = React.useState(null);
    const [baseDocName, setBaseDocName] = React.useState("");

    const handleOpenEditFileName = (ID, fileName, attachedFlg, registDT, docName) => {
        setFileID(ID)
        setBaseFileName(fileName)
        setAttachedFlg(attachedFlg)
        setRegistDT(registDT)
        setBaseDocName(docName)
        setOpenEditFileName(true);
    }

    return (
        <React.Fragment>
            <LoadingDialog load={processing} />
            <DigitalConstractDocumentDelConfirm
                open={openDelete}
                close={setOpenDelete}
                setDeleteCheck={setDeleteCheck}
                fileName={fileName}
                attachedFile={attachFlg}
                setAttachedFile={setAttachFlg}
            />
            <AddIcon type="button" sx={{ fontSize: 45 }} style={{ color: "#1976d2" }} onClick={handleOpen} />
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    maxWidth="lg"
                >
                    <UploadContractTitle id="alert-dialog-title">
                        <Grid container columns={12} rowSpacing={{ xs: 3 }} columnSpacing={4} >
                            <Grid item xs={11} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                添付書類登録
                            </Grid>
                            <Grid item xs={1} style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                <ClearIcon fontSize="large" onClick={() => handleClose()} />
                            </Grid>
                        </Grid>
                    </UploadContractTitle>
                    <SelectContractContent >
                        <Typography sx={{ pt: 2 }}>添付書類を登録します。</Typography>
                        <Typography >表中にファイルをドラッグ＆ドロップ、または「ファイル追加」ボタンからファイルを選択してください。</Typography>
                        <Typography >契約書に含める資料はチェックを付けてください。</Typography>
                        <Typography sx={{ pb: 2 }}>契約書に含める書類名を編集したい場合、対象行の{<EditIcon sx={{ pt: 1 }} />}をクリックしてください。</Typography>
                        <Paper sx={{ width: '100%', height: "100%", boxShadow: 0, }}>
                            <TableFlame {...getRootProps()} sx={{ height: "100%" }}>
                                <input {...getInputProps()} />
                                <Table
                                    stickyHeader
                                    aria-labelledby="attachTable"
                                    align="center"
                                >
                                    <EnhancedTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={list?.length}
                                    />
                                    <TableBody>
                                        {stableSort(list, getComparator(order, orderBy))
                                            ?.map((row, index) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                        key={index}
                                                        sx={{ backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#f4ffff" }}
                                                        style={{ height: 10 }}

                                                    >
                                                        <CLTableCell align="center" width="5%" >
                                                            <Checkbox
                                                                checked={row.attachedFlg === 1 ? true : false}
                                                                onChange={() => onSubmit_attached(row.fileID, row.attachedFlg)}
                                                            />
                                                        </CLTableCell>
                                                        <CLTableCell align="left" width="15%" sx={{ pl: 2 }}>{row.registDT}</CLTableCell>
                                                        <CLTableCell align="left" width="35%" >{row.fileName}</CLTableCell>
                                                        <CLTableCell
                                                            align="left" width="35%"
                                                            className="docCell"
                                                        >
                                                            <Grid container>
                                                                <Grid item xs={10.5} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                                                    {row.docName}
                                                                </Grid>
                                                                <Grid item xs={1.5} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}
                                                                    className="editDocCell"
                                                                >
                                                                    <EditIcon
                                                                        className="editIcon"
                                                                        onClick={() => handleOpenEditFileName(row.fileID, row.fileName, row.attachedFlg, row.registDT, row.docName)}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </CLTableCell>
                                                        <CLTableCell align="left" width="5%" >
                                                            {row.docType !== 1 ? <DeleteIcon sx={{ mt: 0.4 }} onClick={() => onSubmit_Delete(row.fileID, row.fileName)} /> : null}
                                                        </CLTableCell>
                                                        <CLTableCell align="left" width="5%" onClick={() => handleOpenPDF(row.fileID, row.docName)}><PictureAsPdfIcon sx={{ mt: 0.4 }} color="primary" /></CLTableCell>
                                                    </TableRow>

                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableFlame>
                            <DigitalConstractEditFileName
                                kojiCD={kojiCD}
                                seqNo={seqNo}
                                contractType={contractType}
                                fileID={fileID}
                                fileName={baseFileName}
                                docName={baseDocName}
                                attachedFlg={attachedFlg}
                                registDT={registDT}
                                open={openEditFileName}
                                close={setOpenEditFileName}
                            />
                        </Paper>
                    </SelectContractContent>
                    <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Grid sx={{ mr: 5 }}>
                            <SelectContractCloseButton type="button" onClick={handleClose}>閉じる<KeyboardReturnIcon sx={{ ml: 1 }} /></SelectContractCloseButton>
                        </Grid>
                        <Grid sx={{ mr: 5 }}>
                            <UploadButton type="button"
                                kojiCD={kojiCD}
                                seqNo={seqNo}
                                contractType={contractType}
                            />
                        </Grid>
                    </DialogActions>
                </Dialog>
            </div>
        </React.Fragment >
    );
}