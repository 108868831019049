import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { SelectContractContent, SelectContractCloseButton, SelectContractTitle } from '../../css/CSS.js';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

export default function DigitalConstractPDFViewer(props) {

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
        props.close(false)
    };

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return (
        <React.Fragment>
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    fullScreen
                >
                    <SelectContractTitle id="alert-dialog-title">
                            書類確認
                    </SelectContractTitle>
                    <SelectContractContent>
                        <Grid container columns={12} rowSpacing={{ xs: 1 }} columnSpacing={5} sx={{ pt: 3 }}>
                            <Grid item xs={12}>
                                <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Grid item xs={12}>
                                        <embed type="application/pdf" src={props.PDF} width="100%" height="780px" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </SelectContractContent>
                    <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <SelectContractCloseButton type="button" onClick={handleClose}>閉じる<KeyboardReturnIcon sx={{ ml: 1 }} /></SelectContractCloseButton>
                    </DialogActions>
                </Dialog>
            </div>
        </React.Fragment>
    );
}