import React, { useContext, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CheckBox from '../CheckBox'
import { useForm, Controller } from "react-hook-form";
import { UserInputData } from './ShunkoFrame';
import { Contents, FormTitle } from '../css/CSS.js';
import { useNavigate } from 'react-router-dom';
import useAuthAxios from "../../useAuthAxios";
import { ConfirmationContext } from "../../Confirmation";
import LoadingDialog from '../Loading';

export default function SyokaiSonota(props) {

    //各コンポーネントの動作を設定
    const validationRules = {
        sonota: {
            maxLength: { value: 389, message: '380文字以内で入力してください。' },
            validate: (value) => {
                // 1行38文字以内
                for (let v of value.split("\n")) {
                    if (v.length > 38) return "1行38文字以内で入力してください。";
                }
                // 最大10行
                if (value.split("\n").length > 10) {
                    return "10行以内で入力してください。";
                }
            }
        },
    }

    const { currentState, setCurrentState } = useContext(UserInputData);
    const { control, handleSubmit, getValues, formState: { errors }, watch } = useForm({
        defaultValues: { ...currentState, ...currentState.sonota },
        mode: 'onChange'
    });

    const [formBack, setFormBack] = useState(false)
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (formBack === true) {
                props.handleBack();
            }
        }
        setFormBack(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formBack]);
    const { kojiCD, cutTeimei, changeTeimei } = useContext(ConfirmationContext);
    const authAxios = useAuthAxios();

    const navigate = useNavigate();

    const [processing_post, setProcessing_post] = useState(false);
    const [formPost, setFormPost] = useState(false)
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (formPost === true) {
                setProcessing_post(true)
                authAxios
                    .post('/api/confirmation/shunko/' + kojiCD + "?order=update", currentState)
                    .then(response => {
                        if (response.status === 200) {
                            navigate("/confirmation/list");
                            setProcessing_post(false)
                        }
                    }).catch((response) => {
                        alert("保存に失敗しました。", response.body);
                        setProcessing_post(false)
                    })
            }
        }
        setFormPost(false);
        setTimeout(() => {
            setProcessing_post(false);
        }, 10000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formPost]);

    const [formSave, setFormSave] = useState(false)
    const [processing_save, setProcessing_save] = useState(false);
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (formSave === true) {
                setProcessing_save(true)
                authAxios
                    .post('/api/confirmation/shunko/' + kojiCD + "?order=update", currentState)
                    .then(response => {
                        if (response.status === 200) {
                            props.handleSave(true);
                            setProcessing_save(true)
                        }
                    }).catch((response) => {
                        alert("保存に失敗しました。", response.body);
                        setProcessing_save(true)
                    })
            }
        }
        setFormSave(false);
        setTimeout(() => {
            setProcessing_save(false);
        }, 10000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formSave]);

    const onSubmit = (action) => {

        const shokaiTenken = getValues("shokaiTenken");
        const sonota = { "sonota": getValues("sonota"), "sonotaNote": getValues("sonotaNote") }

        if (action === "post") {
            setCurrentState({ ...currentState, shokaiTenken, sonota, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
            setFormPost(true);
        } else if (action === "save") {
            setCurrentState({ ...currentState, shokaiTenken, sonota, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
            setFormSave(true);
        } else if (action === "back") {
            setCurrentState({ ...currentState, shokaiTenken, sonota, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
            setFormBack(true)
        }
    };
    return (
        <Contents component="main" sx={{ pt: 1 }}>
            <form onSubmit={handleSubmit(onSubmit)} >
                <Grid container columns={12} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pl: 2 }}>
                    <Grid>
                        <FormTitle>
                            初回点検(3カ月点検)
                        </FormTitle>
                    </Grid>
                </Grid>
                <Grid container columns={12} sx={{ pt: 1, pl: 2 }}>
                    <Grid item xs={3} >
                        <Controller
                            control={control}
                            name="shokaiTenken"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 0.5, pb: 0.5 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("shokaiTenken") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >ご案内させて頂きました。</Typography>} labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container columns={12} columnSpacing={{ xs: 1, sm: 3, md: 3 }}>
                    <Grid>
                        <FormTitle>
                            その他の確認
                        </FormTitle>
                    </Grid>
                </Grid>
                <Grid container columns={12} sx={{ pt: 0.5 }}>
                    <Grid item xs={12} sx={{}}>
                        <Controller
                            control={control}
                            name="sonota"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 0.5, pr: 6 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("sonota") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >メモ</Typography>}
                                    labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid container columns={16} sx={{ pl: 1.3 }}>
                        <Controller
                            control={control}
                            name="sonotaNote"
                            rules={validationRules.sonota}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    style={{ width: '680px', minWidth: '680px' }}
                                    margin="normal"
                                    multiline
                                    minRows={10}
                                    maxRows={10}
                                    variant="outlined"
                                    error={errors.sonotaNote}
                                    helperText={errors.sonotaNote && errors.sonotaNote?.message}
                                    inputProps={{ maxLength: 389 }}
                                    disabled={watch("sonota") === 2 ? true : false}
                                    placeholder="38文字、10行まで入力できます。"

                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container columns={12} rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 2 }}>
                    <Grid container columns={16} rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 5 }}>
                        <Grid item xs={11} >
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("post")} disabled={processing_post}>
                                {processing_post ? "処理中" : "保存して一覧へ"}
                            </Button>
                            <LoadingDialog load={processing_post} />
                        </Grid>
                        <Grid item xs={2} >
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("back")} >
                                戻る
                            </Button>
                        </Grid>
                        <Grid item xs={3} >
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("save")} disabled={processing_save}>
                                {processing_save ? "処理中" : "確認画面へ"}
                            </Button>
                            <LoadingDialog load={processing_save} />
                        </Grid>
                    </Grid>
                </Grid>
            </form >
        </Contents >
    );
}