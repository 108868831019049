import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import useAuthAxios from "../../useAuthAxios";
import { AuthContext } from "../../Auth";
import SearchIcon from "@mui/icons-material/Search";
import { useForm, Controller } from "react-hook-form";
import { ConfirmationContents } from '../css/CSS.js';
import Typography from '@mui/material/Typography';
import LoadingDialog from '../Loading';
import ConfirmationDelConfirm from './ConfirmationDelConfirm';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ConfirmationContext } from "../../Confirmation";

const TableFlame = styled(TableContainer)({
    borderRadius: "10px 10px 0 0",
});
const Header = styled(TableRow)({
    backgroundColor: "#289bdf",
});
const Text = styled(Typography)({
    color: "#FFFFFF",
    transform: "scale(0.9)",
});
const CLTableCell = styled(TableCell)({
    padding: "5px",
});
const Works = styled(Button)({
    color: "#000000",
});

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
    {
        id: 'customerCD',
        numeric: true,
        disablePadding: false,
        label: 'お客様No',
        align: 'left',
    },
    {
        id: 'kojiCD',
        numeric: true,
        disablePadding: false,
        label: '工事コード',
        align: 'left'
    },
    {
        id: 'agreeShitenName',
        numeric: true,
        disablePadding: true,
        label: '契約支店',
        align: 'left'
    },
    {
        id: 'buildShitenName',
        numeric: true,
        disablePadding: true,
        label: '施工支店',
        align: 'left'
    },
    {
        id: 'kojiName',
        numeric: true,
        disablePadding: false,
        label: '邸名',
        align: 'left'
    },
    {
        id: 'chakko',
        numeric: true,
        disablePadding: false,
        label: '着工（地縄）',
        align: 'center'
    },
    {
        id: 'jyoto',
        numeric: true,
        disablePadding: false,
        label: '上棟',
        align: 'center'
    },

    {
        id: 'shunko',
        numeric: true,
        disablePadding: false,
        label: '竣工',
        align: 'center'
    },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <Header>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={headCell.id === 'customerCD' ? { pr: 0, pl: 1.5 } : { pr: 0, pl: 0 }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <Text >{headCell.label}</Text>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </Header>
        </TableHead >
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number,
};

export default function ConfirmationList() {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const authAxios = useAuthAxios();
    const [list, setList] = useState()
    const { auth } = useContext(AuthContext);
    const headers = {
        "Authorization": "Bearer " + auth,
        'Content-Type': 'application/json',
    };

    const [processing_setList, setProcessing_setList] = useState(false);
    React.useEffect(() => {
        setProcessing_setList(true)
        authAxios
            .get('/api/confirmations', { headers })
            .then(response => {
                if (response.status === 200) {
                    const listdata = JSON.parse((JSON.stringify(response.data)))
                    setPage(0)
                    setList(listdata.kojiList)
                    setProcessing_setList(false)
                } else if (response.status === 401) {
                    alert("認証に失敗しました。");
                    navigate("/");
                    setProcessing_setList(false)
                }
            }).catch((response) => {
                alert("一覧情報取得に失敗しました。", response.body);
                navigate("/");
                setProcessing_setList(false)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigate = useNavigate();

    const { control, handleSubmit, setValue, getValues } = useForm({
        defaultValues: {
            customerCD: "",
            kojiCD: "",
        },
    });

    const [processing_searchReset, setProcessing_searchReset] = useState(false);
    const onSubmit_Reset = () => {
        setProcessing_searchReset(true)
        setValue("customerCD", "")
        setValue("kojiCD", "")
        authAxios
            .get('/api/confirmations', { headers })
            .then(response => {
                if (response.status === 200) {
                    const listdata = JSON.parse((JSON.stringify(response.data)))
                    setPage(0)
                    setList(listdata.kojiList)
                    setProcessing_searchReset(false)
                } else if (response.status === 401) {
                    alert("認証に失敗しました。");
                    navigate("/");
                    setProcessing_searchReset(false)
                }
            }).catch((response) => {
                alert("絞り込み情報取得に失敗しました。", response.body);
                navigate("/");
                setProcessing_searchReset(false)
            });
        setTimeout(() => {
            setProcessing_searchReset(false);
        }, 10000);
    };


    const [processing_search, setProcessing_search] = useState(false);
    const onSubmit = () => {
        setProcessing_search(true)
        authAxios
            .get('api/confirmations?csCD=' + getValues("customerCD") + "&kojiCD=" + getValues("kojiCD"), { headers })
            .then((response) => {
                if (response.status === 200) {
                    const listdata = JSON.parse((JSON.stringify(response.data)))
                    setPage(0)
                    setList(listdata.kojiList)
                    setProcessing_search(false);
                } else if (response.status === 401) {
                    alert("認証に失敗しました。");
                    navigate("/");
                    setProcessing_search(false);
                }
            }).catch((response) => {
                alert("絞り込み情報取得に失敗しました。", response.body);
                setProcessing_search(false);
            })
        setTimeout(() => {
            setProcessing_search(false);
        }, 10000);
    };

    //削除機能 
    const { confirm, setConfirm, deleteCheck, setDeleteCheck } = useContext(ConfirmationContext);

    const [KojiCD, setKojiCD] = React.useState("");
    const [Confirmation, setConfirmation] = React.useState("");
    const chakko = "chakko"
    const jyoto = "jyoto"
    const shunko = "shunko"

    const deleteChecked = (kojiCD, confirmationType) => {
        setKojiCD(kojiCD)
        setConfirmation(confirmationType)
        setConfirm(true)
    }

    React.useEffect(() => {
        if (deleteCheck === true) {
            authAxios
                .delete('api/confirmation/delete/' + KojiCD, { headers: headers, data: { Confirmation } })
                .then(response => {
                    if (response.status === 200) {
                        setDeleteCheck(false)
                        setConfirm(false)
                        setProcessing_setList(true)
                        authAxios
                            .get('/api/confirmations', { headers })
                            .then(response => {
                                if (response.status === 200) {
                                    const listdata = JSON.parse((JSON.stringify(response.data)))
                                    setPage(0)
                                    setList(listdata.kojiList)
                                    setProcessing_setList(false)
                                } else if (response.status === 401) {
                                    alert("認証に失敗しました。");
                                    navigate("/");
                                    setProcessing_setList(false)
                                }
                            }).catch((response) => {
                                alert("一覧情報取得に失敗しました。", response.body);
                                navigate("/");
                                setProcessing_setList(false)
                            });
                    }
                }).catch((response) => {
                    alert("作成済み帳票の削除に失敗しました。", response.body);
                    setDeleteCheck(false)
                    setConfirm(false)
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteCheck]);

    const onSubmit_Delete = () => {
        navigate("/confirmation/list");
    };

    return (
        <ConfirmationContents>
            <form onSubmit={handleSubmit()} >
                <LoadingDialog load={processing_setList} />
                <ConfirmationDelConfirm value={KojiCD} open={confirm} />
                <Grid container columns={20} sx={{ mb: 2, pb: 3 }}>
                    <Grid item xs={5} sx={{ mt: 1 }}>
                        <SearchIcon />
                        <Controller
                            control={control}
                            name="customerCD"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id="customerCD"
                                    label="お客様No"
                                    variant="standard"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={7} sx={{ mt: 1 }}>
                        <SearchIcon />
                        <Controller
                            control={control}
                            name="kojiCD"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id="kojiCD"
                                    label="工事コード"
                                    variant="standard"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={2} sx={{ mt: 2 }}>
                        <Button
                            className="confButton"
                            type="submit"
                            variant="contained"
                            onClick={() => onSubmit()}
                            sx={{ transform: "scale(1.2)" }}
                            disabled={processing_search}
                        >
                            {processing_search ? "検索中" : "検索"}
                        </Button>
                        <LoadingDialog load={processing_search} />
                    </Grid>
                    <Grid item xs={4} sx={{ mt: 2 }}>
                        <Button
                            color="error"
                            type="button"
                            variant="contained"
                            onClick={() => onSubmit_Reset()}
                            sx={{ transform: "scale(1.2)" }}
                            disabled={processing_searchReset}
                        >{processing_searchReset ? "リセット中" : "検索条件リセット"}
                        </Button>
                        <LoadingDialog load={processing_searchReset} />
                    </Grid>

                    <Grid item xs={2} sx={{ mt: 2 }}>
                        <Button
                            className="confButton"
                            type="button"
                            variant="contained"
                            onClick={() => onSubmit_Delete()}
                            sx={{ transform: "scale(1.2)" }}
                        >
                            一覧モード
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Paper sx={{ width: '100%', mb: 2, }}>
                <TableFlame>
                    <Table
                        sx={{ minWidth: 900 }}
                        aria-labelledby="tableTitle"
                        align="center"
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={list?.length}
                        />
                        <TableBody>
                            {stableSort(list, getComparator(order, orderBy))
                                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                ?.map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.kojiCD}
                                            sx={{ backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#EEEEEE" }}
                                        >
                                            <TableCell align="left" width="110px" sx={{ pr: 0 }}>{row.customerCD}</TableCell>
                                            <TableCell align="left" width="110px" sx={{ pl: 1, pr: 0 }}>{row.kojiCD}</TableCell>
                                            <TableCell align="left" width="100px" sx={{ pl: 0.8, pr: 0 }}>{row.agreeShitenName}</TableCell>
                                            <TableCell align="left" width="100px" sx={{ pl: 0.8, pr: 0 }}>{row.buildShitenName}</TableCell>
                                            <TableCell align="left" width="350px" sx={{ pl: 0.0, pr: 0 }}>{row.kojiName}</TableCell>
                                            {row.chakko === 0 ?
                                                <CLTableCell align="center" width="130px" >未作成</CLTableCell>
                                                :
                                                (row.chakko === 1 ?
                                                    <CLTableCell align="center" width="130px" >
                                                        <Works
                                                            type="button"
                                                            name="chakko"
                                                            value={row.kojiCD}
                                                            onClick={(e) => deleteChecked(row.kojiCD, chakko)}
                                                        >
                                                            作成中
                                                            <DeleteForeverIcon />
                                                        </Works>
                                                    </CLTableCell>
                                                    :
                                                    <CLTableCell align="center" width="130px" >
                                                        <Works
                                                            type="button"
                                                            name="chakko"
                                                            value={row.kojiCD}
                                                            onClick={(e) => deleteChecked(row.kojiCD, chakko)}
                                                        >
                                                            作成済み<br />{row.chakkoDate}
                                                            <DeleteForeverIcon />
                                                        </Works>
                                                    </CLTableCell>
                                                )
                                            }
                                            {row.jyoto === 0 ?
                                                <CLTableCell align="center" width="100px" >未作成</CLTableCell>
                                                :
                                                (row.jyoto === 1 ?
                                                    <CLTableCell align="center" width="100px">
                                                        <Works
                                                            type="button"
                                                            name="jyoto"
                                                            value={row.kojiCD}
                                                            onClick={(e) => deleteChecked(row.kojiCD, jyoto)}
                                                        >
                                                            作成中
                                                            <DeleteForeverIcon />
                                                        </Works>
                                                    </CLTableCell>
                                                    :
                                                    <CLTableCell align="center" width="100px" >
                                                        <Works
                                                            type="button"
                                                            name="jyoto"
                                                            value={row.kojiCD}
                                                            onClick={(e) => deleteChecked(row.kojiCD, jyoto)}
                                                        >
                                                            作成済み<br />{row.jyotoDate}
                                                            <DeleteForeverIcon />
                                                        </Works>
                                                    </CLTableCell>
                                                )
                                            }
                                            {row.shunko === 0 ?
                                                <CLTableCell align="center" width="100px" >未作成</CLTableCell>
                                                :
                                                (row.shunko === 1 ?
                                                    <CLTableCell align="center" width="100px">
                                                        <Works
                                                            type="button"
                                                            name="shunko"
                                                            value={row.kojiCD}
                                                            onClick={(e) => deleteChecked(row.kojiCD, shunko)}
                                                        >
                                                            作成中
                                                            <DeleteForeverIcon />
                                                        </Works>
                                                    </CLTableCell>
                                                    :
                                                    <CLTableCell align="center" width="100px" >
                                                        <Works
                                                            type="button"
                                                            name="shunko"
                                                            value={row.kojiCD}
                                                            onClick={(e) => deleteChecked(row.kojiCD, shunko)}
                                                        >
                                                            作成済み<br />{row.shunkoDate}
                                                            <DeleteForeverIcon />
                                                        </Works>
                                                    </CLTableCell>
                                                )
                                            }
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableFlame>
                {list ?
                    <TablePagination
                        component="div"
                        count={list?.length}
                        labelRowsPerPage="表示件数"
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[5, 10, 25, 30, 50]}
                        page={page}
                        labelDisplayedRows={(from, to, count = list?.length) => (`全${count}中　${from.from}件～${from.to === -1 ? from.count : from.to}件を表示  `)}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    : <Grid />
                }

            </Paper>
        </ConfirmationContents >
    );
}
