import React, { useState, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import { SelectContractContent, SelectContractTitle, ReturnStatusButton, AdvancedStatusButton } from '../../css/CSS.js';
import useAuthAxios from "../../../useAuthAxios";
import { useNavigate } from 'react-router-dom';
import LoadingDialog from '../../Loading';
import Typography from '@mui/material/Typography';
import AssignmentReturnedOutlinedIcon from '@mui/icons-material/AssignmentReturnedOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import Checkbox from '@mui/material/Checkbox';
import UndoIcon from '@mui/icons-material/Undo';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useForm, Controller } from "react-hook-form";
import { DigitalConstractsContext } from "../../../DigitalConstracts";


const TableFlame = styled(TableContainer)({
    borderRadius: "5px 5px 0 0",
    maxHeight: 165,
});
const TableHeader = styled(TableCell)({
    backgroundColor: "#1976d2",
    padding: "5px",
});
const Header = styled(TableRow)({
    backgroundColor: "#1976d2",
});
const Text = styled(Typography)({
    color: "#FFFFFF",
    transform: "scale(0.9)",
});
const CLTableCell = styled(TableCell)({
    padding: "0px",
});
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}
const headCells = [
    {
        id: 'checkbox',
        label: '',
        align: 'left'
    },
    {
        id: 'department',
        label: '部門',
        align: 'left',
    },
    {
        id: 'empolyName',
        label: '氏名',
        align: 'left'
    },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <Header>
                {headCells.map((headCell) => (
                    <TableHeader
                        key={headCell.id}
                        align={headCell.align}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{ pl: 0 }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <Text >{headCell.label}</Text>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableHeader>
                ))}
            </Header>
        </TableHead >
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number,
};


export default function DigitalConstractDropRequest(props) {
    const [open, setOpen] = React.useState(false);
    const authAxios = useAuthAxios();
    const navigate = useNavigate();
    const [processing, setProcessing] = useState(false);

    const headers = { "Authorization": "Bearer " + localStorage.getItem("Auth"), };
    const { kojiCD, seqNo, contractType, setStatusCheck } = useContext(DigitalConstractsContext);


    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClose = () => {
        setOpen(false);
        props.close(false)
    };

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const { control, handleSubmit } = useForm({
        defaultValues: { kojiCD: !props.kojiCD ? "" : props.kojiCD }
        , mode: 'onChange'
    });

    const [checkList, setCheckList] = React.useState();
    React.useEffect(() => {
        if (processing === true) { return }
        if (open === true) {
            setProcessing(true)
            authAxios
                .get('api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + '/approvers'
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        const listData = JSON.parse((JSON.stringify(response.data)))
                        setCheckList(listData.approversList)
                    }
                    setProcessing(false)
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        alert("承認依頼先の取得を失敗しました。");
                        setProcessing(false)
                    }
                    setProcessing(false)
                });
            setTimeout(() => {
            }, 10000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const [approversList, setApproversList] = React.useState([]);
    const onSubmit = (employCD) => {
        setApproversList([...approversList, { employCD: employCD }])
    };
    const [request, setRequest] = React.useState(false);
    const handleRequest = () => {
        setRequest(true)
    };

    React.useEffect(() => {
        if (processing === true) { return }
        if (request === true) {
            setStatusCheck(false)
            authAxios
                .post('api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + '/approval?action=request'
                    , { approversList }
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        setRequest(false)
                        setProcessing(false)
                        setStatusCheck(true)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        alert("承認依頼に失敗しました。");
                        setProcessing(false)
                    }
                    setProcessing(false)
                });

            setTimeout(() => {
            }, 10000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [request]);

    const [rejected, setRejected] = React.useState(false);
    const handleRejected = () => {
        setRejected(true)
    };
    React.useEffect(() => {
        if (processing === true) { return }
        if (rejected === true) {
            setProcessing(true)
            setStatusCheck(false)
            authAxios
                .post('api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + '/confirm?action=rejected'
                    , { comfirmerList: null }
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        setRejected(false)
                        setProcessing(false)
                        setStatusCheck(true)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        alert("差戻しに失敗しました。");
                    }
                    setRejected(false)
                    setProcessing(false)
                });
            setTimeout(() => {
            }, 10000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rejected]);


    return (
        <React.Fragment>
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    fullWidth
                    maxWidth="sm"
                >
                    <LoadingDialog load={processing} />
                    <SelectContractTitle >
                        <Grid container columns={12} rowSpacing={{ xs: 3 }} columnSpacing={4} >
                            <Grid item xs={11} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                社内確認及び承認依頼
                            </Grid>
                            <Grid item xs={1} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                <ClearIcon fontSize="large" onClick={() => handleClose()} />
                            </Grid>
                        </Grid>
                    </SelectContractTitle>
                    <SelectContractContent>
                        <Grid container columns={12} columnSpacing={1} sx={{ pt: 2, pb: 2 }}>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                <Typography sx={{ fontSize: 15 }} >
                                    契約書の確認をしてください。<br />
                                    契約書の確認後、内容が正しければ承認依頼を行います。<br />
                                    承認者は下記チェックリストから選択してください。<br />
                                    ※複数選択可<br />
                                </Typography>
                            </Grid>
                        </Grid>
                        <form onSubmit={handleSubmit(onSubmit)} >
                            <Paper sx={{ width: '100%', boxShadow: 0, pt: 0 }} >
                                <TableFlame style={{ minHeight: '250px' }}>
                                    <Table
                                        stickyHeader
                                        aria-labelledby="tableTitle"
                                        align="center"
                                    >
                                        <EnhancedTableHead
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={handleRequestSort}
                                            rowCount={checkList?.length}
                                        />
                                        <TableBody>
                                            {stableSort(checkList, getComparator(order, orderBy))
                                                ?.map((row, index) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            tabIndex={-1}
                                                            key={index}
                                                            sx={{ backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#f4fff4" }}
                                                            style={{ height: 10 }}
                                                        >
                                                            <CLTableCell align="left" width="5%">
                                                                <Controller
                                                                    control={control}
                                                                    name="kojiCD"
                                                                    render={({ field, fieldState }) => (
                                                                        <Checkbox onClick={() => onSubmit(row.employCD)} />
                                                                    )}
                                                                />
                                                            </CLTableCell>
                                                            <CLTableCell align="left" width="20%" sx={{ pl: 0.5 }}>{row.secName}</CLTableCell>
                                                            <CLTableCell align="left" width="75%" >{row.employName}</CLTableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableFlame>
                            </Paper>
                        </form>
                    </SelectContractContent>

                    <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Grid sx={{ mr: 5 }}>
                            <ReturnStatusButton
                                sx={{ width: "250px" }} type="button"
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                onClick={() => handleRejected()}
                            ><UndoIcon sx={{ mr: 1 }} />差戻す
                            </ReturnStatusButton>
                        </Grid >
                        <Grid >
                            <AdvancedStatusButton
                                sx={{ width: "250px" }}
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                onClick={handleRequest}
                            >
                                <AssignmentReturnedOutlinedIcon sx={{ mr: 1 }} />承認依頼
                            </AdvancedStatusButton>
                        </Grid >
                    </DialogActions>
                </Dialog>
            </div>
        </React.Fragment>
    );
}