import { authAxios } from "./axios";
import { useContext, useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import { AuthContext } from "./Auth";
import { useNavigate } from 'react-router-dom';
import { setCookie } from "./components/Action/HandleCookie"

const useAuthAxios = () => {
    const refresh = useRefreshToken();
    const { auth } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        // リクエスト前に実行。headerに認証情報を付与する
        const requestIntercept = authAxios.interceptors.request.use(
            (config) => {
                if (!config.headers["Authorization"]) {
                    config.headers["Authorization"] = `Bearer ${localStorage.getItem("Auth")}`;
                }
                return config;
            },
            (error) => Promise.reject(error)
        );

        // レスポンスを受け取った直後に実行。もし認証エラーだった場合、再度リクエストする。
        const responseIntercept = authAxios.interceptors.response.use(
            (response) => response,
            async (error) => {
                const prevRequest = error?.config;
                // 403認証エラー(headerにaccess_tokenがない。もしくはaccess_tokenが無効)
                if (error?.response?.status === 403 && !prevRequest.sent) {
                    prevRequest.sent = true;
                    // 新しくaccess_tokenを発行する
                    const newAccessToken = await refresh();
                    prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
                    // 再度実行する
                    return authAxios(prevRequest);
                } else if (error?.response?.status === 401 && !prevRequest.sent) {
                    setCookie("reload", true)
                    navigate("/");
                }
                return Promise.reject(error);
            }
        );

        return () => {
            // 離脱するときにejectする
            authAxios.interceptors.request.eject(requestIntercept);
            authAxios.interceptors.response.eject(responseIntercept);
        };
    }, [auth, refresh]);

    return authAxios;
};

export default useAuthAxios;
