import React, { useContext, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CheckBox from '../CheckBox'
import { useForm, Controller } from "react-hook-form";
import { UserInputData } from './JyotoFrame';
import Toggle, { TOGGLE_STATES } from '../Toggle'
import { Contents, FormTitle } from '../css/CSS.js';
import { useNavigate } from 'react-router-dom';
import useAuthAxios from "../../useAuthAxios";
import { ConfirmationContext } from "../../Confirmation";
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import LoadingDialog from '../Loading';

const Select = styled(TextField)({
    minWidth: 140,
    maxHeight: 20,
});

export default function Setubi(props) {

    //各コンポーネントの動作を設定
    const validationRules = {
        denkiNote: { maxLength: { value: 20, message: '20桁以内で入力してください。' } },
    }

    const { currentState, setCurrentState } = useContext(UserInputData);
    const { control, handleSubmit, getValues, formState: { errors }, watch } = useForm({
        defaultValues: { ...currentState.setubi },
        mode: 'onChange'
    });

    const [formNext, setFormNext] = useState(false)
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (formNext === true) {
                props.handleNext();
            }
        }
        setFormNext(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formNext]);
    const [formBack, setFormBack] = useState(false)
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (formBack === true) {
                props.handleBack();
            }
        }
        setFormBack(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formBack]);
    const { kojiCD, cutTeimei, changeTeimei } = useContext(ConfirmationContext);
    const authAxios = useAuthAxios();

    const navigate = useNavigate();

    const [processing_post, setProcessing_post] = useState(false);
    const [formPost, setFormPost] = useState(false)
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (formPost === true) {
                setProcessing_post(true);
                authAxios
                    .post('/api/confirmation/jyoto/' + kojiCD + "?order=update", currentState)
                    .then(response => {
                        if (response.status === 200) {
                            navigate("/confirmation/list");
                            setProcessing_post(false);
                        }
                    }).catch((response) => {
                        alert("保存に失敗しました。", response.body);
                        setProcessing_post(false);
                    })
            }
        }
        setFormPost(false);
        setTimeout(() => {
            setProcessing_post(false);
        }, 10000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formPost]);

    const onSubmit = (action) => {

        const setubi = getValues();
        if (action === "post") {
            setCurrentState({ ...currentState, setubi, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
            setFormPost(true);
        } else if (action === "next") {
            setCurrentState({ ...currentState, setubi, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
            setFormNext(true)
        } else if (action === "back") {
            setCurrentState({ ...currentState, setubi, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
            setFormBack(true)
        }
    };

    return (
        <Contents component="main" sx={{ pt: 1 }}>
            <form onSubmit={handleSubmit(onSubmit)} >
                <Grid container columns={12} columnSpacing={{ xs: 1, sm: 3, md: 3 }}>
                    <Grid>
                        <FormTitle>
                            設備工事の確認
                        </FormTitle>
                    </Grid>
                </Grid>
                <Grid container columns={12} sx={{ pt: 1 }}>
                    <Grid item xs={1.8} sx={{ pl: 0.3 }}>
                        <Controller
                            control={control}
                            name="kitchen"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("kitchen") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >キッチン</Typography>} labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={1.5} sx={{}}>
                        <Controller
                            control={control}
                            name="ub"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("ub") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >UB</Typography>} labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={2.3} sx={{}}>
                        <Controller
                            control={control}
                            name="senmenKeshoDai"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("senmenKeshoDai") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >洗面化粧台</Typography>} labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={1.8} sx={{}}>
                        <Controller
                            control={control}
                            name="toilet"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("toilet") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >トイレ</Typography>} labelPlacement="end"

                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Typography sx={{ transform: "scale(1.1)", pt: 1 }}
                        >製本図からの仕様変更</Typography>
                    </Grid>
                    <Grid item xs={2.5}>
                        <Controller
                            control={control}
                            name="seihonzuHenkoUmu"
                            render={({ field: { value, onChange } }) => (
                                <Toggle
                                    setCheckedToggle={onChange}
                                    checkedToggle={value}
                                    toggleValue={"無"}
                                    toggleSts={TOGGLE_STATES.One}
                                    toggleValue2={"有"}
                                    toggleSts2={TOGGLE_STATES.Two}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container columns={16} sx={{ pt: 2 }} >
                    <Grid item xs={16} sx={{ pl: 0.9 }}>
                        <Controller
                            control={control}
                            name="denkiSetubiKoji"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)" }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }} style={watch("denkiSetubiKoji") === 2 ? { textDecoration: "line-through" } : {}}>電気設備工事</Typography>}
                                    labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={16} alignContent="flex-start">
                        <Grid container columns={14} sx={{ transform: "scale(1.1)", pt: 1, pl: 8.5 }}>
                            <Grid item xs={1.5} >
                                <Controller
                                    control={control}
                                    name="denkiBundenban"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("denkiSetubiKoji") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("denkiSetubiKoji") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("denkiBundenban") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >分電盤</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={5} >
                                <Controller
                                    control={control}
                                    name="denkiSwConKasai"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("denkiSetubiKoji") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("denkiSetubiKoji") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("denkiSwConKasai") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >スイッチ、コンセント、火災報知器</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3} >
                                <Controller
                                    control={control}
                                    name="denkiTvLanTelHaisen"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("denkiSetubiKoji") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("denkiSetubiKoji") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("denkiTvLanTelHaisen") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >TV、LAN、TEL配線</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={16} alignContent="flex-start">
                            <Grid container columns={14} sx={{ transform: "scale(1.1)", pt: 1.3, pl: 8.5 }}>
                                <Grid item xs={1.5} sx={{ pt: 2.8 }}>
                                    <Controller
                                        control={control}
                                        name="denkiKeiyakuYoryo"
                                        render={({ field: { onChange, value } }) => (
                                            <FormControlLabel
                                                control={
                                                    <CheckBox
                                                        setCheckedvalue={onChange}
                                                        checkedvalue={value}
                                                        color='primary'
                                                        disabled={watch("denkiSetubiKoji") === 2 ? true : false}
                                                    />
                                                }
                                                label={<Typography sx={{ pl: 1 }}
                                                    style={watch("denkiSetubiKoji") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : watch("denkiKeiyakuYoryo") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                            : {}
                                                    }
                                                >契約容量</Typography>}
                                                labelPlacement="end"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={2.2} sx={{ pt: 1.5 }}>
                                    <Controller
                                        control={control}
                                        name="denkiKeiyakuYoryoAmps"
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                label="アンペア"
                                                disabled={watch("denkiSetubiKoji") === 2 ? true : watch("denkiKeiyakuYoryo") === 2 ? true : false}
                                                id="select"
                                                select
                                                size="small"
                                            >
                                                <MenuItem value={"0"}>　</MenuItem>
                                                <MenuItem value={"40"}>40A</MenuItem>
                                                <MenuItem value={"50"}>50A</MenuItem>
                                                <MenuItem value={"60"}>60A</MenuItem>
                                                <MenuItem value={"80"}>80A(8KVA)</MenuItem>
                                                <MenuItem value={"100"}>100A(10KAV)</MenuItem>
                                            </Select>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={8} sx={{ display: 'flex', alignItems: 'end' }}>
                                    <Typography
                                        style={watch("denkiSetubiKoji") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                            : watch("denkiKeiyakuYoryo") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                : {}
                                        }
                                    >※尚、後から契約容量を変更する場合は、別途費用が発生致します。</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={16} alignContent="flex-start">
                        <Grid container columns={14} sx={{ transform: "scale(1.1)", pt: 2.5, pl: 8.5 }}>
                            <Grid item xs={3.3} sx={{ pt: 0.8 }}>
                                <Controller
                                    control={control}
                                    name="denkiJiyukaPlanSetumei"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("denkiSetubiKoji") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("denkiSetubiKoji") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("denkiJiyukaPlanSetumei") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >電力自由化プラン変更説明</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={1} >
                                <Typography sx={{ pt: 1, pl: 4 }}
                                    style={watch("denkiSetubiKoji") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                        : {}
                                    }
                                >メモ</Typography>
                            </Grid>
                            <Grid item xs={4.6} sx={{ pt: 0.4 }}>
                                <Controller
                                    control={control}
                                    name="denkiNote"
                                    rules={validationRules.denkiKeiyakuYoryoNote}
                                    render={({ field, fieldState }) => (
                                        < TextField
                                            {...field}
                                            style={{ width: '100%' }}
                                            variant="standard"
                                            error={fieldState.invalid}
                                            inputProps={{ maxLength: 20 }}
                                            disabled={watch("denkiSetubiKoji") === 2 ? true : false}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container columns={16} sx={{ pt: 2 }} >
                    <Grid item xs={16} sx={{ pl: 1 }}>
                        <Controller
                            control={control}
                            name="suidoSetubiKoji"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)" }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }} style={watch("suidoSetubiKoji") === 2 ? { textDecoration: "line-through" } : {}}>水道設備工事</Typography>}
                                    labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container columns={16} sx={{ pt: 2 }} >
                    <Grid item xs={10} sx={{ pl: 8.5 }}>
                        <Controller
                            control={control}
                            name="remoconAccessory"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)" }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("remoconAccessory") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                            : {}
                                        }
                                    >リモコン、アクセサリー(手摺り、紙巻、タオル掛けなど)の位置確認</Typography>}
                                    labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'end' }}>
                        <Typography sx={{ transform: "scale(1.1)", pl: 2 }}
                            style={watch("remoconAccessory") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : {}}
                        >※標準位置に取付けさせて頂きます。</Typography>
                    </Grid>
                </Grid>

                <Grid container columns={16} sx={{ pt: 1 }}>
                    <Grid item xs={4} sx={{ pl: 0.4 }}>
                        <Controller
                            control={control}
                            name="gasKoji"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("gasKoji") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >ガス工事</Typography>} labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={4} sx={{}}>
                        <Controller
                            control={control}
                            name="gasOven"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("gasOven") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >ガスオーブン</Typography>} labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={4} sx={{}}>
                        <Controller
                            control={control}
                            name="gasKaran"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("gasKaran") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >ガスカラン(コンセント)</Typography>} labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={4} sx={{}}>
                        <Controller
                            control={control}
                            name="kankiKoji"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("kankiKoji") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >換気工事</Typography>} labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container columns={16} sx={{ pt: 1 }}>
                    <Grid item xs={4} sx={{ pl: 1.4 }}>
                        <Controller
                            control={control}
                            name="kuuchoAircon"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("kuuchoAircon") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >空調・エアコン</Typography>} labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3.7} sx={{ pl: 0.9 }}>
                        <Controller
                            control={control}
                            name="securityKoji"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("securityKoji") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >セキュリティー工事</Typography>} labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={4.3} sx={{ pl: 0.45 }}>
                        <Controller
                            control={control}
                            name="danbouKoji"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("danbouKoji") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >暖房工事</Typography>} labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={4} sx={{ pl: 0.5 }}>
                        <Controller
                            control={control}
                            name="taiyokoKoji"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("taiyokoKoji") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >太陽光工事</Typography>} labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid container columns={16} sx={{ pt: 1 }}>
                        <Grid item xs={4.08} sx={{ pl: 2.2 }}>
                            <Controller
                                control={control}
                                name="jukyuKeiyakuMosikomisho"
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        sx={{ transform: "scale(1.3)", pt: 1 }}
                                        control={
                                            <CheckBox
                                                setCheckedvalue={onChange}
                                                checkedvalue={value}
                                                color='primary'
                                            />
                                        }
                                        label={<Typography sx={{ pl: 1 }}
                                            style={watch("jukyuKeiyakuMosikomisho") === 2 ? { textDecoration: "line-through" } : {}
                                            }
                                        >受給契約申込書お渡し</Typography>} labelPlacement="end"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={3} sx={{}}>
                            <Controller
                                control={control}
                                name="ninteiTuchisho"
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        sx={{ transform: "scale(1.3)", pt: 1 }}
                                        control={
                                            <CheckBox
                                                setCheckedvalue={onChange}
                                                checkedvalue={value}
                                                color='primary'
                                            />
                                        }
                                        label={<Typography sx={{ pl: 1 }}
                                            style={watch("ninteiTuchisho") === 2 ? { textDecoration: "line-through" } : {}
                                            }
                                        >認定通知書お渡し</Typography>} labelPlacement="end"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container columns={12} rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 2 }}>
                    <Grid container columns={16} rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 5 }}>
                        <Grid item xs={12} >
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("post")} disabled={processing_post}>
                                {processing_post ? "処理中" : "保存して一覧へ"}
                            </Button>
                            <LoadingDialog load={processing_post} />
                        </Grid>
                        <Grid item xs={2} sx={{ pr: 1 }}>
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("back")} >
                                戻る
                            </Button>
                        </Grid>
                        <Grid item xs={1.5} sx={{ pl: 1 }}>
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("next")}>
                                次へ
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form >
        </Contents >
    );
}
