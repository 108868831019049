import React, { useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { NomalButton, SelectContractTitle, SelectContractContent, SelectContractText, SelectContractCloseButton, ContractTitle } from '../../css/CSS.js';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import LoadingDialog from '../../Loading';
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import useAuthAxios from "../../../useAuthAxios";
import InputBase from '@mui/material/InputBase';
import EditIcon from '@mui/icons-material/Edit';
import MenuItem from '@mui/material/MenuItem';
import TextField from "@mui/material/TextField";
import ClearIcon from '@mui/icons-material/Clear';
import { DigitalConstractsContext } from "../../../DigitalConstracts";

export default function DigitalConstractEditFileName(props) {
    const navigate = useNavigate()
    const authAxios = useAuthAxios();
    const headers = { "Authorization": "Bearer " + localStorage.getItem("Auth") };
    const [open, setOpen] = React.useState(false);
    const { kojiCD, seqNo, contractType, setStatusCheck } = useContext(DigitalConstractsContext);

    const handleClose = () => {
        setOpen(false);
        props.close(false)
    };

    React.useEffect(() => {
        setValue("docName", "")
        setOpen(props.open);
    }, [props.open]);

    const [processing, setProcessing] = React.useState(false);
    const validationRules = {
        editfileName: {
            maxLength: { value: 150, message: '150文字以内で入力してください。' }
        },
    }

    const { control, handleSubmit, getValues, setValue, watch } = useForm({
        defaultValues: {
            fileName: ""
            , editdocName: ""
            , fileID: ""
            , docName: ""
            , registDT: ""
            , attachedFlg: ""
        },
        mode: 'onChange'
    });

    const [confrim, setConfirm] = React.useState(false)
    const [docName, setDocName] = React.useState(null);

    const onSubmit_Confirm = () => {
        setConfirm(true)
        setValue("fileID", props.fileID)
        setValue("registDT", props.registDT)
        setValue("attachedFlg", props.attachedFlg)
        setValue("fileName", props.fileName)
        if (getValues("docName") === "直接入力") {
            setDocName(getValues("editdocName"))
        } else {
            setDocName(getValues("docName"))
        }
    };

    React.useEffect(() => {
        if (processing === true) { return }
        if (confrim === true) {
            setProcessing(true)
            setStatusCheck(false)
            authAxios
                .put('api/contracts/' + (contractType === "工事請負契約書" ? "base" : "modfy") + "/" + kojiCD + "/" + seqNo + "/attach/" + props.fileID, {
                    fileName: getValues("fileName"),
                    fileID: getValues("fileID"),
                    docName: docName,
                    registDT: getValues("registDT"),
                    attachedFlg: getValues("attachedFlg"),
                }, { headers })
                .then(() => {
                    setOpen(false);
                    props.close(false);
                    setConfirm(false);
                    setProcessing(false);
                    setStatusCheck(true);
                    props.setReload(true);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        alert("書類名編集に失敗しました。");
                        setProcessing(false);
                        setConfirm(false);
                    }
                    setOpen(false);
                    props.close(false);
                    setProcessing(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confrim]);

    return (
        <React.Fragment>
            <div>
                <LoadingDialog load={processing} />
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth
                    maxWidth="md"
                >
                    <form onSubmit={handleSubmit()} >
                        <SelectContractTitle id="alert-dialog-title">
                            <Grid container columns={12} rowSpacing={{ xs: 3 }} columnSpacing={4} >
                                <Grid item xs={11} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                    書類名編集
                                </Grid>
                                <Grid item xs={1} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                    <ClearIcon fontSize="large" onClick={() => handleClose()} />
                                </Grid>
                            </Grid>
                        </SelectContractTitle>
                        <SelectContractContent >
                            <Typography sx={{ pt: 2 }}> チェックリストに表示する書類名を選択してください。</Typography>
                            <Typography sx={{ pb: 2 }}>「直接入力」を選択し、書類名を直接入力することも出来ます。</Typography>
                            <Grid container columns={12} columnSpacing={2}>
                                <Grid item xs={2} style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                    <ContractTitle >
                                        ファイル名
                                    </ContractTitle>
                                </Grid>
                                <Grid item xs={10} >
                                    <ContractTitle >
                                        {props.fileName}
                                    </ContractTitle>
                                </Grid>
                                <Grid item xs={2} style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                    <ContractTitle sx={{ mt: 1 }}>
                                        書類名
                                    </ContractTitle>
                                </Grid>
                                <Grid item xs={3}>
                                    <Controller
                                        control={control}
                                        name="docName"
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                margin="normal"
                                                id="docName"
                                                select
                                                size="small"
                                                style={{ minWidth: 200, backgroundColor: "#ffffff" }}
                                            >
                                                <MenuItem value={""}>　</MenuItem>
                                                <MenuItem value={"工事請負契約約款"}>工事請負契約約款</MenuItem>
                                                <MenuItem value={"保証約款"}>保証約款</MenuItem>
                                                <MenuItem value={"見積書表紙"}>見積書表紙</MenuItem>
                                                <MenuItem value={"見積書内訳"}>見積書内訳</MenuItem>
                                                <MenuItem value={"図面"}>図面</MenuItem>
                                                <MenuItem value={"仕様書"}>仕様書</MenuItem>
                                                <MenuItem value={"直接入力"}>直接入力</MenuItem>
                                            </TextField>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    {watch("docName") === "直接入力" ?
                                        <Grid container columns={12} sx={{ pt: 1 }} columnSpacing={2}>
                                            <Grid item xs={12}>
                                                <Controller
                                                    control={control}
                                                    name="editdocName"
                                                    rules={validationRules.editfileName}
                                                    render={({ field }) => (
                                                        <SelectContractText component="div">
                                                            <InputBase
                                                                {...field}
                                                                id="editdocName"
                                                                variant="standard"
                                                                style={{ width: '100%' }}
                                                            />
                                                        </SelectContractText>
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                        : null
                                    }
                                </Grid>
                            </Grid>
                        </SelectContractContent>
                        <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Grid sx={{ mr: 5 }}>
                                <SelectContractCloseButton type="button" onClick={handleClose}>キャンセル<KeyboardReturnIcon sx={{ ml: 1 }} /></SelectContractCloseButton>
                            </Grid >
                            <Grid >
                                <NomalButton type="button" sx={{ width: "250px" }}
                                    disabled={watch("docName") === "" ? true : watch("docName") === "直接入力" && watch("editdocName") === "" ? true : false}
                                    onClick={() => onSubmit_Confirm()}>
                                    <EditIcon sx={{ mr: 1 }} />
                                    確定
                                </NomalButton>
                            </Grid >
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        </React.Fragment >
    );
}