import React, { useContext, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import CheckBox from '..//CheckBox'
import { useForm, Controller } from "react-hook-form";
import { UserInputData } from './StartRopeFrame';
import { styled } from '@mui/material/styles';
import Toggle, { TOGGLE_STATES } from '../Toggle'
import { Contents, FormTitle } from '../css/CSS.js';
import { useNavigate } from 'react-router-dom';
import useAuthAxios from "../../useAuthAxios";
import { ConfirmationContext } from "../../Confirmation";
import LoadingDialog from '../Loading';

const SelectDirection = styled(TextField)({
    minWidth: 80,
    marginTop: "10px",
    marginLeft: "20px",
});

export default function Kakunin(props) {

    //各コンポーネントの動作を設定
    const validationRules = {
        haichiHenko1Before: { maxLength: { value: 4, message: '4桁以内で入力してください。' }, pattern: { value: /^[0-9]+$/, message: '半角数字のみで入力してください。' }, },
        haichiHenko1After: { maxLength: { value: 4, message: '4桁以内で入力してください。' }, pattern: { value: /^[0-9]+$/, message: '半角数字のみで入力してください。' } },
        haichiHenko2Before: { maxLength: { value: 4, message: '4桁以内で入力してください。' }, pattern: { value: /^[0-9]+$/, message: '半角数字のみで入力してください。' } },
        haichiHenko2After: { maxLength: { value: 4, message: '4桁以内で入力してください。' }, pattern: { value: /^[0-9]+$/, message: '半角数字のみで入力してください。' } },
        sekkeiGLHenkoBefore: { maxLength: { value: 4, message: '4桁以内で入力してください。' }, pattern: { value: /^[0-9]+$/, message: '半角数字のみで入力してください。' } },
        sekkeiGLHenkoAfter: { maxLength: { value: 4, message: '4桁以内で入力してください。' }, pattern: { value: /^[0-9]+$/, message: '半角数字のみで入力してください。' } },
        kyokaiPointNote: { maxLength: { value: 30, message: '30桁以内で入力してください。' } },
        sekkeiGLBMNote: { maxLength: { value: 30, message: '30桁以内で入力してください。' } },
    }

    const { currentState, setCurrentState } = useContext(UserInputData);
    const { control, handleSubmit, getValues, setValue, formState: { errors }, watch } = useForm({
        defaultValues: { ...currentState, ...currentState.kakunin },
        mode: 'onChange'
    });

    const [formNext, setFormNext] = useState(false)
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (formNext === true) {
                props.handleNext();
            }
        }
        setFormNext(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formNext]);

    const { kojiCD, cutTeimei, changeTeimei } = useContext(ConfirmationContext);
    const authAxios = useAuthAxios();

    const navigate = useNavigate();

    const [formPost, setFormPost] = useState(false)
    const [processing_post, setProcessing_post] = useState(false);
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (formPost === true) {
                setProcessing_post(true)
                authAxios
                    .post('/api/confirmation/chakko/' + kojiCD + "?order=update", currentState)
                    .then(response => {
                        if (response.status === 200) {
                            navigate("/confirmation/list");
                            setProcessing_post(false)
                        }
                    }).catch((response) => {
                        alert("保存に失敗しました。", response.body);
                        setProcessing_post(false)
                    })
            }
        }
        setFormPost(false);
        setTimeout(() => {
            setProcessing_post(false);
        }, 10000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formPost]);

    const onSubmit = (action) => {
        if (getValues("haichiHenko1Before") === "") { setValue("haichiHenko1Before", 0) }
        if (getValues("haichiHenko1After") === "") { setValue("haichiHenko1After", 0) }
        if (getValues("haichiHenko2Before") === "") { setValue("haichiHenko2Before", 0) }
        if (getValues("haichiHenko2After") === "") { setValue("haichiHenko2After", 0) }
        if (getValues("sekkeiGLHenkoBefore") === "") { setValue("sekkeiGLHenkoBefore", 0) }
        if (getValues("sekkeiGLHenkoAfter") === "") { setValue("sekkeiGLHenkoAfter", 0) }

        const kakunin = getValues();
        const kiroku = getValues("kiroku");
        if (action === "post") {
            setFormPost(true);
            setCurrentState({ ...currentState, Kakunin1Enable: "true", kakunin, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei, kiroku });
        } else if (action === "next") {
            setCurrentState({ ...currentState, Kakunin1Enable: "true", kakunin, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei, kiroku });
            setFormNext(true)
        }
    };

    return (
        <Contents component="main" >
            <form onSubmit={handleSubmit(onSubmit)} >
                <Grid container columns={12} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 2 }}>
                    <Grid item xs={4} >
                        <Typography sx={{ pl: 5.5, pt: 0.5, transform: "scale(1.3)" }} >別紙　打合せ記録</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Controller
                            control={control}
                            name="kiroku"
                            render={({ field: { value, onChange } }) => (
                                <Toggle
                                    setCheckedToggle={onChange}
                                    checkedToggle={value}
                                    toggleValue={"有"}
                                    toggleSts={TOGGLE_STATES.Two}
                                    toggleValue2={"無"}
                                    toggleSts2={TOGGLE_STATES.One}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container columns={12} columnSpacing={{ xs: 1, sm: 3, md: 3 }}>
                    <Grid>
                        <FormTitle >
                            確認事項1
                        </FormTitle>
                    </Grid>
                </Grid>
                <Grid container columns={12} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 2 }}>
                    <Grid item xs={4} >
                        <Controller
                            control={control}
                            name="kyokaiPoint"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1, pl: 0.5 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }} style={watch("kyokaiPoint") === 2 ? { textDecoration: "line-through" } : {}}>境界ポイントの確認</Typography>}
                                    labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={2.5}>
                        <Controller
                            control={control}
                            name="kyokaiPointKakunin"
                            render={({ field: { value, onChange } }) => (
                                <Toggle
                                    setCheckedToggle={onChange}
                                    checkedToggle={value}
                                    disabled={watch("kyokaiPoint") === 2 ? true : false}
                                    toggleValue={"すべて有"}
                                    toggleSts={TOGGLE_STATES.One}
                                    toggleValue2={"一部無"}
                                    toggleSts2={TOGGLE_STATES.Two}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={0.6} sx={{ pt: 1 }}>
                        <Typography style={watch("kyokaiPoint") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : {}}
                        >メモ</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ pt: 0.5 }} >
                        <Controller
                            control={control}
                            name="kyokaiPointNote"
                            rules={validationRules.kyokaiPointNote}
                            render={({ field, fieldState }) => (
                                <
                                    TextField
                                    {...field}
                                    style={{ width: '140%' }}
                                    variant="standard"
                                    error={fieldState.invalid}
                                    inputProps={{ maxLength: 30 }}
                                    disabled={watch("kyokaiPoint") === 2 ? true : false}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container columns={12} rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 2 }}>
                    <Grid item xs={4} >
                        <Controller
                            control={control}
                            name="haichi"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1.5, }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1.1 }} style={watch("haichi") === 2 ? { textDecoration: "line-through" } : {}}>配置確認・変更</Typography>} labelPlacement="end"

                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <Controller
                            control={control}
                            name="haichiHenkoUmu"
                            render={({ field: { value, onChange } }) => (
                                <Toggle
                                    setCheckedToggle={onChange}
                                    checkedToggle={value}
                                    disabled={watch("haichi") === 2 ? true : false}
                                    toggleValue={"無"}
                                    toggleSts={TOGGLE_STATES.One}
                                    toggleValue2={"有"}
                                    toggleSts2={TOGGLE_STATES.Two}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container columns={16} sx={{ pt: 0.5 }}>
                    <Grid item xs={2} sx={{ pt: 2 }}>
                        <Controller
                            control={control}
                            name="haichiHenko1Side"
                            render={({ field }) => (
                                <FormControlLabel
                                    control={
                                        <SelectDirection
                                            {...field}
                                            label="変更内容"
                                            fullWidth
                                            disabled={watch("haichi") === 2 ? true : watch("haichiHenkoUmu") === TOGGLE_STATES.Two ? false : true}
                                            margin="normal"
                                            id="select"
                                            select
                                            size="small"
                                        >
                                            <MenuItem value={"0"}>　</MenuItem>
                                            <MenuItem value={"1"}>東</MenuItem>
                                            <MenuItem value={"2"}>西</MenuItem>
                                            <MenuItem value={"3"}>南</MenuItem>
                                            <MenuItem value={"4"}>北</MenuItem>
                                        </SelectDirection>
                                    }
                                    label={<Typography sx={{ pl: 1, pt: 3 }} style={watch("haichi") === 2 ? { color: "#BBBBBB" } : watch("haichiHenkoUmu") === TOGGLE_STATES.Two ? {} : { color: "#BBBBBB" }} > 側</Typography>}
                                    labelPlacement="end"
                                />

                            )}
                        />
                    </Grid>
                    <Grid item xs={1.2} sx={{ pt: 3 }}>
                        <Controller
                            control={control}
                            name="haichiHenko1Before"
                            rules={validationRules.haichiHenko1Before}
                            render={({ field, fieldState }) => (
                                <FormControlLabel
                                    control={<
                                        TextField
                                        sx={{ pt: 2 }}
                                        {...field}
                                        variant="standard"
                                        disabled={watch("haichi") === 2 ? true : watch("haichiHenkoUmu") === TOGGLE_STATES.Two ? false : true}
                                        error={fieldState.invalid}
                                        inputProps={{ maxLength: 4, style: { textAlign: 'right' } }}
                                    />}
                                    label={<Typography sx={{ pl: 1, pt: 1.5 }} style={watch("haichi") === 2 ? { color: "#BBBBBB" } : watch("haichiHenkoUmu") === TOGGLE_STATES.Two ? {} : { color: "#BBBBBB" }}>mm</Typography>}
                                    labelPlacement="end"

                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={0.5} sx={{ pt: 5, mr: 1 }}>
                        <ArrowForwardIcon style={watch("haichi") === 2 ? { color: "#BBBBBB" } : watch("haichiHenkoUmu") === TOGGLE_STATES.Two ? {} : { color: "#BBBBBB" }} />
                    </Grid>
                    <Grid item xs={1.2} sx={{ pt: 3 }}>
                        <Controller
                            control={control}
                            name="haichiHenko1After"
                            rules={validationRules.haichiHenko1After}
                            render={({ field, fieldState }) => (
                                <FormControlLabel
                                    control={<
                                        TextField
                                        sx={{ pt: 2 }}
                                        {...field}
                                        variant="standard"
                                        disabled={watch("haichi") === 2 ? true : watch("haichiHenkoUmu") === TOGGLE_STATES.Two ? false : true}
                                        error={fieldState.invalid}
                                        inputProps={{ maxLength: 4, style: { textAlign: 'right' } }}
                                    />}
                                    label={<Typography sx={{ pl: 1, pt: 1.5 }} style={watch("haichi") === 2 ? { color: "#BBBBBB" } : watch("haichiHenkoUmu") === TOGGLE_STATES.Two ? {} : { color: "#BBBBBB" }}>mm</Typography>}
                                    labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={2} sx={{ pt: 2 }}>
                        <Controller
                            control={control}
                            name="haichiHenko2Side"
                            render={({ field }) => (
                                <FormControlLabel
                                    control={
                                        <SelectDirection
                                            {...field}
                                            label="変更内容"
                                            fullWidth
                                            disabled={watch("haichi") === 2 ? true : watch("haichiHenkoUmu") === TOGGLE_STATES.Two ? false : true}
                                            margin="normal"
                                            id="select"
                                            select
                                            size="small"
                                        >
                                            <MenuItem value={"0"}>　</MenuItem>
                                            <MenuItem value={"1"}>東</MenuItem>
                                            <MenuItem value={"2"}>西</MenuItem>
                                            <MenuItem value={"3"}>南</MenuItem>
                                            <MenuItem value={"4"}>北</MenuItem>
                                        </SelectDirection>
                                    }
                                    label={<Typography sx={{ pl: 1, pt: 3 }} style={watch("haichi") === 2 ? { color: "#BBBBBB" } : watch("haichiHenkoUmu") === TOGGLE_STATES.Two ? {} : { color: "#BBBBBB" }}>側</Typography>}
                                    labelPlacement="end"
                                />

                            )}
                        />
                    </Grid>
                    <Grid item xs={1.2} sx={{ mt: 3 }}>
                        <Controller
                            control={control}
                            name="haichiHenko2Before"
                            rules={validationRules.haichiHenko2Before}
                            render={({ field, fieldState }) => (
                                <FormControlLabel
                                    control={<
                                        TextField
                                        sx={{ pt: 2 }}
                                        {...field}
                                        disabled={watch("haichi") === 2 ? true : watch("haichiHenkoUmu") === TOGGLE_STATES.Two ? false : true}
                                        variant="standard"
                                        error={fieldState.invalid}
                                        inputProps={{ maxLength: 4, style: { textAlign: 'right' } }}
                                    />}
                                    label={<Typography sx={{ pl: 1, pt: 1.5 }} style={watch("haichi") === 2 ? { color: "#BBBBBB" } : watch("haichiHenkoUmu") === TOGGLE_STATES.Two ? {} : { color: "#BBBBBB" }}>mm</Typography>}
                                    labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={0.5} sx={{ pt: 5, mr: 1 }}>
                        <ArrowForwardIcon style={watch("haichi") === 2 ? { color: "#BBBBBB" } : watch("haichiHenkoUmu") === TOGGLE_STATES.Two ? {} : { color: "#BBBBBB" }} />
                    </Grid>
                    <Grid item xs={1.2} sx={{ pt: 3 }}>
                        <Controller
                            control={control}
                            name="haichiHenko2After"
                            rules={validationRules.haichiHenko2After}
                            render={({ field, fieldState }) => (
                                <FormControlLabel
                                    control={<
                                        TextField
                                        sx={{ pt: 2 }}
                                        {...field}
                                        disabled={watch("haichi") === 2 ? true : watch("haichiHenkoUmu") === TOGGLE_STATES.Two ? false : true}
                                        variant="standard"
                                        error={fieldState.invalid}
                                        inputProps={{ maxLength: 4, style: { textAlign: 'right' } }}
                                    />}
                                    label={<Typography sx={{ pl: 1, pt: 1.5 }} style={watch("haichi") === 2 ? { color: "#BBBBBB" } : watch("haichiHenkoUmu") === TOGGLE_STATES.Two ? {} : { color: "#BBBBBB" }}>mm</Typography>}
                                    labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container columns={16} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 2, pl: 3 }} >
                    <Grid item xs={16} >
                        <Controller
                            control={control}
                            name="houkisei"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1.1 }} style={watch("houkisei") === 2 ? { textDecoration: "line-through" } : {}}>法規制等の確認(設計担当者確認事項)</Typography>}
                                    labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={16} alignContent="flex-start">
                        <Grid container columns={14} sx={{ transform: "scale(1.1)", pt: 1, pl: 5 }}>
                            <Grid item xs={2} >
                                <Controller
                                    control={control}
                                    name="houKitaShasen"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("houkisei") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("houkisei") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("houKitaShasen") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >北側斜線</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2} >
                                <Controller
                                    control={control}
                                    name="houDoroShasen"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("houkisei") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }} style={watch("houkisei") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : watch("houDoroShasen") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : {}}>道路斜線</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2} >
                                <Controller
                                    control={control}
                                    name="houTakasaSeigen"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("houkisei") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }} style={watch("houkisei") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : watch("houTakasaSeigen") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : {}}>高さ制限</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={1.5}  >
                                <Controller
                                    control={control}
                                    name="houSaikou"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("houkisei") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }} style={watch("houkisei") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : watch("houSaikou") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : {}}>採光</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2} >
                                <Controller
                                    control={control}
                                    name="houHekimenKoutai"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value ?? ""}
                                                    color='primary'
                                                    disabled={watch("houkisei") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }} style={watch("houkisei") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : watch("houHekimenKoutai") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : {}}>壁面後退</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2} >
                                <Controller
                                    control={control}
                                    name="houEnshoLine"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value ?? ""}
                                                    color='primary'
                                                    disabled={watch("houkisei") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }} style={watch("houkisei") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : watch("houEnshoLine") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : {}}>延焼ライン</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2.5}  >
                                <Controller
                                    control={control}
                                    name="houMinpoRikaku500"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value ?? ""}
                                                    color='primary'
                                                    disabled={watch("houkisei") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }} style={watch("houkisei") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : watch("houMinpoRikaku500") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : {}} >民法離隔500㎜</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container columns={16} columnSpacing={{ xs: 1, sm: 1, md: 1 }} sx={{ pt: 2 }} >
                    <Grid item xs={16} alignContent="flex-start">
                        <Controller
                            control={control}
                            name="sekkeiGLKakunin"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1.5, }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value ?? ""}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }} style={watch("sekkeiGLKakunin") === 2 ? { textDecoration: "line-through" } : {}}>設計GLの確認</Typography>}
                                    labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={5.5}>
                        <Controller
                            control={control}
                            name="sekkeiGLBM"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.1)", pt: 1.5, pl: 3.6 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value ?? ""}
                                            color='primary'
                                            disabled={watch("sekkeiGLKakunin") === 2 ? true : false}
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }} style={watch("sekkeiGLKakunin") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : watch("sekkeiGLBM") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : {}}>ベンチマーク（配置図にて確認）</Typography>}
                                    labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={0.6} sx={{ pt: 1 }}>
                        <Typography style={watch("sekkeiGLKakunin") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : watch("sekkeiGLBM") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : {}}
                        >メモ</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ pt: 0.5 }} >
                        <Controller
                            control={control}
                            name="sekkeiGLBMNote"
                            rules={validationRules.sekkeiGLBMNote}
                            render={({ field, fieldState }) => (
                                <
                                    TextField
                                    {...field}
                                    style={{ width: '120%' }}
                                    variant="standard"
                                    error={fieldState.invalid}
                                    inputProps={{ maxLength: 30 }}
                                    disabled={watch("sekkeiGLKakunin") === 2 ? true : watch("sekkeiGLBM") === 2 ? true : false}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={5.5} sx={{ pt: 2 }}>
                        <Controller
                            control={control}
                            name="sekkeiGLHenko"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.1)", pt: 1.5, pl: 2.8 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value ?? ""}
                                            color='primary'
                                            disabled={watch("sekkeiGLKakunin") === 2 ? true : false}
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }} style={watch("sekkeiGLKakunin") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : watch("sekkeiGLHenko") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : {}}>設計GLの変更</Typography>}
                                    labelPlacement="end"
                                />
                            )}
                        />
                    </Grid >
                    <Grid item xs={3.5} sx={{ pt: 3 }} >
                        <Controller
                            control={control}
                            name="sekkeiGLHenkoUmu"
                            render={({ field: { value, onChange } }) => (
                                <Toggle
                                    setCheckedToggle={onChange}
                                    checkedToggle={value ?? ""}
                                    disabled={watch("sekkeiGLKakunin") === 2 ? true : watch("sekkeiGLHenko") === 2 ? true : false}
                                    toggleValue={"無"}
                                    toggleSts={TOGGLE_STATES.One}
                                    toggleValue2={"有"}
                                    toggleSts2={TOGGLE_STATES.Two}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={7} >
                        <Grid container columns={16} sx={{}}>
                            <Grid item xs={3.4} sx={{ pt: 3.4 }}>
                                <Typography sx={{ pl: 1 }} style={watch("sekkeiGLKakunin") === 2 ? { color: "#BBBBBB" } : watch("sekkeiGLHenko") === 2 ? { color: "#BBBBBB" } : watch("sekkeiGLHenkoUmu") === TOGGLE_STATES.One ? { color: "#BBBBBB" } : {}}>GL = BM + </Typography>
                            </Grid>
                            <Grid item xs={2.9} sx={{ pl: 1 }}>
                                <Controller
                                    control={control}
                                    name="sekkeiGLHenkoBefore"
                                    rules={validationRules.sekkeiGLHenkoBefore}
                                    render={({ field, fieldState }) => (
                                        <FormControlLabel
                                            control={<
                                                TextField
                                                sx={{ pt: 3 }}
                                                {...field}
                                                disabled={watch("sekkeiGLKakunin") === 2 ? true : watch("sekkeiGLHenko") === 2 ? true : watch("sekkeiGLHenkoUmu") === TOGGLE_STATES.One ? true : false}
                                                variant="standard"
                                                error={fieldState.invalid}
                                                inputProps={{ maxLength: 4, style: { textAlign: 'right' } }}
                                            />}
                                            label={<Typography sx={{ pl: 1, pt: 2.5 }} style={watch("sekkeiGLKakunin") === 2 ? { color: "#BBBBBB" } : watch("sekkeiGLHenko") === 2 ? { color: "#BBBBBB" } : watch("sekkeiGLHenkoUmu") === TOGGLE_STATES.One ? { color: "#BBBBBB" } : {}}>mm</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={1.5} sx={{ pt: 3 }}>
                                <ArrowForwardIcon style={watch("sekkeiGLKakunin") === 2 ? { color: "#BBBBBB" } : watch("sekkeiGLHenko") === 2 ? { color: "#BBBBBB" } : watch("sekkeiGLHenkoUmu") === TOGGLE_STATES.One ? { color: "#BBBBBB" } : {}} />
                            </Grid>
                            <Grid item xs={3} sx={{ pl: 1 }}>
                                <Controller
                                    control={control}
                                    name="sekkeiGLHenkoAfter"
                                    rules={validationRules.sekkeiGLHenkoAfter}
                                    render={({ field, fieldState }) => (
                                        <FormControlLabel
                                            control={<
                                                TextField
                                                sx={{ pt: 3 }}
                                                {...field}
                                                disabled={watch("sekkeiGLKakunin") === 2 ? true : watch("sekkeiGLHenko") === 2 ? true : watch("sekkeiGLHenkoUmu") === TOGGLE_STATES.One ? true : false}
                                                variant="standard"
                                                error={fieldState.invalid}
                                                inputProps={{ maxLength: 4, style: { textAlign: 'right' } }}
                                            />}
                                            label={<Typography sx={{ pl: 2, pt: 2.5 }} style={watch("sekkeiGLKakunin") === 2 ? { color: "#BBBBBB" } : watch("sekkeiGLHenko") === 2 ? { color: "#BBBBBB" } : watch("sekkeiGLHenkoUmu") === TOGGLE_STATES.One ? { color: "#BBBBBB" } : {}}>mm</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container columns={12} rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 2 }}>
                    <Grid container columns={16} rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 5 }}>
                        <Grid item xs={14} >
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("post")} disabled={processing_post}>
                                {processing_post ? "処理中" : "保存して一覧へ"}
                            </Button>
                            <LoadingDialog load={processing_post} />
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("next")}>
                                次へ
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form >
        </Contents >
    );
}