import React, { useState, useContext } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';
import { useForm, Controller } from "react-hook-form";
import { UserInputData } from './StartRopeFrame';
import { useNavigate } from 'react-router-dom';
import CheckBox from '../CheckBox'
import Button from '@mui/material/Button';
import useAuthAxios from "../../useAuthAxios";
import { ConfirmationContext } from "../../Confirmation";
import { Contents, FormTitle } from '../css/CSS.js';
import LoadingDialog from '../Loading';

export default function GenbaKensa(props) {
    const validationRules = {
        sonotaKensaNote: {
            maxLength: { value: 163, message: '160文字以内で入力してください。' },
            validate: (value) => {
                // 1行40文字以内
                for (let v of value.split("\n")) {
                    if (v.length > 40) return "1行40文字以内で入力してください。";
                }
                if (value.split("\n").length > 4) {
                    return "4行以内で入力してください。";
                }
            }
        },
    }

    const { currentState, setCurrentState } = useContext(UserInputData);
    const { kojiCD, cutTeimei, changeTeimei } = useContext(ConfirmationContext);
    const { control, handleSubmit, getValues, formState: { errors }, watch } = useForm({
        defaultValues: { ...currentState.genbaKensa },
        mode: 'onChange'
    });

    const authAxios = useAuthAxios();
    const navigate = useNavigate();

    const [processing_save, setProcessing_save] = useState(false);
    const [formSave, setFormSave] = useState(false)
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (formSave === true) {
                setProcessing_save(true)
                authAxios
                    .post('/api/confirmation/chakko/' + kojiCD + "?order=update", currentState)
                    .then(response => {
                        if (response.status === 200) {
                            props.handleSave(true);
                            setProcessing_save(true)
                        }
                    }).catch((response) => {
                        alert("保存に失敗しました。", response.body);
                        setProcessing_save(true)
                    })
            }
        }
        setFormSave(false);
        setTimeout(() => {
            setProcessing_save(false);
        }, 10000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formSave]);

    const [formBack, setFormBack] = useState(false)
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (formBack === true) {
                props.handleBack();
            }
        }
        setFormBack(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formBack]);

    const [processing_post, setProcessing_post] = useState(false);
    const [formPost, setFormPost] = useState(false)
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (formPost === true) {
                setProcessing_post(true)
                authAxios
                    .post('/api/confirmation/chakko/' + kojiCD + "?order=update", currentState)
                    .then(response => {
                        if (response.status === 200) {
                            navigate("/confirmation/list");
                            setProcessing_post(false)
                        }
                    }).catch((response) => {
                        alert("保存に失敗しました。", response.body);
                        setProcessing_post(false)
                    })
            }
        }
        setFormPost(false);
        setTimeout(() => {
            setProcessing_post(false);
        }, 10000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formPost]);

    const onSubmit = (action) => {
        const genbaKensa = getValues();

        if (action === "post") {
            setCurrentState({ ...currentState, genbaKensaEnable: "true", genbaKensa, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
            setFormPost(true);
        } else if (action === "back") {
            setCurrentState({ ...currentState, genbaKensaEnable: "true", genbaKensa, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
            setFormBack(true);
        }
        else {
            setCurrentState({ ...currentState, genbaKensaEnable: "true", genbaKensa, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
            setFormSave(true);
        }
    };
    return (
        <Contents component="main"  >
            <form onSubmit={handleSubmit(onSubmit)} >
                <Grid container columns={12} columnSpacing={{ xs: 1, sm: 3, md: 3 }}>
                    <Grid sx={{}}>
                        <FormTitle>
                            現場検査
                        </FormTitle>
                    </Grid>
                </Grid>
                <Grid container columns={12} rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 3 }}>
                    <Grid item xs={16}>
                        <Grid container columns={20} rowSpacing={1} columnSpacing={{ xs: 1, sm: 3, md: 3 }} >
                            <Grid item xs={4} sx={{ mt: 1 }}>
                                <Controller
                                    control={control}
                                    name="ninteiTeitanso"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            sx={{ transform: "scale(1.3)", pt: 1 }}
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }} style={watch("ninteiTeitanso") === 2 ? { textDecoration: "line-through" } : {}} > 認定低炭素</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                                <Grid item xs={16} sx={{ transform: "scale(1.1)", pl: 2, pt: 1 }} >
                                    <Typography style={watch("ninteiTeitanso") === 2 ? { textDecoration: "line-through" } : {}}>
                                        配筋検査
                                    </Typography>
                                    <Typography style={watch("ninteiTeitanso") === 2 ? { textDecoration: "line-through" } : {}}>
                                        躯体防水検査
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} >
                                <Controller
                                    control={control}
                                    name="sumaiKyufu"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            sx={{ transform: "scale(1.3)", pt: 1.5 }}
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }} style={watch("sumaiKyufu") === 2 ? { textDecoration: "line-through" } : {}}>住まい給付</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                                <Grid item xs={16} sx={{ transform: "scale(1.1)", pl: 2, pt: 1 }} >
                                    <Typography style={watch("sumaiKyufu") === 2 ? { textDecoration: "line-through" } : {}}>
                                        配筋検査
                                    </Typography>
                                    <Typography style={watch("sumaiKyufu") === 2 ? { textDecoration: "line-through" } : {}}>
                                        躯体防水検査
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} >
                                <Controller
                                    control={control}
                                    name="jyutakuSeinoHyoji"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            sx={{ transform: "scale(1.3)", pt: 1.5, }}
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }} style={watch("jyutakuSeinoHyoji") === 2 ? { textDecoration: "line-through" } : {}}>住宅性能表示</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                                <Grid item xs={16} sx={{ transform: "scale(1.1)", pl: 2, pt: 1 }} >
                                    <Typography style={watch("jyutakuSeinoHyoji") === 2 ? { textDecoration: "line-through" } : {}}>
                                        配筋検査
                                    </Typography>
                                    <Typography style={watch("jyutakuSeinoHyoji") === 2 ? { textDecoration: "line-through" } : {}}>
                                        躯体検査
                                    </Typography>
                                    <Typography style={watch("jyutakuSeinoHyoji") === 2 ? { textDecoration: "line-through" } : {}}>
                                        竣工検査
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} >
                                <Controller
                                    control={control}
                                    name="flat35"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            sx={{ transform: "scale(1.3)", pt: 1.5, }}
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }} style={watch("flat35") === 2 ? { textDecoration: "line-through" } : {}}>フラット35</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                                <Grid item xs={16} sx={{ transform: "scale(1.1)", pl: 2, pt: 1 }} >
                                    <Typography style={watch("flat35") === 2 ? { textDecoration: "line-through" } : {}}>
                                        中間検査
                                    </Typography>
                                    <Typography style={watch("flat35") === 2 ? { textDecoration: "line-through" } : {}}>
                                        竣工検査
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} >
                                <Controller
                                    control={control}
                                    name="chokiYuryoNintei"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            sx={{ transform: "scale(1.3)", pt: 1.5, }}
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }} style={watch("chokiYuryoNintei") === 2 ? { textDecoration: "line-through" } : {}}>長期優良認定</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                                <Grid item xs={16} sx={{ transform: "scale(1.1)", pl: 2, pt: 1 }} >
                                    <Typography style={watch("chokiYuryoNintei") === 2 ? { textDecoration: "line-through" } : {}}>
                                        工事完了報告書
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={16} sx={{ mt: 1 }}>
                        <Grid container columns={20} rowSpacing={1} columnSpacing={{ xs: 1, sm: 3, md: 3 }} >
                            <Grid item xs={4} alignContent="flex-start" >
                                <Controller
                                    control={control}
                                    name="chukanKensa"
                                    render={({ field: { onChange, value } }) => (
                                        < FormControlLabel
                                            sx={{ transform: "scale(1.3)", pt: 1.5, }}
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }} style={watch("chukanKensa") === 2 ? { textDecoration: "line-through" } : {}}>中間検査</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={5} alignContent="flex-start" >
                                <Controller
                                    control={control}
                                    name="kanryoKensa"
                                    render={({ field: { onChange, value } }) => (
                                        < FormControlLabel
                                            sx={{ transform: "scale(1.3)", pt: 1.5, }}
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }} style={watch("kanryoKensa") === 2 ? { textDecoration: "line-through" } : {}}>完了検査</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={16} sx={{ mt: 1 }}>
                        <Grid item xs={16}>
                            <Controller
                                control={control}
                                name="sonotaKensa"
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        sx={{ transform: "scale(1.3)", pt: 1.5, pl: 0.3 }}
                                        control={
                                            <CheckBox
                                                setCheckedvalue={onChange}
                                                checkedvalue={value}
                                                color='primary'
                                            />
                                        }
                                        label={<Typography sx={{ pl: 1 }} style={watch("sonotaKensa") === 2 ? { textDecoration: "line-through" } : {}}>その他検査</Typography>}
                                        labelPlacement="end"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={16} sx={{}}>
                            <Controller
                                control={control}
                                name="sonotaKensaNote"
                                rules={validationRules.sonotaKensaNote}
                                render={({ field }) => (
                                    <FormControlLabel
                                        control={<
                                            TextField
                                            {...field}
                                            style={{ width: '700px' }}
                                            margin="normal"
                                            multiline
                                            minRows={4}
                                            maxRows={4}
                                            variant="outlined"
                                            error={errors.sonotaKensaNote}
                                            helperText={errors.sonotaKensaNote && errors.sonotaKensaNote?.message}
                                            inputProps={{ maxLength: 163 }}
                                            disabled={watch("sonotaKensa") === 2 ? true : false}
                                            color="primary"
                                            placeholder="40文字、4行まで入力できます。"
                                        />}
                                        label=""
                                        labelPlacement="top"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container columns={16} rowSpacing={1} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 5 }}>
                        <Grid item xs={11} >
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("post")} disabled={processing_post}>
                                {processing_post ? "処理中" : "保存して一覧へ"}
                            </Button>
                            <LoadingDialog load={processing_post} />
                        </Grid>
                        <Grid item xs={2} >
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("back")} >
                                戻る
                            </Button>
                        </Grid>
                        <Grid item xs={3} >
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("save")} disabled={processing_save}>
                                {processing_save ? "処理中" : "確認画面へ"}
                            </Button>
                            <LoadingDialog load={processing_save} />
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Contents >

    );
}
