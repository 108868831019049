
/**
 * Cookie�ɒl���Z�b�g����
 * @param {String} name Cookie�ɐݒ肵�������O��ݒ�
 *  @param {String} value Cookie�ɐݒ肵�����l��ݒ�
 */
export function setCookie(name, value) {
    return (
        document.cookie = name + "=" + value
    );
}

/**
 * Cookie�̒l���폜����
 * @param {String} name �폜������Cookie�̖��O��ݒ�
 */
export function removeCookie(name) {
    return (
        document.cookie = name + "=;max-age=0;"
    );
}

/**
 * Cookie�̒l���擾����
 * @param {String} name �擾������Cookie�̖��O��ݒ�
 */
export function getCookie(name) {
    if (document.cookie.indexOf(name) === -1) {
        return false
    } else {
        const value = document.cookie.match(
            new RegExp(name + '\=([^\;]*)\;*')
        )[1];
        return (
            value
        );
    }

}