import React, { Component } from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { DigitalSignContainer } from './css/CSS.js';

const apptheme = createTheme({
    typography: {
        fontFamily: [
            '"Noto Sans JP"',
            'Roboto',
            '"Helvetica"',
            'Arial',
            'sans-serif',
        ].join(','),
    }
});

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        const currentURL = window.location.href;
        return (
            <div>
                <ThemeProvider theme={apptheme}>
                    <CssBaseline />
                    <NavMenu />
                    {currentURL.includes("digitalconstract") ?
                        <DigitalSignContainer maxWidth={false}>
                            {this.props.children}
                        </DigitalSignContainer> :
                        <Container>
                            {this.props.children}
                        </Container>
                    }
                </ThemeProvider>
            </div>
        );
    }
}
