import React, { createContext, useState } from "react";

export const ConfirmationContext = createContext({});

const ConfirmationProvider = ({ children }) => {
    const [kojiCD, setKojiCD] = useState({});
    const [kojiNAME, setKojiNAME] = useState({});
    const [being, setBeing] = useState(false);
    const [none, setNone] = useState(false);
    const [being_jyoto, setBeing_jyoto] = useState(false);
    const [none_jyoto, setNone_jyoto] = useState(false);
    const [formData, setFormData] = useState({});
    const [jyotoformData, setjyotoFormData] = useState({});
    const [being_shunko, setBeing_shunko] = useState(false);
    const [none_shunko, setNone_shunko] = useState(false);
    const [shunkoformData, setshunkoFormData] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [deleteCheck, setDeleteCheck] = useState(false); 
    const [cutTeimei, setCutTeimei] = useState(""); 
    const [changeTeimei, setChangeTeimei] = useState(""); 
    const [signCustomer, setSignCustomer] = useState(false); 
    const [signJyotoCustomer, setJyotoSignCustomer] = useState(false); 
    const [signShunkoCustomer, setSignShunkoCustomer] = useState(false); 

    return (
        <ConfirmationContext.Provider
            value={{
                kojiCD, setKojiCD, kojiNAME, setKojiNAME, being, setBeing, formData, setFormData, none, setNone, being_jyoto, setBeing_jyoto, none_jyoto, setNone_jyoto, jyotoformData, setjyotoFormData,
                being_shunko, setBeing_shunko, none_shunko, setNone_shunko, shunkoformData, setshunkoFormData, confirm, setConfirm, deleteCheck, setDeleteCheck,
                cutTeimei, setCutTeimei, changeTeimei, setChangeTeimei, signCustomer, setSignCustomer, signJyotoCustomer, setJyotoSignCustomer, signShunkoCustomer, setSignShunkoCustomer
            }}>
            {children}
        </ConfirmationContext.Provider>
    );
};

export default ConfirmationProvider;