import React from 'react';

export const CHECKBOX_STATES = {
    Checked: 1,
    Indeterminate: 2,
    Empty: 0,
};

const Checkbox = ({ label, value, onChange, disabled }) => {
    const checkboxRef = React.useRef();

    React.useEffect(() => {
        if (value === CHECKBOX_STATES.Checked) {
            checkboxRef.current.checked = true;
            checkboxRef.current.indeterminate = false;
        } else if (value === CHECKBOX_STATES.Empty) {
            checkboxRef.current.checked = false;
            checkboxRef.current.indeterminate = false;
        } else if (value === CHECKBOX_STATES.Indeterminate) {
            checkboxRef.current.checked = false;
            checkboxRef.current.indeterminate = true;
        }
    }, [value]);

    return (
        <label>
            <input
                ref={checkboxRef}
                type="checkbox"
                onChange={onChange}
                disabled={disabled}
            />
            {label}
        </label>
    );
};

export default function CheckBox(props) {
    const [checked, setChecked] = React.useState(props.checkedvalue);
    React.useEffect(() => {
        setChecked(props.checkedvalue);
    }, [props.checkedvalue]);
    const [disabled, setdisabled] = React.useState(props.disabled);
    React.useEffect(() => {
        setdisabled(props.disabled);
    }, [props.disabled]);


    const handleChange = () => {
        let updatedChecked;

        if (checked === CHECKBOX_STATES.Empty) {
            updatedChecked = CHECKBOX_STATES.Checked;
        } else if (checked === CHECKBOX_STATES.Checked) {
            updatedChecked = CHECKBOX_STATES.Indeterminate;
        } else if (checked === CHECKBOX_STATES.Indeterminate) {
            updatedChecked = CHECKBOX_STATES.Empty;
        } else {
            updatedChecked = CHECKBOX_STATES.Checked;
        }

        setChecked(updatedChecked);
        props.setCheckedvalue(updatedChecked)
    };

    return (
        <Checkbox
            value={checked}
            onChange={handleChange}
            disabled={disabled}
        />
    );
}
