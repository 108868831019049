import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const DialogLoad = styled(Dialog)({
    backgroundColor: 'transparent'
});
const DialogLoadContent = styled(DialogContent)({
    backgroundColor: 'transparent'
});

export default function LoadingDialog(props) {
    const [open, setOpen] = React.useState(false);


    const handleClose = () => {
        setOpen(props.load);
    };

    React.useEffect(() => {
        setOpen(props.load);
    }, [props.load]);

    return (
        <DialogLoad
            open={open}
            onClose={handleClose}
        >
            <Grid container alignItems="center" justifyContent="center">
                <Grid item>
                    <DialogLoadContent >
                        <CircularProgress />
                    </DialogLoadContent>
                </Grid>
            </Grid>
        </DialogLoad>
    );
}
