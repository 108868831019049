import Login from "./components/Login";
import Logout from "./components/Logout";
import AuthProvider from "./Auth";
import WorkSelect from "./components/WorkSelect";
import ConfirmationProvider from "./Confirmation";
import ConfirmationFrame from "./components/Confirmation/ConfirmationFrame";
import ConfirmationDelFrame from "./components/Confirmation/ConfirmationDelFrame";
import ChakkoForm from "./components/chakko/StartRopeFrame";
import Chakko from './components/chakko/Chakko';
import JyotoForm from "./components/jyoto/JyotoFrame";
import Jyoto from './components/jyoto/Jyoto';
import ShunkoForm from "./components/shunko/ShunkoFrame";
import Shunko from './components/shunko/Shunko';
import DigitalConstractsProvider from "./DigitalConstracts";
import DigitalConstractFrame from "./components/DigitalConstract/DigitalConstractConstitution/DigitalConstractFrame";
import DigitalConstractCustomerConfirmation from "./components/DigitalConstract/CustomerConfirmation/DigitalConstractCustomerConfirmation"
import DigitalConstractCustomerFrame from "./components/DigitalConstract/CustomerConfirmation/DigitalConstractCustomerFrame"
import DigitalConstractListFrame from "./components/DigitalConstract/DigitalConstractList/DigitalConstractListFrame"

const PrivateRoutes = [
    {
        index: true,
        element: <AuthProvider><Login /></AuthProvider>
    },
    {
        path: '/logout',
        element: <AuthProvider> <Logout /></AuthProvider>
    },
    {
        path: '/top',
        element: <AuthProvider> <WorkSelect /></AuthProvider>
    },
    {
        path: '/confirmation/list',
        element: <AuthProvider><ConfirmationProvider><ConfirmationFrame /></ConfirmationProvider></AuthProvider>
    },
    {
        path: '/confirmation/dellist',
        element: <AuthProvider><ConfirmationProvider><ConfirmationDelFrame /></ConfirmationProvider></AuthProvider>
    },
    {
        path: '/confirmation/chakko/edit',
        element: <AuthProvider><ConfirmationProvider><ChakkoForm /></ConfirmationProvider></AuthProvider>
    },
    {
        path: '/confirmation/chakko/chakko',
        element: <AuthProvider><ConfirmationProvider><Chakko /></ConfirmationProvider></AuthProvider>
    },
    {
        path: '/confirmation/jyoto/edit',
        element: <AuthProvider><ConfirmationProvider><JyotoForm /></ConfirmationProvider></AuthProvider>
    },
    {
        path: '/confirmation/jyoto/jyoto',
        element: <AuthProvider><ConfirmationProvider><Jyoto /></ConfirmationProvider></AuthProvider>
    },
    {
        path: '/confirmation/shunko/edit',
        element: <AuthProvider><ConfirmationProvider><ShunkoForm /></ConfirmationProvider></AuthProvider>
    },
    {
        path: '/confirmation/shunko/shunko',
        element: <AuthProvider><ConfirmationProvider><Shunko /></ConfirmationProvider></AuthProvider>
    },
    {
        path: '/digitalconstract',
        element: <AuthProvider><DigitalConstractsProvider><DigitalConstractFrame /></DigitalConstractsProvider></AuthProvider>
    },
    {
        path: '/digitalconstract/customerconfirm',
        element: <AuthProvider><DigitalConstractsProvider><DigitalConstractCustomerFrame /></DigitalConstractsProvider></AuthProvider>
    },
    {
        path: '/digitalconstract/list',
        element: <AuthProvider><DigitalConstractsProvider><DigitalConstractListFrame /></DigitalConstractsProvider></AuthProvider>
    }
];

export default PrivateRoutes;
