import * as React from 'react';
import { ConstructCompositionContainer } from '../../css/CSS.js';
import Grid from '@mui/material/Grid';
import DigitalConstractUploadList from './DigitalConstractUploadList'

export default function DigitalConstractUploadFrame(props) {
    return (
        <ConstructCompositionContainer sx={{ pr: 1, mt: 2, pb: 1 }}>
            <Grid container >
                <Grid item xs={12} >
                    <DigitalConstractUploadList />
                </Grid>
            </Grid>
        </ConstructCompositionContainer >
    );
}