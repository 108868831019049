import React, { useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import { SelectContractContent, SelectContractTitle, SelectContractText, NomalButton, GrayButton, WhiteTextBack } from '../../css/CSS.js';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { useNavigate } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import useAuthAxios from "../../../useAuthAxios";
import LoadingDialog from '../../Loading';
import EditIcon from '@mui/icons-material/Edit';
import { DigitalConstractsContext } from "../../../DigitalConstracts";

export default function DigitalConstractSelectCusomer(props) {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const authAxios = useAuthAxios();
    const [processing, setProcessing] = React.useState(false);
    const [customerList, setCustomerList] = React.useState()
    const headers = { "Authorization": "Bearer " + localStorage.getItem("Auth"), };
    const { kojiCD, seqNo, contractType, setCustomerNo, setSelectPDF } = useContext(DigitalConstractsContext);

    const toDigitalconstract = () => {
        setOpen(false);
        props.close(false)
        setCustomerNo("")
        navigate("/digitalconstract?kojiCD=" + kojiCD + "&costructtype=" + (contractType === "工事請負契約書" ? "base" : "modfy") + "&seqno=" + seqNo)
    };

    const handleClose = () => {
        setOpen(false);
        props.close(false)
    };

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);


    const handleSelectCustomer = (customerNumber) => {
        setCustomerNo(customerNumber)
        setSelectPDF("")
        setOpen(false);
        props.close(false)
    };

    React.useLayoutEffect(() => {
        if (processing === true) { return }
        if (open === true) {
            setProcessing(true)
            authAxios
                .get('api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + '/info/customer'
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        const customerListData = JSON.parse((JSON.stringify(response.data)))
                        setCustomerList(customerListData)
                    }
                    setProcessing(false)
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        alert("お客様情報の取得に失敗しました。");
                        props.setOpen(false)
                        setProcessing(false)
                    }
                    setProcessing(false)
                });

            setProcessing(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <React.Fragment>
            <LoadingDialog load={processing} />
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth="md"
            >
                <SelectContractTitle >
                    <Grid container columns={12} rowSpacing={{ xs: 3 }} columnSpacing={4} >
                        <Grid item xs={11} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                            お客様選択
                        </Grid>
                        <Grid item xs={1} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                            <ClearIcon fontSize="large" onClick={() => handleClose()} />
                        </Grid>
                    </Grid>
                </SelectContractTitle>
                <SelectContractContent>
                    <Grid container columns={12} rowSpacing={{ xs: 3 }} columnSpacing={1} sx={{ pt: 3 }}>
                        {customerList?.customer1Name ?
                            <Grid container columns={12} rowSpacing={{ xs: 3 }} columnSpacing={1} sx={{ pt: 3 }}>
                                <Grid item xs={2.5} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                    <NomalButton
                                        sx={{ transform: "scale(1.2)" }}
                                        type="button"
                                        onClick={() => handleSelectCustomer("1")}
                                    >
                                        <EditIcon sx={{ mr: 1 }} />
                                        サイン
                                    </NomalButton>
                                </Grid>
                                <Grid item xs={7.5} >
                                    <WhiteTextBack >
                                        {customerList?.customer1Name}
                                    </WhiteTextBack>
                                </Grid>
                                <Grid item xs={2} >
                                    <SelectContractText >
                                        {customerList?.customer1Sign !== null ? "サイン済" : "未サイン"}
                                    </SelectContractText>
                                </Grid>
                            </Grid>
                            : null
                        }
                        {customerList?.customer2Name ?
                            <Grid container columns={12} rowSpacing={{ xs: 3 }} columnSpacing={1} sx={{ pt: 3 }}>
                                <Grid item xs={2.5} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                    <NomalButton sx={{ transform: "scale(1.2)" }} type="button"
                                        onClick={() => handleSelectCustomer("2")}><EditIcon sx={{ mr: 1 }} />サイン</NomalButton>
                                </Grid>
                                <Grid item xs={7.5} >
                                    <WhiteTextBack >
                                        {customerList?.customer2Name}
                                    </WhiteTextBack>
                                </Grid>
                                <Grid item xs={2} >
                                    <WhiteTextBack >
                                        {customerList?.customer2Sign !== null ? "サイン済" : "未サイン"}
                                    </WhiteTextBack>
                                </Grid>
                            </Grid>
                            : null
                        }
                        {customerList?.customer3Name ?
                            <Grid container columns={12} rowSpacing={{ xs: 3 }} columnSpacing={1} sx={{ pt: 3 }}>
                                <Grid item xs={2.5} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                    <NomalButton sx={{ transform: "scale(1.2)" }} type="button"
                                        onClick={() => handleSelectCustomer("3")}><EditIcon sx={{ mr: 1 }} />サイン</NomalButton>
                                </Grid>
                                <Grid item xs={7.5} >
                                    <WhiteTextBack >
                                        {customerList?.customer3Name}
                                    </WhiteTextBack>
                                </Grid>
                                <Grid item xs={2} >
                                    <WhiteTextBack >
                                        {customerList?.customer3Sign !== null ? "サイン済" : "未サイン"}
                                    </WhiteTextBack>
                                </Grid>
                            </Grid>
                            : null
                        }
                        {/*{customerList?.customer4Name  ?*/}
                        {/*    <Grid container columns={12} rowSpacing={{ xs: 3 }} columnSpacing={1} sx={{ pt: 3 }}>*/}
                        {/*        <Grid item xs={2.5} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>*/}
                        {/*            <NomalButton sx={{ transform: "scale(1.2)" }} type="button"*/}
                        {/*                onClick={() => handleSelectCustomer("4")}><EditIcon sx={{ mr: 1 }} />サイン</NomalButton>*/}
                        {/*        </Grid>*/}
                        {/*        <Grid item xs={7.5} >*/}
                        {/*            <WhiteTextBack >*/}
                        {/*                {customerList?.customer4Name}*/}
                        {/*            </WhiteTextBack>*/}
                        {/*        </Grid>*/}
                        {/*        <Grid item xs={2} >*/}
                        {/*            <WhiteTextBack >*/}
                        {/*                {customerList?.customer4Sign !== null ? "サイン済" : "未サイン"}*/}
                        {/*            </WhiteTextBack>*/}
                        {/*        </Grid>*/}
                        {/*    </Grid>*/}
                        {/*    : null*/}
                        {/*}*/}
                    </Grid>
                </SelectContractContent>
                <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <GrayButton
                        type="button"
                        onClick={toDigitalconstract}>
                        構成画面へ
                        <KeyboardReturnIcon sx={{ ml: 1 }} />
                    </GrayButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}