import React, { useContext, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useForm, Controller } from "react-hook-form";
import { UserInputData } from './StartRopeFrame';
import CheckBox from '../CheckBox'
import Button from '@mui/material/Button';
import { Contents, FormTitle } from '../css/CSS.js';
import { useNavigate } from 'react-router-dom';
import useAuthAxios from "../../useAuthAxios";
import { AuthContext } from "../../Auth";
import { ConfirmationContext } from "../../Confirmation";
import LoadingDialog from '../Loading';

export default function Ryuui(props) {
    const { currentState, setCurrentState } = useContext(UserInputData);
    const { control, handleSubmit, getValues, watch } = useForm({
        defaultValues: { ...currentState.ryuui },
    });

    const { kojiCD, cutTeimei, changeTeimei } = useContext(ConfirmationContext);
    const { auth } = useContext(AuthContext);
    const authAxios = useAuthAxios();
    const headers = {
        "Authorization": "Bearer " + auth,
        'Content-Type': 'application/json',
    };
    const navigate = useNavigate();

    const [processing_post, setProcessing_post] = useState(false);
    const [formPost, setFormPost] = useState(false)
    React.useEffect(() => {
        if (formPost === true) {
            setProcessing_post(true);
            authAxios
                .post('/api/confirmation/chakko/' + kojiCD + "?order=update", currentState, { headers })
                .then(response => {
                    if (response.status === 200) {
                        navigate("/confirmation/list");
                        setProcessing_post(false);
                    }
                }).catch((response) => {
                    alert("保存に失敗しました。", response.body);
                    setProcessing_post(false);
                })
        }
        setFormPost(false);
        setTimeout(() => {
            setProcessing_post(false);
        }, 10000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formPost]);


    const onSubmit = (action) => {

        const ryuui = getValues();

        if (action === "post") {
            setFormPost(true);
            setCurrentState({ ...currentState, ryuuiEnable: "true", ryuui, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
        } else if (action === "back") {
            props.handleBack();
            setCurrentState({ ...currentState, ryuuiEnable: "true", ryuui, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
        }
        else {
            props.handleNext();
            setCurrentState({ ...currentState, ryuuiEnable: "true", ryuui, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
        }
    };
    return (
        <Contents component="main"  >
            <form onSubmit={handleSubmit(onSubmit)} >
                <Grid container columns={12} columnSpacing={{ xs: 1, sm: 3, md: 3 }}>
                    <Grid sx={{ pl: 3 }}>
                        <FormTitle>
                            工事着手のご留意事項
                        </FormTitle>
                    </Grid>
                </Grid>
                <Grid container columns={12} rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 3 }}>
                    <Grid container >
                        <Grid item xs={12} sx={{ pt: 4, pl: 9 }}>
                            <Controller
                                control={control}
                                name="ryuui1"
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        sx={{ transform: "scale(1.3)", pt: 1.5, pl: 0.5 }}
                                        control={
                                            <CheckBox
                                                setCheckedvalue={onChange}
                                                checkedvalue={value}
                                                color='primary'
                                            />
                                        }
                                        label={<Typography sx={{ pl: 1 }} style={watch("ryuui1") === 2 ? { textDecoration: "line-through" } : {}}>着工後の追加変更工事は、原則としてお受け致しかねます。</Typography>}
                                        labelPlacement="end"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={16} >
                            <Typography sx={{ transform: "scale(1.1)", pl: 10 }} style={watch("ryuui1") === 2 ? { textDecoration: "line-through" } : {}}>
                                (但し、やむを得ず、追加変更工事を要望される場合は追加金額の発生、工期の延長になる場合がございますので、ご了承ください。)
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 1, pl: 8.09 }}>
                        <Grid item xs={16} >
                            <Controller
                                control={control}
                                name="ryuui2"
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        sx={{ transform: "scale(1.3)", pt: 1.5, pl: 0.5 }}
                                        control={
                                            <CheckBox
                                                setCheckedvalue={onChange}
                                                checkedvalue={value}
                                                color='primary'
                                            />
                                        }
                                        label={<Typography sx={{ pl: 1 }} style={watch("ryuui2") === 2 ? { textDecoration: "line-through" } : {}}>建築にあたっては、行政指導に従い尊守いたします。</Typography>}
                                        labelPlacement="end"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 1, pl: 8.7 }}>
                        <Grid item xs={16} >
                            <Controller
                                control={control}
                                name="ryuui3"
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        sx={{ transform: "scale(1.3)", pt: 1.5, pl: 2.4 }}
                                        control={
                                            <CheckBox
                                                setCheckedvalue={onChange}
                                                checkedvalue={value}
                                                color='primary'
                                            />
                                        }
                                        label={<Typography sx={{ pl: 1 }} style={watch("ryuui3") === 2 ? { textDecoration: "line-through" } : {}}>建物のお引渡しは、工事代金のご精算が確認出来てからとなりますので、</Typography>}
                                        labelPlacement="end"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={16} alignContent="flex-start" >
                            <Typography sx={{ transform: "scale(1.3)", pl: 11 }} style={watch("ryuui3") === 2 ? { textDecoration: "line-through" } : {}}>
                                ご契約時のお支払い条件が変更となる場合は、速やかに営業担当者迄ご連絡ください。
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container columns={16} rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 5 }}>
                        <Grid item xs={12} >
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("post")} disabled={processing_post}>
                                {processing_post ? "処理中" : "保存して一覧へ"}
                            </Button>
                            <LoadingDialog load={processing_post} />
                        </Grid>
                        <Grid item xs={2} >
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("back")} >
                                戻る
                            </Button>
                        </Grid>
                        <Grid item xs={1.5} >
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("next")}>
                                次へ
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Contents >
    );
}
