import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ConfirmationContext } from "../../Confirmation";

export default function ConfirmationDelConfirm(props) {
    const [open, setOpen] = React.useState(false);
    const { setConfirm, setDeleteCheck } = useContext(ConfirmationContext);

    const handleClose = () => {
        setOpen(false);
        setConfirm(false)
    };

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const handleOpen = () => {
        setDeleteCheck(true)
    }

    return (
        <React.Fragment>
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"立会確認書削除確認"}
                    </DialogTitle>
                    <DialogContent>
                        <div>
                            下記、立会確認書を削除します。<br />
                            <p />
                            工事コード:{props.value}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button type="button" onClick={handleOpen}>確定</Button>
                        <Button type="button" onClick={handleClose}>キャンセル</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </React.Fragment>
    );
}