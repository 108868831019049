import React, { useState, useContext } from 'react';
import { useForm } from "react-hook-form";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import SignatureCustomer from '../signature_Shunko/SignatureCustomer';
import SignatureSalesStaff from '../signature_Shunko/SignatureSalesStaff';
import SignatureDesigner from '../signature_Shunko/SignatureDesigner';
import SignatureConstructionManeger from '../signature_Shunko/SignatureConstructionManeger';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import useAuthAxios from "../../useAuthAxios";
import { AuthContext } from "../../Auth";
import { ConfirmationContext } from "../../Confirmation";
import { Contents } from '../css/CSS.js';
import LoadingDialog from '../Loading';
import { UserInputData } from './ShunkoFrame';
import Typography from '@mui/material/Typography';

export default function Review(props) {
    const { handleSubmit } = useForm({});
    const { currentState, setCurrentState } = useContext(UserInputData);
    const { auth } = useContext(AuthContext);
    const authAxios = useAuthAxios();
    const navigate = useNavigate();
    const { kojiCD, cutTeimei, changeTeimei, signShunkoCustomer } = useContext(ConfirmationContext);
    const headers = {
        "Authorization": "Bearer " + auth,
        'Content-Type': 'application/json',
    };
    const [processing_post, setProcessing_post] = useState(false);
    const onSubmit = (action) => {

        if (action === "post") {
            setCurrentState({ ...currentState, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
            navigate("/confirmation/shunko/shunko");
        } else if (action === "back") {
            setCurrentState({ ...currentState, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
            props.handleBack();
        }
    }

    const [pdf, setPdf] = React.useState()
    React.useEffect(() => {
        setProcessing_post(true)
        authAxios
            .get('api/confirmation/shunko/' + kojiCD + '/report?order=0', { headers })
            .then(response => {
                const data = response.data;
                const pdfdata = JSON.parse((JSON.stringify(data)))
                setPdf("data:application/pdf;base64," + pdfdata.confirmationPdfData64)
                setProcessing_post(false)
            });
        setTimeout(() => {
            setProcessing_post(false);
        }, 10000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const inputKakunin = {
        kiroku: currentState.kiroku,
        naibuKakunin: currentState.kakunin.naibuKakunin,
        naibuCloth: currentState.kakunin.naibuCloth,
        naibuFlooring: currentState.kakunin.naibuFlooring,
        naibuMado: currentState.kakunin.naibuMado,
        naibuTategu: currentState.kakunin.naibuTategu,
        naibuKitchen: currentState.kakunin.naibuKitchen,
        naibuUB: currentState.kakunin.naibuUB,
        naibuSenmenKeshoDai: currentState.kakunin.naibuSenmenKeshoDai,
        naibuToilet: currentState.kakunin.naibuToilet,
        naibuKankiSystem: currentState.kakunin.naibuKankiSystem,
        naibuAircon: currentState.kakunin.naibuAircon,
        naibuDanbou: currentState.kakunin.naibuDanbou,
        naibuSonota: currentState.kakunin.naibuSonota,
        gaibuKakunin: currentState.kakunin.gaibuKakunin,
        gaibuYane: currentState.kakunin.gaibuYane,
        gaibuGaiheki: currentState.kakunin.gaibuGaiheki,
        gaibuiBalconySundeck: currentState.kakunin.gaibuiBalconySundeck,
        gaibuMeterMas: currentState.kakunin.gaibuMeterMas,
        gaibuKyutokiEcocute: currentState.kakunin.gaibuKyutokiEcocute,
        gaibuAirconSitugaiki: currentState.kakunin.gaibuAirconSitugaiki,
        gaibuTeakGenkanOil: currentState.kakunin.gaibuTeakGenkanOil,
        kagi: currentState.kakunin.kagi,
        hikiwatasi: currentState.kakunin.hikiwatasi,
        nyukyo: currentState.kakunin.nyukyo,
        lifeLineSetumei: currentState.kakunin.lifeLineSetumei,
        lifeSuido: currentState.kakunin.lifeSuido,
        lifeDenki: currentState.kakunin.lifeDenki,
        lifeGas: currentState.kakunin.lifeGas,
        lifeTV: currentState.kakunin.lifeTV,
        lifeTEL: currentState.kakunin.lifeTEL,
        lifeInternet: currentState.kakunin.lifeInternet,
        lifeSekiyu: currentState.kakunin.lifeSekiyu,
    }
    const inputSetumeiHikiwatasi = {
        tokuteiHoshu: currentState.setumei.tokuteiHoshu,
        TokuteiHoshuUmu: currentState.setumei.tokuteiHoshuUmu,
        TokuteiYokusituDenkiKanso: currentState.setumei.tokuteiYokusituDenkiKanso,
        TokuteiBuiltinShokkiArai: currentState.setumei.tokuteiBuiltinShokkiArai,
        TokuteiShitunaiGasYuwakasi: currentState.setumei.tokuteiShitunaiGasYuwakasi,
        TokuteiSekiyuKyuto: currentState.setumei.tokuteiSekiyuKyuto,
        TokuteiFFSekiyuOnpuDanbou: currentState.setumei.tokuteiFFSekiyuOnpuDanbou,
        OkyakuHosyuTenken: currentState.setumei.okyakuHosyuTenken,
        OkyakuBoukaDamper: currentState.setumei.okyakuBoukaDamper,
        OkyakuAnnzen: currentState.setumei.okyakuAnnzen,
        OkyakuBlindRoll: currentState.setumei.okyakuBlindRoll,
        kojiKanriHoukokusho: currentState.hikiwatasi.kojiKanriHoukokusho,
        kojiKanriHoukokushoJyotoWatasi: currentState.hikiwatasi.kojiKanriHoukokushoJyotoWatasi,
        kojiKanriHoukokushoShunkoWatasi: currentState.hikiwatasi.kojiKanriHoukokushoShunkoWatasi,
        kojiKanriKanryouHoukokusho: currentState.hikiwatasi.kojiKanriKanryouHoukokusho,
        setubiKikiSetumeisho: currentState.hikiwatasi.setubiKikiSetumeisho,
    }
    const inputShokaiSonota = {
        shokaiTenken: currentState.shokaiTenken,
        sonota: currentState.sonota.sonota,
    }
    const [checkKakuninCheck, setCheckKakuninCheck] = useState(0)
    const [checkSetumeiHikiwatasiCheck, setCheckSetumeiHikiwatasiCheck] = useState(0)
    const [checkShokaiSonotaCheck, setCheckShokaiSonotaCheck] = useState(0)

    React.useEffect(() => {
        if (inputKakunin.kiroku === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
        if (inputKakunin.naibuKakunin === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
        else if (inputKakunin.naibuKakunin === 1) {
            if (inputKakunin.naibuCloth === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin.naibuFlooring === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin.naibuMado === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin.naibuTategu === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin.naibuKitchen === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin.naibuUB === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin.naibuSenmenKeshoDai === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin.naibuToilet === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin.naibuKankiSystem === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin.naibuAircon === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin.naibuDanbou === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin.naibuSonota === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
        }
        if (inputKakunin.gaibuKakunin === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
        else if (inputKakunin.gaibuKakunin === 1) {
            if (inputKakunin.gaibuYane === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin.gaibuGaiheki === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin.gaibuiBalconySundeck === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin.gaibuMeterMas === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin.gaibuKyutokiEcocute === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin.gaibuAirconSitugaiki === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin.gaibuTeakGenkanOil === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
        }
        if (inputKakunin.kagi === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
        if (inputKakunin.hikiwatasi === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
        if (inputKakunin.nyukyo === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
        if (inputKakunin.lifeLineSetumei === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
        else if (inputKakunin.lifeLineSetumei === 1) {
            if (inputKakunin.lifeSuido === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin.lifeDenki === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin.lifeGas === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin.lifeTV === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin.lifeTEL === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin.lifeInternet === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
            if (inputKakunin.lifeSekiyu === 0) { setCheckKakuninCheck(checkKakuninCheck + 1) }
        }
        if (inputSetumeiHikiwatasi.tokuteiHoshu === 0) { setCheckSetumeiHikiwatasiCheck(checkSetumeiHikiwatasiCheck + 1) }
        else if (inputSetumeiHikiwatasi.tokuteiHoshu === 1) {
            if (inputSetumeiHikiwatasi.TokuteiHoshuUmu === 0) { setCheckSetumeiHikiwatasiCheck(checkSetumeiHikiwatasiCheck + 1) }
            if (inputSetumeiHikiwatasi.TokuteiYokusituDenkiKanso === 0) { setCheckSetumeiHikiwatasiCheck(checkSetumeiHikiwatasiCheck + 1) }
            if (inputSetumeiHikiwatasi.TokuteiBuiltinShokkiArai === 0) { setCheckSetumeiHikiwatasiCheck(checkSetumeiHikiwatasiCheck + 1) }
            if (inputSetumeiHikiwatasi.TokuteiShitunaiGasYuwakasi === 0) { setCheckSetumeiHikiwatasiCheck(checkSetumeiHikiwatasiCheck + 1) }
            if (inputSetumeiHikiwatasi.TokuteiSekiyuKyuto === 0) { setCheckSetumeiHikiwatasiCheck(checkSetumeiHikiwatasiCheck + 1) }
            if (inputSetumeiHikiwatasi.TokuteiFFSekiyuOnpuDanbou === 0) { setCheckSetumeiHikiwatasiCheck(checkSetumeiHikiwatasiCheck + 1) }
        }
        if (inputSetumeiHikiwatasi.OkyakuHosyuTenken === 0) { setCheckSetumeiHikiwatasiCheck(checkSetumeiHikiwatasiCheck + 1) }
        else if (inputSetumeiHikiwatasi.OkyakuHosyuTenken === 1) {
            if (inputSetumeiHikiwatasi.OkyakuBoukaDamper === 0) { setCheckSetumeiHikiwatasiCheck(checkSetumeiHikiwatasiCheck + 1) }
        }
        if (inputSetumeiHikiwatasi.OkyakuAnnzen === 0) { setCheckSetumeiHikiwatasiCheck(checkSetumeiHikiwatasiCheck + 1) }
        else if (inputSetumeiHikiwatasi.OkyakuAnnzen === 1) {
            if (inputSetumeiHikiwatasi.OkyakuBlindRoll === 0) { setCheckSetumeiHikiwatasiCheck(checkSetumeiHikiwatasiCheck + 1) }
        }
        if (inputSetumeiHikiwatasi.kojiKanriHoukokusho === 0) { setCheckSetumeiHikiwatasiCheck(checkSetumeiHikiwatasiCheck + 1) }
        else if (inputSetumeiHikiwatasi.kojiKanriHoukokusho === 1) {
            if (inputSetumeiHikiwatasi.kojiKanriHoukokushoJyotoWatasi === 0) { setCheckSetumeiHikiwatasiCheck(checkSetumeiHikiwatasiCheck + 1) }
            if (inputSetumeiHikiwatasi.kojiKanriHoukokushoShunkoWatasi === 0) { setCheckSetumeiHikiwatasiCheck(checkSetumeiHikiwatasiCheck + 1) }
        }
        if (inputSetumeiHikiwatasi.kojiKanriKanryouHoukokusho === 0) { setCheckSetumeiHikiwatasiCheck(checkSetumeiHikiwatasiCheck + 1) }
        if (inputSetumeiHikiwatasi.setubiKikiSetumeisho === 0) { setCheckSetumeiHikiwatasiCheck(checkSetumeiHikiwatasiCheck + 1) }

        if (inputShokaiSonota.shokaiTenken === 0) { setCheckShokaiSonotaCheck(checkShokaiSonotaCheck + 1) }
        if (inputShokaiSonota.sonota === 0) { setCheckShokaiSonotaCheck(checkShokaiSonotaCheck + 1) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            {checkKakuninCheck === 0 && checkSetumeiHikiwatasiCheck === 0 && checkShokaiSonotaCheck === 0 ?
                <Contents>
                    <Grid sx={{ pt: 3, pb: 1 }}>
                        <Card sx={{ minWidth: 300, boxShadow: 0 }}>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={3} >
                                        <SignatureCustomer />
                                        {signShunkoCustomer === false ?
                                            <Typography sx={{ color: "#FF0000", pt: 1 }} >
                                                お客様サインは必須です
                                            </Typography> : ""
                                        }
                                    </Grid>
                                    <Grid item xs={3} >
                                        <SignatureSalesStaff />
                                    </Grid>
                                    <Grid item xs={3} >
                                        <SignatureDesigner />
                                    </Grid>
                                    <Grid item xs={3} >
                                        <SignatureConstructionManeger />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Card sx={{ width: "100%", p: 1, boxShadow: 0, border: 1, borderColor: "#EEEEEE" }} >
                        <Grid container alignItems="center" justify="center" sx={{ pt: 1 }}>
                            <Grid item xs={12}>
                                <embed type="application/pdf" src={pdf} width="100%" height="1000px" />
                            </Grid>
                        </Grid>
                    </Card>
                    <Grid item xs={12} sx={{ pt: 2 }}>
                        {signShunkoCustomer === true ?
                            <Card sx={{ boxShadow: 0 }}>
                                <CardContent style={{ display: 'flex', paddingBottom: 0, justifyContent: 'end', alignItems: 'end' }} >
                                    <form onSubmit={handleSubmit(onSubmit)} >
                                        <Grid container columnSpacing={{ xs: 1, sm: 3, md: 3 }}>
                                            <Grid item xs={6} >
                                                <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("back")} >
                                                    戻る
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("post")} disabled={processing_post}>
                                                    {processing_post ? "処理中" : "作成"}
                                                </Button>
                                                <LoadingDialog load={processing_post} />
                                            </Grid>
                                        </Grid>
                                    </form>
                                </CardContent>
                            </Card> :
                            <Card sx={{ boxShadow: 0 }}>
                                <CardContent style={{ display: 'flex', paddingBottom: 0, justifyContent: 'end', alignItems: 'end' }} >
                                    <form onSubmit={handleSubmit(onSubmit)} >
                                        <Grid container columnSpacing={{ xs: 1, sm: 3, md: 3 }}>
                                            <Grid item xs={6} >
                                                <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("back")} >
                                                    戻る
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button variant="contained" color="primary" size="large" type="button" disabled={true}>
                                                    作成
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </CardContent>
                            </Card>
                        }
                    </Grid>
                </Contents> :
                <Contents component="main" >
                    <Grid container columns={12} rowSpacing={4} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 3 }}>
                        <Grid item xs={12} sx={{ transform: "scale(1.5)" }}>
                            <Typography sx={{ color: "#FF0000", pl: 20 }}>
                                入力が不十分です。全てのチェック項目を入力してください。
                            </Typography>
                        </Grid>
                        {checkKakuninCheck > 0 ?
                            <Grid item xs={12} sx={{ transform: "scale(1.3)" }}>
                                <Typography sx={{ pl: 15 }}>
                                    確認事項：未入力あり
                                </Typography>
                            </Grid> : null
                        }
                        {checkSetumeiHikiwatasiCheck > 0 ?
                            <Grid item xs={12} sx={{ transform: "scale(1.3)" }}>
                                <Typography sx={{ pl: 15 }}>
                                    説明事項、お引渡し書類：未入力あり
                                </Typography>
                            </Grid> : null
                        }
                        {checkShokaiSonotaCheck > 0 ?
                            <Grid item xs={12} sx={{ transform: "scale(1.3)" }}>
                                <Typography sx={{ pl: 15 }}>
                                    初回点検(3カ月点検)、その他の確認：未入力あり
                                </Typography>
                            </Grid> : null
                        }
                        <Grid item xs={12} >
                            <Card sx={{ boxShadow: 0 }}>
                                <CardContent style={{ display: 'flex', paddingBottom: 0, justifyContent: 'end', alignItems: 'end' }} >
                                    <form onSubmit={handleSubmit(onSubmit)} >
                                        <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("back")} >
                                            戻る
                                        </Button>
                                    </form>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sx={{}}>
                            <Card sx={{ width: "100%", p: 1, boxShadow: 0, border: 1, borderColor: "#EEEEEE" }} >
                                <Grid container alignItems="center" justify="center" >
                                    <Grid item xs={12}>
                                        <embed type="application/pdf" src={pdf} width="100%" height="1000px" />
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} >
                        <Card sx={{ boxShadow: 0 }}>
                            <CardContent style={{ display: 'flex', paddingBottom: 0, justifyContent: 'end', alignItems: 'end' }} >
                                <form onSubmit={handleSubmit(onSubmit)} >
                                    <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("back")} >
                                        戻る
                                    </Button>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Contents>
            }
        </React.Fragment >
    );
}