import React, { useState, useRef, useContext } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SignaturePad from 'react-signature-canvas'
import useAuthAxios from "../../useAuthAxios";
import { AuthContext } from "../../Auth";
import { ConfirmationContext } from "../../Confirmation";
import { SignDialog } from '../css/CSS.js';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';

export default function Signature_ConstructionManeger() {
    const [open, setOpen] = React.useState(false);

    const canvasSize = { width: 490, height: 490 }
    const handleClickOpen = () => {
        setOpen(true);
        setTimeout(() => {
            sigCanvas.current.fromDataURL(imageURL, canvasSize);
        }, 200);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [imageURL, setImageURL] = useState(null);
    const [activeSave, setActiveSave] = useState(false);
    const [activeDelete, setActiveDelete] = useState(false);
    const { kojiCD } = useContext(ConfirmationContext);
    const { auth } = useContext(AuthContext);
    const authAxios = useAuthAxios();
    const headers = {
        "Authorization": "Bearer " + auth,
        'Content-Type': 'application/json',
    };
    const sigCanvas = useRef({});
    const clear = () => sigCanvas.current.clear();
    const save = () => {
        setImageURL(sigCanvas.current.toDataURL("image/png"));
        setActiveSave(true)
    }

    React.useEffect(() => {
        if (activeSave === true) {
            authAxios
                .post('/api/confirmation/chakko/' + kojiCD + '/sign?signType=builder', { "signData": imageURL.replace("data:image/png;base64,", "") }, { headers })
                .then(response => {
                    if (response.status === 200) {
                        setOpen(false)
                        setActiveSave(false)
                    }
                }).catch((response) => {
                    alert("保存に失敗しました。", response.body);
                    setActiveSave(false)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSave]);

    const deleteSign = () => {
        setActiveDelete(true)
        setOpen(false)
    }

    React.useEffect(() => {
        if (activeDelete === true) {
            authAxios
                .delete('/api/confirmation/chakko/' + kojiCD + '/sign?signType=builder', { headers })
                .then(response => {
                    if (response.status === 200) {
                        setActiveDelete(false)
                        setImageURL(null)
                        setOpen(false)
                    }
                }).catch((response) => {
                    alert("保存に失敗しました。", response.body);
                    setActiveDelete(false)
                    setOpen(false)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDelete]);

    React.useEffect(() => {
        authAxios
            .get('/api/confirmation/chakko/' + kojiCD + '/sign?signType=builder', { headers })
            .then(response => {
                const data = response.data;
                if (data.builderSign === "") {
                    setImageURL(data.builderSign)
                } else {
                    setImageURL("data:image/png;base64," + data.builderSign)
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <div>
                <Button variant="outlined" onClick={handleClickOpen} sx={{ minWidth: "170px" }}>
                    施工管理者サイン
                </Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <Grid container columns={12} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 2, bgcolor: "#EEEEEE" }}>
                        <Grid item xs={11} sx={{ bgcolor: "#EEEEEE" }}>
                            <DialogTitle id="alert-dialog-title" sx={{ bgcolor: "#EEEEEE" }}>
                                {"施工管理者サイン"}
                            </DialogTitle>
                        </Grid >
                        <Grid item xs={1} sx={{ bgcolor: "#EEEEEE" }}>
                            <DialogActions sx={{ bgcolor: "#EEEEEE" }}>
                                <Button onClick={handleClose}><CloseIcon /></Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                    <DialogContent sx={{ bgcolor: "#EEEEEE" }}>
                        <SignDialog>
                            <SignaturePad
                                ref={sigCanvas}
                                canvasProps={canvasSize}
                                minWidth={0.5}
                                maxWidth={4.0}
                                dotSize={3.0}
                                minDistance={1.0}
                                throttle={16}
                                velocityFilterWeight={0.7}
                                clearOnResize={false}
                            />
                        </SignDialog>
                    </DialogContent>
                    <DialogActions sx={{ bgcolor: "#EEEEEE" }}>
                        <Button onClick={save}>保存</Button>
                        <Button onClick={clear}>クリア</Button>
                        <Button onClick={deleteSign}>削除</Button>
                    </DialogActions>
                </Dialog>
                {imageURL ? (
                    <Grid sx={{ pt: 0.5 }}>
                        <img
                            src={imageURL}
                            alt="ConstructionManeger"
                            style={{
                                display: "block",
                                border: "thin solid rgba(25, 118, 210, 0.5)",
                                borderRadius: "5px",
                                padding: "5px",
                                height: "170px",
                                width: "170px"
                            }}
                            onClick={handleClickOpen}
                        />
                    </Grid>
                ) : null}
            </div>
        </React.Fragment>
    );
}
