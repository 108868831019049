import React, { useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SelectContractTitle, SelectContractContent, NomalButton, GrayButton } from '../../css/CSS.js';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import ClearIcon from '@mui/icons-material/Clear';
import useAuthAxios from "../../../useAuthAxios";
import LoadingDialog from '../../Loading';
import { DigitalConstractsContext } from "../../../DigitalConstracts";
import { useNavigate } from 'react-router-dom';

export default function DigitalConstractDocumentDelConfirm(props) {
    const [open, setOpen] = React.useState(false);
    const authAxios = useAuthAxios();
    const [processing, setProcessing] = React.useState(false);
    const [activeDelete, setActiveDelete] = React.useState(false)
    const headers = { "Authorization": "Bearer " + localStorage.getItem("Auth") };
    const { kojiCD, seqNo, contractType, setStatusCheck } = useContext(DigitalConstractsContext);
    const navigate = useNavigate()

    const handleClose = () => {
        setOpen(false);
        props.close(false)
    };

    React.useEffect(() => { setOpen(props.open); }, [props.open]);

    const onSubmit = () => {
        if (props.attachedFile === true) {
            props.setAttachedFile(false)
            props.setDeleteCheck(true)
            setOpen(false)
            props.close(false)
        }
        else {
            setActiveDelete(true)
        }
    };

    React.useEffect(() => {
        if (processing === true) { return }
        if (activeDelete == true) {
            setProcessing(true)
            setStatusCheck(false)
            authAxios
                .delete('api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + '/file'
                    + "?docFileTypeCD=" + props.docID
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        setOpen(false)
                        props.close(false)
                        setActiveDelete(false)
                        setProcessing(false)
                        setStatusCheck(true)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        alert("ファイル削除に失敗しました。");
                        setActiveDelete(false)
                        props.setDeleteCheck(false)
                        setOpen(false)
                        props.close(false)
                    }
                    setProcessing(false)
                });
            setProcessing(false)
        }
    }, [activeDelete]);

    return (
        <React.Fragment>
            <div>
                <LoadingDialog load={processing} />
                <Dialog
                    open={open}
                    onClose={handleClose}
                    fullWidth
                >
                    <SelectContractTitle id="alert-dialog-title">

                        <Grid container columns={12} rowSpacing={{ xs: 3 }} columnSpacing={4} >
                            <Grid item xs={11} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                添付資料削除
                            </Grid>
                            <Grid item xs={1} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                <ClearIcon fontSize="large" onClick={() => handleClose()} />
                            </Grid>
                        </Grid>
                    </SelectContractTitle>
                    <SelectContractContent >
                        <Grid container columns={12} rowSpacing={{ xs: 3 }} columnSpacing={1} sx={{ pt: 3 }}>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography>
                                    下記、ファイルを削除します。<br />
                                    {props.docID === "3" ? "重要事項説明書" : props.docID === "4" ? "設計及び工事監理業務に関する書面" : null}
                                    {props.attachedFile === true ? props.fileName : null}
                                </Typography>
                            </Grid>
                        </Grid>
                    </SelectContractContent>
                    <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <GrayButton type="button" onClick={handleClose} sx={{ mr: 5 }}>キャンセル<KeyboardReturnIcon sx={{ ml: 1 }} /></GrayButton>
                        <NomalButton type="button" style={{ width: "200px" }} onClick={() => onSubmit()}><DeleteForeverIcon sx={{ mr: 1 }} />削除</NomalButton>
                    </DialogActions>
                </Dialog>
            </div>
        </React.Fragment>
    );
}