import React, { useContext, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CheckBox from '../CheckBox'
import { useForm, Controller } from "react-hook-form";
import { UserInputData } from './JyotoFrame';
import Toggle, { TOGGLE_STATES } from '../Toggle'
import { Contents, FormTitle } from '../css/CSS.js';
import { useNavigate } from 'react-router-dom';
import useAuthAxios from "../../useAuthAxios";
import { ConfirmationContext } from "../../Confirmation";
import LoadingDialog from '../Loading';

export default function Naibu(props) {

    //各コンポーネントの動作を設定
    const validationRules = {
        yukaHenkoNaiyou: { maxLength: { value: 15, message: '15桁以内で入力してください。' } },
        peelingHenkoNaiyou: { maxLength: { value: 12, message: '12桁以内で入力してください。' } },
    }

    const { currentState, setCurrentState } = useContext(UserInputData);
    const { control, handleSubmit, getValues, formState: { errors }, watch } = useForm({
        defaultValues: { ...currentState.naibu },
        mode: 'onChange'
    });

    const [formNext, setFormNext] = useState(false)
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (formNext === true) {
                props.handleNext();
            }
        }
        setFormNext(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formNext]);
    const [formBack, setFormBack] = useState(false)
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (formBack === true) {
                props.handleBack();
            }
        }
        setFormBack(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formBack]);
    const { kojiCD, cutTeimei, changeTeimei } = useContext(ConfirmationContext);
    const authAxios = useAuthAxios();
    const navigate = useNavigate();

    const [processing_post, setProcessing_post] = useState(false);
    const [formPost, setFormPost] = useState(false)
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (formPost === true) {
                setProcessing_post(true);
                authAxios
                    .post('/api/confirmation/jyoto/' + kojiCD + "?order=update", currentState)
                    .then(response => {
                        if (response.status === 200) {
                            navigate("/confirmation/list");
                            setProcessing_post(false);
                        }
                    }).catch((response) => {
                        alert("保存に失敗しました。", response.body);
                        setProcessing_post(false);
                    })
            }
        }
        setFormPost(false);
        setTimeout(() => {
            setProcessing_post(false);
        }, 10000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formPost]);

    const onSubmit = (action) => {

        const naibu = getValues();
        if (action === "post") {
            setFormPost(true);
            setCurrentState({ ...currentState, naibu, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
        } else if (action === "next") {
            setCurrentState({ ...currentState, naibu, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
            setFormNext(true)
        } else if (action === "back") {
            setCurrentState({ ...currentState, naibu, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
            setFormBack(true)
        }
    };

    return (
        <Contents component="main" sx={{ pt: 1 }}>
            <form onSubmit={handleSubmit(onSubmit)} >
                <Grid container columns={12} columnSpacing={{ xs: 1, sm: 3, md: 3 }}>
                    <Grid>
                        <FormTitle>
                            内部確認
                        </FormTitle>
                    </Grid>
                </Grid>
                <Grid container columns={12} sx={{ pt: 1, }}>
                    <Grid item xs={2} sx={{}}>
                        <Controller
                            control={control}
                            name="yukaZai"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("yukaZai") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >床材</Typography>} labelPlacement="end"

                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={2.7} sx={{ transform: "scale(1.1)", }}>
                        <Typography sx={{ pt: 1 }}
                            style={watch("yukaZai") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : {}}
                        >使用材・貼手方向変更確認</Typography>
                    </Grid>
                    <Grid item xs={2.5}>
                        <Controller
                            control={control}
                            name="yukaHenkoUmu"
                            render={({ field: { value, onChange } }) => (
                                <Toggle
                                    setCheckedToggle={onChange}
                                    checkedToggle={value}
                                    toggleValue={"無"}
                                    toggleSts={TOGGLE_STATES.One}
                                    toggleValue2={"有"}
                                    toggleSts2={TOGGLE_STATES.Two}
                                    disabled={watch("yukaZai") === 2 ? true : false}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={0.8} sx={{ transform: "scale(1.1)", pt: 1 }}>
                        <Typography
                            style={watch("yukaZai") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                :
                                watch("yukaHenkoUmu") === TOGGLE_STATES.Two ? {}
                                    : { textDecoration: "line-through", color: "#BBBBBB" }
                            }
                        >変更内容</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ transform: "scale(1.1)", pt: 0.5, pl: 2.5 }}>
                        <Controller
                            control={control}
                            name="yukaHenkoNaiyou"
                            rules={validationRules.yukaHenkoNaiyou}
                            render={({ field, fieldState }) => (
                                < TextField
                                    {...field}
                                    style={{ width: '74%' }}
                                    variant="standard"
                                    error={fieldState.invalid}
                                    inputProps={{ maxLength: 15 }}
                                    disabled={watch("yukaZai") === 2 ? true : watch("yukaHenkoUmu") === TOGGLE_STATES.Two ? false : true}
                                />
                            )}
                        />
                    </Grid>
                </Grid>

                <Grid container columns={12} sx={{ pt: 2 }}>
                    <Grid item xs={2} sx={{ pl: 1 }}>
                        <Controller
                            control={control}
                            name="peeling"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("peeling") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >ピーリング</Typography>} labelPlacement="end"

                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={2.7}>
                        <Typography sx={{ transform: "scale(1.1)", pt: 1 }}
                            style={watch("peeling") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" } : {}}
                        >施工範囲・貼手方向変更確認</Typography>
                    </Grid>
                    <Grid item xs={2.5}>
                        <Controller
                            control={control}
                            name="peelingHenkoUmu"
                            render={({ field: { value, onChange } }) => (
                                <Toggle
                                    setCheckedToggle={onChange}
                                    checkedToggle={value}
                                    toggleValue={"無"}
                                    toggleSts={TOGGLE_STATES.One}
                                    toggleValue2={"有"}
                                    toggleSts2={TOGGLE_STATES.Two}
                                    disabled={watch("peeling") === 2 ? true : false}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={0.8} sx={{ transform: "scale(1.1)", pt: 1 }}>
                        <Typography
                            style={watch("peeling") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                :
                                watch("peelingHenkoUmu") === TOGGLE_STATES.Two ? {}
                                    : { textDecoration: "line-through", color: "#BBBBBB" }
                            }
                        >変更内容</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ transform: "scale(1.1)", pt: 0.5, pl: 2.5 }}>
                        <Controller
                            control={control}
                            name="peelingHenkoNaiyou"
                            rules={validationRules.peelingHenkoNaiyou}
                            render={({ field, fieldState }) => (
                                < TextField
                                    {...field}
                                    style={{ width: '59%' }}
                                    variant="standard"
                                    error={fieldState.invalid}
                                    inputProps={{ maxLength: 12 }}
                                    disabled={watch("peeling") === 2 ? true : watch("peelingHenkoUmu") === TOGGLE_STATES.Two ? false : true}
                                />
                            )}
                        />
                    </Grid>
                </Grid>

                <Grid container columns={12} sx={{ pt: 2 }}>
                    <Grid item xs={4} sx={{ pl: 3.4 }}>
                        <Controller
                            control={control}
                            name="sagariTenjo"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("sagariTenjo") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >下がり天井・梁型下がり天井</Typography>} labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3} sx={{}}>
                        <Controller
                            control={control}
                            name="fukasiKabe"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("fukasiKabe") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >ふかし壁</Typography>} labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3} sx={{}}>
                        <Controller
                            control={control}
                            name="pipeSpace"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("pipeSpace") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >パイプスペースの確認</Typography>} labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container columns={12} sx={{ pt: 2 }}>
                    <Grid item xs={4.086} sx={{ pl: 3 }}>
                        <Controller
                            control={control}
                            name="tobiraIchiHiraki"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("tobiraIchiHiraki") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >扉の位置、開き勝手の確認</Typography>} labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={2.95} sx={{}}>
                        <Controller
                            control={control}
                            name="shunoZosaku"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("shunoZosaku") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >収納、造作確認</Typography>} labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3} sx={{}}>
                        <Controller
                            control={control}
                            name="makuradanaCounter"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("makuradanaCounter") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >枕棚、カウンターの確認</Typography>} labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container columns={12} sx={{ pt: 2 }}>
                    <Grid item xs={4} sx={{ pl: 0.5 }}>
                        <Controller
                            control={control}
                            name="hannyukeiro"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("hannyukeiro") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >搬入経路</Typography>} labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={2.83} sx={{}}>
                        <Controller
                            control={control}
                            name="reizoko"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("reizoko") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >冷蔵庫</Typography>} labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3} sx={{}}>
                        <Controller
                            control={control}
                            name="sentakuki"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("sentakuki") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >洗濯機</Typography>} labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container columns={12} sx={{ pt: 2 }}>

                    <Grid item xs={4} sx={{}}>
                        <Controller
                            control={control}
                            name="kagu"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("kagu") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >家具</Typography>} labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={4} sx={{}}>
                        <Controller
                            control={control}
                            name="piano"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("piano") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >ピアノ</Typography>} labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container columns={12} rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 2 }}>
                    <Grid container columns={16} rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 5 }}>
                        <Grid item xs={12} >
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("post")} disabled={processing_post}>
                                {processing_post ? "処理中" : "保存して一覧へ"}
                            </Button>
                            <LoadingDialog load={processing_post} />
                        </Grid>
                        <Grid item xs={2} sx={{ pr: 1 }}>
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("back")} >
                                戻る
                            </Button>
                        </Grid>
                        <Grid item xs={1.5} sx={{ pl: 1 }}>
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("next")}>
                                次へ
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form >
        </Contents >
    );
}