import React, { useContext } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { AuthContext } from "../Auth";
import useAuthAxios from "../useAuthAxios";
import { removeCookie } from "./Action/HandleCookie"

export default function Logout() {
    const navigate = useNavigate();
    const { auth } = useContext(AuthContext);
    const authAxios = useAuthAxios();
    const headers = {
        "Authorization": "Bearer " + auth,
        'Content-Type': 'application/json',
    };

    React.useEffect(() => {
        authAxios.post('api/authenticate/logout', "", { headers })
            .then((response) => {
                if (response.status === 200) {
                    localStorage.removeItem("Auth");
                    localStorage.removeItem('use')
                    localStorage.removeItem('contractListOpe')
                    localStorage.removeItem('contractDelete')
                    localStorage.removeItem('contractConfigOpe')
                    localStorage.removeItem('internalApproval')
                    localStorage.removeItem('internalConfirm')
                    removeCookie("authorization")
                    removeCookie("userName")
                    removeCookie("userShozokuName")

                    navigate("/");
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    navigate("/");
                } else {
                    alert("認証情報削除に失敗しました。");
                    navigate("/");
                }
            });

    });

    return (

        <Container component="main" maxWidth="lg" sx={{ mb: 1 }}>
            <Grid container spacing={3} >
                <Grid item xs={8} >
                    <Typography sx={{ color: 'text.primary' }} >
                        ログアウトしました。
                    </Typography>
                </Grid>

            </Grid>
        </Container>
    );
}
