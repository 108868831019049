import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';
import { ConfirmationContents } from '../../css/CSS.js';
import Typography from '@mui/material/Typography';
import useAuthAxios from "../../../useAuthAxios";
import { useNavigate } from 'react-router-dom';
import { DigitalConstractsContext } from "../../../DigitalConstracts";

const TableFlame = styled(TableContainer)({
    borderRadius: "10px 10px 0 0",
    maxHeight: 400
});
const TableHeader = styled(TableCell)({
    backgroundColor: "#a0a0a0",
    padding: "10px",
});
const Header = styled(TableRow)({
    backgroundColor: "#a0a0a0",
});
const Text = styled(Typography)({
    color: "#202020",
    transform: "scale(0.9)",
});

const CLTableCell = styled(TableCell)({
    padding: "5px",
});


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
    {
        id: 'eventDate',
        label: '更新日時',
        align: 'left',
    },
    {
        id: 'event',
        label: 'ステータス',
        align: 'left'
    },
    {
        id: 'opeUserSectionName',
        label: '所属部署',
        align: 'left'
    },
    {
        id: 'opeUserName',
        label: '更新者',
        align: 'left'
    },
    {
        id: 'eventInfo',
        label: '更新内容',
        align: 'left'
    }
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <Header>
                {headCells.map((headCell) => (
                    <TableHeader
                        key={headCell.id}
                        align={headCell.align}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={headCell.id === 'customerCD' ? { pr: 0, pl: 0.5 } : { pr: 0, pl: 0 }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <Text >{headCell.label}</Text>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableHeader>
                ))}
            </Header>
        </TableHead >
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number,
};

export default function ConfirmationList(props) {
    const headers = { "Authorization": "Bearer " + localStorage.getItem("Auth") };

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const authAxios = useAuthAxios();
    const [list, setList] = useState()
    const navigate = useNavigate();

    const { kojiCD, seqNo, contractType, statusCheck } = useContext(DigitalConstractsContext);

    React.useEffect(() => {
        if (statusCheck === true) {
            authAxios
                .get('api/contracts'
                    + "/" + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + "/hist"
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        const listdata = JSON.parse((JSON.stringify(response.data)))
                        setList(listdata.contractHist)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        alert("更新履歴一覧情報の取得に失敗しました。");
                    }
                });
            setTimeout(() => {
            }, 10000);
        } else {
            setList(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusCheck]);

    return (
        <ConfirmationContents style={{ paddingRight: "0px" }}>
            <Paper sx={{ width: '100%', boxShadow: 0, mt: 1 }} style={{ paddingRight: "0px" }}>
                <TableFlame>
                    <Table
                        stickyHeader
                        aria-labelledby="tableTitle"
                        align="center"
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={list?.length}
                        />
                        <TableBody>
                            {stableSort(list, getComparator(order, orderBy))
                                ?.map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={index}
                                            sx={{ backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#EEEEEE" }}
                                            style={{ height: 10 }}
                                        >
                                            <CLTableCell align="left" width="15%" >{row.eventDate}</CLTableCell>
                                            <CLTableCell align="left" width="20%" >{row.event}</CLTableCell>
                                            <CLTableCell align="left" width="15%">{row.opeUserSectionName}</CLTableCell>
                                            <CLTableCell align="left" width="15%" >{row.opeUserName}</CLTableCell>
                                            <CLTableCell align="left" width="50%" >{row.eventInfo}</CLTableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableFlame>
            </Paper>
        </ConfirmationContents >
    );
}
