import React, { useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { useForm, Controller } from "react-hook-form";
import useAuthAxios from "../../../useAuthAxios";
import { NomalButton } from '../../css/CSS.js';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import LoadingDialog from '../../Loading';
import { DigitalConstractsContext } from "../../../DigitalConstracts";
import { useNavigate } from 'react-router-dom';

const Input = styled('input')({
    display: 'none',
});

export default function UploadButton(props) {
    const { control } = useForm({
        defaultValues: {
            attachFileData: "",
            attachFileName: "",
        },
    });
    const { setStatusCheck } = useContext(DigitalConstractsContext);

    const authAxios = useAuthAxios();
    const [file, setFile] = useState(null)
    const [fileName, setFileName] = useState(null)
    const [activePost, setactivePost] = useState(false)
    const navigate = useNavigate()

    const headers = {
        "Authorization": "Bearer " + localStorage.getItem("Auth"),
        'Content-Type': 'multipart/form-data'
    };

    const handleFileInputChange = ({ target }) => {
        setactivePost(false)
        if (target.files < 1 || !target.validity.valid) {
            return
        }
        setFileName(target.files[0].name)
        setFile(target.files[0])
        setactivePost(true)
    }

    const [processing, setProcessing] = useState(false)
    const [contractType, setContractType] = useState()
    React.useEffect(() => {
        if (props.contractType === "工事請負契約書") { setContractType("base") }
        else if (props.contractType === "変更工事請負契約書") { setContractType("modfy") }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (processing === true) { return }
        if (activePost == true) {
            setProcessing(true)
            setStatusCheck(false)
            const body = new FormData();
            body.append("fileName", fileName)
            body.append("file", file)

            authAxios
                .post('api/contracts/'
                    + contractType
                    + '/' + props.kojiCD
                    + '/' + props.seqNo
                    + '/attach'
                    , body
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        setactivePost(false)
                        setStatusCheck(true)

                    }
                    setProcessing(false)
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        alert("ファイル登録に失敗しました。");
                    }
                    setactivePost(false)
                    setProcessing(false)
                });
        }
    }, [activePost]);

    const onSubmit = () => {
        if (file != null) {
            setactivePost(true)
        }
    };

    return (
        <label htmlFor="contained-button-file">
            <LoadingDialog load={processing} />
            <form onSubmit={onSubmit}>
                <Controller
                    control={control}
                    name="attachmentFile"
                    render={({ field }) => (
                        <FileUploadButton
                            {...field}
                            className="primary"
                            name="image"
                            onChange={handleFileInputChange}
                            sx={{ boxShadow: 0, }}
                        >
                            <UploadFileIcon sx={{ mr: 1 }} />ファイル追加
                        </FileUploadButton>
                    )}
                />
            </form>
        </label>

    );
}

export const FileUploadButton = (props) => {
    return (
        <label htmlFor={`upload-button-${props.name}`}>
            <Input
                accept="application/pdf"
                id={`upload-button-${props.name}`}
                name={props.name}
                multiple
                type="file"
                onChange={props.onChange}
            />
            <NomalButton variant="contained" component="span" {...props} >
                {props.children}
            </NomalButton>
        </label>
    );
};
