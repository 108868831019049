import React, { useState, useContext } from 'react';
import { ConstructContainer, SelectCustomerButton, CheckListUploadButton, WhiteTextBack } from '../../css/CSS.js';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import LoadingDialog from '../../Loading';
import useAuthAxios from "../../../useAuthAxios";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import DigitalConstractSignature from './DigitalConstractSignature'
import UploadIcon from '@mui/icons-material/Upload';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import DigitalConstractSelectCustomer from './DigitalConstractSelectCustomer'
import { DigitalConstractsContext } from "../../../DigitalConstracts";


export default function DigitalConstractCustomerConfirmation(props) {
    const authAxios = useAuthAxios();
    const headers = { "Authorization": "Bearer " + localStorage.getItem("Auth") };
    const { kojiCD, seqNo, contractType, customerNo, setSelectPDF
        , status, setStatus, setStatusCheck
    } = useContext(DigitalConstractsContext);
    const navigate = useNavigate();
    const [processing, setProcessing] = React.useState(false);
    const [checkList, setCheckList] = useState();
    const [attachCheckList, setAttachCheckList] = useState();

    const [selectCustomerAddress, setSelectCustomerAddress] = useState();
    const [selectCustomerName, setSelectCustomerName] = useState();
    const [openSelectCustomer, setOpenSelectCustomer] = React.useState(false);
    const [checkRequiredNumber, setCheckRequiredNumber] = useState(0);
    const [countCheck, setCountCheck] = useState(0);
    const [doPOST, setDoPOST] = useState(false);
    const [signData, setSignData] = useState();
    const [disabledCheck, setDisabledCheck] = useState(false);
    const [donePost, setDonePost] = React.useState(false);


    React.useLayoutEffect(() => {
        checkList
            ?.forEach((row) => {
                if (row !== null) {
                    setCheckRequiredNumber((prevCount) => prevCount + 1)
                }
            })
        attachCheckList?.forEach((row) => {
            if (row !== null) {
                setCheckRequiredNumber((prevCount) => prevCount + 1)
            }
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkList]);

    React.useLayoutEffect(() => {
        if (processing === true) { return; }
        if (customerNo) {
            setCheckRequiredNumber(0)
            setCountCheck(0)
            setSignData()
            setDisabledCheck(false)
            setProcessing(true)
            authAxios
                .get('api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + '/info/customer'
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        const customerList = JSON.parse((JSON.stringify(response.data)))
                        if (customerNo === "1") {
                            setSelectCustomerAddress(customerList.customer1Address)
                            setSelectCustomerName(customerList.customer1Name)
                            if (customerList.customer1Sign != null) {
                                setDisabledCheck(true)
                            }
                        } else if (customerNo === "2") {
                            setSelectCustomerAddress(customerList.customer2Address)
                            setSelectCustomerName(customerList.customer2Name)
                            if (customerList.customer2Sign != null) {
                                setDisabledCheck(true)
                            }
                        } else if (customerNo === "3") {
                            setSelectCustomerAddress(customerList.customer3Address)
                            setSelectCustomerName(customerList.customer3Name)
                            if (customerList.customer3Sign != null) {
                                setDisabledCheck(true)
                            }
                        } else if (customerNo === "4") {
                            setSelectCustomerAddress(customerList.customer4Address)
                            setSelectCustomerName(customerList.customer4Name)
                            if (customerList.customer4Sign != null) {
                                setDisabledCheck(true)
                            }
                        }
                    }
                    setProcessing(false)
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        alert("お客様情報取得に失敗しました。");
                        setProcessing(false)
                    }
                    setProcessing(false)
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerNo]);

    React.useLayoutEffect(() => {
        if (processing === true) { return; }
        if (customerNo) {
            setProcessing(true)
            authAxios
                .get('api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + '/customer/checklist'
                    + "?customerNo=" + customerNo
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        const checkList = JSON.parse((JSON.stringify(response.data)))
                        setCheckList(checkList.checkList)
                        setAttachCheckList(checkList.checkList[0].attachedList)
                    }
                    setProcessing(false)
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        alert("チェックリスト取得に失敗しました。");
                        setProcessing(false)
                    }
                    setProcessing(false)
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerNo]);


    const handleChangeCustomer = () => { setOpenSelectCustomer(true) }

    const handleChangePDF = (docName) => { setSelectPDF(docName) }

    const checkCount = (status, index) => {
        if (status === true) {
            setCountCheck((prevCount) => prevCount + 1)
            checkList?.forEach((value, i, array) => {
                array[index].checkStatus = 1
            })
        }
        else if (status === false) {
            setCountCheck((prevCount) => prevCount - 1)
            checkList?.forEach((value, i, array) => {
                array[index].checkStatus = 0
            })
        }
    }

    const checkCountAttach = (status, index) => {
        if (status === true) {
            setCountCheck((prevCount) => prevCount + 1)
            attachCheckList?.forEach((value, i, array) => {
                array[index].checkStatus = 1
            })
        }
        else if (status === false) {
            setCountCheck((prevCount) => prevCount - 1)
            attachCheckList?.forEach((value, i, array) => {
                array[index].checkStatus = 0
            })
        }
    }

    const handlePOST = () => {
        setDoPOST(true)
        //checkList[0].attachedList = attachCheckList
    };

    React.useLayoutEffect(() => {
        if (processing === true) { return; }
        if (doPOST === true) {
            setProcessing(true)
            authAxios
                .put('api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + '/customer/checklist'
                    + "?customerNo=" + customerNo
                    , { checkList }
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        authAxios
                            .post('/api/contracts/'
                                + (contractType === "工事請負契約書" ? "base" : "modfy")
                                + "/" + kojiCD
                                + "/" + seqNo
                                + '/customer/sign'
                                + "?customer=" + customerNo
                                , { "signData": signData.replace("data:image/png;base64,", "") }
                                , { headers }
                            )
                            .then(response => {
                                if (response.status === 200) {
                                    setOpenSelectCustomer(true)
                                    setDonePost(true)
                                    setDisabledCheck(true)
                                }
                            }).catch((error) => {
                                if (error.response.status === 401) {
                                    alert("認証情報が正しくありません。");
                                    navigate("/");
                                } else {
                                    alert("サインの保存に失敗しました。");
                                    setProcessing(false)
                                }
                                setProcessing(false)
                            });
                        setDoPOST(false)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        alert("チェックリスト更新に失敗しました。");
                        setProcessing(false)
                    }
                    setDoPOST(false)
                    setProcessing(false)
                });
            setProcessing(false)
        }
        setProcessing(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doPOST]);

    React.useEffect(() => {
        if (processing === true) { return; }
        if (donePost === true) {
            setProcessing(true)
            authAxios
                .get('api/contracts'
                    + "/" + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + "/info/mng"
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        const listdata = JSON.parse((JSON.stringify(response.data)))
                        setStatus(listdata.status)
                    }
                    setProcessing(false)
                    setCountCheck(0)
                    setSignData()
                    setSelectPDF("change")
                    setDonePost(false)
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        alert("契約書管理情報の取得に失敗しました");
                    }
                    setProcessing(false)
                    setDonePost(false)
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [donePost]);

    React.useLayoutEffect(() => {
        if (processing === true) { return; }

        if (status === "お客様署名済み") {
            setProcessing(true)
            authAxios
                .get('api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + "/file"
                    + "/5"
                    + "/書類内容のご承諾.pdf"
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        setStatusCheck(true)
                    }
                    setProcessing(false)
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        alert("チェックリストPDF取得に失敗しました。");
                        setProcessing(false)
                    }
                    setProcessing(false)
                });
            setProcessing(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    return (
        <ConstructContainer >
            <Grid container columns={12} rowSpacing={{ xs: 1 }}>
                <LoadingDialog load={processing} />
                <Grid item xs={12} >
                    <Typography sx={{ fontSize: 20 }}>書類内容のご確認</Typography>
                </Grid>
                <Grid item xs={12} sx={{ mb: 3 }}>
                    <Typography sx={{ fontSize: 18 }}>
                        ご承諾頂ける書類にチェックを付け、ご署名をお願い致します。
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container rowSpacing={{ xs: 2 }}
                        style={{
                            maxHeight: '250px', overflow: 'auto', backgroundColor: "#ffffff",
                            border: "1px solid",
                            borderColor: "#e9e9e9"
                        }}
                    >
                        {checkList?.map((row, index) => {
                            return (
                                <Grid container key={index}>
                                    {!(row === null) ?
                                        <Grid item xs={12}>
                                            <Grid container >
                                                <Grid item xs={11} >
                                                    <Grid container>
                                                        <FormControlLabel
                                                            sx={{ ml: 1 }}
                                                            control={
                                                                <Checkbox
                                                                    disabled={disabledCheck === false ? signData == null ? false : true : true}
                                                                    checked={row?.checkStatus === 1 ? true : false}
                                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                                                                    onChange={(event) => checkCount(event.target.checked, index)}
                                                                />
                                                            }
                                                            label={<Typography sx={{ pl: 1, fontSize: 20 }} >{row?.docName}</Typography>}
                                                            labelPlacement="end"
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={1} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                                    <ZoomInIcon sx={{ fontSize: 30 }} onClick={() => handleChangePDF(row.docName)} />
                                                </Grid>

                                                {!(row?.attachedList === null) ?
                                                    attachCheckList?.map((Attachrow, index) => {
                                                        return (
                                                            <Grid container key={index}>
                                                                <Grid item xs={12} sx={{ ml: 8 }} key={index}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                disabled={disabledCheck === false ? signData == null ? false : true : true}
                                                                                checked={Attachrow?.checkStatus === 1 ? true : false}
                                                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                                                                                onChange={(event) => checkCountAttach(event.target.checked, index)}
                                                                            />
                                                                        }
                                                                        label={<Typography sx={{ pl: 1, fontSize: 20 }} >{Attachrow?.docName}</Typography>}
                                                                        labelPlacement="end"
                                                                    />
                                                                </Grid>

                                                            </Grid>
                                                        )
                                                    })
                                                    : null
                                                }
                                            </Grid>
                                        </Grid>
                                        : null
                                    }
                                </Grid>

                            )
                        })}
                    </Grid>
                    <Grid container columns={12} rowSpacing={{ xs: 0.5 }} >
                        <Grid item xs={12} sx={{ mt: 1 }}>
                            <Typography>
                                ご住所
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <WhiteTextBack style={{
                                backgroundColor: "#ffffff", border: "1px solid", borderColor: "#e9e9e9", fontSize: 20
                            }}>
                                {selectCustomerAddress}
                            </WhiteTextBack>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{ mt: 1 }}>
                                お客様名
                            </Typography>
                        </Grid>
                        <Grid item xs={12} >
                            <WhiteTextBack style={{
                                backgroundColor: "#ffffff", border: "1px solid", borderColor: "#e9e9e9", fontSize: 20
                            }}>
                                {selectCustomerName}
                            </WhiteTextBack>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{ mt: 1 }}>
                                ご署名
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} >
                                    <DigitalConstractSignature
                                        contractType={contractType}
                                        kojiCD={kojiCD}
                                        seqNo={seqNo}
                                        customerNumber={customerNo}
                                        setSignData={setSignData}
                                        disabled={checkRequiredNumber === countCheck ? false : true}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 2, mb: 1 }}>
                        <Grid container style={{
                            backgroundColor: "#ffffdb",
                        }} >
                            <Grid item xs={5}
                                style={{
                                    display: 'flex', justifyContent: 'center', alignItems: 'center'
                                }}>
                                <SelectCustomerButton
                                    className="customerSelectButton"
                                    type="button"
                                    onClick={handleChangeCustomer}
                                >
                                    <PeopleOutlineIcon sx={{ mr: 1 }} />
                                    お客様選択へ
                                </SelectCustomerButton>
                                <DigitalConstractSelectCustomer
                                    open={openSelectCustomer}
                                    close={setOpenSelectCustomer}
                                />
                            </Grid>
                            <Grid item xs={7}
                                style={{
                                    display: 'flex', justifyContent: 'center', alignItems: 'center'
                                }}>
                                <CheckListUploadButton
                                    type="button"
                                    sx={{ mt: 1, mb: 1 }}
                                    onClick={handlePOST}
                                    disabled={checkRequiredNumber === countCheck ? signData != null ? false : true : true}
                                >
                                    <UploadIcon sx={{ mr: 1, transform: "scale(1.3)" }} />
                                    登録
                                </CheckListUploadButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ConstructContainer >
    );
}