import React, { createContext, useState } from "react";

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({});
    const [userShozokuCD, setUserShozokuCD] = useState({});
    const [digitalConstractUser, setDigitalConstractUser] = useState(false);
    const [userSysAuthority, setUserSysAuthority] = useState();
    const [userSysAuthoritycheck, setUserSysAuthorityCheck] = useState(false);

    return (
        <AuthContext.Provider value={{
            auth, setAuth, userShozokuCD, setUserShozokuCD, digitalConstractUser, setDigitalConstractUser
            , userSysAuthority, setUserSysAuthority, userSysAuthoritycheck, setUserSysAuthorityCheck
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
