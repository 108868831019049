import React, { useState, createContext, useContext } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Gaibu from './GaibuForm';
import Naibu from './NaibuForm';
import Setubi from './SetubiForm';
import TuikaKojiKanri from './TuikaKojiKanriForm';
import Review from './Review';
import { styled } from '@mui/material/styles';
import { CHECKBOX_STATES } from "../CheckBox"
import { ConfirmationContext } from "../../Confirmation";
import { Contents } from '../css/CSS.js';
//import UploadButtons from '../FileUpload';
import { Title, ConfirmationName } from '../css/CSS.js';
import Card from '@mui/material/Card';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";

const Steppers = styled(Stepper)({
    backgroundColor: "#FFFFCC",
    padding: "10px",
    borderRadius: "10px",
    transform: "scale(1.1)"
});

const getSteps = ['外部確認', '内部確認', '設備工事の確認', '追加・変更工事、工事監理報告書の確認', '署名'];

export const JyotoInitialInputData = {
    gaibu: {
        yaneHafuIro: CHECKBOX_STATES.Empty,
        mizukiriIro: CHECKBOX_STATES.Empty,
        mizukiriDodai: CHECKBOX_STATES.Empty,
        mizukiriMadoue: CHECKBOX_STATES.Empty,
        mizukiriMadosita: CHECKBOX_STATES.Empty,
        mizukiriChukan: CHECKBOX_STATES.Empty,
        mizkiriGenkanDoorSita: CHECKBOX_STATES.Empty,
        kabeMadoIro: CHECKBOX_STATES.Empty,
        kabeMadoKeshowaku: CHECKBOX_STATES.Empty,
        kabeMadoPouchBasira: CHECKBOX_STATES.Empty,
        kabeMadoMadokibu: CHECKBOX_STATES.Empty,
        kabeMadoMokuseiBalcony: CHECKBOX_STATES.Empty,
        kabeMadoTukebasira: CHECKBOX_STATES.Empty,
        kabeMadoHenko: CHECKBOX_STATES.Empty,
        kabeMadoHenkoNaiyou: "",
        gaikoKoji: CHECKBOX_STATES.Empty,
        gaikoKojiUmu: 0,
        gaikoGyosha: "",
        gaikoSetubiToriai: CHECKBOX_STATES.Empty,
        toriaiSansuisen: CHECKBOX_STATES.Empty,
        toriaiRissuisen: CHECKBOX_STATES.Empty,
        toriaiHaisuiMas: CHECKBOX_STATES.Empty,
        toriaiAirconSitugaiki: CHECKBOX_STATES.Empty,
        toriaiJB: CHECKBOX_STATES.Empty,
        toriaiBosuiConcent: CHECKBOX_STATES.Empty,
        meterIchi: CHECKBOX_STATES.Empty,
        meterIchiDenki: CHECKBOX_STATES.Empty,
        meterIchiSuido: CHECKBOX_STATES.Empty,
        meterIchiGas: CHECKBOX_STATES.Empty,
        meterIchiNote: "",
        masIchiTakasa: CHECKBOX_STATES.Empty,
        masIchiTakasaNote: "",
        kansenHikikomi: CHECKBOX_STATES.Empty,
        kansenHikikomiNote: "",
    },
    naibu: {
        yukaZai: CHECKBOX_STATES.Empty,
        yukaHenkoUmu: 0,
        yukaHenkoNaiyou: "",
        peeling: CHECKBOX_STATES.Empty,
        peelingHenkoUmu: 0,
        peelingHenkoNaiyou: "",
        sagariTenjo: CHECKBOX_STATES.Empty,
        fukasiKabe: CHECKBOX_STATES.Empty,
        pipeSpace: CHECKBOX_STATES.Empty,
        tobiraIchiHiraki: CHECKBOX_STATES.Empty,
        shunoZosaku: CHECKBOX_STATES.Empty,
        makuradanaCounter: CHECKBOX_STATES.Empty,
        hannyukeiro: CHECKBOX_STATES.Empty,
        reizoko: CHECKBOX_STATES.Empty,
        sentakuki: CHECKBOX_STATES.Empty,
        kagu: CHECKBOX_STATES.Empty,
        piano: CHECKBOX_STATES.Empty,
    },
    setubi: {
        kitchen: CHECKBOX_STATES.Empty,
        ub: CHECKBOX_STATES.Empty,
        senmenKeshoDai: CHECKBOX_STATES.Empty,
        toilet: CHECKBOX_STATES.Empty,
        seihonzuHenkoUmu: 0,
        denkiSetubiKoji: CHECKBOX_STATES.Empty,
        denkiBundenban: CHECKBOX_STATES.Empty,
        denkiSwConKasai: CHECKBOX_STATES.Empty,
        denkiTvLanTelHaisen: CHECKBOX_STATES.Empty,
        denkiKeiyakuYoryo: CHECKBOX_STATES.Empty,
        denkiKeiyakuYoryoAmps: 0,
        denkiJiyukaPlanSetumei: CHECKBOX_STATES.Empty,
        denkiNote: "",
        suidoSetubiKoji: CHECKBOX_STATES.Empty,
        remoconAccessory: CHECKBOX_STATES.Empty,
        gasKoji: CHECKBOX_STATES.Empty,
        gasOven: CHECKBOX_STATES.Empty,
        gasKaran: CHECKBOX_STATES.Empty,
        kankiKoji: CHECKBOX_STATES.Empty,
        kuuchoAircon: CHECKBOX_STATES.Empty,
        securityKoji: CHECKBOX_STATES.Empty,
        danbouKoji: CHECKBOX_STATES.Empty,
        taiyokoKoji: CHECKBOX_STATES.Empty,
        jukyuKeiyakuMosikomisho: CHECKBOX_STATES.Empty,
        ninteiTuchisho: CHECKBOX_STATES.Empty,
    },
    tuikaHenko: {
        tuikaHenkoKoji: CHECKBOX_STATES.Empty,
        tuikaHenkoKojiNote: ""
    },
    kojiKanriHoukokusho: {
        kojiKanriHoukokusho: CHECKBOX_STATES.Empty,
        kojiKanriHoukokushoWatasiHaikin: 0,
        kojiKanriHoukokushoWatasiJyoto: 0,
    },
    reportKojiName: "",
    reportKojiNameHonorific: "",
    kiroku: 0,
};

//入力値用context
export const UserInputData = createContext(JyotoInitialInputData);
//POST用context
export const PostData = createContext({});

export default function JyotoForm() {
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps;
    const { kojiCD, jyotoformData, being_jyoto, setBeing_jyoto, cutTeimei, setCutTeimei, changeTeimei, setChangeTeimei } = useContext(ConfirmationContext);
    const [currentState, setCurrentState] = useState(being_jyoto ? jyotoformData : JyotoInitialInputData);

    const value = { currentState, setCurrentState };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleSave = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <Gaibu handleNext={handleNext} />;
            case 1:
                return <Naibu handleNext={handleNext} handleBack={handleBack} />;
            case 2:
                return <Setubi handleNext={handleNext} handleBack={handleBack} />;
            case 3:
                return <TuikaKojiKanri handleSave={handleSave} handleBack={handleBack} />;
            case 4:
                return <Review handleBack={handleBack} />;
            default:
                throw new Error('Unknown step');
        }
    }


    //邸名編集機能
    const validationRules = {
        reportKojiNameHonorific: { maxLength: { value: 3, message: '3桁以内で入力してください。' }, },
    }
    const { control, handleSubmit, watch } = useForm({
        defaultValues: { ...currentState },
    });
    React.useEffect(() => {
        if (!watch("reportKojiNameHonorific")) {
            setCutTeimei(cutTeimei)
            setChangeTeimei(changeTeimei)
        } else {
            setCutTeimei(cutTeimei)
            !watch("reportKojiNameHonorific") ?
                setChangeTeimei(changeTeimei)
                : setChangeTeimei(watch("reportKojiNameHonorific"))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch("reportKojiNameHonorific")]);

    React.useEffect(() => {
        setBeing_jyoto(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <CssBaseline />
            <Title sx={{ pb: 2, pt: 4, }}>
                <Grid container columns={12} spacing={3} >
                    <Grid container columns={12} style={{ display: 'flex', alignItems: 'end' }}>
                        <Grid item xs={2.5} >
                            <Typography sx={{ fontSize: 20, }} >
                                上棟立会確認書
                            </Typography>
                            <Typography sx={{ fontSize: 15, }} >
                                工事コード:{kojiCD}
                            </Typography>
                        </Grid>
                        <Grid item xs={7} sx={{ pl: 1 }}>
                            <Card sx={{ boxShadow: 0 }} >
                                <ConfirmationName style={{ display: 'flex', paddingBottom: 0, justifyContent: 'center', alignItems: 'end' }}>
                                    <Typography
                                        sx={{
                                            fontSize: 25,
                                            pb: 0
                                        }} >
                                        {currentState.reportKojiName}
                                    </Typography>
                                </ConfirmationName>
                            </Card>
                        </Grid>
                        {activeStep !== 4 ?
                            <form onSubmit={handleSubmit()} >
                                <Grid item xs={3} sx={{ transform: "scale(1.5)", pb: 1 }}>
                                    <Controller
                                        control={control}
                                        name="reportKojiNameHonorific"
                                        rules={validationRules.reportKojiNameHonorific}
                                        render={({ field, fieldState }) => (
                                            < TextField
                                                {...field}
                                                sx={{ pt: 2 }}
                                                variant="standard"
                                                inputProps={{ maxLength: 3, style: { padding: '0px', textAlign: 'bottom' } }}
                                                style={{ width: '100%' }}
                                                error={fieldState.invalid}
                                            />
                                        )}
                                    />
                                </Grid>
                            </form>
                            : <Grid item xs={1} sx={{ transform: "scale(1.5)", pt: 5.6, pb: 1 }}>
                                <Typography
                                    sx={{
                                        pt: 1,
                                        pb: 0
                                    }} >
                                    {currentState.reportKojiNameHonorific}
                                </Typography>
                            </Grid>
                        }

                        {/*<Grid item xs={2.5} >*/}
                        {/*    <UploadButtons />*/}
                        {/*</Grid>*/}
                    </Grid>
                </Grid>
            </Title>

            <Contents component="main" sx={{ mb: 1 }}>
                <Steppers activeStep={activeStep} sx={{ pb: 1 }}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel >{label}</StepLabel>
                        </Step>
                    ))}
                </Steppers>
                <UserInputData.Provider value={value}>
                    {getStepContent(activeStep, handleNext)}
                </UserInputData.Provider>
            </Contents>

        </React.Fragment>
    );
}