import React, { useState, createContext, useContext } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Kakunin from './kakuninForm';
import Kakunin2 from './kakuninForm2';
import KoukiForm from './KoukiForm';
import RyuuiForm from './RyuuiForm';
import GenbaForm from './GenbaForm';
import Review from './Review';
import { styled } from '@mui/material/styles';
import { CHECKBOX_STATES } from "../CheckBox";
import { ConfirmationContext } from "../../Confirmation";
import { Contents } from '../css/CSS.js';
//import UploadButtons from '../FileUpload';
import { Title, ConfirmationName } from '../css/CSS.js';
import Card from '@mui/material/Card';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";

const Steppers = styled(Stepper)({
    backgroundColor: "#FFFFCC",
    padding: "10px",
    borderRadius: "10px",
    transform: "scale(1.1)"
});

const getSteps = ['確認事項1', '確認事項2', '工期', '工事着手のご留意事項', '現場検査', '署名'];

export const ChakkoInitialInputData = {
    kakunin: {
        kyokaiPoint: CHECKBOX_STATES.Empty,
        kyokaiPointKakunin: 0,
        kyokaiPointNote: "",
        haichi: CHECKBOX_STATES.Empty,
        haichiHenkoUmu: 0,
        haichiHenko1Side: 0,
        haichiHenko1Before: 0,
        haichiHenko1After: 0,
        haichiHenko2Side: 0,
        haichiHenko2Before: 0,
        haichiHenko2After: 0,
        houkisei: CHECKBOX_STATES.Empty,
        houKitaShasen: CHECKBOX_STATES.Empty,
        houDoroShasen: CHECKBOX_STATES.Empty,
        houTakasaSeigen: CHECKBOX_STATES.Empty,
        houSaikou: CHECKBOX_STATES.Empty,
        houHekimenKoutai: CHECKBOX_STATES.Empty,
        houEnshoLine: CHECKBOX_STATES.Empty,
        houMinpoRikaku500: CHECKBOX_STATES.Empty,
        sekkeiGLKakunin: CHECKBOX_STATES.Empty,
        sekkeiGLBM: CHECKBOX_STATES.Empty,
        sekkeiGLBMNote: "",
        sekkeiGLHenko: CHECKBOX_STATES.Empty,
        sekkeiGLHenkoUmu: 0,
        sekkeiGLHenkoBefore: 0,
        sekkeiGLHenkoAfter: 0,
        jibanKairyo: CHECKBOX_STATES.Empty,
        jibanKairyoUmu: 0,
        jibanHyuoso: CHECKBOX_STATES.Empty,
        jibanKoukankui: CHECKBOX_STATES.Empty,
        jibanTigerPile: CHECKBOX_STATES.Empty,
        jibanKiseiConcreteKui: CHECKBOX_STATES.Empty,
        jibanPurePileKouho: CHECKBOX_STATES.Empty,
        jibanSonota: CHECKBOX_STATES.Empty,
        jibanSonotaNote: "",
        kakuninShinsei: CHECKBOX_STATES.Empty,
        kakuninShinseiSts: 0,
        kakuninShinseiDate: null,
        zandoShori: CHECKBOX_STATES.Empty,
        zandoShoriUmu: 0,
        zandoShoriNote: "",
        senkoGaikoKoji: CHECKBOX_STATES.Empty,
        senkoGaikoKojiUmu: 0,
        pouchIchi: CHECKBOX_STATES.Empty,
        meterIchi: CHECKBOX_STATES.Empty,
        meterSuido: CHECKBOX_STATES.Empty,
        meterSuidoKoukei: 0,
        meterDenki: CHECKBOX_STATES.Empty,
        meterGas: CHECKBOX_STATES.Empty,
        kyuhaisuiRoute: CHECKBOX_STATES.Empty,
        kyuhaisuiUsuiMas: CHECKBOX_STATES.Empty,
        kyuhaisuiOsuiMas: CHECKBOX_STATES.Empty,
        kyuhaisuiSaishuMas: CHECKBOX_STATES.Empty,
        kyuhaisuiJokasou: CHECKBOX_STATES.Empty,
        kyuhaisuiRissuisen: CHECKBOX_STATES.Empty,
        kyuhaisuiSansuisen: CHECKBOX_STATES.Empty,
        acShitugaiki: CHECKBOX_STATES.Empty,
        ecoCuteEneFarm: CHECKBOX_STATES.Empty,
    },
    kouki: {
        chakkoDate: null,
        jyotoYoteiDate: null,
        shunkoYoteiDate: null,
        hikiwatasiYoteiDate: null,
        koteihyoKakunin: CHECKBOX_STATES.Empty,
    },
    ryuui: {
        ryuui1: CHECKBOX_STATES.Empty,
        ryuui2: CHECKBOX_STATES.Empty,
        ryuui3: CHECKBOX_STATES.Empty,

    },
    genbaKensa: {
        ninteiTeitanso: CHECKBOX_STATES.Empty,
        sumaiKyufu: CHECKBOX_STATES.Empty,
        jyutakuSeinoHyoji: CHECKBOX_STATES.Empty,
        flat35: CHECKBOX_STATES.Empty,
        chokiYuryoNintei: CHECKBOX_STATES.Empty,
        chukanKensa: CHECKBOX_STATES.Empty,
        kanryoKensa: CHECKBOX_STATES.Empty,
        sonotaKensa: CHECKBOX_STATES.Empty,
        sonotaKensaNote: "",
    },
    reportKojiName: "",
    reportKojiNameHonorific: "",
    kiroku: 0,
};

//入力値用context
export const UserInputData = createContext(ChakkoInitialInputData);

export default function ChakkoForm() {
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps;
    const { kojiCD, formData, being, setBeing, cutTeimei, setCutTeimei, changeTeimei, setChangeTeimei } = useContext(ConfirmationContext);
    const [currentState, setCurrentState] = useState(being ? formData : ChakkoInitialInputData);
    const value = { currentState, setCurrentState };
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleSave = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <Kakunin handleNext={handleNext} />;
            case 1:
                return <Kakunin2 handleNext={handleNext} handleBack={handleBack} />;
            case 2:
                return <KoukiForm handleNext={handleNext} handleBack={handleBack} />;
            case 3:
                return <RyuuiForm handleNext={handleNext} handleBack={handleBack} />;
            case 4:
                return <GenbaForm handleNext={handleNext} handleBack={handleBack} handleSave={handleSave} />;
            case 5:
                return <Review handleBack={handleBack} />;
            default:
                throw new Error('Unknown step');
        }
    }
    //邸名編集機能
    const validationRules = {
        reportKojiNameHonorific: { maxLength: { value: 3, message: '3桁以内で入力してください。' }, },
    }
    const { control, watch, handleSubmit } = useForm({
        defaultValues: { ...currentState },
    });

    React.useEffect(() => {
        setBeing(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (!watch("reportKojiNameHonorific")) {
            setCutTeimei(cutTeimei)
            setChangeTeimei(changeTeimei)
        } else {
            setCutTeimei(cutTeimei)
            !watch("reportKojiNameHonorific") ?
                setChangeTeimei(changeTeimei)
                : setChangeTeimei(watch("reportKojiNameHonorific"))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch("reportKojiNameHonorific")]);

    return (
        <React.Fragment>
            <CssBaseline />
            <Title sx={{ pb: 2, pt: 4, }}>
                <Grid container columns={12} spacing={3} >
                    <Grid container columns={12} style={{ display: 'flex', alignItems: 'end' }}>
                        <Grid item xs={2.5} >
                            <Typography sx={{ fontSize: 20, }} >
                                着工（地縄）立会確認書
                            </Typography>
                            <Typography sx={{ fontSize: 15, }} >
                                工事コード:{kojiCD}
                            </Typography>
                        </Grid>
                        <Grid item xs={7} sx={{ pl: 1 }}>
                            <Card sx={{ boxShadow: 0 }} >
                                <ConfirmationName style={{ display: 'flex', paddingBottom: 0, justifyContent: 'center', alignItems: 'end' }}>
                                    <Typography
                                        sx={{
                                            fontSize: 25,
                                            pb: 0
                                        }} >
                                        {currentState.reportKojiName}
                                    </Typography>
                                </ConfirmationName>
                            </Card>

                        </Grid>
                        {activeStep !== 5 ?
                            <form onSubmit={handleSubmit()} >
                                <Grid item xs={3} sx={{ transform: "scale(1.5)", pb: 1 }}>
                                    <Controller
                                        control={control}
                                        name="reportKojiNameHonorific"
                                        rules={validationRules.reportKojiNameHonorific}
                                        render={({ field, fieldState }) => (
                                            <TextField
                                                {...field}
                                                sx={{ pt: 2 }}
                                                variant="standard"
                                                inputProps={{ maxLength: 3, style: { padding: '0px', textAlign: 'bottom' } }}
                                                style={{ width: '100%' }}
                                                error={fieldState.invalid}
                                            />
                                        )}
                                    />
                                </Grid>

                            </form>
                            :
                            <Grid item xs={1} sx={{ transform: "scale(1.5)", pt: 5.6, pb: 1 }}>
                                <Typography
                                    sx={{
                                        pt: 1,
                                        pb: 0
                                    }} >
                                    {currentState.reportKojiNameHonorific}
                                </Typography>
                            </Grid>
                        }
                        {/*<Grid item xs={2.5} >*/}
                        {/*    <UploadButtons />*/}
                        {/*</Grid>*/}
                    </Grid>
                </Grid>
            </Title>
            <Contents component="main" sx={{ mb: 1 }}>
                <Steppers activeStep={activeStep} sx={{ pb: 1 }}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel >{label}</StepLabel>
                        </Step>
                    ))}
                </Steppers>
                <UserInputData.Provider value={value}>
                    {getStepContent(activeStep, handleNext)}
                </UserInputData.Provider>
            </Contents>
        </React.Fragment>
    );
}