import React from 'react';
import { ConstractContents } from '../../css/CSS.js';
import Grid from '@mui/material/Grid';
import DigitalConstractListBody from './DigitalConstractListBody';

export default function DigitalConstractFrame() {
    return (
        <ConstractContents sx={{ pb: 2, pt: 2, }} maxWidth="xl">
            <Grid container columns={12} spacing={0} sx={{ pt: 5 }} maxWidth="xl" >
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <DigitalConstractListBody />
                </Grid>
            </Grid>
        </ConstractContents>
    );
}