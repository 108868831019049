import React, { useContext, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CheckBox from '../CheckBox'
import { useForm, Controller } from "react-hook-form";
import { UserInputData } from './JyotoFrame';
import Toggle, { TOGGLE_STATES } from '../Toggle'
import { Contents, FormTitle } from '../css/CSS.js';
import { useNavigate } from 'react-router-dom';
import useAuthAxios from "../../useAuthAxios";
import { ConfirmationContext } from "../../Confirmation";
import LoadingDialog from '../Loading';

export default function Gaibu(props) {

    //各コンポーネントの動作を設定
    const validationRules = {
        kabeMadoHenkoNaiyou: { maxLength: { value: 25, message: '25桁以内で入力してください。' } },
        gaikoGyosha: { maxLength: { value: 20, message: '20桁以内で入力してください。' } },
        meterIchiNote: { maxLength: { value: 20, message: '20桁以内で入力してください。' } },
        masIchiTakasaNote: { maxLength: { value: 30, message: '30桁以内で入力してください。' } },
        kansenHikikomiNote: { maxLength: { value: 30, message: '30桁以内で入力してください。' } },
    }
    const { currentState, setCurrentState } = useContext(UserInputData);
    const { control, handleSubmit, getValues, formState: { errors }, watch } = useForm({
        defaultValues: { ...currentState.gaibu, ...currentState },
        mode: 'onChange'
    });

    const [formNext, setFormNext] = useState(false)
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (formNext === true) {
                props.handleNext();
            }
        }
        setFormNext(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formNext]);

    const { kojiCD, cutTeimei, changeTeimei } = useContext(ConfirmationContext);
    const authAxios = useAuthAxios();

    const navigate = useNavigate();

    const [formPost, setFormPost] = useState(false)
    const [processing_post, setProcessing_post] = useState(false);
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (formPost === true) {
                setProcessing_post(true);
                authAxios
                    .post('/api/confirmation/jyoto/' + kojiCD + "?order=update", currentState)
                    .then(response => {
                        if (response.status === 200) {
                            navigate("/confirmation/list");
                            setProcessing_post(false);
                        }
                    }).catch((response) => {
                        alert("保存に失敗しました。", response.body);
                        setProcessing_post(false);
                    })
            }
        }
        setFormPost(false);
        setTimeout(() => {
            setProcessing_post(false);
        }, 10000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formPost]);

    const onSubmit = (action) => {

        const gaibu = getValues();
        const kiroku = getValues("kiroku");
        if (action === "post") {
            setFormPost(true);
            setCurrentState({ ...currentState, gaibu, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei, kiroku });
        } else if (action === "next") {
            setCurrentState({ ...currentState, gaibu, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei, kiroku });
            setFormNext(true)
        }
    };

    return (
        <Contents component="main" sx={{ pt: 1 }}>
            <form onSubmit={handleSubmit(onSubmit)} >
                <Grid container columns={12} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 2 }}>
                    <Grid item xs={4} >
                        <Typography sx={{ pl: 6.2, pt: 0.5, transform: "scale(1.3)" }} >別紙　打合せ記録</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Controller
                            control={control}
                            name="kiroku"
                            render={({ field: { value, onChange } }) => (
                                <Toggle
                                    setCheckedToggle={onChange}
                                    checkedToggle={value}
                                    toggleValue={"有"}
                                    toggleSts={TOGGLE_STATES.Two}
                                    toggleValue2={"無"}
                                    toggleSts2={TOGGLE_STATES.One}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container columns={12} columnSpacing={{ xs: 1, sm: 3, md: 3 }}>
                    <Grid>
                        <FormTitle>
                            外部確認
                        </FormTitle>
                    </Grid>
                </Grid>
                <Grid container columns={12} >
                    <Grid item xs={4} >
                        <Controller
                            control={control}
                            name="yaneHafuIro"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1.5, pl: 0.5 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }} style={watch("yaneHafuIro") === 2 ? { textDecoration: "line-through" } : {}} >屋根・破風色</Typography>}
                                    labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container columns={16} sx={{ pt: 2 }} >
                    <Grid item xs={16} >
                        <Controller
                            control={control}
                            name="mizukiriIro"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)" }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }} style={watch("mizukiriIro") === 2 ? { textDecoration: "line-through" } : {}}>水切り色</Typography>}
                                    labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={16} alignContent="flex-start">
                        <Grid container columns={14} columnSpacing={{ xs: 1, sm: 2, md: 1 }} sx={{ transform: "scale(1.1)", pt: 1, pl: 8.5 }}>
                            <Grid item xs={2} >
                                <Controller
                                    control={control}
                                    name="mizukiriDodai"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("mizukiriIro") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("mizukiriIro") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("mizukiriDodai") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >土台</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2} >
                                <Controller
                                    control={control}
                                    name="mizukiriMadoue"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("mizukiriIro") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("mizukiriIro") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("mizukiriMadoue") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >窓上</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2} >
                                <Controller
                                    control={control}
                                    name="mizukiriMadosita"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("mizukiriIro") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("mizukiriIro") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("mizukiriMadosita") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >窓下</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={1.5}  >
                                <Controller
                                    control={control}
                                    name="mizukiriChukan"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("mizukiriIro") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("mizukiriIro") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("mizukiriChukan") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >中間</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2} >
                                <Controller
                                    control={control}
                                    name="mizkiriGenkanDoorSita"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("mizukiriIro") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("mizukiriIro") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("mizkiriGenkanDoorSita") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >玄関ドア下</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container columns={16} sx={{ pt: 2 }} >
                    <Grid item xs={16} >
                        <Controller
                            control={control}
                            name="kabeMadoIro"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pl: 0.8 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("kabeMadoIro") === 2 ? { textDecoration: "line-through" } : {}
                                        }> 外壁・窓枠の色</Typography>}
                                    labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={16} alignContent="flex-start">
                        <Grid container columns={14} sx={{ transform: "scale(1.1)", pt: 1, pl: 8.5 }}>
                            <Grid item xs={2.03} >
                                <Controller
                                    control={control}
                                    name="kabeMadoKeshowaku"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("kabeMadoIro") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("kabeMadoIro") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("kabeMadoKeshowaku") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >化粧枠</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2.03} >
                                <Controller
                                    control={control}
                                    name="kabeMadoPouchBasira"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("kabeMadoIro") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("kabeMadoIro") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("kabeMadoPouchBasira") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >ポーチ柱</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2.01} >
                                <Controller
                                    control={control}
                                    name="kabeMadoMadokibu"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("kabeMadoIro") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("kabeMadoIro") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("kabeMadoMadokibu") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >窓木部</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}  >
                                <Controller
                                    control={control}
                                    name="kabeMadoMokuseiBalcony"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("kabeMadoIro") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("kabeMadoIro") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("kabeMadoMokuseiBalcony") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >木製バルコニー</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2} >
                                <Controller
                                    control={control}
                                    name="kabeMadoTukebasira"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("kabeMadoIro") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("kabeMadoIro") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("kabeMadoTukebasira") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >付柱</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container columns={12}>
                    <Grid item xs={6} >
                        <Controller
                            control={control}
                            name="kabeMadoHenko"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ pt: 1.5 }}
                                    control={
                                        <Toggle
                                            setCheckedToggle={onChange}
                                            checkedToggle={value}
                                            toggleValue={"無"}
                                            toggleSts={TOGGLE_STATES.One}
                                            toggleValue2={"有"}
                                            toggleSts2={TOGGLE_STATES.Two}
                                            disabled={watch("kabeMadoIro") === 2 ? true : false}
                                        />
                                    }
                                    label={<Typography sx={{ transform: "scale(1.1)", pr: 5.7, pl: 0.3 }}
                                        style={watch("kabeMadoIro") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                            : {}
                                        }
                                    >塗装色変更確認</Typography>}
                                    labelPlacement="start"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={1} sx={{ pt: 2.2 }}>
                        <Typography
                            sx={{ transform: "scale(1.1)", }}
                            style={watch("kabeMadoIro") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                :
                                watch("kabeMadoHenko") === TOGGLE_STATES.One ? { textDecoration: "line-through", color: "#BBBBBB" }
                                    : {}
                            }
                        >変更内容</Typography>
                    </Grid>
                    <Grid item xs={5} sx={{ transform: "scale(1.1)", pt: 1.4, pl: 1.8 }}>
                        <Controller
                            control={control}
                            name="kabeMadoHenkoNaiyou"
                            rules={validationRules.kabeMadoHenkoNaiyou}
                            render={({ field, fieldState }) => (
                                < TextField
                                    {...field}
                                    style={{ width: '96%' }}
                                    variant="standard"
                                    error={fieldState.invalid}
                                    inputProps={{ maxLength: 25 }}
                                    disabled={watch("kabeMadoIro") === 2 ? true : watch("kabeMadoHenko") === TOGGLE_STATES.One ? true : false}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container columns={12} sx={{ pt: 2 }}>
                    <Grid item xs={1.92} >
                        <Controller
                            control={control}
                            name="gaikoKoji"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1.5, }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("gaikoKoji") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >外構工事</Typography>} labelPlacement="end"

                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={4.2} sx={{ pt: 1 }}>
                        <Controller
                            control={control}
                            name="gaikoKojiUmu"
                            render={({ field: { value, onChange } }) => (
                                <Toggle
                                    setCheckedToggle={onChange}
                                    checkedToggle={value}
                                    toggleValue={"無"}
                                    toggleSts={TOGGLE_STATES.One}
                                    toggleValue2={"有"}
                                    toggleSts2={TOGGLE_STATES.Two}
                                    toggleValue3={"未定"}
                                    toggleSts3={TOGGLE_STATES.Three}
                                    disabled={watch("gaikoKoji") === 2 ? true : false}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={0.8} sx={{ transform: "scale(1.1)", pt: 1.5 }}>
                        <Typography
                            style={watch("gaikoKoji") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                :
                                watch("gaikoKojiUmu") === TOGGLE_STATES.Two ? {}
                                    : { textDecoration: "line-through", color: "#BBBBBB" }
                            }
                        >業者名</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ transform: "scale(1.1)", pt: 1.0, pl: 2 }}>
                        <Controller
                            control={control}
                            name="gaikoGyosha"
                            rules={validationRules.gaikoGyosha}
                            render={({ field, fieldState }) => (
                                < TextField
                                    {...field}
                                    style={{ width: '96%' }}
                                    variant="standard"
                                    error={fieldState.invalid}
                                    inputProps={{ maxLength: 20 }}
                                    disabled={watch("gaikoKoji") === 2 ? true : watch("gaikoKojiUmu") === TOGGLE_STATES.Two ? false : true}
                                />
                            )}
                        />
                    </Grid>
                </Grid>

                <Grid container columns={16} sx={{ pt: 3 }} >
                    <Grid item xs={16} sx={{ pl: 1.9 }}>
                        <Controller
                            control={control}
                            name="gaikoSetubiToriai"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)" }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }} style={watch("gaikoSetubiToriai") === 2 ? { textDecoration: "line-through" } : {}}>外構工事と設備取合い</Typography>}
                                    labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={16} alignContent="flex-start">
                        <Grid container columns={14} columnSpacing={{ xs: 1, sm: 2, md: 1 }} sx={{ transform: "scale(1.1)", pt: 1, pl: 8.5 }}>
                            <Grid item xs={2} >
                                <Controller
                                    control={control}
                                    name="toriaiSansuisen"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("gaikoSetubiToriai") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("gaikoSetubiToriai") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("toriaiSansuisen") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >散水栓</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2} >
                                <Controller
                                    control={control}
                                    name="toriaiRissuisen"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("gaikoSetubiToriai") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("gaikoSetubiToriai") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("toriaiRissuisen") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >立水栓</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2} >
                                <Controller
                                    control={control}
                                    name="toriaiHaisuiMas"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("gaikoSetubiToriai") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("gaikoSetubiToriai") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("toriaiHaisuiMas") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >排水マス</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}  >
                                <Controller
                                    control={control}
                                    name="toriaiAirconSitugaiki"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("gaikoSetubiToriai") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("gaikoSetubiToriai") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("toriaiAirconSitugaiki") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >エアコン室外機</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2} >
                                <Controller
                                    control={control}
                                    name="toriaiJB"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("gaikoSetubiToriai") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("gaikoSetubiToriai") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("toriaiJB") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >JB</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3} >
                                <Controller
                                    control={control}
                                    name="toriaiBosuiConcent"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("gaikoSetubiToriai") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("gaikoSetubiToriai") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("toriaiBosuiConcent") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >防水コンセント</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container columns={16} sx={{ pt: 2 }} >
                    <Grid item xs={16} sx={{ pl: 0.7 }}>
                        <Controller
                            control={control}
                            name="meterIchi"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)" }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }} style={watch("meterIchi") === 2 ? { textDecoration: "line-through" } : {}}>メーター位置</Typography>}
                                    labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={16} alignContent="flex-start">
                        <Grid container columns={14} sx={{ transform: "scale(1.1)", pl: 8.4 }}>
                            <Grid item xs={2} sx={{ pt: 1 }}>
                                <Controller
                                    control={control}
                                    name="meterIchiDenki"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("meterIchi") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("meterIchi") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("meterIchiDenki") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >電気</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2.05} sx={{ pt: 1 }}>
                                <Controller
                                    control={control}
                                    name="meterIchiSuido"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("meterIchi") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("meterIchi") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("meterIchiSuido") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >水道</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={1.9} sx={{ pt: 1 }}>
                                <Controller
                                    control={control}
                                    name="meterIchiGas"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("meterIchi") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("meterIchi") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("meterIchiGas") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >ガス</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={0.57} sx={{ pt: 1 }}>
                                <Typography
                                    style={watch("meterIchi") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                        : {}}
                                > メモ</Typography>
                            </Grid>
                            <Grid item xs={5} sx={{ pt: 0.6 }}>
                                <Controller
                                    control={control}
                                    name="meterIchiNote"
                                    rules={validationRules.meterIchiNote}
                                    render={({ field, fieldState }) => (
                                        < TextField
                                            {...field}
                                            style={{ width: '92%' }}
                                            variant="standard"
                                            error={fieldState.invalid}
                                            inputProps={{ maxLength: 20 }}
                                            disabled={watch("meterIchi") === 2 ? true : false}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container columns={16} sx={{}} >
                        <Grid item xs={3} sx={{ pl: 1.1, pt: 1 }}>
                            <Controller
                                control={control}
                                name="masIchiTakasa"
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        sx={{ transform: "scale(1.3)" }}
                                        control={
                                            <CheckBox
                                                setCheckedvalue={onChange}
                                                checkedvalue={value}
                                                color='primary'
                                            />
                                        }
                                        label={<Typography sx={{ pl: 1 }} style={watch("masIchiTakasa") === 2 ? { textDecoration: "line-through" } : {}}>桝の位置・高さ</Typography>}
                                        labelPlacement="end"
                                    />
                                )}
                            />

                        </Grid>
                        <Grid item xs={7.5} sx={{ pt: 0.5, transform: "scale(1.1)", }}>
                            <Controller
                                control={control}
                                name="masIchiTakasaNote"
                                rules={validationRules.masIchiTakasaNote}
                                render={({ field, fieldState }) => (
                                    < TextField
                                        {...field}
                                        style={{ width: '98%' }}
                                        variant="standard"
                                        error={fieldState.invalid}
                                        inputProps={{ maxLength: 30 }}
                                        disabled={watch("masIchiTakasa") === 2 ? true : false}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container columns={16} sx={{ pt: 1.5 }} >
                        <Grid item xs={3.7} sx={{ pl: 1.8 }}>
                            <Controller
                                control={control}
                                name="kansenHikikomi"
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        sx={{ transform: "scale(1.3)" }}
                                        control={
                                            <CheckBox
                                                setCheckedvalue={onChange}
                                                checkedvalue={value}
                                                color='primary'
                                            />
                                        }
                                        label={<Typography sx={{ pl: 1 }} style={watch("kansenHikikomi") === 2 ? { textDecoration: "line-through" } : {}}>幹線引込位置・高さ</Typography>}
                                        labelPlacement="end"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={7.5} sx={{ transform: "scale(1.1)" }}>
                            <Controller
                                control={control}
                                name="kansenHikikomiNote"
                                rules={validationRules.kansenHikikomiNote}
                                render={({ field, fieldState }) => (
                                    < TextField
                                        {...field}
                                        style={{ width: '98%' }}
                                        variant="standard"
                                        error={fieldState.invalid}
                                        inputProps={{ maxLength: 30 }}
                                        disabled={watch("kansenHikikomi") === 2 ? true : false}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container columns={12} rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 2 }}>
                    <Grid container columns={16} rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 5 }}>
                        <Grid item xs={14} >
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("post")} disabled={processing_post}>
                                {processing_post ? "処理中" : "保存して一覧へ"}
                            </Button>
                            <LoadingDialog load={processing_post} />

                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("next")}>
                                次へ
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form >
        </Contents >
    );
}