import React, { useState, useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { ConstractContents } from '../../css/CSS.js';
import Typography from '@mui/material/Typography';
import useAuthAxios from "../../../useAuthAxios";
import LoadingDialog from '../../Loading';
import DigitalConstractFileUpload from './DigitalConstractFileUpload';
import DesignUploadButtons from './DigitalConstractDesignFileUploads';
import ImportantsUploadButtons from './DigitalConstractFileimpotantsUploads';
import { ArrowUpwardButton, ArrowDownwardButton, UpdateContent, PointUpBackGround, EditSquareButton, CancelSquareButton } from '../../css/CSS.js';
import { useNavigate } from 'react-router-dom';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import DigitalConstractDocumentDelConfirm from './DigitalConstractDocumentDelConfirm';
import { DigitalConstractsContext } from "../../../DigitalConstracts";
import ClearIcon from '@mui/icons-material/Clear';


const TableFlame = styled(TableContainer)({
    borderRadius: "10px 10px 0 0",
    maxHeight: 250,
});
const TableHeader = styled(TableCell)({
    backgroundColor: "#1976d2",
    padding: "5px",

});
const Header = styled(TableRow)({
    backgroundColor: "#289bdf",
});
const Text = styled(Typography)({
    color: "#FFFFFF",
    transform: "scale(0.9)",
});

const CLTableCell = styled(TableCell)({
    padding: "3px",
});

const headCells = [
    {
        id: 'seq',
        label: '並び順',
        align: 'right',
    },
    {
        id: 'fileName',
        label: '書類名',
        align: 'left'
    }
];

function EnhancedTableHead(props) {

    return (
        <TableHead>
            <Header>
                {headCells.map((headCell) => (
                    <TableHeader
                        key={headCell.id}
                        align={headCell.align}
                    >
                        <Text >{headCell.label}</Text>
                    </TableHeader>
                ))}
            </Header>
        </TableHead >
    );
}

function reorderArray(array, from, to) {
    const copyArray = array.slice();
    const element = copyArray[from];
    copyArray.splice(from, 1);
    copyArray.splice(to, 0, element);
    return copyArray;
}

export default function DigitalConstractUploadList(props) {
    const headers = { "Authorization": "Bearer " + localStorage.getItem("Auth") };
    const authAxios = useAuthAxios();
    const [list, setList] = useState()
    const [processing, setProcessing] = useState(false);
    const navigate = useNavigate();
    const [baseFileID, setBaseFileID] = useState()
    const { kojiCD, seqNo, contractType, status, statusCheck, setChangePDF } = useContext(DigitalConstractsContext);
    const [fileList, setFileList] = useState()
    const [combineFileList, setCombineFileList] = React.useState();
    const [changeSortMode, setChangeSortMode] = React.useState(false);
    const [changeListPost, setChangeListPost] = React.useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteDocID, setDeleteDocID] = React.useState("");
    const [importantsFileName, setImportantsFileName] = React.useState();
    const [designFileName, setDesignFileName] = React.useState();

    //請負契約書 添付書類一覧 取得
    React.useLayoutEffect(() => {
        if (processing === true) { return }
        if (statusCheck === true) {
            setChangeSortMode(false)
            setProcessing(true)
            authAxios
                .get('api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + '/files'
                    , { headers })
                .then(response => {
                    if (response.status === 200) {
                        const listdata = JSON.parse((JSON.stringify(response.data)))
                        setFileList(listdata)
                        if (listdata.fileList !== null) {
                            setBaseFileID(listdata.fileList[0].fileID)
                            authAxios
                                .get('api/contracts/'
                                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                                    + "/" + kojiCD
                                    + "/" + seqNo
                                    + "/" + listdata.fileList[0].fileID
                                    + '/combines'
                                    , { headers })
                                .then(response => {
                                    if (response.status === 200) {
                                        const listdata = JSON.parse((JSON.stringify(response.data)))
                                        setList(listdata.combineFileList)
                                        setCombineFileList(listdata.combineFileList)
                                    }
                                    setProcessing(false)
                                }).catch((error) => {
                                    if (error.response.status === 401) {
                                        alert("認証情報が正しくありません。");
                                        navigate("/");
                                    } else {
                                        alert("契約書添付一覧情報の取得に失敗しました。");
                                    }
                                    setProcessing(false)
                                });
                        } else if (response.status === 401) {
                            navigate("/");
                        }
                    }
                    setProcessing(false)

                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        alert("契約書構成情報の取得に失敗しました。");
                    }
                    setProcessing(false)
                });
        } else {
            setList(null)
            setCombineFileList(null)
            setFileList(null)
            setImportantsFileName(null)
            setDesignFileName(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusCheck]);

    //順番変更
    const onSubmit_ChangeOrderUP = (index) => {
        if (index !== 0) { setCombineFileList(reorderArray(combineFileList, index, index - 1)) }
    };
    const onSubmit_ChangeOrderDOWN = (index) => {
        setCombineFileList(reorderArray(combineFileList, index, index + 1))
    };

    //並び替えモード変更
    const onSubmit_ChangeSortMode = () => {
        setChangeSortMode(true)
    };

    //並び替えモードキャンセル
    const onSubmit_CancelSortMode = () => {
        setChangeSortMode(false)
        setCombineFileList(list)
    };

    //確定モード変更
    const onSubmit_ChangePostMode = () => {
        setChangeSortMode(false)
        JSON.stringify(combineFileList) === JSON.stringify(list) ?
            setChangeListPost(false) : setChangeListPost(true)
    };

    React.useEffect(() => {
        if (processing === true) { return }
        if (changeListPost === true) {
            setProcessing(true)
            authAxios
                .put('api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + "/" + baseFileID
                    + '/combines'
                    , { combineFileList }
                )
                .then(response => {
                    if (response.status === 200) {
                        authAxios
                            .get('api/contracts/'
                                + (contractType === "工事請負契約書" ? "base" : "modfy")
                                + "/" + kojiCD
                                + "/" + seqNo
                                + "/" + baseFileID
                                + '/combines'
                                , { headers })
                            .then(response => {
                                if (response.status === 200) {
                                    const listdata = JSON.parse((JSON.stringify(response.data)))
                                    setList(listdata.combineFileList)
                                    setCombineFileList(listdata.combineFileList)
                                    setProcessing(false)
                                } else if (response.status === 401) {
                                    navigate("/");
                                    setProcessing(false)
                                }
                            }).catch((response) => {
                                alert("契約書構成一覧情報の取得に失敗しました。", response.body);
                                setProcessing(false)
                                navigate("/");
                            });
                        setChangePDF(true)
                    }
                    else if (response.status === 401) {
                        setProcessing(false)
                        navigate("/");
                    }
                    setChangeListPost(false)
                }).catch((response) => {
                    alert("資料の順番変更に失敗しました。", response.body);
                    setChangeListPost(false)
                    setProcessing(false)
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeListPost]);

    const onSubmit_Delete = (docID) => {
        setOpenDelete(true)
        setDeleteDocID(docID)
    };

    React.useEffect(() => {
        fileList?.fileList
            ?.map((row) => {
                if (row.docFileTypeCD === 3) { setImportantsFileName(row.fileName); }
                else if (row.docFileTypeCD === 4) { setDesignFileName(row.fileName); }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileList]);

    return (
        <ConstractContents style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Grid container sx={{ pl: 0 }}>
                <LoadingDialog load={processing} />
                <DigitalConstractDocumentDelConfirm
                    open={openDelete}
                    close={setOpenDelete}
                    docID={deleteDocID}
                />
                <Grid item xs={5}>
                    <Typography sx={{ fontSize: 17, mt: 2 }}>
                        契約書構成
                    </Typography>
                </Grid>
                {changeSortMode === true ?
                    <Grid item xs={7} style={{ display: 'flex', justifyContent: 'end' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={5.5} style={{ display: 'flex', justifyContent: 'end' }}>
                                <CancelSquareButton onClick={() => onSubmit_CancelSortMode()}>
                                    <ClearIcon sx={{ mr: 1, }} />キャンセル
                                </CancelSquareButton>
                            </Grid>
                            <Grid item xs={6.5} style={{ display: 'flex', justifyContent: 'start' }}>
                                <EditSquareButton style={{ width: "115px" }} onClick={() => onSubmit_ChangePostMode()}>
                                    <CheckIcon sx={{ mr: 1, }} />確定
                                </EditSquareButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    : status === "社内作成中" ?
                        <Grid item xs={5} style={{ display: 'flex', justifyContent: 'end' }}>
                            <EditSquareButton
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                onClick={() => onSubmit_ChangeSortMode()}>
                                <SwapVertIcon sx={{ mr: 1 }} />並び替え
                            </EditSquareButton>
                        </Grid> : null
                }
                {status === "社内作成中" && changeSortMode === false ?
                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'end' }}>
                        <DigitalConstractFileUpload
                            baseFileID={baseFileID}
                        />
                    </Grid> : null
                }
                <Grid item xs={12} >
                    {combineFileList?.length !== 0 ?
                        <UpdateContent sx={{ width: '100%', mt: 1, mb: 1, ml: 3, boxShadow: 0 }}>
                            <TableFlame >
                                <Table
                                    stickyHeader
                                    align="center"
                                >
                                    <EnhancedTableHead
                                        rowCount={combineFileList?.length}
                                    />
                                    <TableBody >
                                        {combineFileList
                                            ?.map((row, index) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                        key={index}
                                                        sx={{ backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#f4ffff" }}
                                                        style={{ height: 10 }}
                                                    >
                                                        <CLTableCell align="right" width="10%">
                                                            <Typography sx={{ fontSize: 17 }} >
                                                                {index + 1}
                                                            </Typography>
                                                        </CLTableCell>

                                                        <CLTableCell align="left" sx={{ pl: 4, pr: 0 }}>
                                                            {changeSortMode === true ?
                                                                <Grid container columns={12} >
                                                                    <Grid item xs={9} sx={{ pt: 1, pb: 1 }}>
                                                                        <Typography sx={{ fontSize: 17 }} >
                                                                            {row?.docName}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={1.5}>
                                                                        <Button><ArrowDownwardButton onClick={() => onSubmit_ChangeOrderDOWN(index)} /></Button>
                                                                    </Grid>
                                                                    <Grid item xs={0.5}>
                                                                        <Button><ArrowUpwardButton onClick={() => onSubmit_ChangeOrderUP(index)} /></Button>
                                                                    </Grid>
                                                                </Grid>
                                                                :
                                                                <Grid container columns={12} >
                                                                    <Grid item xs={9} sx={{ pt: 1, pb: 1 }}>
                                                                        <Typography sx={{ fontSize: 17 }} >
                                                                            {row?.docName}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            }
                                                        </CLTableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableFlame>
                        </UpdateContent> : null
                    }
                </Grid>
            </Grid>
            <Grid container columns={12} rowSpacing={{ xs: 1 }} sx={{ pb: 1.5 }}>
                <Grid item xs={10}>
                    <Typography sx={{ fontSize: 17, mt: 2 }}>
                        重要事項説明書
                    </Typography>
                </Grid>
                <Grid item xs={2} style={{ display: 'flex', justifyContent: 'end' }}>
                    {!importantsFileName ? status === "社内作成中" ?
                        <ImportantsUploadButtons
                            kojiCD={kojiCD}
                            seqNo={seqNo}
                            contractType={contractType}
                        /> : null : null
                    }
                </Grid>
            </Grid>
            {importantsFileName ?
                <PointUpBackGround container columns={12} rowSpacing={{ xs: 1 }} sx={{ pt: 1, pb: 1, ml: 3 }}
                    style={{
                        backgroundColor: "#ffffff", border: "1px solid", borderColor: "#e9e9e9",
                    }}
                >
                    <Grid item xs={10.8} style={{ display: 'flex', alignItems: 'center' }} sx={{ pt: 0 }}>
                        <Typography sx={{ fontSize: 20, ml: 1 }}>
                            {importantsFileName}
                        </Typography>
                    </Grid>
                    <Grid item xs={1.2} style={{ display: 'flex', justifyContent: 'center' }}>
                        {status === "社内作成中" ?
                            <IconButton>
                                <DeleteIcon sx={{ fontSize: 28 }} style={{ color: "#C0C0C0" }} onClick={() => onSubmit_Delete("3")} />
                            </IconButton> : null
                        }
                    </Grid >
                </PointUpBackGround> : null
            }
            <Grid container columns={12} sx={{ pt: 1, pb: 1 }}>
                <Grid item xs={11} >
                    <Typography sx={{ fontSize: 17, mt: 1.5 }} >
                        設計及び工事監理業務に関する書面
                    </Typography>
                </Grid>
                <Grid item xs={1} style={{ display: 'flex', justifyContent: 'end' }}>
                    {!designFileName ? status === "社内作成中" ?
                        <DesignUploadButtons
                            kojiCD={kojiCD}
                            seqNo={seqNo}
                            contractType={contractType}
                        /> : null : null
                    }
                </Grid>
            </Grid>
            {designFileName ?
                <PointUpBackGround container columns={12} sx={{ pt: 1, pb: 1, ml: 3 }}
                    style={{
                        backgroundColor: "#ffffff", border: "1px solid", borderColor: "#e9e9e9",
                    }}            >
                    <Grid item xs={10.8} style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ fontSize: 20, ml: 1 }}>
                            {designFileName}
                        </Typography>
                    </Grid>
                    <Grid item xs={1.2} style={{ display: 'flex', justifyContent: 'center' }} >
                        {status === "社内作成中" ?
                            <IconButton>
                                <DeleteIcon sx={{ fontSize: 28 }} style={{ color: "#C0C0C0" }} onClick={() => onSubmit_Delete("4")} />
                            </IconButton> : null
                        }
                    </Grid >
                </PointUpBackGround> : null
            }
        </ConstractContents >
    );
}
