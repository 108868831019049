import React, { createContext, useState } from "react";

export const DigitalConstractsContext = createContext({});

const DigitalConstractsProvider = ({ children }) => {
    const [kojiCD, setKojiCD] = useState();
    const [seqNo, setSeqNo] = useState();
    const [constructionName, setContractName] = useState();
    const [contractType, setContractType] = useState();
    const [status, setStatus] = useState();
    const [fromList, setFromList] = useState(false);
    const [customerNo, setCustomerNo] = useState();
    const [selectPDF, setSelectPDF] = useState();
    const [statusCheck, setStatusCheck] = useState();
    const [changePDF, setChangePDF] = useState(false);

    return (
        <DigitalConstractsContext.Provider
            value={{
                kojiCD, setKojiCD, seqNo, setSeqNo, constructionName, setContractName, contractType, setContractType, status, setStatus
                , fromList, setFromList, customerNo, setCustomerNo, selectPDF, setSelectPDF, statusCheck, setStatusCheck
                , changePDF, setChangePDF
            }}>
            {children}
        </DigitalConstractsContext.Provider>
    );
};

export default DigitalConstractsProvider;