import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DigitalConstractsContext } from "../../../DigitalConstracts";
import useAuthAxios from "../../../useAuthAxios";
import { AdvancedStatusButton, ConstructContainer, ReturnStatusButton } from '../../css/CSS.js';
import GetPDFError from '../../GetPDFError';
import LoadingDialog from '../../Loading';
import DigitalConstractCustomerConfirmationAgreement from '../CustomerConfirmation/DigitalConstractCustomerConfirmationAgreement';
import DigitalConstractApprovedInternal from './DigitalConstractApprovedInternal';
import DigitalConstractApprovedInternalRequest from './DigitalConstractApprovedInternalRequest';
import DigitalConstractConfirmationRequest from './DigitalConstractConfirmationRequest';
import DigitalConstractDropRequest from './DigitalConstractDropRequest';
import PropTypes from 'prop-types';

import PersonIcon from '@mui/icons-material/Person';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import UndoIcon from '@mui/icons-material/Undo';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

export default function DigitalConstractPDFFrame(props) {
    const authAxios = useAuthAxios();
    const headers = { "Authorization": "Bearer " + localStorage.getItem("Auth") };
    const { kojiCD, seqNo, contractType, status, statusCheck, changePDF, setChangePDF, fromList
    } = useContext(DigitalConstractsContext);
    const [fileList, setFileList] = useState()
    const [getPDFData, setGetPDFData] = React.useState();

    const [getImportantPDFData, setGetImportantPDFData] = React.useState();
    const [getDesignPDFData, setGetDesignPDFData] = React.useState();
    const [getCheckListPDFData, setGetCheckListPDFData] = React.useState();
    const [processing, setProcessing] = useState(false);
    const [processingPDF, setProcessingPDF] = useState(false);
    const [docFileTypeCD3Check, setDocFileTypeCD3Check] = useState(false);
    const [docFileTypeCD4Check, setDocFileTypeCD4Check] = useState(false);
    const [docFileTypeCD5Check, setDocFileTypeCD5Check] = useState(false);
    const [docFileCertificatCheck, setDocFileCertificatCheck] = useState(false);
    const [getCertificatPDFData, setGetCertificatPDFData] = React.useState();
    const [cloudSignURLCheck, setCloudSignURLCheck] = useState(false);
    const [PDFTitle, sePDFTitle] = React.useState();
    const [PDFTitle3, sePDFTitle3] = React.useState();
    const [PDFTitle4, sePDFTitle4] = React.useState();
    const [PDFTitle5, sePDFTitle5] = React.useState();
    const [getNewPDFID, setGetNewPDFID] = React.useState();

    const navigate = useNavigate();

    const handleOpenCheckList = () => {
        navigate("/digitalconstract/customerconfirm", {
            state: {
                kojiCD: props.kojiCD, seqNo: props.seqNo, contractType: props.contractType,
                constructionName: props.constructionName, status: props.status
            }
        });
    }

    const [openDropRequest, setOpenDropRequest] = React.useState(false);
    const handleOpenDropRequest = () => { setOpenDropRequest(true); }
    const [openApprovedInternal, setOpenApprovedInternal] = React.useState(false);
    const handleOpenApprovedInternal = () => { setOpenApprovedInternal(true); }
    const [openApprovedInternalRequest, setOpenApprovedInternalRequest] = React.useState(false);
    const handleOpenApprovedInternalRequest = () => { setOpenApprovedInternalRequest(true); }
    const [openCustomerConfirmationAgreement, setOpenCustomerConfirmationAgreement] = React.useState(false);
    const handleOpenCustomerConfirmationAgreement = () => { setOpenCustomerConfirmationAgreement(true); }
    const [openConstractConfirmationRequest, setOpenConstractConfirmationRequest] = React.useState(false);
    const handleOpenConstractConfirmationRequest = () => { setOpenConstractConfirmationRequest(true); }


    React.useEffect(() => {
        if (changePDF === true) {
            authAxios
                .get('api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + "/file"
                    + "/" + getNewPDFID
                    + "/" + PDFTitle
                )
                .then(response => {
                    if (response.status === 200) {
                        setGetPDFData(process.env.REACT_APP_API_URL
                            + 'api/contracts/'
                            + (contractType === "工事請負契約書" ? "base" : "modfy")
                            + "/" + kojiCD
                            + "/" + seqNo
                            + "/file"
                            + "/" + getNewPDFID
                            + "/" + PDFTitle)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        setProcessingPDF(true)
                        alert("契約書PDFの取得に失敗しました。");
                    }
                });
            setChangePDF(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changePDF]);

    React.useLayoutEffect(() => {
        if (processing === true) { return }
        if (statusCheck === true) {
            setProcessing(true)
            authAxios
                .get('api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + '/files'
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        const listdata = JSON.parse((JSON.stringify(response.data)))
                        setFileList(listdata)
                    }
                    setProcessing(false)
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        alert("書類情報の取得に失敗しました。");
                    }
                });
        } else {
            setFileList(null)
            setGetPDFData(null)
            setGetImportantPDFData(null)
            setGetDesignPDFData(null)
            setGetCheckListPDFData(null)
            setDocFileTypeCD3Check(false)
            setDocFileTypeCD4Check(false)
            setDocFileTypeCD5Check(false)
            setGetNewPDFID(null)
        }
        setValue(0)
        setProcessingPDF(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusCheck]);

    React.useLayoutEffect(() => {
        fileList?.fileList
            ?.forEach((row) => {
                if (row.docFileTypeCD === 3) {
                    sePDFTitle3(row.fileName)
                    authAxios
                        .get('api/contracts/'
                            + (contractType === "工事請負契約書" ? "base" : "modfy")
                            + "/" + kojiCD
                            + "/" + seqNo
                            + "/file"
                            + "/" + row.docFileTypeCD
                            + "/" + row.fileName
                        )
                        .then(response => {
                            if (response.status === 200) {
                                setGetImportantPDFData(process.env.REACT_APP_API_URL
                                    + "api/contracts/"
                                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                                    + "/" + kojiCD
                                    + "/" + seqNo
                                    + "/file"
                                    + "/" + row.docFileTypeCD
                                    + "/" + row.fileName
                                )
                            }
                            setDocFileTypeCD3Check(true);
                        }).catch((error) => {
                            if (error.response.status === 401) {
                                alert("認証情報が正しくありません。");
                                navigate("/");
                            } else {
                                setProcessingPDF(true)
                                alert("重要事項説明書PDFの取得に失敗しました。");
                            }
                        });
                }
                else if (row.docFileTypeCD === 4) {
                    sePDFTitle4(row.fileName)
                    authAxios
                        .get('api/contracts/'
                            + (contractType === "工事請負契約書" ? "base" : "modfy")
                            + "/" + kojiCD
                            + "/" + seqNo
                            + "/file"
                            + "/" + row.docFileTypeCD
                            + "/" + row.fileName
                        )
                        .then(response => {
                            if (response.status === 200) {
                                setGetDesignPDFData(process.env.REACT_APP_API_URL
                                    + "api/contracts/"
                                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                                    + "/" + kojiCD
                                    + "/" + seqNo
                                    + "/file"
                                    + "/" + row.docFileTypeCD
                                    + "/" + row.fileName
                                )
                            }
                            setDocFileTypeCD4Check(true);
                        }).catch((error) => {
                            if (error.response.status === 401) {
                                alert("認証情報が正しくありません。");
                                navigate("/");
                            } else {
                                setProcessingPDF(true)
                                alert("設計及び工事監理業務に関する書面PDFの取得に失敗しました。");
                            }
                        });

                }
                else if (status !== "社内作成中" && row.docFileTypeCD === 5) {
                    sePDFTitle5(row.fileName)
                    authAxios
                        .get('api/contracts/'
                            + (contractType === "工事請負契約書" ? "base" : "modfy")
                            + "/" + kojiCD
                            + "/" + seqNo
                            + "/file"
                            + "/" + row.docFileTypeCD
                            + "/" + row.fileName
                        )
                        .then(response => {
                            if (response.status === 200) {
                                setGetCheckListPDFData(process.env.REACT_APP_API_URL
                                    + "api/contracts/"
                                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                                    + "/" + kojiCD
                                    + "/" + seqNo
                                    + "/file"
                                    + "/" + row.docFileTypeCD
                                    + "/" + row.fileName
                                )
                            }
                            setDocFileTypeCD5Check(true);
                        }).catch((error) => {
                            if (error.response.status === 401) {
                                alert("認証情報が正しくありません。");
                                navigate("/");
                            } else {
                                setProcessingPDF(true)
                                alert("チェックリストPDFの取得に失敗しました。");
                            }
                        });
                }
                else {
                    sePDFTitle(row.fileName);
                    setGetNewPDFID(row.docFileTypeCD)
                    authAxios
                        .get('api/contracts/'
                            + (contractType === "工事請負契約書" ? "base" : "modfy")
                            + "/" + kojiCD
                            + "/" + seqNo
                            + "/file"
                            + "/" + row.docFileTypeCD
                            + "/" + row.fileName
                        )
                        .then(response => {
                            if (response.status === 200) {
                                setGetPDFData(process.env.REACT_APP_API_URL
                                    + "api/contracts/"
                                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                                    + "/" + kojiCD
                                    + "/" + seqNo
                                    + "/file"
                                    + "/" + row.docFileTypeCD
                                    + "/" + row.fileName
                                )
                                setProcessingPDF(false)
                            }
                        }).catch((error) => {
                            if (error.response.status === 401) {
                                alert("認証情報が正しくありません。");
                                navigate("/");
                            } else {
                                setProcessingPDF(true)
                                alert("契約書PDFの取得に失敗しました。");
                            }
                        });
                }

            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileList]);


    React.useLayoutEffect(() => {
        setCloudSignURLCheck(false)

        if (status === "お客様承認済み(契約締結)") {
            setDocFileCertificatCheck(true)
            setCloudSignURLCheck(true)
        } else if (status === "お客様承認依頼中" || status === "お客様承認依頼エラー" || status === "お客様承認依頼却下") {
            setCloudSignURLCheck(true)
        } else {
            setCloudSignURLCheck(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    React.useEffect(() => {
        if (docFileCertificatCheck === true) {
            authAxios
                .get('api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + "/certificat"
                    + "/合意締結証明書.pdf"
                )
                .then(response => {
                    if (response.status === 200) {
                        setGetCertificatPDFData(process.env.REACT_APP_API_URL
                            + 'api/contracts/'
                            + (contractType === "工事請負契約書" ? "base" : "modfy")
                            + "/" + kojiCD
                            + "/" + seqNo
                            + "/certificat"
                            + "/合意締結証明書.pdf"
                        )
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        setProcessingPDF(true)
                        alert("PDFの取得に失敗しました。");
                    }
                    setDocFileCertificatCheck(false)
                });
            setTimeout(() => {
            }, 10000);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docFileCertificatCheck]);

    const [cloudSignURL, setCloudSignURL] = React.useState();
    React.useEffect(() => {
        if (cloudSignURLCheck === true) {
            console.log("here")

            authAxios
                .get('api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + "/cloudsign/link"
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        setCloudSignURL(response.data.link)
                        setCloudSignURLCheck(false)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        alert("認証情報が正しくありません。");
                        navigate("/");
                    } else {
                        alert("cloudSignURLの取得に失敗しました。");
                    }
                    setCloudSignURLCheck(false)
                });
            setTimeout(() => {
            }, 10000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cloudSignURLCheck]);

    const [openPDF, setOpenPDF] = React.useState(false);
    const [PDFLoading, setPDFLoading] = React.useState("");

    const handleOpenPDF = () => {
        setOpenPDF(true);
        setPDFLoading(true)
    }

    React.useEffect(() => {
        if (openPDF === true) {
            if (value === 1) {
                window.open(process.env.REACT_APP_API_URL
                    + "api/contracts/"
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + "/file"
                    + "/3"
                    + "/" + PDFTitle3
                )
            } else if (value === 2) {
                window.open(process.env.REACT_APP_API_URL
                    + "api/contracts/"
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + "/file"
                    + "/4"
                    + "/" + PDFTitle4
                )
            }
            else if (value === 3) {
                window.open(process.env.REACT_APP_API_URL
                    + "api/contracts/"
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + "/file"
                    + "/5"
                    + "/" + PDFTitle5
                )
            }
            else if (value === 4) {
                window.open(process.env.REACT_APP_API_URL
                    + 'api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + "/certificat"
                    + "/合意締結証明書.pdf"
                )
            } else {
                window.open(process.env.REACT_APP_API_URL
                    + "api/contracts/"
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + "/file"
                    + "/" + (contractType === "工事請負契約書" ? "1" : "2")
                    + "/" + PDFTitle
                )
            }
            setOpenPDF(false)
        }
        setPDFLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openPDF]);

    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <ConstructContainer sx={{ pt: 2 }} style={{ height: "100%" }} maxWidth={false}>
            <LoadingDialog load={processing} />
            <Grid container columns={12} columnSpacing={1} sx={{ pl: 1 }} >
                <Grid item xs={12}>
                    <Grid container columnSpacing={5} >
                        <Grid item xs={9}>
                            {status === "社内作成中" ?
                                <Grid container rowSpacing={{ xs: 2 }} columnSpacing={8} >
                                    <Grid item xs={6}>
                                        <AdvancedStatusButton
                                            className="ApprovedInternalRequestButton"
                                            type="button"
                                            style={{ width: "250px", }}
                                            onClick={handleOpenConstractConfirmationRequest}
                                        >
                                            <PersonIcon sx={{ mr: 1 }} />社内確認依頼
                                        </AdvancedStatusButton>
                                        <DigitalConstractConfirmationRequest
                                            contractType={contractType}
                                            kojiCD={props.kojiCD}
                                            seqNo={props.seqNo}
                                            open={openConstractConfirmationRequest}
                                            close={setOpenConstractConfirmationRequest}
                                        />
                                    </Grid>
                                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
                                    </Grid>
                                </Grid>
                                : null
                            }
                            {status === "社内確認依頼中"
                                && localStorage.getItem("internalConfirm") === "0"
                                && localStorage.getItem("internalApproval") === "0" ?
                                <Grid container rowSpacing={{ xs: 2 }} columnSpacing={8} >
                                    <Grid item xs={12}>
                                        <ReturnStatusButton
                                            className="DropRequestButton"
                                            type="button"
                                            onClick={handleOpenDropRequest}
                                        >
                                            <UndoIcon sx={{ mr: 1 }} />取下げ
                                        </ReturnStatusButton>
                                        <DigitalConstractDropRequest
                                            requestType={status}
                                            open={openDropRequest}
                                            close={setOpenDropRequest}
                                        />
                                    </Grid>
                                </Grid>
                                : null
                            }
                            {status === "社内確認依頼中" && localStorage.getItem("internalConfirm") === "1" ?
                                <Grid container rowSpacing={{ xs: 2 }} columnSpacing={8} >
                                    <Grid item xs={6}>
                                        <AdvancedStatusButton
                                            className="ApprovedInternalRequestButton"
                                            type="button"
                                            style={{ width: "250px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                            onClick={handleOpenApprovedInternalRequest}
                                        >
                                            <PersonIcon sx={{ mr: 1 }} />社内確認及び承認依頼
                                        </AdvancedStatusButton>
                                        <DigitalConstractApprovedInternalRequest
                                            contractType={contractType}
                                            kojiCD={props.kojiCD}
                                            seqNo={props.seqNo}
                                            open={openApprovedInternalRequest}
                                            close={setOpenApprovedInternalRequest}
                                        />
                                    </Grid>
                                </Grid>
                                : null
                            }
                            {status === "社内承認依頼中" && localStorage.getItem("internalConfirm") === "1" && localStorage.getItem("internalApproval") === "0" ?
                                <Grid container rowSpacing={{ xs: 2 }} columnSpacing={8} >
                                    <Grid item xs={12} >
                                        <ReturnStatusButton
                                            className="DropRequestButton"
                                            type="button"
                                            onClick={handleOpenDropRequest}
                                        >
                                            <UndoIcon sx={{ mr: 1 }} />取下げ
                                        </ReturnStatusButton>
                                        <DigitalConstractDropRequest
                                            requestType={status}
                                            open={openDropRequest}
                                            close={setOpenDropRequest}
                                        />
                                    </Grid>
                                </Grid>
                                : null
                            }
                            {status === "社内承認依頼中" && localStorage.getItem("internalApproval") === "1" ?
                                <Grid container rowSpacing={{ xs: 2 }} columnSpacing={8} style={{ display: 'flex', justifyContent: 'start' }}>
                                    <Grid item xs={6}>
                                        <AdvancedStatusButton
                                            className="ApprovedInternalButton"
                                            type="button"
                                            style={{ width: "250px", }}
                                            onClick={handleOpenApprovedInternal}
                                        >
                                            <PersonIcon sx={{ mr: 1 }} />
                                            <Typography sx={{ fontSize: 20 }}>
                                                社内承認
                                            </Typography>
                                        </AdvancedStatusButton>
                                        <DigitalConstractApprovedInternal
                                            contractType={contractType}
                                            kojiCD={props.kojiCD}
                                            seqNo={props.seqNo}
                                            open={openApprovedInternal}
                                            close={setOpenApprovedInternal}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ReturnStatusButton
                                            className="DropRequestButton"
                                            type="button"
                                            onClick={handleOpenDropRequest}
                                        >
                                            <UndoIcon sx={{ mr: 1 }} />取下げ
                                        </ReturnStatusButton>
                                        <DigitalConstractDropRequest
                                            requestType={status}
                                            open={openDropRequest}
                                            close={setOpenDropRequest}
                                        />
                                    </Grid>
                                </Grid>
                                : null
                            }
                            {status === "社内承認済み" || status === "お客様署名中" ?
                                <Grid container>
                                    <Grid item xs={12} >
                                        <AdvancedStatusButton
                                            style={{ width: "250px", }}
                                            className="confButton"
                                            type="button"
                                            onClick={handleOpenCheckList}
                                        >
                                            <PersonIcon sx={{ mr: 1 }} />
                                            <Typography sx={{ fontSize: 20 }}>
                                                お客様確認
                                            </Typography>
                                        </AdvancedStatusButton>
                                    </Grid>
                                </Grid>
                                : null
                            }
                            {status === "お客様署名済み" ?
                                <Grid container rowSpacing={{ xs: 2 }} columnSpacing={8} style={{ display: 'flex', justifyContent: 'start' }}>
                                    <Grid item xs={12}>
                                        <AdvancedStatusButton
                                            className="ConfirmationAgreement"
                                            type="button"
                                            style={{ width: "250px", }}
                                            onClick={handleOpenCustomerConfirmationAgreement}
                                        >
                                            <PersonIcon sx={{ mr: 1 }} />ご本人様確認依頼
                                        </AdvancedStatusButton>
                                        <DigitalConstractCustomerConfirmationAgreement
                                            contractType={contractType}
                                            kojiCD={props.kojiCD}
                                            seqNo={props.seqNo}
                                            open={openCustomerConfirmationAgreement}
                                            close={setOpenCustomerConfirmationAgreement}
                                        />
                                    </Grid>
                                </Grid>
                                : null
                            }
                            {status === "お客様承認依頼中" ?
                                <Grid container rowSpacing={{ xs: 2 }} columnSpacing={3} style={{ display: 'flex', justifyContent: 'start' }}>
                                    <Grid item xs={6.5}>
                                        <AdvancedStatusButton
                                            className="ConfirmationAgreement"
                                            type="button"
                                            style={{ width: "250px", }}
                                            onClick={handleOpenCustomerConfirmationAgreement}
                                        >
                                            <PersonIcon sx={{ mr: 1 }} />ご本人様確認依頼
                                        </AdvancedStatusButton>
                                        <DigitalConstractCustomerConfirmationAgreement
                                            contractType={contractType}
                                            kojiCD={props.kojiCD}
                                            seqNo={props.seqNo}
                                            open={openCustomerConfirmationAgreement}
                                            close={setOpenCustomerConfirmationAgreement}
                                        />
                                    </Grid>
                                    <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <ReturnStatusButton
                                            className="DropRequestButton"
                                            type="button"
                                            onClick={handleOpenDropRequest}
                                        >
                                            <UndoIcon sx={{ mr: 1 }} />取下げ
                                        </ReturnStatusButton>
                                        <DigitalConstractDropRequest
                                            contractType={contractType}
                                            kojiCD={props.kojiCD}
                                            seqNo={props.seqNo}
                                            open={openDropRequest}
                                            close={setOpenDropRequest}
                                        />
                                    </Grid>
                                    <Grid item xs={1.5}>
                                        <Tooltip disableFocusListener title="クラウドサインで書類を確認" placement="top">
                                            <Button
                                                variant="text"
                                                className="confButton"
                                                type="button"
                                                component="a"
                                                target="_blank"
                                                href={cloudSignURL}
                                            >
                                                <img src={`${process.env.PUBLIC_URL}/Logo/cloudsign_logo.png`} alt="logo" width="60" height="33" />

                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                : null
                            }
                            {status === "お客様承認依頼エラー" ?
                                <Grid container rowSpacing={{ xs: 2 }} columnSpacing={8} style={{ display: 'flex', justifyContent: 'start' }}>
                                    <Grid item xs={4}>
                                        <ReturnStatusButton
                                            className="DropRequestButton"
                                            type="button"
                                            onClick={handleOpenDropRequest}
                                        >
                                            <UndoIcon sx={{ mr: 1 }} />取下げ
                                        </ReturnStatusButton>
                                        <DigitalConstractDropRequest
                                            contractType={contractType}
                                            kojiCD={props.kojiCD}
                                            seqNo={props.seqNo}
                                            open={openDropRequest}
                                            close={setOpenDropRequest}
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Tooltip disableFocusListener title="クラウドサインで書類を確認" placement="top">
                                            <Button
                                                variant="text"
                                                className="confButton"
                                                type="button"
                                                component="a"
                                                target="_blank"
                                                href={cloudSignURL}
                                            >
                                                <img src={`${process.env.PUBLIC_URL}/Logo/cloudsign_logo.png`} alt="logo" width="60" height="33" />
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                : null
                            }
                            {status === "お客様承認依頼却下" ?
                                <Grid container rowSpacing={{ xs: 2 }} columnSpacing={8} style={{ display: 'flex', justifyContent: 'start' }}>
                                    <Grid item xs={4}>
                                        <ReturnStatusButton
                                            className="DropRequestButton"
                                            type="button"
                                            onClick={handleOpenDropRequest}
                                        >
                                            <UndoIcon sx={{ mr: 1 }} />取下げ
                                        </ReturnStatusButton>
                                        <DigitalConstractDropRequest
                                            contractType={contractType}
                                            kojiCD={props.kojiCD}
                                            seqNo={props.seqNo}
                                            open={openDropRequest}
                                            close={setOpenDropRequest}
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Tooltip disableFocusListener title="クラウドサインで書類を確認" placement="top">
                                            <Button
                                                variant="text"
                                                className="confButton"
                                                type="button"
                                                component="a"
                                                target="_blank"
                                                href={cloudSignURL}
                                            >
                                                <img src={`${process.env.PUBLIC_URL}/Logo/cloudsign_logo.png`} alt="logo" width="60" height="33" />
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                : null
                            }
                            {status === "お客様承認済み(契約締結)" ?
                                <Grid container rowSpacing={{ xs: 2 }} columnSpacing={8} style={{ display: 'flex', justifyContent: 'start' }}>
                                    <Grid item xs={12}>
                                        <Tooltip disableFocusListener title="クラウドサインで書類を確認" placement="top">
                                            <Button
                                                variant="text"
                                                className="confButton"
                                                type="button"
                                                component="a"
                                                target="_blank"
                                                href={cloudSignURL}
                                            >
                                                <img src={`${process.env.PUBLIC_URL}/Logo/cloudsign_logo.png`} alt="logo" width="60" height="33" />
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                : null
                            }
                        </Grid>
                        <Grid item xs={3} style={{ display: 'flex', justifyContent: 'end' }}>
                            <Button
                                variant="text"
                                className="confButton"
                                type="button"
                                disabled={PDFLoading}
                                onClick={handleOpenPDF}
                            ><PictureAsPdfIcon sx={{ mr: 1 }} />
                                PDFを表示
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container columns={12} rowSpacing={{ xs: 1 }} sx={{ pt: 3 }} style={{ height: "calc(100% - 50px)" }} >
                <Grid item xs={12} >
                    <Grid container style={{ height: "calc(100% - 50px)" }}>
                        <Grid item xs={12}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                                style={{
                                    backgroundColor: "#e0e0e0"
                                }}
                            >
                                <Tab label="契約書" {...a11yProps(0)} />
                                <Tab label="重要事項説明書" {...a11yProps(1)} disabled={docFileTypeCD3Check === true ? false : true} />
                                <Tab label="設計及び工事監理業務に関する書面" {...a11yProps(2)} disabled={docFileTypeCD4Check === true ? false : true} />
                                <Tab label="チェックリスト" {...a11yProps(3)} disabled={docFileTypeCD5Check === true ? false : true} />
                                {docFileCertificatCheck === true ?
                                    <Tab label="合意締結証明書" {...a11yProps(4)} />
                                    : null}
                            </Tabs>
                            <TabPanel value={value} index={0} dir={theme.direction} style={{ height: "100%" }}>
                                {fromList == true ?
                                    getPDFData == null ?
                                        <GetPDFError loading={processingPDF} />
                                        :
                                        <embed
                                            type="application/pdf"
                                            src={getPDFData}
                                            width="100%"
                                            style={{
                                                border: "2px solid",
                                                borderColor: "#2c2c2c",
                                                height: "100%",
                                                width: "100%",
                                            }}
                                        />
                                    :
                                    <embed
                                        type="application/pdf"
                                        src={getPDFData}
                                        width="100%"
                                        style={{
                                            border: "2px solid",
                                            borderColor: "#2c2c2c",
                                            height: "100%",
                                            width: "100%",
                                        }}
                                    />
                                }
                            </TabPanel>
                            <TabPanel value={value} index={1} dir={theme.direction} style={{ height: "100%" }}>
                                {getImportantPDFData == null ?
                                    <GetPDFError loading={processingPDF} />
                                    :
                                    <embed
                                        type="application/pdf"
                                        src={getImportantPDFData}
                                        width="100%"
                                        style={{
                                            border: "2px solid",
                                            borderColor: "#2c2c2c",
                                            height: "100%",
                                            width: "100%",
                                            margin: "auto"
                                        }}
                                    />
                                }
                            </TabPanel>
                            <TabPanel value={value} index={2} dir={theme.direction} style={{ height: "100%" }}>
                                {getDesignPDFData == null ?
                                    <GetPDFError loading={processingPDF} />
                                    :
                                    <embed
                                        type="application/pdf"
                                        src={getDesignPDFData}
                                        width="100%"
                                        style={{
                                            border: "2px solid",
                                            borderColor: "#2c2c2c",
                                            height: "100%",
                                            width: "100%"
                                        }}
                                    />
                                }
                            </TabPanel>
                            {docFileTypeCD5Check === true ?
                                <TabPanel value={value} index={3} dir={theme.direction} style={{ height: "100%" }}>
                                    {getCheckListPDFData == null ?
                                        <GetPDFError loading={processingPDF} />
                                        :
                                        <embed
                                            type="application/pdf"
                                            src={getCheckListPDFData}
                                            width="100%"
                                            style={{
                                                border: "2px solid",
                                                borderColor: "#2c2c2c",
                                                height: "100%",
                                                width: "100%"
                                            }}
                                        />
                                    }
                                </TabPanel>
                                : null}
                            {docFileCertificatCheck === true ?
                                <TabPanel value={value} index={4} dir={theme.direction} style={{ height: "100%" }}>
                                    {getCertificatPDFData == null ?
                                        <GetPDFError loading={processingPDF} />
                                        :
                                        <embed
                                            type="application/pdf"
                                            src={getCertificatPDFData}
                                            width="100%"
                                            style={{
                                                border: "2px solid",
                                                borderColor: "#2c2c2c",
                                                height: "100%",
                                                width: "100%"
                                            }}
                                        />
                                    }
                                </TabPanel>
                                : null}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        </ConstructContainer >
    );
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box style={{ height: "100%" }}>
                    <Typography style={{ height: "100%" }}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}