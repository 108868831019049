import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { Contents, WorkButton, WorkCard } from './css/CSS.js';

function WorkSelect() {
    const works = [
        {
            description: [
                '着工（地縄）',
                '上棟',
                '竣工',
            ],
            buttonText: '立会確認書作成',
            buttonVariant: 'contained',
            link: '/confirmation/list',
        },
        {
            description: [
                '工事請負契約書',
                '変更工事請負契約書'
            ],
            buttonText: '電子契約',
            buttonVariant: 'contained',
            link: '/digitalconstract',
            adminlink: '/digitalconstract/list',
        },
        {
            description: [
            ],
            buttonText: ' ',
            buttonVariant: 'contained',
            link: '',
        }
    ];

    //const [authority, setAuthority] = React.useState();
    //React.useLayoutEffect(() => {
    //    setAuthority(JSON.parse(localStorage.getItem("userAuthority")))
    //    // eslint-disable-next-line react-hooks/exhaustive-deps
    //}, []);

    React.useLayoutEffect(() => {
        localStorage.removeItem('URLparams')
    }, []);

    return (
        <React.Fragment>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />
            <Grid sx={{ mt: 5, mb: 1 }}>
            </Grid>
            <Contents>
                <Grid container spacing={5} alignItems="flex-end">
                    {works.map((work) => (
                        <Grid item key={work.buttonText} xs={12} md={4}>
                            {work.buttonText === "電子契約" && localStorage.getItem("use") === "0" ?
                                <Card>
                                    <CardActions
                                        sx={{ p: 0 }}>
                                        <WorkButton
                                            fullWidth variant="contained"
                                            size="large"
                                            component={Link}
                                            to=""
                                        >
                                            <Typography sx={{ fontSize: 20 }}></Typography>
                                        </WorkButton>
                                    </CardActions>
                                    <WorkCard>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'baseline',
                                                mb: 2,
                                            }}
                                        >
                                        </Box>
                                        <ul>
                                        </ul>
                                    </WorkCard>
                                </Card>
                                :
                                <Card>
                                    <CardActions
                                        sx={{ p: 0 }}>
                                        {work.buttonText === "電子契約" && localStorage.getItem("contractListOpe") === "1" ?
                                            <WorkButton
                                                fullWidth variant={work.buttonVariant}
                                                size="large"
                                                component={Link}
                                                to={work.adminlink}
                                            >
                                                <Typography sx={{ fontSize: 20 }}>{work.buttonText}</Typography>
                                            </WorkButton>
                                            :
                                            <WorkButton
                                                fullWidth variant={work.buttonVariant}
                                                size="large"
                                                component={Link}
                                                to={work.link}
                                            >
                                                <Typography sx={{ fontSize: 20 }}>{work.buttonText}</Typography>
                                            </WorkButton>
                                        }
                                    </CardActions>
                                    <WorkCard>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'baseline',
                                                mb: 2,
                                            }}
                                        >
                                        </Box>
                                        <ul>
                                            {work.description.map((line) => (
                                                <Typography
                                                    variant="subtitle1"
                                                    align="center"
                                                    key={line}
                                                    sx={{
                                                        fontSize: 18
                                                    }}
                                                >
                                                    {line}
                                                </Typography>
                                            ))}
                                        </ul>
                                    </WorkCard>
                                </Card>
                            }
                        </Grid>
                    ))}
                </Grid>
            </Contents>
        </React.Fragment>
    );
}

export default function WorkSelection() {
    return <WorkSelect />;
}
