import React, { useContext } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { AuthContext } from "../../Auth";
import useAuthAxios from "../../useAuthAxios";
import { ConfirmationContext } from "../../Confirmation";
import { Title, Contents, ConfirmationName } from '../css/CSS.js';
import Card from '@mui/material/Card';

export default function Shunko() {
    const { auth } = useContext(AuthContext);
    const authAxios = useAuthAxios()
    const headers = {
        "Authorization": "Bearer " + auth,
        'Content-Type': 'application/json',
    };
    const { kojiCD, kojiNAME } = useContext(ConfirmationContext);
    const [pdf, setPdf] = React.useState()
    const navigate = useNavigate();
    React.useEffect(() => {
        authAxios
            .get('api/confirmation/shunko/' + kojiCD + '/report?order=1', { headers })
            .then(response => {
                if (response.status === 200) {
                    const data = response.data;
                    const pdfdata = JSON.parse((JSON.stringify(data)))
                    setPdf("data:application/pdf;base64," + pdfdata.confirmationPdfData64)
                } else if (response.status === 401) {
                    alert("認証に失敗しました。");
                    navigate("/");
                }
            }).catch((response) => {
                alert("情報取得に失敗しました。", response.body);
                navigate("/");
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <CssBaseline />
            <Title sx={{ pt: 2, mb: 1 }}>
                <Grid container columns={12} alignItems="center" justify="center">
                    <Grid item xs={2.5} >
                        <Typography sx={{ fontSize: 20, }} >
                            竣工立会確認書
                        </Typography>
                        <Typography sx={{ fontSize: 15, }} >
                            工事コード:{kojiCD}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ pl: 1 }}>
                        <Card sx={{ boxShadow: 0 }} >
                            <ConfirmationName style={{ display: 'flex', alignContent: " flex-end", justifyContent: 'center' }}>
                                <Typography
                                    sx={{
                                        fontSize: 25,
                                    }} >
                                    {kojiNAME}
                                </Typography>
                            </ConfirmationName>
                        </Card>
                    </Grid>
                </Grid>
            </Title>
            <Contents>
                <Card sx={{ width: "100%", p: 1, boxShadow: 0, border: 1, borderColor: "#EEEEEE" }} >
                    <Grid container alignItems="center" justify="center">
                        <Grid item xs={12}>
                            <embed type="application/pdf" src={pdf} width="100%" height="1000px" />
                        </Grid>
                    </Grid>
                </Card>
                <Grid container alignItems="center" justify="center" sx={{ pt: 1 }}>
                    <Grid item xs={12} sx={{ mb: 1 }}>
                        <Button variant="contained" color="primary" type="button" component={Link} to="/confirmation/list">
                            一覧へ戻る
                        </Button>
                    </Grid>
                </Grid>
            </Contents>
        </React.Fragment >
    );
}