import React from 'react';
import { ConstructCompositionContainer } from '../../css/CSS.js';
import Grid from '@mui/material/Grid';
import DigitalConstractEditFrame from './DigitalConstractEditFrame.js';
import DigitalConstractPDFFrame from './DigitalConstractPDFFrame.js';
import { useNavigate } from 'react-router-dom';
import { DigitalConstractsContext } from "../../../DigitalConstracts";

export default function DigitalConstractFrame() {
    const navigate = useNavigate();

    const {
        setKojiCD, setSeqNo, setContractName, setContractType, setStatus
    } = React.useContext(DigitalConstractsContext);

    React.useEffect(() => {
        let urlParamStr = window.location.search

        if (localStorage.getItem("Auth") === null) {
            localStorage.setItem('ToConstruct', true);

            if (urlParamStr) {
                urlParamStr = urlParamStr.substring(1)
                let params = {}
                urlParamStr.split('&').forEach(param => {
                    const temp = param.split('=')

                    params = {
                        ...params,
                        [temp[0]]: temp[1]
                    }
                })
                if (window.location.search !== "") {
                    localStorage.setItem('URLparams', JSON.stringify(params));
                }
            }
            navigate("/");
        } else {
            urlParamStr = urlParamStr.substring(1)
            let params = {}
            urlParamStr.split('&').forEach(param => {
                const temp = param.split('=')

                params = {
                    ...params,
                    [temp[0]]: temp[1]
                }
            })
            if (window.location.search !== "") {
                localStorage.setItem('URLparams', JSON.stringify(params));
                navigate("/digitalconstract" + window.location.search);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useLayoutEffect(() => {
        if (localStorage.getItem("URLparams")) {
            const URLparams = JSON.parse(localStorage.getItem("URLparams"))

            setKojiCD(URLparams.kojiCD)
            setSeqNo(URLparams.seqNo)
            setContractType(URLparams.constructionName)
            setContractName(URLparams.contractType)
            setStatus(URLparams.status)
        }
    }, []);

    return (
        <ConstructCompositionContainer sx={{ pt: 1, }} maxWidth={false}>
            <Grid container columns={12} spacing={1} >
                <Grid container columns={12} >
                    <Grid item lg={6} sm={12}>
                        <DigitalConstractEditFrame />
                    </Grid>
                    <Grid item lg={6} sm={12} style={{ backgroundColor: "#fafafa", backgroundAttachment: "fixed" }}>
                        <DigitalConstractPDFFrame />
                    </Grid>
                </Grid>
            </Grid>
        </ConstructCompositionContainer>
    );
}