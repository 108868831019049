import React, { useState, createContext, useContext } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Kakunin from './KakuninForm';
import SetumeiHikiwatasi from './SetumeiHikiwatasiForm';
import SyokaiSonota from './SyokaiSonotaForm';
import Review from './Review';
import { styled } from '@mui/material/styles';
import { CHECKBOX_STATES } from "../CheckBox"
import { ConfirmationContext } from "../../Confirmation";
import { Contents } from '../css/CSS.js';
//import UploadButtons from '../FileUpload';
import { Title, ConfirmationName } from '../css/CSS.js';
import Card from '@mui/material/Card';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form"

const Steppers = styled(Stepper)({
    backgroundColor: "#FFFFCC",
    padding: "10px",
    borderRadius: "10px",
    transform: "scale(1.1)"
});

const getSteps = ['確認事項', '説明事項、お引渡し書類', '初回点検(3カ月点検)、その他の確認', '署名'];

export const ShunkoInitialInputData = {
    kakunin: {
        naibuKakunin: CHECKBOX_STATES.Empty,
        naibuCloth: CHECKBOX_STATES.Empty,
        naibuFlooring: CHECKBOX_STATES.Empty,
        naibuMado: CHECKBOX_STATES.Empty,
        naibuTategu: CHECKBOX_STATES.Empty,
        naibuKitchen: CHECKBOX_STATES.Empty,
        naibuUB: CHECKBOX_STATES.Empty,
        naibuSenmenKeshoDai: CHECKBOX_STATES.Empty,
        naibuToilet: CHECKBOX_STATES.Empty,
        naibuKankiSystem: CHECKBOX_STATES.Empty,
        naibuAircon: CHECKBOX_STATES.Empty,
        naibuDanbou: CHECKBOX_STATES.Empty,
        naibuSonota: CHECKBOX_STATES.Empty,
        gaibuKakunin: CHECKBOX_STATES.Empty,
        gaibuYane: CHECKBOX_STATES.Empty,
        gaibuGaiheki: CHECKBOX_STATES.Empty,
        gaibuiBalconySundeck: CHECKBOX_STATES.Empty,
        gaibuMeterMas: CHECKBOX_STATES.Empty,
        gaibuKyutokiEcocute: CHECKBOX_STATES.Empty,
        gaibuAirconSitugaiki: CHECKBOX_STATES.Empty,
        gaibuTeakGenkanOil: CHECKBOX_STATES.Empty,
        kagi: CHECKBOX_STATES.Empty,
        kagiHonsu: 0,
        kagi2Door: "",
        kagi2Honsu: 0,
        kagi3Door: "",
        kagi3Honsu: 0,
        hikiwatasi: CHECKBOX_STATES.Empty,
        hikiwatasiYMD: null,
        hikiwatasiHM: null,
        nyukyo: CHECKBOX_STATES.Empty,
        nyukyoYMD: null,
        lifeLineSetumei: CHECKBOX_STATES.Empty,
        lifeSuido: CHECKBOX_STATES.Empty,
        lifeSuidoNote: "",
        lifeDenki: CHECKBOX_STATES.Empty,
        lifeDenkiNote: "",
        lifeGas: CHECKBOX_STATES.Empty,
        lifeGasNote: "",
        lifeTV: CHECKBOX_STATES.Empty,
        lifeTVNote: "",
        lifeTEL: CHECKBOX_STATES.Empty,
        lifeTELNote: "",
        lifeInternet: CHECKBOX_STATES.Empty,
        lifeInternetNote: "",
        lifeSekiyu: CHECKBOX_STATES.Empty,
        lifeSekiyuNote: "",
    },
    setumei: {
        tokuteiHoshu: CHECKBOX_STATES.Empty,
        TokuteiHoshuUmu: 0,
        TokuteiYokusituDenkiKanso: CHECKBOX_STATES.Empty,
        TokuteiBuiltinShokkiArai: CHECKBOX_STATES.Empty,
        TokuteiShitunaiGasYuwakasi: CHECKBOX_STATES.Empty,
        TokuteiSekiyuKyuto: CHECKBOX_STATES.Empty,
        TokuteiFFSekiyuOnpuDanbou: CHECKBOX_STATES.Empty,
        OkyakuHosyuTenken: CHECKBOX_STATES.Empty,
        OkyakuBoukaDamper: CHECKBOX_STATES.Empty,
        OkyakuAnnzen: CHECKBOX_STATES.Empty,
        OkyakuBlindRoll: CHECKBOX_STATES.Empty,

    },
    hikiwatasi: {
        kojiKanriHoukokusho: CHECKBOX_STATES.Empty,
        kojiKanriHoukokushoJyotoWatasi: CHECKBOX_STATES.Empty,
        kojiKanriHoukokushoShunkoWatasi: CHECKBOX_STATES.Empty,
        kojiKanriKanryouHoukokusho: CHECKBOX_STATES.Empty,
        setubiKikiSetumeisho: CHECKBOX_STATES.Empty,
    },
    shokaiTenken: CHECKBOX_STATES.Empty,
    sonota: {
        sonota: CHECKBOX_STATES.Empty,
        sonotaNote: ""
    },
    reportKojiName: "",
    reportKojiNameHonorific: "",
    kiroku: 0,
};

//入力値用context
export const UserInputData = createContext(ShunkoInitialInputData);
//POST用context
export const PostData = createContext({});

export default function ShunkoForm() {
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps;
    const { kojiCD, shunkoformData, being_shunko, setBeing_shunko, cutTeimei, setCutTeimei, changeTeimei, setChangeTeimei } = useContext(ConfirmationContext);
    const [currentState, setCurrentState] = useState(being_shunko ? shunkoformData : ShunkoInitialInputData);
    const value = { currentState, setCurrentState };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleSave = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <Kakunin handleNext={handleNext} />;
            case 1:
                return <SetumeiHikiwatasi handleNext={handleNext} handleBack={handleBack} />;
            case 2:
                return <SyokaiSonota handleSave={handleSave} handleBack={handleBack} />;
            case 3:
                return <Review handleBack={handleBack} />;
            default:
                throw new Error('Unknown step');
        }
    }

    //邸名編集機能
    const validationRules = {
        reportKojiNameHonorific: { maxLength: { value: 3, message: '3桁以内で入力してください。' }, },
    }
    const { control, handleSubmit, watch } = useForm({
        defaultValues: { ...currentState },
        mode: 'onChange'
    });
    React.useEffect(() => {
        if (!watch("reportKojiNameHonorific")) {
            setCutTeimei(cutTeimei)
            setChangeTeimei(changeTeimei)
        } else {
            setCutTeimei(cutTeimei)
            !watch("reportKojiNameHonorific") ?
                setChangeTeimei(changeTeimei)
                : setChangeTeimei(watch("reportKojiNameHonorific"))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch("reportKojiNameHonorific")]);

    React.useEffect(() => {
        setBeing_shunko(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <CssBaseline />
            <Title sx={{ pb: 2, pt: 4, }}>
                <Grid container columns={12} spacing={3} >
                    <Grid container columns={12} style={{ display: 'flex', alignItems: 'end' }}>
                        <Grid item xs={2.5} >
                            <Typography sx={{ fontSize: 20, }} >
                                竣工立会確認書
                            </Typography>
                            <Typography sx={{ fontSize: 15, }} >
                                工事コード:{kojiCD}
                            </Typography>
                        </Grid>
                        <Grid item xs={7} sx={{ pl: 1 }}>
                            <Card sx={{ boxShadow: 0 }} >
                                <ConfirmationName style={{ display: 'flex', paddingBottom: 0, justifyContent: 'center', alignItems: 'end' }}>
                                    <Typography
                                        sx={{
                                            fontSize: 25,
                                            pt: 1,
                                            pb: 0
                                        }} >
                                        {currentState.reportKojiName}
                                    </Typography>
                                </ConfirmationName>
                            </Card>
                        </Grid>
                        {activeStep !== 3 ?
                            <form onSubmit={handleSubmit()} >
                                <Grid item xs={3} sx={{ transform: "scale(1.5)", pb: 1 }}>
                                    <Controller
                                        control={control}
                                        name="reportKojiNameHonorific"
                                        rules={validationRules.reportKojiName}
                                        render={({ field, fieldState }) => (
                                            < TextField
                                                {...field}
                                                sx={{ pt: 2 }}
                                                variant="standard"
                                                inputProps={{ maxLength: 3, style: { padding: '0px', textAlign: 'bottom' } }}
                                                style={{ width: '100%' }}
                                                error={fieldState.invalid}
                                            />
                                        )}
                                    />
                                </Grid>
                            </form>
                            : <Grid item xs={1} sx={{ transform: "scale(1.5)", pt: 5.6, pb: 1 }}>
                                <Typography
                                    sx={{
                                        pt: 1,
                                        pb: 0
                                    }} >
                                    {currentState.reportKojiNameHonorific}
                                </Typography>
                            </Grid>
                        }

                        {/*<Grid item xs={2.5} >*/}
                        {/*    <UploadButtons />*/}
                        {/*</Grid>*/}
                    </Grid>
                </Grid>
            </Title>

            <Contents component="main" sx={{ mb: 1 }}>
                <Steppers activeStep={activeStep} sx={{ pb: 1 }}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel >{label}</StepLabel>
                        </Step>
                    ))}
                </Steppers>
                <UserInputData.Provider value={value}>
                    {getStepContent(activeStep, handleNext)}
                </UserInputData.Provider>
            </Contents>

        </React.Fragment>

    );
}