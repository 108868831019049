import React, { useContext } from 'react';
import { ConstructContainer, SelectCustomerButton } from '../../css/CSS.js';
import Grid from '@mui/material/Grid';
import DigitalConstractSelectCustomer from './DigitalConstractSelectCustomer'
import DigitalConstractCustomerPDFFrame from './DigitalConstractCustomerPDFFrame'
import DigitalConstractCustomerCheckList from './DigitalConstractCustomerCheckList'
import { DigitalConstractsContext } from "../../../DigitalConstracts";
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

export default function DigitalConstractCustomerFrame(props) {
    const [openSelectCustomer, setOpenSelectCustomer] = React.useState(false);
    const { customerNo, setCustomerNo } = useContext(DigitalConstractsContext);

    const handleChangeCustomer = () => { setOpenSelectCustomer(true) }

    //画面遷移時、410_お客様選択画面を表示
    React.useLayoutEffect(() => {
        if (openSelectCustomer === true) { return }
        setCustomerNo("")
        setOpenSelectCustomer(true)
    }, []);

    return (
        <ConstructContainer sx={{ pt: 1, }} maxWidth={false}>
            <DigitalConstractSelectCustomer
                open={openSelectCustomer}
                close={setOpenSelectCustomer}
            />
            <Grid container columns={12} columnSpacing={1}  >
                {!customerNo == "" ?
                    <Grid container >
                        <Grid item lg={6} sm={12}>
                            <DigitalConstractCustomerCheckList
                                customerNo={customerNo}
                                open={setOpenSelectCustomer}
                            />
                        </Grid>
                        <Grid item lg={6} sm={12} >
                            <DigitalConstractCustomerPDFFrame />
                        </Grid>
                    </Grid>
                    :
                    <Grid container >
                        <SelectCustomerButton
                            className="customerSelectButton"
                            type="button"
                            onClick={handleChangeCustomer}
                        >
                            <PeopleOutlineIcon sx={{ mr: 1 }} />
                            お客様選択へ
                        </SelectCustomerButton>
                    </Grid>
                }
            </Grid>
        </ConstructContainer >
    );
}