import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

//共通
export const Title = styled(Container)({
    maxWidth: "lg"
});

export const Contents = styled(Container)({
    maxWidth: "lg"
});

//左メニュー
export const Menu = styled(List)({
    backgroundColor: "#289bdf"

});
export const MenuText = styled(ListItemText)({
    color: "#FFFFFF"

});

//業務選択画面
export const WorkHeader = styled(Title)({
    fontSize: "25px",
    borderBottom: "1px solid",
    borderBottomColor: "#289bdf",
    marginBottom: "30px",
});

export const WorkButton = styled(Button)({
    minHeight: "60px",
    backgroundColor: "#289bdf",
    fonSize: "20px",
    color: "#FFFFFF",
});

export const WorkCard = styled(CardContent)({
    minHeight: "200px",
    backgroundColor: "#EEEEEE",
});


//立会確認書一覧
export const ConfirmationHeader = styled(Title)({
    fontSize: "25px",
    borderBottom: "1px solid",
    borderBottomColor: "#289bdf",
    marginBottom: "30px",
});
export const ConfirmationContents = styled(Container)({
    maxWidth: "xl",
    minWidth: "xl"
});

//立会確認書確認
export const ConfirmationName = styled(CardContent)({
    color: "#223e69",
    boxShadow: 0,
    paddingBottom: "0",
});

//各フォームタイトル
export const FormTitle = styled(Typography)({
    color: "#223e69",
    paddingTop: "20px",
    fontSize: "25px",
});

//サイン背景
export const SignDialog = styled(Grid)({
    backgroundImage: "linear-gradient(0deg, transparent 248px, #AAAAAA 250px), linear-gradient(90deg, transparent 248px, #AAAAAA 250px)",
    backgroundColor: "#ddd",
    backgroundSize: "249px 249px",
    border: "2px solid",
    borderColor: "#CCCCCC"
});


//電子契約
export const ConstructHeader = styled(Grid)({
    fontSize: "25px",
    borderBottom: "1px solid",
    borderBottomColor: "#289bdf",
    marginBottom: "30px",
});

export const ConstructSign = styled(Grid)({
    backgroundImage: "linear-gradient(0deg, transparent calc(100% - 1px), #ececec calc(100% - 1px)), linear-gradient(90deg, transparent calc(100% - 1px), #ececec calc(100% - 1px))",
    backgroundColor: "#ffffff",
    backgroundSize: "167px 85px",
    border: "1px solid",
    borderColor: "#CCCCCC"
});

export const ReadingButton = styled(Button)({
    backgroundColor: "#1976d2",
    fonSize: "20px",
    color: "#ffffff",
    border: "1px solid",
    borderColor: "#CCCCCC",
    borderRadius: "50px",
    "&:hover": {
        background: "#289bdf"
    },
});

export const BackListButton = styled(Button)({
    backgroundColor: "#1976d2",
    fonSize: "20px",
    color: "#ffffff",
    border: "1px solid",
    borderColor: "#CCCCCC",
    borderRadius: "50px",
    "&:hover": {
        background: "#289bdf"
    },
});

export const CustomerConfirmButton = styled(Button)({
    backgroundColor: "#1976d2",
    fonSize: "20px",
    color: "#ffffff",
    border: "1px solid",
    borderColor: "#CCCCCC",
    borderRadius: "50px",
    "&:hover": {
        background: "#289bdf"
    },
});

export const PDFViewButton = styled(Button)({
    backgroundColor: "#1976d2",
    fonSize: "20px",
    color: "#ffffff",
    border: "1px solid",
    borderColor: "#CCCCCC",
    borderRadius: "50px",
    "&:hover": {
        background: "#289bdf"
    },
});

export const ConstructionButton = styled(Button)({
    backgroundColor: "#da9943",
    fonSize: "20px",
    color: "#ffffff",
    border: "1px solid",
    borderColor: "#CCCCCC",
    borderRadius: "50px",
    "&:hover": {
        background: "#da9943"
    },
});
export const ModificationConstructionButton = styled(Button)({
    backgroundColor: "#85bb92",
    fonSize: "20px",
    color: "#ffffff",
    border: "1px solid",
    borderColor: "#CCCCCC",
    borderRadius: "50px",
    "&:hover": {
        background: "#85bb92"
    },
});

export const SelectContractTitle = styled(DialogTitle)({
    backgroundColor: "#1976d2",
    color: "#ffffff",
    paddingTop: "10px",
    paddingBottom: "10px",

});
export const SelectContractContent = styled(DialogContent)({
    backgroundColor: "#dcdcdc",
    paddingTop: "0px"
});

export const SelectContractText = styled(Typography)({
    backgroundColor: "#ffffff",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px",
});

export const ContractText = styled(Typography)({
    backgroundColor: "#dcdcdc",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px",
    borderRadius: "10px",
});

export const ContractTitle = styled(Typography)({

});

export const SelectContractCloseButton = styled(Button)({
    backgroundColor: "#d3d3d3",
    fontSize: "17px",
    color: "#000000",
    border: "1px solid",
    borderColor: "#CCCCCC",
    borderRadius: "50px",
    "&:hover": {
        background: "#c0c0c0"
    },
});

export const SearchResetButton = styled(Button)({
    backgroundColor: "#d32f2f",
    fonSize: "20px",
    color: "#ffffff",
    border: "1px solid",
    borderColor: "#CCCCCC",
    borderRadius: "50px",
    "&:hover": {
        background: "#ff4500"
    },
});

export const DeleteModeButton = styled(Button)({
    backgroundColor: "#C0C0C0",
    fonSize: "20px",
    color: "#000000",
    border: "1px solid",
    borderColor: "#C0C0C0",
    borderRadius: "50px",
    "&:hover": {
        background: "#a9a9a9"
    },
});

export const EditModeButton = styled(Button)({
    backgroundColor: "#1976d2",
    fonSize: "20px",
    color: "#ffffff",
    border: "1px solid",
    borderColor: "#CCCCCC",
    borderRadius: "50px",
    "&:hover": {
        background: "#289bdf"
    },
});

export const UploadButton = styled(Button)({
    backgroundColor: "#1976d2",
    fontSize: "20px",
    color: "#ffffff",
    border: "1px solid",
    borderColor: "#CCCCCC",
    borderRadius: "50px",
    "&:hover": {
        background: "#1976d2"
    },
});

export const ArrowUpwardButton = styled(ArrowUpwardIcon)({
    color: "#1976d2",
});

export const ArrowDownwardButton = styled(ArrowDownwardIcon)({
    color: "#1976d2",
});

export const UploadContractTitle = styled(DialogTitle)({
    backgroundColor: "#1976d2",
    color: "#ffffff",
    paddingTop: "10px",
    paddingBottom: "10px",

});

export const BackConstitutionButton = styled(Button)({
    backgroundColor: "#C0C0C0",
    fonSize: "20px",
    color: "#000000",
    border: "1px solid",
    borderColor: "#C0C0C0",
    borderRadius: "50px",
    "&:hover": {
        background: "#a9a9a9"
    },
});

export const CustomerSignButton = styled(Button)({
    backgroundColor: "#1976d2",
    fonSize: "20px",
    color: "#ffffff",
    border: "1px solid",
    borderColor: "#CCCCCC",
    borderRadius: "50px",
    "&:hover": {
        background: "#289bdf"
    },
});

export const SignContent = styled(DialogContent)({
    backgroundColor: "#F4F5F7",
    paddingTop: "0px"
});

export const ConstructTitle = styled(Container)({
    backgroundColor: "#F4F5F7",
});

export const ArrowContent = styled(CardContent)({
    backgroundColor: "#F4F5F7",
});

export const UpdateContent = styled(Paper)({
    backgroundColor: "#F4F5F7",
});

export const SignContract = styled(Grid)({
    backgroundColor: "#dcdcdc",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingLeft: "5px",
    borderRadius: "5px",
});


//電子契約共通コンポーネント
export const DigitalSignContainer = styled(Container)({
    backgroundColor: "#fafafa",
    height: "100%",

});
//背景
export const ConstructContainer = styled(Container)({
    backgroundColor: "#fafafa",
    height: "100%",
});
//構成背景
export const ConstructCompositionContainer = styled(Container)({
    backgroundColor: "#fafafa",
});
//ファイル名背景
export const PointUpBackGround = styled(Grid)({
    backgroundColor: "#ffffff"
});

//正常ボタン
export const NomalButton = styled(Button)({
    backgroundColor: "#1976d2",
    fontSize: "17px",
    color: "#ffffff",
    border: "1px solid",
    borderColor: "#CCCCCC",
    borderRadius: "50px",
    "&:hover": {
        background: "#289bdf"
    },
});
//注意警告ボタン
export const CautionButton = styled(Button)({
    backgroundColor: "#d32f2f",
    fontSize: "17px",
    color: "#ffffff",
    border: "1px solid",
    borderColor: "#CCCCCC",
    borderRadius: "50px",
    "&:hover": {
        background: "#ff4500"
    },
});
//
export const GrayButton = styled(Button)({
    backgroundColor: "#C0C0C0",
    fontSize: "17px",
    color: "#000000",
    border: "1px solid",
    borderColor: "#C0C0C0",
    borderRadius: "50px",
    "&:hover": {
        background: "#a9a9a9"
    },
});

//コンテンツテンプレート
export const ConstractContents = styled(Container)({
});

//
export const GrayBack = styled(Grid)({
    backgroundColor: "#e0e0e0",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingLeft: "5px",
    borderRadius: "5px",
});

export const WhiteTextBack = styled(Typography)({
    backgroundColor: "#ffffff",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px",
});

export const KojiCDTextField = styled(TextField)({
    backgroundColor: "#ffffff",
    borderRadius: "50px 0 0 50px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px",
})


//スクエアボタン
export const SquareButton = styled(Button)({
    backgroundColor: "#ff8c00 ",
    fontSize: "16px",
    color: "#ffffff",
    border: "1px solid",
    borderColor: "#ff8c00",
    height: "100px",
    width: "100%",
    "&:hover": {
        background: "#ffa333 ",
        border: "1px solid",
        borderColor: "#ffa333",
    },
    borderRadius: "0",
});

//サイン登録ボタン
export const CheckListUploadButton = styled(Button)({
    backgroundColor: "#1976d2",
    fontSize: "25px",
    color: "#ffffff",
    border: "1px solid",
    borderColor: "#CCCCCC",
    borderRadius: "5px",
    height: "100px",
    width: "340px",
    "&:hover": {
        background: "#289bdf"
    },
});

//
export const SelectCustomerButton = styled(GrayButton)({
    fontSize: "18px",
    height: "80px",
    width: "240px",
    borderRadius: "5px",
});

//PDFボタン
export const PDFViewerButton = styled(NomalButton)({
    background: 'transparent',
    color: "#1976d2",
    border: "2px solid",
    borderColor: "#1976d2",
    "&:hover": {
        background: "#e0efff"
    },
});

//正常ボタン
export const EditSquareButton = styled(PDFViewerButton)({
    borderRadius: "5px",
    color: "#1976d2",
    border: "1px solid",
    borderColor: "#1976d2",

});

export const CancelSquareButton = styled(EditSquareButton)({
    borderRadius: "5px",
    color: "#000000",
    background: 'transparent',
    border: "1px solid",
    borderColor: "#C0C0C0",
    "&:hover": {
        background: "#a9a9a9"
    },
});

//正常ボタン
export const DoneSquareButton = styled(PDFViewerButton)({
    borderRadius: "5px",
    background: '#1976d2',
    color: "#ffffff",
    border: "2px solid",
    borderColor: "#ffffff",
    "&:hover": {
        background: "#289bdf"
    },
});

//
export const GraySquareButton = styled(GrayButton)({
    background: 'transparent',
    color: "#C0C0C0",
    border: "2px solid",
    borderColor: "#C0C0C0",
    "&:hover": {
        background: "#a9a9a9"
    },
});


export const FilesUploadButton = styled(Button)({
    background: 'transparent',
    color: "#000000",
    "&:hover": {
        background: "#a9a9a9"
    },
});


export const AdvancedStatusButton = styled(Button)({
    backgroundColor: "#ff0000",
    fontSize: "17px",
    color: "#ffffff",
    border: "1px solid",
    borderColor: "#ff0000",
    borderRadius: "50px",
    "&:hover": {
        borderColor: "#e0efff",
        background: "#ff7f7f"
    },
});
export const ReturnStatusButton = styled(NomalButton)({
    background: 'transparent',
    color: "#ff0000",
    border: "2px solid",
    borderColor: "#ff0000",
    "&:hover": {
        background: "#ffd1d1"
    },
});

//正常ボタン
export const ConstrustSearchButton = styled(NomalButton)({
    backgroundColor: "#1976d2",
    fontSize: "17px",
    color: "#ffffff",
    border: "1px solid",
    borderColor: "#CCCCCC",
    borderRadius: "5px",
    "&:hover": {
        background: "#289bdf"
    },
});

export const ConstrustBaseButton = styled(NomalButton)({
    backgroundColor: "#1976d2",
    fontSize: "20px",
    color: "#ffffff",
    border: "1px solid",
    borderColor: "#CCCCCC",
    borderRadius: "5px",
    "&:hover": {
        background: "#289bdf"
    },
});
export const SelectModifyButton = styled(Button)({
    backgroundColor: "#1976d2",
    fontSize: "20px",
    color: "#ffffff",
    border: "1px solid",
    borderColor: "#1976d2",
    borderRadius: "5px",
    "&:hover": {
        borderColor: "#289bdf",
        background: "#289bdf"
    },
});