import * as React from 'react';
import { ConstructCompositionContainer } from '../../css/CSS.js';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DigitalConstractUpdateList from './DigitalConstractUpdateList '
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';

export default function DigitalConstractUpdateFrame(props) {

    return (
        <ConstructCompositionContainer sx={{ pb: 2, pt: 2 }} style={{ paddingRight: "0px" }} maxWidth={false}>
            <Grid container columns={12} rowSpacing={{ xs: 0 }} columnSpacing={1} >
                <Grid item xs={12} >
                    <Accordion sx={{ boxShadow: 0 }}>
                        <Grid item xs={12} sx={{ borderBottom: "1px solid", borderColor: "#e9e9e9" }}>
                            <AccordionSummary
                                sx={{ pl: 0.5 }}
                                expandIcon={
                                    <Tooltip title="更新履歴を開く">
                                        <Grid
                                            item xs="auto"
                                            sx={{ mr: 2.5 }}
                                            style={{
                                                backgroundColor: "#ffffff",
                                                borderRadius: "80px",
                                                display: 'flex', justifyContent: 'center', alignItems: 'center'

                                            }}
                                        ><ExpandMoreIcon />
                                        </Grid>
                                    </Tooltip>
                                }
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography sx={{ fontSize: 17 }} >
                                    更新履歴
                                </Typography>
                            </AccordionSummary>
                        </Grid>
                        <AccordionDetails style={{ backgroundColor: "#ffffff", paddingRight: "0px", paddingLeft: "0px" }}>
                            <Grid item xs={12} sx={{ pt: 0 }} style={{ paddingRight: "0px" }}>
                                <DigitalConstractUpdateList
                                    selectCheck={props.selectCheck}
                                />
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </ConstructCompositionContainer >
    );
}