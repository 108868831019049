import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';


const ToggleGroup = styled(ToggleButtonGroup)({
    backgroundColor: "#289bdf",
    color: "#FFFFFF",

});

const Toggles = styled(ToggleButton)({
    backgroundColor: "#F0F8FF",
    color: "#BBBBBB",

});
const DisToggleGroup = styled(ToggleButtonGroup)({
    backgroundColor: "#EEEEEE",
    color: "#EEEEEE",
});

const DisToggles = styled(ToggleButton)({
    backgroundColor: "#EEEEEE",
    color: "#EEEEEE",
});
const DisTypography = styled(Typography)({
    color: "#DDDDDD",
})

const theme = createTheme({
    palette: {
        primary: {
            main: '#EEEEEE',
        },
    },
});


export const TOGGLE_STATES = {
    One: 1,
    Two: 2,
    Three: 3,
    Zero: 0,
};

export default function Toggle(props) {
    const [checkToggle, setCheckToggle] = React.useState(props.checkedToggle);

    React.useEffect(() => {
        setCheckToggle(props.checkedToggle);
    }, [props.checkedToggle]);

    const handleChange = (e, newValue) => {
        let updatedCheckedToggle

        if (newValue === TOGGLE_STATES.One) {
            updatedCheckedToggle = TOGGLE_STATES.One;
        } else if (newValue === TOGGLE_STATES.Two) {
            updatedCheckedToggle = TOGGLE_STATES.Two;
        } else if (newValue === TOGGLE_STATES.Three) {
            updatedCheckedToggle = TOGGLE_STATES.Three;
        } else if (newValue === TOGGLE_STATES.Zero) {
            updatedCheckedToggle = TOGGLE_STATES.Zero;
        } else {
            updatedCheckedToggle = TOGGLE_STATES.One;
        }

        setCheckToggle(updatedCheckedToggle);
        props.setCheckedToggle(updatedCheckedToggle)

    };
    return (
        <ThemeProvider theme={theme}>
            {props.disabled === true ?
                <DisToggleGroup
                    color="primary"
                    exclusive
                    disabled={props.disabled}
                    onChange={handleChange}
                    sx={{
                        minWidth: 180,
                        minHeight: 10,
                    }}
                >
                    <DisToggles
                        value={props.toggleSts}
                        sx={{
                            minWidth: 95,
                            fontSize: 18,
                            padding: 0.6,
                        }}
                    >
                        <DisTypography>{props.toggleValue}</DisTypography>
                    </DisToggles>
                    <DisToggles
                        value={props.toggleSts2}
                        sx={{
                            minWidth: 95,
                            fontSize: 18,
                            padding: 0.6,
                        }}
                    >
                        <DisTypography >{props.toggleValue2}</DisTypography>
                    </DisToggles>
                    {props.toggleValue3 &&
                        <DisToggles
                            value={props.toggleSts3}
                            sx={{
                                minWidth: 95,
                                fontSize: 18,
                                padding: 0.6,
                            }}
                        >
                            <DisTypography >{props.toggleValue3}</DisTypography>
                        </DisToggles>
                    }
                </DisToggleGroup>
                :
                <ToggleGroup
                    color="primary"
                    value={checkToggle}
                    exclusive
                    disabled={props.disabled}
                    onChange={handleChange}
                    sx={{
                        minWidth: 180,
                        minHeight: 10,
                    }}
                >
                    <Toggles
                        value={props.toggleSts}
                        sx={{
                            minWidth: 95,
                            fontSize: 18,
                            padding: 0.6,
                        }}
                    >
                        <Typography>{props.toggleValue}</Typography>
                    </Toggles>
                    <Toggles
                        value={props.toggleSts2}
                        sx={{
                            minWidth: 95,
                            fontSize: 18,
                            padding: 0.6,
                        }}
                    >
                        <Typography >{props.toggleValue2}</Typography>
                    </Toggles>
                    {props.toggleValue3 &&
                        <Toggles
                            value={props.toggleSts3}
                            sx={{
                                minWidth: 95,
                                fontSize: 18,
                                padding: 0.6,
                            }}
                        >
                            <Typography >{props.toggleValue3}</Typography>
                        </Toggles>
                    }
                </ToggleGroup>

            }

        </ThemeProvider>
    );
}
