import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import useAuthAxios from "../useAuthAxios";
import { AuthContext } from "../Auth";
import { setCookie, removeCookie, getCookie } from "./Action/HandleCookie"
import packageJson from '../../package.json';

const theme = createTheme();

export default function Login() {
    const navigate = useNavigate();
    const authAxios = useAuthAxios();
    const { control, handleSubmit, getValues } = useForm({
        defaultValues: {
            UserID: "",
            Password: "",
        },
    });

    localStorage.removeItem("Auth");
    localStorage.removeItem('use')
    localStorage.removeItem('contractListOpe')
    localStorage.removeItem('contractDelete')
    localStorage.removeItem('contractConfigOpe')
    localStorage.removeItem('internalApproval')
    localStorage.removeItem('internalConfirm')

    removeCookie("authorization")
    removeCookie("userName")
    removeCookie("userShozokuName")


    React.useLayoutEffect(() => {
        if (!getCookie("reload")) { return; }
        else if (getCookie("reload") === "true") {
            window.location.href = window.location.href
            removeCookie("reload")
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCookie("reload")]);

    const validationRules = {
        userID: {
            required: '社員Noを入力してください。',
            maxLength: { value: 5, message: '社員Noを入力してください。' }
        },
        Password: {
            required: 'パスワードを入力してください。',
            maxLength: { value: 8, message: 'パスワードを入力してください。' }
        }
    }
    const { setAuth, setUserShozokuCD, setUserSysAuthority } = useContext(AuthContext);
    const [processing_login, setProcessing_login] = React.useState(false);
    const onSubmit = () => {
        setProcessing_login(true)
        authAxios.post('api/authenticate/login?frontver=' + packageJson.version, getValues())
            .then((response) => {
                if (response.status === 200) {
                    let tokendata = JSON.parse((JSON.stringify(response.data)))
                    setAuth(tokendata.token)
                    setUserShozokuCD(tokendata.userShozokuCD)
                    setUserSysAuthority(tokendata.userSysAuthority)
                    setCookie("authorization", "Bearer%20" + tokendata.token)
                    setCookie("userName", tokendata.userName)
                    setCookie("userShitenName", tokendata.userShitenName)
                    setCookie("userShozokuName", tokendata.userShozokuName)
                    localStorage.setItem('Auth', tokendata.token);
                    localStorage.setItem('use', tokendata.userAuthority.digiContract.use)
                    localStorage.setItem('contractListOpe', tokendata.userAuthority.digiContract.contractListOpe)
                    localStorage.setItem('contractDelete', tokendata.userAuthority.digiContract.contractDelete)
                    localStorage.setItem('contractConfigOpe', tokendata.userAuthority.digiContract.contractConfigOpe)
                    localStorage.setItem('internalApproval', tokendata.userAuthority.digiContract.internalApproval)
                    localStorage.setItem('internalConfirm', tokendata.userAuthority.digiContract.internalConfirm)

                    if (localStorage.getItem("ToConstruct") === "true") {
                        navigate("/digitalconstract")
                        localStorage.removeItem("ToConstruct");
                    } else {
                        navigate("/top");
                    }
                    setProcessing_login(false)
                }
            }).catch((response) => {
                alert("ログインに失敗しました。", response.body);
                setProcessing_login(false)
            })
        setTimeout(() => {
            setProcessing_login(false)
        }, 10000);
    };

    return (
        <ThemeProvider theme={theme}>
            {!getCookie("reload") ?
                <Container component="main" >
                    <CssBaseline />
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        mt: "100px"
                    }} >
                        <Typography component="h1" variant="h5" sx={{ mt: 5 }} >
                            ログイン
                        </Typography>
                        <form onSubmit={handleSubmit()} >
                            <Box noValidate sx={{ mt: 1 }}>
                                <Controller
                                    control={control}
                                    name="UserID"
                                    rules={validationRules.userID}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            margin="normal"
                                            fullWidth
                                            id="UserID"
                                            label="社員No"
                                            name="UserID"
                                            autoComplete="UserID"
                                            autoFocus
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name="Password"
                                    rules={validationRules.Password}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            margin="normal"
                                            fullWidth
                                            name="Password"
                                            label="パスワード"
                                            type="password"
                                            id="Password"
                                            autoComplete="current-password"
                                        />
                                    )}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    onClick={() => onSubmit()}
                                    disabled={processing_login}
                                >
                                    <Typography sx={{ pt: 1, pb: 1 }}>{processing_login ? "処理中" : "LOGIN"}</Typography>
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </Container>
                : null
            }
            {!getCookie("reload") ?
                <Container
                    maxWidth="md"
                    component="footer"
                    sx={{
                        mt: 4,
                        py: [3, 6],
                    }}
                >
                    <Typography variant="body2" color="text.secondary" align="center" >
                        当システムではカルテシステムのパスワードを利用します。
                    </Typography>
                    <Typography variant="body2" color="text.secondary" align="center" >
                        パスワードを忘れた場合は、カルテシステムでパスワードリセットを行ってください。
                    </Typography>
                </Container>
                : null
            }
        </ThemeProvider>
    );
}