import React, { useContext, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CheckBox from '../CheckBox'
import { useForm, Controller } from "react-hook-form";
import { UserInputData } from './ShunkoFrame';
import Toggle, { TOGGLE_STATES } from '../Toggle'
import { Contents, FormTitle } from '../css/CSS.js';
import { useNavigate } from 'react-router-dom';
import useAuthAxios from "../../useAuthAxios";
import { ConfirmationContext } from "../../Confirmation";
import LoadingDialog from '../Loading';

export default function SetumeiHikiwatasi(props) {

    //各コンポーネントの動作を設定
    const { currentState, setCurrentState } = useContext(UserInputData);
    const { control, handleSubmit, getValues, formState: { errors }, watch } = useForm({
        defaultValues: { ...currentState.setumei, ...currentState.hikiwatasi },
        mode: 'onChange'
    });

    const [formNext, setFormNext] = useState(false)
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (formNext === true) {
                props.handleNext();
            }
        }
        setFormNext(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formNext]);


    const [formBack, setFormBack] = useState(false)
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (formBack === true) {
                props.handleBack();
            }
        }
        setFormBack(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formBack]);
    const { kojiCD, cutTeimei, changeTeimei } = useContext(ConfirmationContext);
    const authAxios = useAuthAxios();
    const navigate = useNavigate();

    const [formPost, setFormPost] = useState(false)
    const [processing_post, setProcessing_post] = useState(false);
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            if (formPost === true) {
                setProcessing_post(true);
                authAxios
                    .post('/api/confirmation/shunko/' + kojiCD + "?order=update", currentState)
                    .then(response => {
                        if (response.status === 200) {
                            navigate("/confirmation/list");
                            setProcessing_post(false);
                        }
                    }).catch((response) => {
                        alert("保存に失敗しました。", response.body);
                        setProcessing_post(false);
                    })
            }
        }
        setFormPost(false);
        setTimeout(() => {
            setProcessing_post(false);
        }, 10000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formPost]);

    const onSubmit = (action) => {

        const setumei = {
            "tokuteiHoshu": getValues("tokuteiHoshu"),
            "tokuteiHoshuUmu": getValues("tokuteiHoshuUmu"),
            "tokuteiYokusituDenkiKanso": getValues("tokuteiYokusituDenkiKanso"),
            "tokuteiBuiltinShokkiArai": getValues("tokuteiBuiltinShokkiArai"),
            "tokuteiShitunaiGasYuwakasi": getValues("tokuteiShitunaiGasYuwakasi"),
            "tokuteiSekiyuKyuto": getValues("tokuteiSekiyuKyuto"),
            "tokuteiFFSekiyuOnpuDanbou": getValues("tokuteiFFSekiyuOnpuDanbou"),
            "okyakuHosyuTenken": getValues("okyakuHosyuTenken"),
            "okyakuBoukaDamper": getValues("okyakuBoukaDamper"),
            "okyakuAnnzen": getValues("okyakuAnnzen"),
            "okyakuBlindRoll": getValues("okyakuBlindRoll"),

        };

        const hikiwatasi = {
            "kojiKanriHoukokusho": getValues("kojiKanriHoukokusho"),
            "kojiKanriHoukokushoJyotoWatasi": getValues("kojiKanriHoukokushoJyotoWatasi"),
            "kojiKanriHoukokushoShunkoWatasi": getValues("kojiKanriHoukokushoShunkoWatasi"),
            "kojiKanriKanryouHoukokusho": getValues("kojiKanriKanryouHoukokusho"),
            "setubiKikiSetumeisho": getValues("setubiKikiSetumeisho"),
        };

        if (action === "post") {
            setCurrentState({ ...currentState, setumei, hikiwatasi, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
            setFormPost(true);
        } else if (action === "next") {
            setCurrentState({ ...currentState, setumei, hikiwatasi, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
            setFormNext(true)
        } else if (action === "back") {
            setCurrentState({ ...currentState, setumei, hikiwatasi, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
            setFormBack(true)
        }
    };

    return (
        <Contents component="main" sx={{ pt: 1 }}>
            <form onSubmit={handleSubmit(onSubmit)} >
                <Grid container columns={12} columnSpacing={{ xs: 1, sm: 3, md: 3 }}>
                    <Grid>
                        <FormTitle>
                            説明事項
                        </FormTitle>
                    </Grid>
                </Grid>
                <Grid container columns={16} sx={{ pt: 0.8 }} >
                    <Grid item xs={3} sx={{ pl: 0.5 }}>
                        <Controller
                            control={control}
                            name="tokuteiHoshu"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("tokuteiHoshu") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >特定保守製品説明</Typography>} labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={2.5}>
                        <Controller
                            control={control}
                            name="tokuteiHoshuUmu"
                            render={({ field: { value, onChange } }) => (
                                <Toggle
                                    setCheckedToggle={onChange}
                                    checkedToggle={value}
                                    toggleValue={"無"}
                                    toggleSts={TOGGLE_STATES.One}
                                    toggleValue2={"有"}
                                    toggleSts2={TOGGLE_STATES.Two}
                                    disabled={watch("tokuteiHoshu") === 2 ? true : false}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={16} alignContent="flex-start">
                        <Grid container columns={16} columnSpacing={{ xs: 1, sm: 2, md: 1 }} sx={{ transform: "scale(1.1)", pt: 1, pl: 8.5 }}>
                            <Grid item xs={2.8} >
                                <Controller
                                    control={control}
                                    name="tokuteiYokusituDenkiKanso"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("tokuteiHoshu") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("tokuteiHoshu") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("tokuteiYokusituDenkiKanso") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >浴室用電気乾燥機</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4.2} >
                                <Controller
                                    control={control}
                                    name="tokuteiBuiltinShokkiArai"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("tokuteiHoshu") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("tokuteiHoshu") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("tokuteiBuiltinShokkiArai") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >ビルトイン式電気食器洗い機</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3} >
                                <Controller
                                    control={control}
                                    name="tokuteiShitunaiGasYuwakasi"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("tokuteiHoshu") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("tokuteiHoshu") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("tokuteiShitunaiGasYuwakasi") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >室内ガス瞬間湯沸器</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={2}  >
                                <Controller
                                    control={control}
                                    name="tokuteiSekiyuKyuto"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("tokuteiHoshu") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("tokuteiHoshu") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("tokuteiSekiyuKyuto") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >石油給湯器</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3} >
                                <Controller
                                    control={control}
                                    name="tokuteiFFSekiyuOnpuDanbou"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("tokuteiHoshu") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("tokuteiHoshu") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("tokuteiFFSekiyuOnpuDanbou") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >FF石油温風暖房器</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container columns={16} sx={{ pt: 2 }} >
                    <Grid item xs={4} sx={{ pl: 2 }}>
                        <Controller
                            control={control}
                            name="okyakuHosyuTenken"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 0.5 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("okyakuHosyuTenken") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >お客様による保守点検の説明</Typography>} labelPlacement="end"

                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={16} alignContent="flex-start">
                        <Grid container columns={14} sx={{ transform: "scale(1.1)", pt: 1, pl: 8.6 }}>
                            <Grid item xs={8} >
                                <Controller
                                    control={control}
                                    name="okyakuBoukaDamper"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("okyakuHosyuTenken") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("okyakuHosyuTenken") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("okyakuBoukaDamper") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >防火ダンパー(定期的に防火ダンパーの開放を目視確認お願いします。)</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container columns={16} sx={{ pt: 2 }} >
                    <Grid item xs={4} sx={{ pl: 1 }}>
                        <Controller
                            control={control}
                            name="okyakuAnnzen"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 0.5 }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("okyakuAnnzen") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >安全に関する注意説明</Typography>} labelPlacement="end"

                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={16} alignContent="flex-start">
                        <Grid container columns={14} sx={{ transform: "scale(1.1)", pt: 1, pl: 8.6 }}>
                            <Grid item xs={8} >
                                <Controller
                                    control={control}
                                    name="okyakuBlindRoll"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("okyakuAnnzen") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("okyakuAnnzen") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("okyakuBlindRoll") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >ブラインド・ロールカーテン、ロール網戸の操作ひも取り扱い</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container columns={12} columnSpacing={{ xs: 1, sm: 3, md: 3 }}>
                    <Grid>
                        <FormTitle>
                            お引渡し書類
                        </FormTitle>
                    </Grid>
                </Grid>
                <Grid container columns={12} sx={{}}>
                    <Grid item xs={2.3} >
                        <Controller
                            control={control}
                            name="kojiKanriHoukokusho"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1.5, }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("kojiKanriHoukokusho") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >工事監理報告書</Typography>} labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={16} alignContent="flex-start">
                        <Grid container columns={16} columnSpacing={{ xs: 1, sm: 2, md: 1 }} sx={{ transform: "scale(1.1)", pt: 1, pl: 8.5 }}>
                            <Grid item xs={1.5} >
                                <Controller
                                    control={control}
                                    name="kojiKanriHoukokushoJyotoWatasi"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("kojiKanriHoukokusho") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("kojiKanriHoukokusho") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("kojiKanriHoukokushoJyotoWatasi") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >上棟</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={1.2} >
                                <Controller
                                    control={control}
                                    name="kojiKanriHoukokushoShunkoWatasi"
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    setCheckedvalue={onChange}
                                                    checkedvalue={value}
                                                    color='primary'
                                                    disabled={watch("kojiKanriHoukokusho") === 2 ? true : false}
                                                />
                                            }
                                            label={<Typography sx={{ pl: 1 }}
                                                style={watch("kojiKanriHoukokusho") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                    : watch("kojiKanriHoukokushoShunkoWatasi") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                                        : {}
                                                }
                                            >竣工</Typography>}
                                            labelPlacement="end"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4} sx={{}}>
                                <Typography sx={{}}
                                    style={watch("kojiKanriHoukokusho") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                        : watch("kojiKanriHoukokushoJyotoWatasi") === 2 && watch("kojiKanriHoukokushoShunkoWatasi") === 2 ? { textDecoration: "line-through", color: "#BBBBBB" }
                                            : {}
                                    }
                                >お渡しさせて頂きました。</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container columns={12} sx={{ pl: 1.2 }}>
                    <Grid item xs={2.8} >
                        <Controller
                            control={control}
                            name="kojiKanriKanryouHoukokusho"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1.5, }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("kojiKanriKanryouHoukokusho") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >工事監理業務完了報告書</Typography>} labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={4} sx={{ pt: 1.8 }}>
                        <Typography sx={{ transform: "scale(1.1)", pl: 1 }}
                            style={
                                watch("kojiKanriKanryouHoukokusho") === 2
                                    ? { textDecoration: "line-through", color: "#BBBBBB" }
                                    : {}
                            }
                        >お渡しさせて頂きました。</Typography>
                    </Grid>
                </Grid>
                <Grid container columns={12} sx={{ pt: 1, pl: 1.2 }}>
                    <Grid item xs={2.8} >
                        <Controller
                            control={control}
                            name="setubiKikiSetumeisho"
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    sx={{ transform: "scale(1.3)", pt: 1.5, }}
                                    control={
                                        <CheckBox
                                            setCheckedvalue={onChange}
                                            checkedvalue={value}
                                            color='primary'
                                        />
                                    }
                                    label={<Typography sx={{ pl: 1 }}
                                        style={watch("setubiKikiSetumeisho") === 2 ? { textDecoration: "line-through" } : {}
                                        }
                                    >設備機器説明書(保証書)</Typography>} labelPlacement="end"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={4} sx={{ pt: 1.8 }}>
                        <Typography sx={{ transform: "scale(1.1)", pl: 1 }}
                            style={
                                watch("setubiKikiSetumeisho") === 2 ?
                                    { textDecoration: "line-through", color: "#BBBBBB" }
                                    : {}
                            }
                        >お渡しさせて頂きました。</Typography>
                    </Grid>
                </Grid>
                <Grid container columns={12} rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 3 }}>
                    <Grid container columns={16} rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 6 }}>
                        <Grid item xs={12} >
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("post")} disabled={processing_post}>
                                {processing_post ? "処理中" : "保存して一覧へ"}
                            </Button>
                            <LoadingDialog load={processing_post} />
                        </Grid>
                        <Grid item xs={2} sx={{ pr: 1 }}>
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("back")} >
                                戻る
                            </Button>
                        </Grid>
                        <Grid item xs={1.5} sx={{ pl: 1 }}>
                            <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("next")}>
                                次へ
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>
            </form >
        </Contents >
    );
}