import React, { useState, useContext } from 'react';
import { useForm } from "react-hook-form";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import SignatureCustomer from '../signature_Jyoto/SignatureCustomer';
import SignatureSalesStaff from '../signature_Jyoto/SignatureSalesStaff';
import SignatureDesigner from '../signature_Jyoto/SignatureDesigner';
import SignatureConstructionManeger from '../signature_Jyoto/SignatureConstructionManeger';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import useAuthAxios from "../../useAuthAxios";
import { AuthContext } from "../../Auth";
import { ConfirmationContext } from "../../Confirmation";
import { Contents } from '../css/CSS.js';
import LoadingDialog from '../Loading';
import { UserInputData } from './JyotoFrame';
import Typography from '@mui/material/Typography';

export default function Review(props) {
    const { handleSubmit } = useForm({});
    const { currentState, setCurrentState } = useContext(UserInputData);
    const { auth } = useContext(AuthContext);
    const authAxios = useAuthAxios();
    const navigate = useNavigate();
    const { kojiCD, cutTeimei, changeTeimei, signJyotoCustomer } = useContext(ConfirmationContext);
    const headers = {
        "Authorization": "Bearer " + auth,
        'Content-Type': 'application/json',
    };
    const [processing_post, setProcessing_post] = useState(false);
    const onSubmit = (action) => {

        if (action === "post") {
            setCurrentState({ ...currentState, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
            navigate("/confirmation/jyoto/jyoto");
        } else if (action === "back") {
            setCurrentState({ ...currentState, "reportKojiName": cutTeimei, "reportKojiNameHonorific": changeTeimei });
            props.handleBack();
        }
    }

    const [pdf, setPdf] = React.useState()
    React.useEffect(() => {
        setProcessing_post(true)
        authAxios
            .get('api/confirmation/jyoto/' + kojiCD + '/report?order=0', { headers })
            .then(response => {
                const data = response.data;
                const pdfdata = JSON.parse((JSON.stringify(data)))
                setPdf("data:application/pdf;base64," + pdfdata.confirmationPdfData64)
                setProcessing_post(false)
            });
        setTimeout(() => {
            setProcessing_post(false);
        }, 10000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const inputGaibu = {
        kiroku: currentState.kiroku,
        yaneHafuIro: currentState.gaibu.yaneHafuIro,
        mizukiriIro: currentState.gaibu.mizukiriIro,
        mizukiriDodai: currentState.gaibu.mizukiriDodai,
        mizukiriMadoue: currentState.gaibu.mizukiriMadoue,
        mizukiriMadosita: currentState.gaibu.mizukiriMadosita,
        mizukiriChukan: currentState.gaibu.mizukiriChukan,
        mizkiriGenkanDoorSita: currentState.gaibu.mizkiriGenkanDoorSita,
        kabeMadoIro: currentState.gaibu.kabeMadoIro,
        kabeMadoKeshowaku: currentState.gaibu.kabeMadoKeshowaku,
        kabeMadoPouchBasira: currentState.gaibu.kabeMadoPouchBasira,
        kabeMadoMadokibu: currentState.gaibu.kabeMadoMadokibu,
        kabeMadoMokuseiBalcony: currentState.gaibu.kabeMadoMokuseiBalcony,
        kabeMadoTukebasira: currentState.gaibu.kabeMadoTukebasira,
        kabeMadoHenko: currentState.gaibu.kabeMadoHenko,
        gaikoKoji: currentState.gaibu.gaikoKoji,
        gaikoKojiUmu: currentState.gaibu.gaikoKojiUmu,
        gaikoSetubiToriai: currentState.gaibu.gaikoSetubiToriai,
        toriaiSansuisen: currentState.gaibu.toriaiSansuisen,
        toriaiRissuisen: currentState.gaibu.toriaiRissuisen,
        toriaiHaisuiMas: currentState.gaibu.toriaiHaisuiMas,
        toriaiAirconSitugaiki: currentState.gaibu.toriaiAirconSitugaiki,
        toriaiJB: currentState.gaibu.toriaiJB,
        toriaiBosuiConcent: currentState.gaibu.toriaiBosuiConcent,
        meterIchi: currentState.gaibu.meterIchi,
        meterIchiDenki: currentState.gaibu.meterIchiDenki,
        meterIchiSuido: currentState.gaibu.meterIchiSuido,
        meterIchiGas: currentState.gaibu.meterIchiGas,
        masIchiTakasa: currentState.gaibu.masIchiTakasa,
        kansenHikikomi: currentState.gaibu.kansenHikikomi,
    }
    const inputNaibu = {
        yukaZai: currentState.naibu.yukaZai,
        yukaHenkoUmu: currentState.naibu.yukaHenkoUmu,
        peeling: currentState.naibu.peeling,
        peelingHenkoUmu: currentState.naibu.peelingHenkoUmu,
        sagariTenjo: currentState.naibu.sagariTenjo,
        fukasiKabe: currentState.naibu.fukasiKabe,
        pipeSpace: currentState.naibu.pipeSpace,
        tobiraIchiHiraki: currentState.naibu.tobiraIchiHiraki,
        shunoZosaku: currentState.naibu.shunoZosaku,
        makuradanaCounter: currentState.naibu.makuradanaCounter,
        hannyukeiro: currentState.naibu.hannyukeiro,
        reizoko: currentState.naibu.reizoko,
        sentakuki: currentState.naibu.sentakuki,
        kagu: currentState.naibu.kagu,
        piano: currentState.naibu.piano,
    }
    const inputSetubi = {
        kitchen: currentState.setubi.kitchen,
        ub: currentState.setubi.ub,
        senmenKeshoDai: currentState.setubi.senmenKeshoDai,
        toilet: currentState.setubi.toilet,
        seihonzuHenkoUmu: currentState.setubi.seihonzuHenkoUmu,
        denkiSetubiKoji: currentState.setubi.denkiSetubiKoji,
        denkiBundenban: currentState.setubi.denkiBundenban,
        denkiSwConKasai: currentState.setubi.denkiSwConKasai,
        denkiTvLanTelHaisen: currentState.setubi.denkiTvLanTelHaisen,
        denkiKeiyakuYoryo: currentState.setubi.denkiKeiyakuYoryo,
        denkiKeiyakuYoryoAmps: currentState.setubi.denkiKeiyakuYoryoAmps,
        denkiJiyukaPlanSetumei: currentState.setubi.denkiJiyukaPlanSetumei,
        suidoSetubiKoji: currentState.setubi.suidoSetubiKoji,
        remoconAccessory: currentState.setubi.remoconAccessory,
        gasKoji: currentState.setubi.gasKoji,
        gasOven: currentState.setubi.gasOven,
        gasKaran: currentState.setubi.gasKaran,
        kankiKoji: currentState.setubi.kankiKoji,
        kuuchoAircon: currentState.setubi.kuuchoAircon,
        securityKoji: currentState.setubi.securityKoji,
        danbouKoji: currentState.setubi.danbouKoji,
        taiyokoKoji: currentState.setubi.taiyokoKoji,
        jukyuKeiyakuMosikomisho: currentState.setubi.jukyuKeiyakuMosikomisho,
        ninteiTuchisho: currentState.setubi.ninteiTuchisho,
    }
    const inputTsuikaKojii = {
        tuikaHenkoKoji: currentState.tuikaHenko.tuikaHenkoKoji,
        kojiKanriHoukokusho: currentState.kojiKanriHoukokusho.kojiKanriHoukokusho,
        kojiKanriHoukokushoWatasiHaikin: currentState.kojiKanriHoukokusho.kojiKanriHoukokushoWatasiHaikin,
        kojiKanriHoukokushoWatasiJyoto: currentState.kojiKanriHoukokusho.kojiKanriHoukokushoWatasiJyoto,
    }
    const [checkGaibuCheck, setCheckGaibuCheck] = useState(0)
    const [checkNaibuCheck, setCheckNaibuCheck] = useState(0)
    const [checkSetubiCheck, setCheckSetubiCheck] = useState(0)
    const [checkTsuikaKojiiCheck, setCheckTsuikaKojiiCheck] = useState(0)

    React.useEffect(() => {

        if (inputGaibu.kiroku === 0) { setCheckGaibuCheck(checkGaibuCheck + 1) }
        if (inputGaibu.yaneHafuIro === 0) { setCheckGaibuCheck(checkGaibuCheck + 1) }
        if (inputGaibu.mizukiriIro === 0) {
            setCheckGaibuCheck(checkGaibuCheck + 1)
        } else if (inputGaibu.mizukiriIro === 1) {
            if (inputGaibu.mizukiriDodai === 0) { setCheckGaibuCheck(checkGaibuCheck + 1) }
            if (inputGaibu.mizukiriMadoue === 0) { setCheckGaibuCheck(checkGaibuCheck + 1) }
            if (inputGaibu.mizukiriMadosita === 0) { setCheckGaibuCheck(checkGaibuCheck + 1) }
            if (inputGaibu.mizukiriChukan === 0) { setCheckGaibuCheck(checkGaibuCheck + 1) }
            if (inputGaibu.mizkiriGenkanDoorSita === 0) { setCheckGaibuCheck(checkGaibuCheck + 1) }
        }
        if (inputGaibu.kabeMadoIro === 0) {
            setCheckGaibuCheck(checkGaibuCheck + 1)
        } else if (inputGaibu.kabeMadoIro === 1) {
            if (inputGaibu.kabeMadoKeshowaku === 0) { setCheckGaibuCheck(checkGaibuCheck + 1) }
            if (inputGaibu.kabeMadoPouchBasira === 0) { setCheckGaibuCheck(checkGaibuCheck + 1) }
            if (inputGaibu.kabeMadoMadokibu === 0) { setCheckGaibuCheck(checkGaibuCheck + 1) }
            if (inputGaibu.kabeMadoMokuseiBalcony === 0) { setCheckGaibuCheck(checkGaibuCheck + 1) }
            if (inputGaibu.kabeMadoTukebasira === 0) { setCheckGaibuCheck(checkGaibuCheck + 1) }
            if (inputGaibu.kabeMadoHenko === 0) { setCheckGaibuCheck(checkGaibuCheck + 1) }
        }
        if (inputGaibu.gaikoKoji === 0) {
            setCheckGaibuCheck(checkGaibuCheck + 1)
        } else if (inputGaibu.gaikoKoji === 1) {
            if (inputGaibu.gaikoKojiUmu === 0) { setCheckGaibuCheck(checkGaibuCheck + 1) }
        }
        if (inputGaibu.gaikoSetubiToriai === 0) {
            setCheckGaibuCheck(checkGaibuCheck + 1)
        } else if (inputGaibu.gaikoSetubiToriai === 1) {
            if (inputGaibu.toriaiSansuisen === 0) { setCheckGaibuCheck(checkGaibuCheck + 1) }
            if (inputGaibu.toriaiRissuisen === 0) { setCheckGaibuCheck(checkGaibuCheck + 1) }
            if (inputGaibu.toriaiHaisuiMas === 0) { setCheckGaibuCheck(checkGaibuCheck + 1) }
            if (inputGaibu.toriaiAirconSitugaiki === 0) { setCheckGaibuCheck(checkGaibuCheck + 1) }
            if (inputGaibu.toriaiJB === 0) { setCheckGaibuCheck(checkGaibuCheck + 1) }
            if (inputGaibu.toriaiBosuiConcent === 0) { setCheckGaibuCheck(checkGaibuCheck + 1) }
        }
        if (inputGaibu.meterIchi === 0) {
            setCheckGaibuCheck(checkGaibuCheck + 1)
        } else if (inputGaibu.meterIchi === 1) {
            if (inputGaibu.meterIchiDenki === 0) { setCheckGaibuCheck(checkGaibuCheck + 1) }
            if (inputGaibu.meterIchiSuido === 0) { setCheckGaibuCheck(checkGaibuCheck + 1) }
            if (inputGaibu.meterIchiGas === 0) { setCheckGaibuCheck(checkGaibuCheck + 1) }
        }
        if (inputGaibu.masIchiTakasa === 0) { setCheckGaibuCheck(checkGaibuCheck + 1) }
        if (inputGaibu.kansenHikikomi === 0) { setCheckGaibuCheck(checkGaibuCheck + 1) }

        if (inputNaibu.yukaZai === 0) { setCheckNaibuCheck(checkNaibuCheck + 1) }
        else if (inputNaibu.yukaZai === 1) {
            if (inputNaibu.yukaHenkoUmu === 0) { setCheckNaibuCheck(checkNaibuCheck + 1) }
        }
        if (inputNaibu.peeling === 0) { setCheckNaibuCheck(checkNaibuCheck + 1) }
        else if (inputNaibu.peeling === 1) {
            if (inputNaibu.peelingHenkoUmu === 0) { setCheckNaibuCheck(checkNaibuCheck + 1) }
        }
        if (inputNaibu.sagariTenjo === 0) { setCheckNaibuCheck(checkNaibuCheck + 1) }
        if (inputNaibu.fukasiKabe === 0) { setCheckNaibuCheck(checkNaibuCheck + 1) }
        if (inputNaibu.pipeSpace === 0) { setCheckNaibuCheck(checkNaibuCheck + 1) }
        if (inputNaibu.tobiraIchiHiraki === 0) { setCheckNaibuCheck(checkNaibuCheck + 1) }
        if (inputNaibu.shunoZosaku === 0) { setCheckNaibuCheck(checkNaibuCheck + 1) }
        if (inputNaibu.makuradanaCounter === 0) { setCheckNaibuCheck(checkNaibuCheck + 1) }
        if (inputNaibu.hannyukeiro === 0) { setCheckNaibuCheck(checkNaibuCheck + 1) }
        if (inputNaibu.reizoko === 0) { setCheckNaibuCheck(checkNaibuCheck + 1) }
        if (inputNaibu.sentakuki === 0) { setCheckNaibuCheck(checkNaibuCheck + 1) }
        if (inputNaibu.kagu === 0) { setCheckNaibuCheck(checkNaibuCheck + 1) }
        if (inputNaibu.piano === 0) { setCheckNaibuCheck(checkNaibuCheck + 1) }

        if (inputSetubi.kitchen === 0) { setCheckSetubiCheck(checkSetubiCheck + 1) }
        if (inputSetubi.ub === 0) { setCheckSetubiCheck(checkSetubiCheck + 1) }
        if (inputSetubi.senmenKeshoDai === 0) { setCheckSetubiCheck(checkSetubiCheck + 1) }
        if (inputSetubi.toilet === 0) { setCheckSetubiCheck(checkSetubiCheck + 1) }
        if (inputSetubi.seihonzuHenkoUmu === 0) { setCheckSetubiCheck(checkSetubiCheck + 1) }
        if (inputSetubi.denkiSetubiKoji === 0) { setCheckSetubiCheck(checkSetubiCheck + 1) }
        else if (inputSetubi.denkiSetubiKoji === 1) {
            if (inputSetubi.denkiBundenban === 0) { setCheckSetubiCheck(checkSetubiCheck + 1) }
            if (inputSetubi.denkiSwConKasai === 0) { setCheckSetubiCheck(checkSetubiCheck + 1) }
            if (inputSetubi.denkiTvLanTelHaisen === 0) { setCheckSetubiCheck(checkSetubiCheck + 1) }
            if (inputSetubi.denkiKeiyakuYoryo === 0) { setCheckSetubiCheck(checkSetubiCheck + 1) }
            else if (inputSetubi.denkiKeiyakuYoryo === 1) {
                if (inputSetubi.denkiKeiyakuYoryoAmps === 0) { setCheckSetubiCheck(checkSetubiCheck + 1) }
            }
            if (inputSetubi.denkiJiyukaPlanSetumei === 0) { setCheckSetubiCheck(checkSetubiCheck + 1) }
        }
        if (inputSetubi.suidoSetubiKoji === 0) { setCheckSetubiCheck(checkSetubiCheck + 1) }
        if (inputSetubi.remoconAccessory === 0) { setCheckSetubiCheck(checkSetubiCheck + 1) }
        if (inputSetubi.gasKoji === 0) { setCheckSetubiCheck(checkSetubiCheck + 1) }
        if (inputSetubi.gasOven === 0) { setCheckSetubiCheck(checkSetubiCheck + 1) }
        if (inputSetubi.gasKaran === 0) { setCheckSetubiCheck(checkSetubiCheck + 1) }
        if (inputSetubi.kankiKoji === 0) { setCheckSetubiCheck(checkSetubiCheck + 1) }
        if (inputSetubi.kuuchoAircon === 0) { setCheckSetubiCheck(checkSetubiCheck + 1) }
        if (inputSetubi.securityKoji === 0) { setCheckSetubiCheck(checkSetubiCheck + 1) }
        if (inputSetubi.danbouKoji === 0) { setCheckSetubiCheck(checkSetubiCheck + 1) }
        if (inputSetubi.taiyokoKoji === 0) { setCheckSetubiCheck(checkSetubiCheck + 1) }
        if (inputSetubi.jukyuKeiyakuMosikomisho === 0) { setCheckSetubiCheck(checkSetubiCheck + 1) }
        if (inputSetubi.ninteiTuchisho === 0) { setCheckSetubiCheck(checkSetubiCheck + 1) }

        if (inputTsuikaKojii.tuikaHenkoKoji === 0) { setCheckTsuikaKojiiCheck(checkTsuikaKojiiCheck + 1) }
        if (inputTsuikaKojii.kojiKanriHoukokusho === 0) { setCheckTsuikaKojiiCheck(checkTsuikaKojiiCheck + 1) }
        else if (inputTsuikaKojii.kojiKanriHoukokusho === 1) {
            if (inputTsuikaKojii.kojiKanriHoukokushoWatasiHaikin === 0) { setCheckTsuikaKojiiCheck(checkTsuikaKojiiCheck + 1) }
            if (inputTsuikaKojii.kojiKanriHoukokushoWatasiJyoto === 0) { setCheckTsuikaKojiiCheck(checkTsuikaKojiiCheck + 1) }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            {checkGaibuCheck === 0 && checkNaibuCheck === 0 && checkSetubiCheck === 0 && checkTsuikaKojiiCheck === 0 ?
                <Contents>
                    <Grid sx={{ pt: 3, pb: 1 }}>
                        <Card sx={{ minWidth: 300, boxShadow: 0 }}>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={3} >
                                        <SignatureCustomer />
                                        {signJyotoCustomer === false ?
                                            <Typography sx={{ color: "#FF0000", pt: 1 }} >
                                                お客様サインは必須です
                                            </Typography> : ""
                                        }
                                    </Grid>
                                    <Grid item xs={3} >
                                        <SignatureSalesStaff />
                                    </Grid>
                                    <Grid item xs={3} >
                                        <SignatureDesigner />
                                    </Grid>
                                    <Grid item xs={3} >
                                        <SignatureConstructionManeger />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Card sx={{ width: "100%", p: 1, boxShadow: 0, border: 1, borderColor: "#EEEEEE" }} >
                        <Grid container alignItems="center" justify="center" sx={{ pt: 1 }}>
                            <Grid item xs={12}>
                                <embed type="application/pdf" src={pdf} width="100%" height="1000px" />
                            </Grid>
                        </Grid>
                    </Card>
                    <Grid item xs={12} sx={{ pt: 2 }}>
                        {signJyotoCustomer === true ?
                            <Card sx={{ boxShadow: 0 }}>
                                <CardContent style={{ display: 'flex', paddingBottom: 0, justifyContent: 'end', alignItems: 'end' }} >
                                    <form onSubmit={handleSubmit(onSubmit)} >
                                        <Grid container columnSpacing={{ xs: 1, sm: 3, md: 3 }}>
                                            <Grid item xs={6} >
                                                <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("back")} >
                                                    戻る
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("post")} disabled={processing_post}>
                                                    {processing_post ? "処理中" : "作成"}
                                                </Button>
                                                <LoadingDialog load={processing_post} />
                                            </Grid>
                                        </Grid>
                                    </form>
                                </CardContent>
                            </Card> :
                            <Card sx={{ boxShadow: 0 }}>
                                <CardContent style={{ display: 'flex', paddingBottom: 0, justifyContent: 'end', alignItems: 'end' }} >
                                    <form onSubmit={handleSubmit(onSubmit)} >
                                        <Grid container columnSpacing={{ xs: 1, sm: 3, md: 3 }}>
                                            <Grid item xs={6} >
                                                <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("back")} >
                                                    戻る
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button variant="contained" color="primary" size="large" type="button" disabled={true}>
                                                    作成
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </CardContent>
                            </Card>
                        }
                    </Grid>
                </Contents> :
                <Contents component="main" >
                    <Grid container columns={12} rowSpacing={4} columnSpacing={{ xs: 1, sm: 3, md: 3 }} sx={{ pt: 3 }}>
                        <Grid item xs={12} sx={{ transform: "scale(1.5)" }}>
                            <Typography sx={{ color: "#FF0000", pl: 20 }}>
                                入力が不十分です。全てのチェック項目を入力してください。
                            </Typography>
                        </Grid>
                        {checkGaibuCheck > 0 ?
                            <Grid item xs={12} sx={{ transform: "scale(1.3)" }}>
                                <Typography sx={{ pl: 15 }}>
                                    外部確認：未入力あり
                                </Typography>
                            </Grid> : null
                        }
                        {checkNaibuCheck > 0 ?
                            <Grid item xs={12} sx={{ transform: "scale(1.3)" }}>
                                <Typography sx={{ pl: 15 }}>
                                    内部確認：未入力あり
                                </Typography>
                            </Grid> : null
                        }
                        {checkSetubiCheck > 0 ?
                            <Grid item xs={12} sx={{ transform: "scale(1.3)" }}>
                                <Typography sx={{ pl: 15 }}>
                                    設備工事の確認：未入力あり
                                </Typography>
                            </Grid> : null
                        }
                        {checkTsuikaKojiiCheck > 0 ?
                            <Grid item xs={12} sx={{ transform: "scale(1.3)" }}>
                                <Typography sx={{ pl: 15 }}>
                                    追加・変更工事、工事監理報告書の確認：未入力あり
                                </Typography>
                            </Grid> : null
                        }
                        <Grid item xs={12} >
                            <Card sx={{ boxShadow: 0 }}>
                                <CardContent style={{ display: 'flex', paddingBottom: 0, justifyContent: 'end', alignItems: 'end' }} >
                                    <form onSubmit={handleSubmit(onSubmit)} >
                                        <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("back")} >
                                            戻る
                                        </Button>
                                    </form>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sx={{}}>
                            <Card sx={{ width: "100%", p: 1, boxShadow: 0, border: 1, borderColor: "#EEEEEE" }} >
                                <Grid container alignItems="center" justify="center" >
                                    <Grid item xs={12}>
                                        <embed type="application/pdf" src={pdf} width="100%" height="1000px" />
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} >
                        <Card sx={{ boxShadow: 0 }}>
                            <CardContent style={{ display: 'flex', paddingBottom: 0, justifyContent: 'end', alignItems: 'end' }} >
                                <form onSubmit={handleSubmit(onSubmit)} >
                                    <Button variant="contained" color="primary" size="large" type="button" onClick={() => onSubmit("back")} >
                                        戻る
                                    </Button>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Contents>
            }
        </React.Fragment >
    );
}