import React from 'react';
import './css/NavMenu.css';
import Menu from "./Menu"
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useLocation } from "react-router-dom"
import packageJson from '../../package.json';
import { getCookie } from "./Action/HandleCookie"
import Grid from '@mui/material/Grid';

export default function NavMenu() {
    const Location = useLocation();
    const [pageName, setPageName] = React.useState("");
    const [userName, setuserName] = React.useState("");
    const [shozokuName, setShozokuName] = React.useState("");

    React.useLayoutEffect(() => {
        setuserName(getCookie("userName"))
        setShozokuName(getCookie("userShitenName") + " " + getCookie("userShozokuName"))
        if (Location.pathname === "/") {
            setPageName("")
            setuserName("")
            setShozokuName("")
        }
        else if (Location.pathname === "/top") { setPageName("業務選択") }
        else if (Location.pathname === "/digitalconstract/list") { setPageName("請負契約書一覧") }
        else if (Location.pathname === "/digitalconstract") { setPageName("請負契約書") }
        else if (Location.pathname === "/digitalconstract/customerconfirm") { setPageName("請負契約書 ご署名") }
        else {
            setPageName("")
        }

    }, [Location.pathname]);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" >
                <Toolbar >
                    {Location.pathname !== "/" ?
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ transform: "scale(1.5)", mt: 1 }}
                        >
                            <Menu />
                        </IconButton>
                        : null
                    }
                    <img src={`${process.env.PUBLIC_URL}/Logo/logo.png`} alt="logo" />
                    <Typography variant="h5" component="div" sx={{ pl: 3, flexGrow: 2.5 }}>
                        電子サイン
                    </Typography>
                    <Typography variant="h5" component="div" sx={{ flexGrow: 3.5 }} >
                        {pageName}
                    </Typography>
                    <Typography variant="caption" component="div">
                        <Grid container justifyContent="end">
                            <Grid item xs={12}>
                                <Typography variant="caption" component="div" >
                                    {shozokuName ? shozokuName : null}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption" component="div" >
                                    {userName ? userName : null}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Typography>
                    <Typography variant="caption" component="div" style={{ textAlign: 'right' }}>
                        Ver{packageJson.version}
                    </Typography>
                </Toolbar>
            </AppBar>
        </Box >
    );
}