import React, { useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SelectContractTitle, SelectContractContent, SelectContractText, SelectContractCloseButton, ContractTitle, AdvancedStatusButton } from '../../css/CSS.js';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import LoadingDialog from '../../Loading';
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import useAuthAxios from "../../../useAuthAxios";
import InputBase from '@mui/material/InputBase';
import ClearIcon from '@mui/icons-material/Clear';
import { DigitalConstractsContext } from "../../../DigitalConstracts";

export default function DigitalConstractCustomerConfirmationAgreement(props) {
    const navigate = useNavigate()
    const authAxios = useAuthAxios();
    const [processing, setProcessing] = React.useState(false);
    const [customerList, setCustomerList] = React.useState()
    const [agreement, setAgreement] = React.useState(false)
    const [open, setOpen] = React.useState(false);
    const headers = { "Authorization": "Bearer " + localStorage.getItem("Auth") };
    const { kojiCD, seqNo, contractType, setStatusCheck, status } = useContext(DigitalConstractsContext);

    const { control, handleSubmit, getValues, setValue, formState: { errors }, setError, clearErrors } = useForm({
        defaultValues: {
            destMailAddress1: customerList?.customer1Mail ? customerList?.customer1Mail : ""
            , destMailAddress2: customerList?.customer2Mail ? customerList?.customer2Mail : ""
            , destMailAddress3: customerList?.customer3Mail ? customerList?.customer3Mail : ""
            //, destMailAddress4: customerList?.customer4Mail ? customerList?.customer4Mail : ""
        },
        mode: 'onChange'
    });

    const validationRules = {
        destMailAddress1: {
            required: true,
            pattern: { value: /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/, message: 'XXX@XXXで入力してください。' }
        },
        destMailAddress2: {
            required: true,
            pattern: { value: /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/, message: 'XXX@XXXで入力してください。' }
        },
        destMailAddress3: {
            required: true,
            pattern: { value: /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/, message: 'XXX@XXXで入力してください。' }
        },
        //destMailAddress4: {
        //required: true,
        //    pattern: { value: /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/, message: 'XXX@XXXで入力してください。' }
        //},
    }

    const handleClose = () => {
        setOpen(false);
        props.close(false)
        setStatusCheck(true)
        setValue('destMailAddress1', "")
        setValue('destMailAddress2', "")
        setValue('destMailAddress3', "")
    };

    React.useEffect(() => {
        setOpen(props.open);
        setStatusCheck(false)
    }, [props.open]);

    React.useLayoutEffect(() => {
        if (open === true) {
            if (processing === true) { return; }
            setProcessing(true)
            authAxios
                .get('api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + "/info/customer"
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        const customerData = JSON.parse((JSON.stringify(response.data)))
                        setCustomerList(customerData)
                        if (status === "お客様承認依頼中") {
                            setValue('destMailAddress1', customerData.customer1CloudSignDestMail)
                            setValue('destMailAddress2', customerData.customer2CloudSignDestMail)
                            setValue('destMailAddress3', customerData.customer3CloudSignDestMail)
                        } else {
                            setValue('destMailAddress1', customerData.customer1Mail)
                            if (customerData.customer1Name && !customerData.customer1Mail) {
                                setError('destMailAddress1')
                            } else {
                                clearErrors('destMailAddress1')
                            }
                            setValue('destMailAddress2', customerData.customer2Mail)
                            if (customerData.customer2Name && !customerData.customer2Mail) {
                                setError('destMailAddress2')
                            } else {
                                clearErrors('destMailAddress2')
                            }
                            setValue('destMailAddress3', customerData.customer3Mail)
                            if (customerData.customer3Name && !customerData.customer3Mail) {
                                setError('destMailAddress3')
                            } else {
                                clearErrors('destMailAddress3')
                            }
                        }
                    } else if (response.status === 401) {
                        navigate("/");
                    }
                    setProcessing(false)
                }).catch((response) => {
                    alert("お客様情報取得に失敗しました。", response.body);
                    setProcessing(false)
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const onSubmit_Agreement = () => {
        setAgreement(true);
    };

    React.useEffect(() => {
        if (processing === true) { return; }
        if (agreement === true) {
            setProcessing(true)
            setStatusCheck(false)
            authAxios
                .post('api/contracts/'
                    + (contractType === "工事請負契約書" ? "base" : "modfy")
                    + "/" + kojiCD
                    + "/" + seqNo
                    + "/customer/approval"
                    + "?order=request"
                    , getValues()
                    , { headers }
                )
                .then(response => {
                    if (response.status === 200) {
                        setAgreement(false)
                        setOpen(false);
                        props.close(false)
                        setProcessing(false)
                        setStatusCheck(true)
                    }
                }).catch((error) => {
                    if (error.response.status === 401) {
                        if (typeof error.response.data === 'object') {
                            alert("認証情報が正しくありません。")
                        }
                        else {
                            alert("認証情報が正しくありません。\n\n" + error.response.data);
                        }
                        navigate("/");
                    } else if (error.response.status === 500) {
                        alert("クラウドサイン連携に失敗しました。\n\n" + error.response.data.title);
                        setProcessing(false)
                        setAgreement(false)
                    } else {
                        if (typeof error.response.data === 'object') {
                            alert("クラウドサイン連携に失敗しました。");
                        } else {
                            alert("クラウドサイン連携に失敗しました。\n\n" + error.response.data);
                        }
                        setProcessing(false)
                        setAgreement(false)
                    }
                    setProcessing(false)
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agreement]);

    return (
        <React.Fragment>
            <LoadingDialog load={processing} />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="md"
            >
                <form onSubmit={handleSubmit()} >
                    <SelectContractTitle id="alert-dialog-title">
                        <Grid container columns={12} rowSpacing={{ xs: 3 }} columnSpacing={4} >
                            <Grid item xs={11} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                ご本人様確認依頼
                            </Grid>
                            <Grid item xs={1} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                <ClearIcon fontSize="large" onClick={() => handleClose()} />
                            </Grid>
                        </Grid>
                    </SelectContractTitle>
                    <SelectContractContent >
                        <Typography sx={{ pt: 3, pl: 2 }}>
                            サイン済みの契約書に対しご本人様確認を行います。
                        </Typography>
                        <Typography sx={{ pl: 2 }}>
                            クラウドサインからお客様宛にご本人様確認メールを送付しますので、
                        </Typography>
                        <Typography sx={{ pl: 2 }}>
                            下記の氏名・メールアドレスに間違いがないことを確認してください。
                        </Typography>
                        <Typography sx={{ pl: 2 }}>
                            (確認依頼先が複数名の場合は、お一人様ずつ承認するごとに次のお客様へ確認メールが送信されます。
                        </Typography>
                        <Typography sx={{ pl: 2 }}>
                            またご確認完了後、電子サインシステムに反映されるのに最大5分程度掛かる場合があります。)
                        </Typography>
                        {customerList?.customer1Name ?
                            <Grid container columns={12} rowSpacing={{ xs: 2 }} columnSpacing={3} sx={{ pt: 5 }}>
                                <Grid item xs={3} >
                                    <ContractTitle sx={{ ml: 2 }}>
                                        お客様氏名
                                    </ContractTitle>
                                </Grid>
                                <Grid item xs={9} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                    <Typography >
                                        {customerList?.customer1Name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <ContractTitle sx={{ ml: 2, mt: 1.5 }} >
                                        メールアドレス
                                    </ContractTitle>
                                </Grid>
                                <Grid item xs={9}>
                                    <Controller
                                        control={control}
                                        name="destMailAddress1"
                                        rules={validationRules.destMailAddress1}
                                        render={({ field, fieldState }) => (
                                            <SelectContractText sx={{ border: !errors.destMailAddress1 ? "none" : " 1px solid red" }}>
                                                <InputBase
                                                    {...field}
                                                    id="destMailAddress1"
                                                    variant="standard"
                                                    style={{ width: '98%', }}
                                                    error={fieldState.invalid}
                                                    disabled={status === "お客様承認依頼中" ? true : false}
                                                />
                                            </SelectContractText>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            : null
                        }
                        {customerList?.customer2Name ?
                            <Grid container columns={12} rowSpacing={{ xs: 2 }} columnSpacing={3} sx={{ pt: 3 }}>
                                <Grid item xs={3}>
                                    <ContractTitle sx={{ ml: 2 }} >
                                        お客様氏名
                                    </ContractTitle>
                                </Grid>
                                <Grid item xs={9} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                    <Typography >
                                        {customerList?.customer2Name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <ContractTitle sx={{ ml: 2, mt: 1.5 }}>
                                        メールアドレス
                                    </ContractTitle>
                                </Grid>
                                <Grid item xs={9} >
                                    <Controller
                                        control={control}
                                        name="destMailAddress2"
                                        rules={validationRules.destMailAddress2}
                                        render={({ field, fieldState }) => (
                                            <SelectContractText sx={{ border: !errors.destMailAddress2 ? "none" : " 1px solid red" }}>
                                                <InputBase
                                                    {...field}
                                                    id="destMailAddress2"
                                                    variant="standard"
                                                    style={{ width: '98%' }}
                                                    error={fieldState.invalid}
                                                    disabled={status === "お客様承認依頼中" ? true : false}
                                                />
                                            </SelectContractText>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            : null
                        }
                        {customerList?.customer3Name ?
                            <Grid container columns={12} rowSpacing={{ xs: 2 }} columnSpacing={3} sx={{ pt: 3 }}>
                                <Grid item xs={3}>
                                    <ContractTitle sx={{ ml: 2 }} >
                                        お客様氏名
                                    </ContractTitle>
                                </Grid>
                                <Grid item xs={9} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                    <Typography >
                                        {customerList?.customer3Name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <ContractTitle sx={{ ml: 2, mt: 1.5 }}>
                                        メールアドレス
                                    </ContractTitle>
                                </Grid>
                                <Grid item xs={9} >
                                    <Controller
                                        control={control}
                                        name="destMailAddress3"
                                        rules={validationRules.destMailAddress3}
                                        render={({ field, fieldState }) => (
                                            <SelectContractText sx={{ border: !errors.destMailAddress3 ? "none" : " 1px solid red" }}>
                                                <InputBase
                                                    {...field}
                                                    id="destMailAddress3"
                                                    variant="standard"
                                                    style={{ width: '98%' }}
                                                    error={fieldState.invalid}
                                                    disabled={status === "お客様承認依頼中" ? true : false}
                                                />
                                            </SelectContractText>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            : null
                        }
                        {/*{customerList?.customer4Name ?*/}
                        {/*    <Grid container columns={12} rowSpacing={{ xs: 2 }} columnSpacing={3} sx={{ pt: 3 }}>*/}
                        {/*        <Grid item xs={3}>*/}
                        {/*            <ContractTitle sx={{ ml: 2, mt: 1.5 }}>*/}
                        {/*                お客様氏名*/}
                        {/*            </ContractTitle>*/}
                        {/*        </Grid>*/}
                        {/*        <Grid item xs={9} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>*/}
                        {/*            <Typography >*/}
                        {/*                {customerList?.customer4Name}*/}
                        {/*            </Typography>*/}
                        {/*        </Grid>*/}
                        {/*        <Grid item xs={3}>*/}
                        {/*            <ContractTitle sx={{ ml: 2, mt: 1.5 }}>*/}
                        {/*                メールアドレス*/}
                        {/*            </ContractTitle>*/}
                        {/*        </Grid>*/}
                        {/*        <Grid item xs={9} >*/}
                        {/*            <Controller*/}
                        {/*                control={control}*/}
                        {/*                name="destMailAddress4"*/}
                        {/*                rules={validationRules.destMailAddress4}*/}
                        {/*                render={({ field }) => (*/}
                        {/*                    <SelectContractText>*/}
                        {/*                        <InputBase*/}
                        {/*                            {...field}*/}
                        {/*                            id="destMailAddress4"*/}
                        {/*                            variant="standard"*/}
                        {/*                            style={{ width: '98%' }}*/}
                        {/*disabled={status === "お客様承認依頼中" ? true : false}*/}
                        {/*                        />*/}
                        {/*                    </SelectContractText>*/}
                        {/*                )}*/}
                        {/*            />*/}
                        {/*        </Grid>*/}
                        {/*    </Grid>*/}
                        {/*    : null*/}
                        {/*}*/}
                    </SelectContractContent>
                    <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Grid sx={{ mr: 5 }}>
                            <SelectContractCloseButton type="button" onClick={handleClose}>キャンセル<KeyboardReturnIcon sx={{ ml: 1 }} /></SelectContractCloseButton>
                        </Grid >
                        <Grid >
                            <AdvancedStatusButton type="button" sx={{ width: "250px" }} onClick={() => onSubmit_Agreement()}
                                disabled={Object.keys(errors).length === 0 ? false : true}
                            ><MailOutlineIcon sx={{ mr: 1 }} />ご本人様確認</AdvancedStatusButton>
                        </Grid >
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment >
    );
}