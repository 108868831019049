import React from 'react';
import Typography from '@mui/material/Typography';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { ConstructContainer } from './css/CSS.js';

export default function GetPDFError(props) {
    return (
        <ConstructContainer style={{ width: "100%", padding: "0px" }} >
            {props.loading === false ?
                <Grid container
                    style={{
                        border: "2px solid",
                        borderColor: "#2c2c2c",
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <Grid item={12}>
                        <CircularProgress />
                    </Grid>
                </Grid>
                :
                <Grid container
                    style={{
                        border: "2px solid",
                        borderColor: "#2c2c2c",
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <Grid item={1} sx={{ pb: 2 }}>
                        <ReportProblemIcon
                            style={{
                                fontWeight: 400,
                                fontSize: "40px",
                                lineHeight: "44px",
                                color: "#FFF500",

                            }} />
                    </Grid>
                    <Grid item={11} >
                        <Typography
                            style={{
                                fontWeight: 400,
                                fontSize: "36px",
                                lineHeight: "44px",
                                textAlign: "center",
                                color: "#747474"
                            }}
                        >
                            PDF取得に失敗しました
                            <Typography
                                style={{
                                    fontWeight: 400,
                                    fontSize: "20px",
                                    lineHeight: "24px",
                                    textAlign: "center",
                                    color: "#747474"
                                }}
                            >再読み込みしてください
                            </Typography>
                        </Typography>
                    </Grid>
                </Grid>
            }
        </ConstructContainer>

    );
}