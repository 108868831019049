import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import ConfirmationDelList from './ConfirmationDelList';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ConfirmationHeader } from '../css/CSS.js';

export default function ConfirmationFrame() {
    return (
        <React.Fragment>
            <CssBaseline />
            <ConfirmationHeader>
                <Grid sx={{ mt: 5, mb: 1 }}>
                    <Typography component="h1" variant="h5" sx={{ mt: 5 }} >
                        立会確認書一覧
                    </Typography>
                </Grid>
            </ConfirmationHeader>
            <ConfirmationDelList />
        </React.Fragment>
    );
}
